import { ConfigService } from './config.service';
import log from '../../core/logging/logger.service';
import { RequestMethod } from '../url-builder';

/**
 * Base class for a config with a value that gets parsed to Java type {@code T}.
 *
 * @param <T> type of the value
 */
abstract class GenericConfig<T> {

  /** Whether the config has been initialized (either based on OTA data or on default value). */
  private isInitialized = false;

  /** The current value of the config. */
  private value: T;

  /**
   * Maps a string received from the config to the given enum type.
   * If using the string as-is fails, the method retries with the camel-case version of the string.
   * If this also fails, then the method returns `undefined`.
   *
   * @param enumType enum type
   * @param stringValue string value from config
   */
  protected static parseEnumString(enumType: any, stringValue: string): number {
    // Returns the corresponding integer value of the enum.
    const value = enumType[stringValue];
    if (value !== undefined) {
      return value;
    }

    // In Java and RBT Plus enums are usually all-upper-case, e.g. 'AN_ENUM_VALUE'.
    // In Typescript enums values are usually camel-case, e.g. 'AnEnumValue'.
    // In order to be able to use the same set of configs on both,
    // retry with camel-case-ification if initial lookup fails.
    const stringValueCamelCase = ('_' + stringValue)
        .toLocaleLowerCase()
        .replace(/_([^_])([^_]*)/g, function(all, head, tail) { return head.toUpperCase() + tail; });
    log.info('Cannot parse "' + stringValue + '". Retrying with camel-case ' + stringValueCamelCase + '".');

    const valueCamelCase = enumType[stringValueCamelCase];
    return valueCamelCase;
  }

  /**
   * Creates a new instance
   * @param key of the config
   * @param default value for the config
   */
  constructor(private key: string, private defaultValue: T) { }

  /**
   * Returns the current value of the config.
   * <p>
   * The implementation ensures that the value is obtained only once by parsing the config receive over the air.
   * After that a cached version is returned.
   *
   * @return value of the config
   */
  getValue(): T {

    if (!this.isInitialized) {

      // Get the value from the server
      const stringValue = ConfigService.getValue(this.key);

      if (stringValue === undefined) {
        this.value = this.defaultValue;
        log.warn('No OTA config. Using default ' + this.defaultValue);
      } else {

        try {
          // Parse the value from the server
          this.value = this.parseString(stringValue);
        } catch (error) {
          this.value = this.defaultValue;
          log.error('Invalid OTA config ' + stringValue
            + '. Using default ' + this.defaultValue);
        }

        this.isInitialized = true;
      }
    }

    return this.value;
  }

  /**
   * Parses a given string value obtained from the server to the value type of the config.
   *
   * @param stringValue  string value from the server
   * @return the corresponding value type
   */
  abstract parseString(input: string): T;

}

/**
 * A client config with values of type {@code string}.
 */
class StringConfig extends GenericConfig<string> {
  constructor(key: string, defaultValue: string) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): string {
    return stringValue;
  }

  getString(): string {
    return this.getValue();
  }
}

/**
 * A client config with values of type {@code number}.
 */
class NumberConfig extends GenericConfig<number> {
  constructor(key: string, defaultValue: number) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): number {
    return Number(stringValue);
  }

  getNumber(): number {
    return this.getValue();
  }

}

/**
 * A client config with values of type {@code boolean}.
 */
class BooleanConfig extends GenericConfig<boolean> {

  constructor(key: string, defaultValue: boolean) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): boolean {

    if (stringValue === 'true') {
      return true;
    } else if (stringValue === 'false') {
      return false;
    }

    throw new TypeError('Invalid parameter supplied.');
  }

  getBoolean(): boolean {
    return this.getValue();
  }
}

/**
 * Config class to handle enums, it treats enums as numbers.
 */
class EnumConfig extends GenericConfig<number> {

  constructor(key: string, defaultValue: number, private enumType: any) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): number {
    // Returns the corresponding integer value of the enum.
    const value = GenericConfig.parseEnumString(this.enumType, stringValue);

    if (value === undefined) {
      throw new TypeError('Invalid parameter "' + stringValue + '" supplied for enum ' + this.enumType);
    }

    return value;
  }

  getEnum(): number {
    return this.getValue();
  }
}


/**
 * Config class to handle enum array, it treats enums as array of numbers.
 */
class EnumArrayConfig extends GenericConfig<number[]> {

  constructor(key: string, defaultValue: number[], private enumType: any) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): number[] {

    let value = stringValue || '';
    value = value.trim();
    const enumArray: number[] = [];

    value.split(';').forEach(item => {
      item = item.trim();
      const itemValue = GenericConfig.parseEnumString(this.enumType, item);
      if (itemValue !== undefined) {
        enumArray.push(itemValue);
      } else {
        // Unknown values are logged, but otherwise ignored.
        log.error('Ignoring item "' + item + '" supplied for enum ' + this.enumType);
      }
    });

    return enumArray;
  }

  getEnumArray(): number[] {
    return this.getValue();
  }
}



/**
 * A client config with values of duration type.
 * This type is currently represented as number containing the number of milliseconds,
 * but this may change in the future.
 */
class DurationConfig extends GenericConfig<number> {
  constructor(key: string, defaultValue: number, unit: TimeUnit) {
    super(key, unit * defaultValue);
  }

  parseString(stringValue: string): number {
    return Number(stringValue);
  }

  /**
   * Returns the duration in milliseconds.
   * @return the duration in milliseconds
   */
  getDurationInMs(): number {
    return this.getValue();
  }

  /**
   * Returns the duration in the given unit of time.
   * @param unit time out of the result
   * @return duration in the requested unit of time
   */
  getDuration(unit: TimeUnit): number {
    return this.getValue() / unit;
  }
}

/**
 * Client config with values of string array.
 */
class StringArrayConfig extends GenericConfig<string[]> {

  constructor(key: string, defaultValue: string[]) {
    super(key, defaultValue);
  }

  /**
   *  @inheritdoc
   * <p/>
   * @returns empty array for {@code null} or {@code empty} config value
   * */
  parseString(stringValue: string): string[] {

    let value = stringValue || '';
    value = value.trim();

    return value !== '' ? value.split(';') : [];

  }

  getStringArray(): string[] {
    return this.getValue();
  }

}

class RegExpConfig extends GenericConfig<RegExp> {

  constructor(key: string, defaultValue: RegExp) {
    super(key, defaultValue);
  }

  parseString(stringValue: string): RegExp {
    return stringValue === '' ? null : RegExp(stringValue);
  }

  getRegExp(): RegExp {
    return this.getValue();
  }
}

/** Time units based on milliseconds */
export enum TimeUnit {
  MILLISECONDS = 1,
  SECONDS = 1000,
  MINUTES = 60 * 1000,
  HOUR = 60 * 60 * 1000,
  DAY = 24 * 60 * 60 * 1000
}

export enum DeactivationMode {

  /** Deactivation is immediate. WAITING_DEACTIVATION state should not occur or be ignored. */
  IMMEDIATE,

  /** Deactivation takes place at the end of the current payment period. The service continues working until then. */
  END_OF_PERIOD_WORKING,

  /** Deactivation takes place at the end of the current payment period, but the service already stopped working. */
  END_OF_PERIOD_DEAD
}

/**
 * Defines how the user's default setting should be adjusted after the purchase of a tone.
 */
export enum SetAsDefaultMode {


  /** The user is asked if the new tone should be set as default. */
  ASK,

  /** The new tone is always set as new default, while keeping the current IGT. */
  SET,

  /** The current default setting is kept. */
  KEEP
}

/** Defines different types of tiles */
export enum Tiles {
  CALLER_LIST,
  DEFAULT_SETTING,
  GROUP_LIST,
  RECORD_UGC,
  RBT_LIBRARY,
  DAY_OF_WEEK_LIST
}


/**
 * Holds configuration that the client received from the server, typically on startup
 */
export class ClientConfig {

  /** ID of the standard tone (toot-toot). */
  public static defaultVcode = new StringConfig('default.vcode', null);

  /**The maximum amount of RBTs a user can have in his library */
  public static maxTonesInLibrary = new NumberConfig('max.tones.in.library', 10);

  /**
   * If set to {@code true}, we always use and store the refresh token.
   * If set to {@code false}, we use it only if specifically allowed by the user ("stay logged in").
   */
  public static authAlwaysStoreRefreshToken = new BooleanConfig('authentication.always.store.refresh.token', true);

  /** Prefix of all URLs that need authentication. */
  public static authUrlPrefix = new StringConfig('auth.url.path.prefix', '/api-plus-tm/authenticated/');

  /**
   * URL for login using some magic depending on the mobile network.
   * This will work using TMO GANG, VF BBX, whatever... The app does not really care.
   * The only requirement is that in the end (maybe after some redirects) the call returns an OAuth token.
   *
   * The URL can be absolute (with scheme), relative to root context (if starting with slash),
   * or relative to API Plus context.
   *
   * The client will always add its client credentials to the request
   * and use the request method defined by `mobileAuthMethod`.
   *
   * If `null` or empty, mobile login will not be used.
   *
   * @see mobileAuthMethod
   */
  public static mobileAuthUrl = new StringConfig('mobile.authentication.url', null);

  /**
   * HTTP method for mobile authentication used in connection with `mobileAuthUrl`.
   * Can be either `Get` or `Post`. Default is `Post`.
   *
   * @see mobileAuthUrl
   */
  public static mobileAuthMethod = new EnumConfig('mobile.authentication.method', RequestMethod.Post, RequestMethod);

  /**
   * Boolean to enable or disable the silent login (Also depends on the `mobileAuthUrl`)
   * If set to {@code true}, the silent login is allowed
   * If set to {@code false}, the silent login is NOT allowed, we will offer the comfort login to the user
   */
  public static mobileAuthAllowSilentLogin = new BooleanConfig('mobile.authentication.allowSilentLogin', true);

  /**
   * Boolean to enable or disable the auth request adding credentials (Also depends on the `mobileAuthAllowSilentLogin`)
   * See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
   * If set to {@code true}, the parameter withCredentials is set and send in the request with value TRUE
   * If set to {@code false}, the parameter withCredentials is set and send in the request with value FALSE
   */
  public static mobileAuthWithCredentials = new BooleanConfig('mobile.authentication.withCredentials', false);

  /** Expiration time for the profile cache. */
  public static profileCacheExpirationTime = new DurationConfig('profile.cache.expiration.time', 120, TimeUnit.SECONDS);

  /** Expiration time for the profile cache if the profile is in a transitional state. */
  public static profileCacheExpirationTimeTransitional = new DurationConfig(
    'profile.cache.expiration.time.transitional', 10, TimeUnit.SECONDS);

  /** Config key for obtaining the ugc record duration. */
  public static ugcMaximumDuration = new DurationConfig('ugc.maximum.duration', 10, TimeUnit.SECONDS);

  /** Maximum number of user generate content items allowed for a user */
  public static maxNumberOfUgc = new NumberConfig('max.number.of.ugcs', 10);

  /** Shop ID to load pre recorded igt tones **/
  public static igtShopId = new StringConfig('default.igt.shopId', '522');

  /**
   * URL for the image transcoder.
   * The required parameters are a local filename path, width and height.
   * Note that images are not cached, therefore images are generated on the fly for every request.
   * @example ${imageTranscoderUrl}?path=${...}&width=${...}&height=${...}`
   * @see ImagePipe for an implementation
   */
  public static imageTranscoderUrl = new StringConfig('image.server.base', null);

  /**
   * Default phone number country code for the country operating the service.
   * For example "49" for Germany, "43" for Austria, "1" for the US.
   * Used for creating the MSISDN based on non-fully-qualified phone numbers.
   */
  public static countryCode = new StringConfig('country.code', '49');

  /**
   * Trunk prefix in the default country.
   * This is the prefix placed in front of the area code for non-local, non-international calls.
   * For most countries this is "0". Exceptions include the US ("1") and Czech ("", no area codes are used).
   */
  public static trunkPrefix = new StringConfig('msisdn.domestic.trunk.prefix', '0');

  /** Tells the client how deactivation works. */
  public static subscriptionDeactivationMode = new EnumConfig('subscription.deactivation.mode',
    DeactivationMode.END_OF_PERIOD_DEAD, DeactivationMode);

  /** If {@code true}, then suspended users need to reactivate before they can use the app. */
  public static subscriptionBlockSuspendedUsers = new BooleanConfig('subscription.block.suspended.users', false);

  /** Tells the client if it should make use of the tone's purchase options */
  public static usePurchaseOptions = new BooleanConfig('toneDetailPage.usePurchaseOptions', true);

  /** Duration before the user should be notified that a purchased content is expiring. */
  public static contentExpireNoticePeriod = new DurationConfig('content.expire.notice.period', 5, TimeUnit.DAY);

  /** Maximum number of caller settings a user can have **/
  public static maxNumberOfCallers = new NumberConfig('max.number.of.callers', 100);

  /** Maximum length of a caller name. */
  public static maxLengthOfCallerName = new NumberConfig('max.length.of.caller.name', 50);

  /** Maximum number of group settings a user can have **/
  public static maxNumberOfGroups = new NumberConfig('max.number.of.groups', 20);

  /** Maximum number of members a group is allowed to have **/
  public static maxNumberOfGroupMembers = new NumberConfig('max.number.of.group.members', 15);

  /** Maximum length of a group member name. */
  public static maxLengthOfGroupName = new NumberConfig('max.length.of.group.name', 50);

  /**
   * Maximum number of tones allowed in the play/shuffle list for a play setting.
   * A value of {@code 0} means that T-Mobile-style shuffle can be used instead of playlists.
   * Any positive value defines the maximum size of the playlist,
   * with a value of {@code 1} restricting the size of the playlist to 1
   * and therefore essentially disabling shuffle.
   */
  public static maxPlayListSize = new NumberConfig('max.playlist.size', 1);

  /** Maximum number of time of day settings that an user can have **/
  public static settingsTimeOfDayMaxNumber = new NumberConfig('settings.timeOfDay.maxNumber', 3);

  /** Maximum number of time and day of week setting that an user can have **/
  public static settingsTimeAndDayOfWeekMaxNumber = new NumberConfig('settings.timeAndDayOfWeek.maxNumber', 3);

  /** Maximum number of day of week settings that an user can have **/
  public static settingsDayOfWeekMaxNumber = new NumberConfig('settings.dayOfWeek.maxNumber', 3);

  /** Maximum number of day of year settings (TMO special occasion) that an user can have **/
  public static settingsDayOfYearMaxNumber = new NumberConfig('settings.dayOfYear.maxNumber', 3);

  /**
   * If set to `true`, the user will be able to choose between RBT with or without ITU
   * If set to `false`, the user will NOT be able to choose between RBT with or without ITU
   */
  public static alternativeAudioEnabled = new BooleanConfig('settings.alternativeAudio.enabled', false);

  /**
   * If set to `true`, the user will be redirected to Settings in case that the purchased tone is set as default
   * If set to `false`, the user will NOT be redirected to Settings in case that the purchased tone is set as default
   */
  public static showDefaultSettingsEnabled = new BooleanConfig('toneDetailPage.showDefaultSettings.enabled', false);

  /** Config to define if a tone should be applied as default after a purchase */
  public static setAsDefaultMode = new EnumConfig('toneDetailPage.setAsDefaultMode', SetAsDefaultMode.ASK, SetAsDefaultMode);

  /**
   * ; Separated list of values to choose which legal texts will be shown in the tone details page.
   * Possible values are TERMSANDCONDITIONS, WITHDRAW, STOP and MONTHLY.
   */
  public static tonePageLegal = new StringArrayConfig('toneDetailPage.enabledItems', ['TERMSANDCONDITIONS', 'STOP', 'WITHDRAW']);

  /** Monthly subscription fee, used in texts */
  public static monthlySubscriptionPrice = new NumberConfig('subscription.monthly.price', 1.49);

  /* Config to define the shop were tones will be taken on the RBT activation flow */
  public static rbtActivationShop = new StringConfig('specialFlow.rbt.shopId', '506');

  /** Configuration Home Slider Interval */
  public static homeSliderInterval = new DurationConfig('home.slider.interval', 5, TimeUnit.SECONDS);

  /** Configuration Top Banner Home */
  public static homeBanner = new StringConfig('home.topBanner.promotionId', '');

  /** Configuration Top Banner Home Mobile */
  public static homeBannerMobile = new StringConfig('home.topBannerMobile.promotionId', '');

  /** Configuration Top Banner Home */
  public static homeTopPromo = new StringConfig('home.topPromo.promotionId', '');

  /** Configuration Bottom Banner Home */
  public static homeBottomPromo = new StringConfig('home.bottomPromo.promotionId', '');

  /** Configuration Top Banner shop */
  public static shopBanner = new StringConfig('shop.topBanner.promotionId', 'PROMOTION(GenrederWoche,button=Genre der Woche)');

  /** Configuration Promotions */
  public static homeWebPromo = new StringArrayConfig('home.slide.promotionIds', []);

  /** Configuration promo mobile tiles */
  public static homeMobilePromo = new StringArrayConfig('home.tiles.mobile.promotionIds', []);

  /** Home page Tiles */
  public static homeTiles = new EnumArrayConfig('home.tiles.config',
    [
      Tiles.CALLER_LIST,
      Tiles.DEFAULT_SETTING,
      Tiles.GROUP_LIST,
      Tiles.RECORD_UGC,
      Tiles.RBT_LIBRARY,
      Tiles.DAY_OF_WEEK_LIST
    ], Tiles
  );

  /** Name of the promotion holding the details for the content popup to show on the home page */
  public static homePromoPopup = new StringConfig('home.promotionPopup.name', '');

  /** Name of the promotion holding the details for the content popup to show after the subscription of a NEW user */
  public static homePromoPopupAfterSubscription = new StringConfig('home.promotionPopup.afterSubscription.name', '');

  /** Pattern to match the promotion URLs that are configured just for Android devices */
  public static homePromoPopupAndroidUrls = new RegExpConfig('home.promotionPopup.androidUrls', /^market:|^https?:\/\/play\.app\.goo\.gl\//i);

  /** Pattern to match the promotion URLs that are configured just for iPhone devices */
  public static homePromoPopupIphoneUrls = new RegExpConfig('home.promotionPopup.iphoneUrls', /^itms-apps:|^itms:|^https?:\/\/apps\.apple\.com\//i);

  /** Shop ID to load new songs shop, currently only used on the web layout */
  public static newSongsShopId = new StringConfig('newSongs.shopId', '506');

  /** Shop ID to load the fallback prelisten tone from a shop when the shared ugc token can not be resolved on the shared ugc landing page (ERBT-5078) */
  public static sharedUgcFallbackToneShopId = new StringConfig('ugc.shared.fallbackTone.shopId', '38');

  /** Promotion ID for the landing page (ERBT-4430)  */
  public static onboardingQuickPromotionName = new StringConfig('onboarding.quick.promotion.name', '');

  /** Link that should be followed when we click the skip button in the landing page (e.g. /shop/id/506) (ERBT-4502) */
  public static onboardingQuickSkipLink = new StringConfig('onboarding.quick.skip.link', '/home');

  /** Conversion ID for the landing page (ERBT-4432). NOTE: An empty string disables the tracking  */
  public static onboardingQuickGtagConversionId = new StringConfig('onboarding.quick.gtag.conversionId', '');

  /** Configuration Top Banner Registration Page */
  public static registrationBanner = new StringConfig('onboarding.signUp.promotionId', '');

  /** Promotion ID for the happy landing page (ERBT-5832) */
  public static landingHappyPromotionName = new StringConfig('landing.happy.promotion.name', '');

  /** Service ID that should be used for the happy promotion subscriptions (ERBT-5832) */
  public static happySubscriptionServiceId = new StringConfig('subscription.serviceId.happy', '');

  /**
   * Related with `newSongsShopId`
   * If set to {@code true}, the content in the shop `newSongsShopId` will be displayed randomly
   * If set to {@code false}, the content in the shop `newSongsShopId` will be displayed always in the same order
   */
  public static newSongsEnableRandomOrder = new BooleanConfig('newSongs.enableRandomOrder', false);

  /**
   * If set to {@code true}, the search for suggestions is triggered
   * If set to {@code false}, the search for suggestions is NOT triggered
   */
  public static searchEnableSuggestions = new BooleanConfig('search.enableSuggestions', false);

  /** Urls for conversion tracking ';' separated values **/
  public static pixelTrackUrls = new StringArrayConfig('pixelTrack.urls', []);

  /**
   * Navigation URL for the opco logo.
   * If this config is empty or not set, user will navigate to the home page
   * */
  public static opcoLogoUrl = new StringConfig('opco.logoUrl', '');

  /** Regex to verify the opco msisdn format **/
  public static opcoMsisdnFormat = new StringConfig('opco.msisdn.format', '');

  /**
   * The type (i.e. CSS class) of skin to show, e.g. skin-xmas.
   * Leave blank to hide the skin altogether.
   */
  public static skin = new StringConfig('home.page.skin', '');

  /** Regex to disable grid view in the devices that are not compatible */
  public static devicesGridViewDisabled = new StringConfig('shop.devicesGridViewDisabled', '');

  /**
   * If set to {@code true}, the shop grid view is show by default in mobile devices
   * If set to {@code false}, the list view is show by default in mobile devices
   */
  public static showGridViewByDefault = new BooleanConfig('shop.showGridViewByDefault', true);

  /**
   * If set to {@code true}, the direct purchase of the tone is used
   * If set to {@code false}, the direct purchase of the tone is NOT used
   */
  public static useToneFromAnonymousBrowsing = new BooleanConfig('subscription.useToneFromAnonymousBrowsing', false);

  /**
   * If set to {@code true}, the cookie notification bar is displayed
   * If set to {@code false}, the cookie notification bar is NOT displayed
   */
  public static showCookieNotificationBar = new BooleanConfig('home.showCookieNotificationBar', true);

  /**
   * ; Separated list of product IDs to exclude from the library (ERBT-4261)
   */
  public static hiddenToneIds = new StringArrayConfig('userLibrary.hiddenToneIds', []);

  /**
   * If set to {@code true}, the analytics for Tealium will be enabled
   * If set to {@code false}, the analytics for Tealium will be disabled
   */
  public static tealiumEnabled = new BooleanConfig('analytics.tealiumEnabled', false);

  /**
   * If set to {@code true}, the request to fetch the booked packages will be done
   * If set to {@code false}, the request to fetch the booked packages will not be done
   */
  public static requestBookedPackages = new BooleanConfig('subscription.bookedPackages.enabled', false);

  /** Name of the promotion used on the Magenta Moments landing page */
  public static magentaMomentsLandingPromo = new StringConfig('magentaMoments.landing.promotion.name', '');
  /** Name of the promotion used on the Magenta Moments opt-in page */
  public static magentaMomentsOptinPromo = new StringConfig('magentaMoments.optin.promotion.name', '');
  /** Id of the song of the week */
  public static shopOfTheWeekId = new StringConfig('toneOfTheWeek.shopId', '');
  /** Title of the song of the week */
  public static toneOfTheWeekTitle = new StringConfig('toneOfTheWeek.title', '');
}
