import { NgModule } from '@angular/core';
import { DEFAULT_BREAKPOINTS, BreakPoint, BREAKPOINTS } from '@angular/flex-layout';
// RAW_DEFAULTS renamed to DEFAULT_BREAKPOINTS

export function customBreakPoints() {
    return DEFAULT_BREAKPOINTS
        .map((it: BreakPoint) => {
            switch (it.alias) {
                case 'xs':
                    it.mediaQuery = 'screen and (max-width: 600px)';
                    break;
                case 'gt-xs':
                    it.mediaQuery = 'screen and (min-width: 600px)';
                    break;
                case 'sm':
                    it.mediaQuery = 'screen and (min-width: 600px) and (max-width: 768px)';
                    break;
                case 'gt-sm':
                    it.mediaQuery = 'screen and (min-width: 768px)';
                    break;
                case 'md':
                    it.mediaQuery = 'screen and (min-width: 768px) and (max-width: 1024px)';
                    break;
                case 'gt-md':
                    it.mediaQuery = 'screen and (min-width: 1024px)';
                    break;
                case 'lg':
                    it.mediaQuery = 'screen and (min-width: 1025px) and (max-width: 1920px)';
                    break;
                case 'gt-lg':
                    it.mediaQuery = 'screen and (min-width: 1920px)';
                    break;
                case 'xl':
                    it.mediaQuery = 'screen and (min-width: 1920px)';
                    break;
            }

            return it;
        });
}

@NgModule({
    providers: [
        // We register a custom provider to override default breakpoints.
        {
            provide: BREAKPOINTS,
            useFactory: customBreakPoints
        }
    ]
})
export class BreakPointsModule { }
