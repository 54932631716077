import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PromoService } from 'app/shared/promotion/promo.service';
import { BasePromoComponent } from 'app/shared/promotion/base-promo.component';
import { PurchaseSources } from './../../tracking/purchase.sources';

@Component({
  selector: 'app-promo',
  templateUrl: 'promo.component.html'
})
export class PromoComponent extends BasePromoComponent {

  /**
   * ERBT-6947: Adding a boolean to
   * true: Fullscreen promo with text
   * false: Not Fullscreen promo and image only.
   */
  @Input() isFullScreen: boolean = true;
  
  /**
   * The source to use for ERBT-3730 purchase tracking
   * This variable is used on the BasePromoComponent which is extended
   */
  @Input() source: PurchaseSources;
  

  constructor(promoService: PromoService, router: Router) {
    super(promoService, router);
  }
}
