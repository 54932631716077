import {
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy
} from '@angular/core';

import { interval ,  Subscription } from 'rxjs';
import { first, map, filter } from 'rxjs/operators';

import { Tone } from 'app/model/tone';
import { LazyDirective } from 'app/shared/directives/lazy.directive';
import { environment } from 'environments/environment';
import { BaseViewComponent } from '../shared/base-view.component';
import { Router } from '@angular/router';


class TileSize {
  width: number;
  height: number;
}

/**
 * Component class to load contents on scroll
 */
@Component({
  selector: 'app-tone-list',
  templateUrl: './tone-list.component.html'
})
export class ToneListComponent extends BaseViewComponent implements OnChanges, OnDestroy {

  private readonly approximateHeightOfToneItem = 75;

  @Input()
  tones: Tone[];

  @Input()
  grid: boolean;

  @Input() purchaseSource: string;
  @Input() purchaseSourceInfo: string;
  @Input() purchaseSourcePropagateCurrent = true;

  @Input() imageSize = this.isRedesign ? 116 : 60;

  gutterSize = environment.opcoConfig.toneGridListGutterSize;

  tracksLoaded = false;

  /** Holds currently displaying contents */
  cachedTones: Tone[] = [];

  /** Holds viewport height */
  private viewportHeight: number;

  private numberOfContentsPerPage: number;

  /** Keeps track of the loaded page */
  private pageIndex: number;

  private subscription: Subscription;

  private loadingTimer: Subscription;

  constructor( router: Router,
    zone: NgZone) {
    super(zone, router);
    this.viewportHeight = window.innerHeight;

  }

  ngOnChanges(): void {

    this.resetView();
    if (this.tones) {
      this.loadTones();
    }
  }


  loadTones() {

    if (this.cachedTones.length < this.tones.length && !this.isLoadingContent()) {

      this.tracksLoaded = false;
      this.loadingTimer = interval(100)
      .pipe(first())
        .subscribe(() => {

          const cachedToneLength = this.cachedTones.length;
          const end = cachedToneLength === 0
            ? this.numberOfContentsPerPage * 2 // Initially load two pages
            : cachedToneLength + this.numberOfContentsPerPage;

          this.cachedTones = this.tones.slice(0, end);
          if (this.cachedTones.length < this.tones.length) {

            this.pageIndex++;
            if (cachedToneLength === 0) {
              this.listenScroll();
            }

          } else {
            // Content loading  finished unsubscribe scroll listener
            this.closeAllSubscriptions();
          }

          this.tracksLoaded = true;

        });
    }

  }

  private listenScroll() {
    this.subscription = LazyDirective.scrollListener.pipe(
      // Take the scrollTop value of the event
      map(() => window.scrollY),
      // Pass the value only if scroll bypassed last updated value
      filter(scrollTop => scrollTop > this.viewportHeight * this.pageIndex),
      filter(() => !this.isLoadingContent()))
      .subscribe(() => this.loadTones());
  }

  private resetView() {

    this.numberOfContentsPerPage = Math.ceil(this.viewportHeight / this.approximateHeightOfToneItem);
    this.pageIndex = 0;

    this.cachedTones = [];
    this.closeAllSubscriptions();
    this.tracksLoaded = false;

  }

  getTileSize(index: number): TileSize {
    const composition = [
      { width: 6, height: 6 },
      { width: 6, height: 6 },
      { width: 8, height: 8 },
      { width: 4, height: 4 },
      { width: 4, height: 4 },
      { width: 6, height: 6 },
      { width: 6, height: 6 },
      { width: 4, height: 4 },
      { width: 8, height: 8 },
      { width: 4, height: 4 }, // Out of place, only works this way. Probably a bug in Material
    ];

    const compositionIndex = index % composition.length;
    return composition[compositionIndex];
  }

  private isLoadingContent() {
    return this.loadingTimer && !this.loadingTimer.closed;
  }

  private closeAllSubscriptions() {

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    if (this.loadingTimer && !this.loadingTimer.closed) {
      this.loadingTimer.unsubscribe();
    }

  }

  ngOnDestroy(): void {
    this.closeAllSubscriptions();
  }

}
