import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";

/**
 * A custom URL Serializer that enhances Angular's default URL Serializer.
 * This custom URL serializer replaces "(" or ")" with "%28" and "%29" to fix an known 
 * issue where Angular router cannot parse URLs with a matrix paremeter containing 
 * parentheses.
 * 
 * https://rncorp.atlassian.net/browse/ERBT-5438
 */
@Injectable()
export class CustomUrlSerializer implements UrlSerializer {

    private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
       // ERBT-5438: Encode parentheses cause it breaks Angular routing 
       // on page refresh or on backwards navigation.
       url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
       // Use the default serializer.
       return this.defaultUrlSerializer.parse(url)
    }

    serialize(tree: UrlTree): string {
       return this.defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
    }
}
