import { Component } from '@angular/core';

import { FormatPipe } from '../pipes/format.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { OfferedPackage } from 'app/model';
import { environment } from 'environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-offers-dialog',
  templateUrl: './offers-dialog.component.html',
})
export class OffersDialogComponent {

  public texts = {
    title: 'tone.page.popup.offers.title',
    content: 'tone.page.popup.offers.description',
    confirm: 'common.button.buy',
    cancel: 'common.button.cancel'
  };

  public offeredPackages: OfferedPackage[];
  public selectedPackage: OfferedPackage;
  public showCloseIcon: boolean = environment.opcoConfig.newLayout;
  private translate = new TranslatePipe();
  private format = new FormatPipe();

  constructor(public dialogRef: MatDialogRef<OffersDialogComponent>) { }

  setOfferedPackages(offeredPackages: OfferedPackage[]) {
    this.offeredPackages = offeredPackages;
    this.selectedPackage = offeredPackages[0];
  }

  getOfferText(p: OfferedPackage) {
    let msg: string;
    if (p.recurring) {
      msg =  'tone.page.popup.offer.recurring.description';
    } else {
      msg = 'tone.page.popup.offer.description';
    }

    let price = this.translate.transform('common.priceFormat');
    price = this.format.transform(price, p.price);

    msg = this.translate.transform(msg, p.totalCredits);
    msg = this.format.transform(msg, p.totalCredits, price);

    return msg;
  }

  confirm() {
    this.dialogRef.close(this.selectedPackage);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
