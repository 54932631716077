<div *ngIf="!isRedesign && promotionItem && isFullScreen" class="slide-promo">
  <img *appLazy [src]="promotionItem.artwork | image:945:340:1" />
  <div class="carousel-TextArea">
    <h3>{{ promotionItem.title }}</h3>
    <button mat-raised-button class="bt-main bt-secondary" (click)="trackAndForward(promotionItem.targetUrl, 'carouselItem.click', promotionItem.title,
            true, source, promotionId)" color="primary">
      <span *ngIf="!buttonText">{{ "banner.button.text" | trans}}</span>
      <span *ngIf="buttonText">{{ buttonText }}</span>
    </button>
  </div>
</div>

<div *ngIf="isRedesign && promotionItem && isFullScreen" class="section-promo-wrapper">
  <a (click)="trackAndForward(promotionItem.targetUrl, 'topPromo.click', 
  promotionItem.title, true, source, promotionId)" class="image-wrapper">
    <img *appLazy [src]="promotionItem.artwork | image:300:300">
  </a>
  <div class="info-wrapper">
    <div class="title">{{promotionItem.title}}</div>
    <div class="description">{{promotionItem.description}}</div>
    <div class="button">
      <button mat-raised-button class="bt-main bt-secondary promotion-section-button" color="primary" (click)="trackAndForward(promotionItem.targetUrl, 'topPromo.click', 
          promotionItem.title, true, source, promotionId)">{{promotionItem.buttonLabel}}</button>
    </div>
  </div>
</div>

<div *ngIf="isRedesign && promotionItem && !isFullScreen" class="single-dynamic-promo">
  <a (click)="trackAndForward(promotionItem.targetUrl, 'bottomPromo.click', 
  promotionItem.title, true, source, promotionId)" class="image-wrapper">
    <img *appLazy [src]="promotionItem.artwork | image:968:313">
  </a>
</div>