/**
 * Component to offer the Android or iPhone app by detecting the device OS.
 */
import { OnInit, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BrowserService } from 'app/core/browser.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-offer-app',
  templateUrl: './offer-app.component.html'
})

export class OfferAppComponent implements OnInit {

  public badgeIcon: string;

  public textWithAppLink;

  private translate = new TranslatePipe();

  constructor(private browserService: BrowserService, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    // ERBT-3756: We offer the app in Android devices
    if (this.browserService.isAndroid()) {
      this.offerTheAppIfAvailable("tone.page.info.webPurchase.html", "icon-app-sale-android");
      // ERBT-4869: We offer the app in iPhone devices (if available)
    } else if (this.browserService.isIphone()) {
      this.offerTheAppIfAvailable("tone.page.info.webPurchase.iphone.html", "icon-app-sale-iphone");
    }
  }

  private offerTheAppIfAvailable(appLinkTextKey: string, badgeIconClassName: string) {
    const appLinkText = this.translate.transform(appLinkTextKey);
	// ERBT-6136: Offer the app only if the text is set (not empty).
    if (appLinkText && appLinkText.length !== 0) {
      this.badgeIcon = badgeIconClassName;
      this.textWithAppLink = this.domSanitizer.bypassSecurityTrustHtml(appLinkText);
    }
  }
}
