import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';

import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-dropdown-navbar',
  templateUrl: './dropdown-navbar.component.html',
})
export class DropdownNavbarComponent {


  public isLogged = false;
  public pageHome = false;
  public pageShop = false;
  public pageCaller = false;
  public pageLibrary = false;
  public pageAccount = false;
  public pageFaq = false;
  public dropdownMenu = true;
  public showContentPackages = false;

  ngOnInit() {
    this.determineLocationPage();
    this.showContentPackages = environment.showContentPackages;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this.isLogged = this.authService.isLoggedIn();
  }


  determineLocationPage(): void {
    this.pageLibrary = false;
    this.pageCaller = false;
    this.pageAccount = false;
    this.pageHome = false;
    this.pageFaq = false;
    var returnUrl = null;

    if (this.router.url.indexOf('login') >= 0) {
        returnUrl = this.route.snapshot.paramMap.get('returnUrl');
    }
    
    if (returnUrl === 'library' || this.router.url.indexOf('library') >= 0) {
      this.pageLibrary = true;
    } else if (returnUrl === 'setting' || this.router.url.indexOf('setting') >= 0) {
      this.pageCaller = true;
    } else if (this.router.url.indexOf('home') >= 0) {
      this.pageHome = true;
    } else if (returnUrl === 'shop' || this.router.url.indexOf('shop') >= 0 || this.router.url.indexOf('rbt') >= 0 ) {
      this.pageShop = true;
    } else if (returnUrl === 'account' || this.router.url.indexOf('account') >= 0) {
      this.pageAccount = true;
    } else if (this.router.url.indexOf('faq') >= 0) {
      this.pageFaq = true;
    } 
}

navigateTo(url) {
    this.router.navigateByUrl(url).then(() => {
      // This is a workaround to additionally hightlight the menu item for a protected page
      // when the user is redirected to the login page.
      this.determineLocationPage();
    });
  }

  showDropdownContent(): void {
      this.dropdownMenu = true;
  }

  menuItemClicked(path) {
    if (this.isLogged) {
      // User is authenticated, show dropdown menu
      this.dropdownMenu = true;
    } else {
      this.navigateTo(path);
    }
  }

  private hideDropdownMenu(event): void {
    // Sub menu item clicked, do not propagate the click event to the parent menu item
    event.stopPropagation();
    this.dropdownMenu = false;
 }
}
