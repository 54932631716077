import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NguCarousel,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent,
  NguTileComponent
} from '@ngu/carousel';
import { BreakPointsModule } from './breakpoints/breakpoints.module';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { ByPassSecurityPipe } from './pipes/by-pass-security.pipe';
import { ToneListItemComponent } from './tone-list-item/tone-list-item.component';
import { DialogComponent } from './dialog/dialog.component';
import { OffersDialogComponent } from './dialog/offers-dialog.component';
import { PromoDialogComponent } from './dialog/promo-dialog.component';
import { CapaDialogComponent } from './dialog/capa-dialog.component';
import { AddCallerComponent } from './dialog/add-caller.component';
import { DialogService } from './dialog/dialog.service';
import { PromoService } from './promotion/promo.service';
import { SearchService } from 'app/search/search.service';
import { BannerItemComponent } from './promotion/banner-item.component';
import { IgtListItemComponent } from './tone-list-item/igt-list-item.component';
import { ArtistListItemComponent } from './tone-list-item/artist-list-item.component';
import { CarouselComponent } from './carousel/carousel.component';
import { SlideComponent } from './carousel/slide.component';
import { ToneGridItemComponent } from './tone-grid-item/tone-grid-item.component';
import { PlayButtonService } from './play-button/play-button.service';
import { ImagePipe } from './pipes/image.pipe';
import { PlayButtonComponent } from './play-button/play-button.component';
import { RbtVersionComponent } from './rbt-version/rbt-version.component';
import { RbtVersionService } from './rbt-version/rbt-version.service';
import { CoverComponent } from './search/cover.component';
import { SearchComponent } from './search/search.component';
import { CallPartyComponent } from './call-party/call-party.component';
import { CallerInfoComponent } from './call-party/caller-info.component';
import { LazyDirective } from './directives/lazy.directive';
import { ResizableDirective } from './directives/resizable.directive';
import { ToneScrollerComponent } from './scroller/tone-scroller.component';
import { LegalTextComponent } from './legal-text/legal-text.component';
import { TrackerComponent } from './tracker/tracker.component';
import { MiniTitleBarComponent } from './title-bar/mini-title-bar.component';
import { SettingMenuComponent } from './title-bar/setting-menu.component';
import { DropdownNavbarComponent } from './title-bar/dropdown-navbar.component';
import { ToneListComponent } from '../shop/tone-list.component';
import { RbtActivationService } from 'app/register/flows/rbt-activation/rbt-activation.service';
import { AccountService } from 'app/account/account.service';
import { OfferAppComponent } from './offer-app/offer-app.component';
import { MaterialModule } from 'app/material.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ShopCarouselComponent } from './shop-tone-scroller/shop-carousel.component';
import { ToneCarouselComponent } from './shop-tone-scroller/tone-carousel.component';
import { ShopService } from '../shop/shop.service';
import { ToneService } from '../tone/tone.service';
import { FaqListComponent } from './faq-list/faq-list.component';
import { AccordionDirective } from './directives/accordion.directive';
import { DeleteButtonComponent } from "./delete-button/delete-button.component";
import { PriceComponent } from './price/price.component';
import { IGTSelectDialogComponent } from './dialog/igt-select-dialog.component';
import { RBTSelectDialogComponent } from './dialog/rbt-select-dialog.component';
import { LibraryService } from '../library/library.service';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BreakPointsModule,
    NguCarousel,
    NguTileComponent,   
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
  ],
  declarations: [
    TitleBarComponent,
    ToneListComponent,
    ToneGridItemComponent,
    ToneListItemComponent,
    IgtListItemComponent,
    ArtistListItemComponent,
    CarouselComponent,
    SlideComponent,
    DialogComponent,
    OffersDialogComponent,
    PromoDialogComponent,
    CapaDialogComponent,
    AddCallerComponent,
    TranslatePipe,
    FormatPipe,
    ImagePipe,
    ByPassSecurityPipe,
    SpinnerComponent,
    PlayButtonComponent,
    RbtVersionComponent,
    SearchComponent,
    CoverComponent,
    CallPartyComponent,
    CallerInfoComponent,
    ToneScrollerComponent,
    LazyDirective,
    ResizableDirective,
    BannerItemComponent,
    LegalTextComponent,
    MiniTitleBarComponent,
    SettingMenuComponent,
    DropdownNavbarComponent,
    TrackerComponent,
    OfferAppComponent,
    BreadcrumbComponent,
    ShopCarouselComponent,
    ToneCarouselComponent,
    FaqListComponent,
    AccordionDirective,
    DeleteButtonComponent,
    PriceComponent,
    IGTSelectDialogComponent,
    RBTSelectDialogComponent
  ],
  providers: [
    DialogService,
    SearchService,
    PromoService,
    PlayButtonService,
    RbtActivationService,
    AccountService,
    RbtVersionService,
    ShopService,
    ToneService,
    LibraryService,
  ],
  exports: [
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    TitleBarComponent,
    SpinnerComponent,
    ToneListComponent,
    ToneListItemComponent,
    IgtListItemComponent,
    ToneGridItemComponent,
    ArtistListItemComponent,
    CarouselComponent,
    SlideComponent,
    PlayButtonComponent,
    RbtVersionComponent,
    CallPartyComponent,
    CallerInfoComponent,
    CoverComponent,
    ToneScrollerComponent,
    TranslatePipe,
    ImagePipe,
    FormatPipe,
    ByPassSecurityPipe,
    CommonModule,
    SearchComponent,
    LazyDirective,
    ResizableDirective,
    BannerItemComponent,
    LegalTextComponent,
    MiniTitleBarComponent,
    TrackerComponent,
    OfferAppComponent,
    ShopCarouselComponent,
    ToneCarouselComponent,
    FaqListComponent,
    AccordionDirective,
    DeleteButtonComponent,
    PriceComponent,
    IGTSelectDialogComponent,
    
  ]
})
export class SharedModule { }
