import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseNetworkService } from '../core/network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import { AuthService } from '../core/auth/auth.service';
import UrlBuilder from '../core/url-builder';
import { SharedUgcResult } from 'app/model/shared-ugc-result';

@Injectable()
export class SharedUgcService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getSharedUgc(token: string): Observable<SharedUgcResult> {
    const url = UrlBuilder.getSharedUgcUrl(token);
    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
