/* eslint-disable max-len */
/**
 * Default routing module
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'landingpromo', loadChildren: () => import('app/home/promotions/landingPromo/landing-promo.module').then(m => m.LandingPromoModule) },
  { path: 'sharedUgc', loadChildren: () => import('app/sharedUgc/shared-ugc.module').then(m => m.SharedUgcModule) },
  { path: 'login', loadChildren: () => import('app/login/login.module').then(m => m.LoginModule) },
  { path: 'shop', loadChildren: () => import('app/shop/shop.module').then(m => m.ShopModule) },
  { path: 'rbt', loadChildren: () => import('app/tone/tone.module').then(m => m.ToneModule),  data: { breadcrumbData : { breadcrumbs: ['home', 'shop'] }}},
  { path: 'library', loadChildren: () => import('app/library/library.module').then(m => m.LibraryModule), data: { breadcrumbData : { breadcrumbs: ['home', 'library'] }}},
  { path: 'account', loadChildren: () => import('app/account/account.module').then(m => m.AccountModule), data: { breadcrumbData : { breadcrumbs: ['home', 'account']}}},
  { path: 'setting', loadChildren: () => import('app/setting/setting.module').then(m => m.SettingModule), data: { breadcrumbData : { breadcrumbs: ['home','setting']}}},
  { path: 'faq', loadChildren: () => import('app/faq/faq.module').then(m => m.FaqModule), data: { breadcrumbData : { breadcrumbs: ['home','faq']}}},
  { path: 'search', loadChildren: () => import('app/search/search.module').then(m => m.SearchModule)},
  { path: 'register', loadChildren: () => import('app/register/register.module').then(m => m.RegisterModule) },
  { path: 'error', loadChildren: () => import('app/error/error.module').then(m => m.ErrorModule) },
  { path: '**', redirectTo: '/error/404' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
