import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';

import { LandingPromoComponent } from './landing-promo.component';

@NgModule({
  imports: [RouterModule.forChild(getRoutes())],
  exports: [RouterModule]
})
export class LandingPromoRoutingModule { }

function getRoutes() {

  const routes: Routes = [
    { path: 'landingpromo', component: LandingPromoComponent }
  ];

  if (environment.opcoConfig.landingHappyPageUrl.length > 0) {
    routes.push({ path: environment.opcoConfig.landingHappyPageUrl, component: LandingPromoComponent });
  }

  return routes;
}