import { Pipe, PipeTransform } from '@angular/core';

import { ClientConfig } from 'app/core/config/client-config';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  static readonly instance = new ImagePipe();
  private static readonly defaultImagePath = 'assets/common/home-dummy.svg';

  transform(value: string, width: number, height: number, defaultDevicePixelRatio?: number): string {

    // Firefox does not identify the height
    // of the image until it loads, we assume
    // that it is a square image and makes the height
    // equal to width
    // TODO we need to scaleProportionally=true here with transcoder
    if (width > 0 && height === 0) {
      height = width;
    }

    if (value && width > 0 && height > 0) {

      // We don't request GIFS to the image transcoder
      // The GIFs should be specified using absolute URLs in the Backoffice
      if (value.split('.').pop().toLowerCase().indexOf('gif') === -1) {

        const baseUrl = ClientConfig.imageTranscoderUrl.getString();
        const path = encodeURIComponent(value);

        // We request to the image transcoder the image taking into account the
        // devicePixelRatio to avoid blurry images
        // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
        // As in RBT+ we will allow to request images up to 4 times bigger than the dimension of the element
        const devicePixelRatio = defaultDevicePixelRatio
              ? defaultDevicePixelRatio
              : (window.devicePixelRatio ? Math.min(window.devicePixelRatio, 4) : 1);

        width = Math.round(devicePixelRatio * width);
        height = Math.round(devicePixelRatio * height);

        return `${baseUrl}?path=${path}&width=${width}&height=${height}`;
      }

      // Returns the absolute URL of a GIF
      return value;
    }

    // show a dummy image until view holder loads width.
    return ImagePipe.defaultImagePath;
  }
}
