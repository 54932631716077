import { OnInit, Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Tone } from 'app/model';
import { BaseViewComponent } from 'app/shared/base-view.component';
import { PromoItem, Promotions } from 'app/model/promotions';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from 'app/tone/tone.service';
import log from 'app/core/logging/logger.service';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { PurchaseSourcesInfo } from 'app/tracking/purchase.sources.info';
import { ExternalRouting } from 'app/core/external.routing';
import { LandingPromoType } from 'app/home/promotions/landingPromo/landing-promo.component';
import { ClientConfig } from 'app/core/config/client-config';
import UrlBuilder from 'app/core/url-builder';

@Component({
    templateUrl: './magenta-black-days.component.html'
})

export class MagentaBlackDaysComponent extends BaseViewComponent implements OnInit {

    public promoItem: PromoItem;
    public tones: Tone[];
    public imageSize: number;
    public selectedToneId: string;
    public checkbox1: boolean;
    public checkbox2: boolean;
    /** Whether or not to show the success page. */
    public success: boolean;

    constructor(zone: NgZone, router: Router,
        private promotionService: PromoService,
        private toneService: ToneService,
        private route: ActivatedRoute,) {
        super(zone, router);
    }

    ngOnInit(): void {

        this.success = this.route.snapshot.queryParams.hasOwnProperty('activation') 
        ? this.route.snapshot.queryParams['activation'] === 'success' 
        : undefined;

        if (!this.success) {
            this.imageSize = this.isMobile ? 80 : 100;
            // load the promo - depending on whether we are the landing page (first step)
            // or the opt-in page (second step)
            this.loadPromoOrRedirectToHome();
        }
    }


    private loadPromoOrRedirectToHome(): void {
        
        let promoName: string = ClientConfig.magentaBlackDaysPromo.getString();

        if (!promoName) {
            // looks like the BlackMagentaDays landing page was not configured, let's redirect to home if the user calls the URL anyway
            log.warn("Promo for BlackMagentaDays Landing Page is not in the configuration, redirecting to home.");
            this.redirectToHome();
        }
        else {
            this.setLandingPromo(promoName);
        }
    }

    /**
     * Loads the page
     */
    private setLandingPromo(promoName: string): void {

        this.showLoading();

        this.promotionService.getPromotionById(promoName)
            .subscribe((promotions: Promotions) => {
                this.promoItem = this.getPromotionWithShopId(promotions);
                if (this.promoItem !== undefined) {
                    const promoShopId = this.parsePromoShopId(this.promoItem.targetUrl);
                    this.toneService.getRbtsForShop(promoShopId).subscribe(toneList => {
                        this.tones = toneList.tone;
                        this.hideLoading();
                    }, error => this.handleError(error));
                } else {
                    log.info('The shop ID configured for the promotion: ' + promoName + ' is not valid. Redirecting to home');
                    this.redirectToHome();
                }

            }, error => this.handleError(error));
    }

    private getPromotionWithShopId(promotions: Promotions): PromoItem {
        for (let i = 0; i < promotions.item.length; i++) {
            const promoItem = promotions.item[i];
            const promoShopId = this.parsePromoShopId(promoItem.targetUrl);
            if (promoShopId !== null) {
                return promoItem;
            }
        }
    }

    private parsePromoShopId(targetUrl: string): string {
        const shopIdMatcher = /.*\/shop\/id\/(\d+)(\?.*)?/.exec(targetUrl);
        return shopIdMatcher == null
            ? null
            : shopIdMatcher[1];
    }

    redirectToLogin(): void {
        ExternalRouting.navigate(this.router, ['/login', {
            landingPromoToneId: this.selectedToneId,
            landingPromoType: LandingPromoType.MAGENTA_BLACK_DAYS
        }],
            true, PurchaseSources.Subscription, PurchaseSourcesInfo.MagentaBlackDaysPromoFlow);
    }

    redirectToLandingPromo(): void {
        ExternalRouting.navigate(this.router, ['/landingpromo'],
            true, PurchaseSources.Subscription, PurchaseSourcesInfo.MagentaBlackDaysPromoFlow);
    }

    isSelected(toneId: string): boolean {
        return this.selectedToneId === toneId;
    }

    toneClicked(tone: Tone): void {
        this.selectedToneId = tone.id;
    }

    /** Listens legal text link clicks */
    listenToLegal(event) {
        UrlBuilder.openExternalOrInternalUrlFromClickEvent(event, this.router);
    }

    private redirectToHome(): void {
        this.router.navigate(['/home']);
    }
}
