import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

import { BaseNetworkService } from './network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import UrlBuilder from './url-builder';
import log from 'app/core/logging/logger.service';
import { AuthService } from './auth/auth.service';
import { BrowserService } from './browser.service';
import CustomHttpParams from './network/custom-http-params';

// Firebase App is always required and must be listed first
import firebase from "firebase/app";
// Add the Firebase SDK for Firebase Messaging
import "firebase/messaging";

@Injectable()
export class PushNotificationService extends BaseNetworkService {

    private static readonly KEY_FAKE_PUSH_TOKEN = 'fakePushToken';
    private UNSUPPORTED_BROWSERS = ['Safari']

    /* Request parameters to Puschel */
    private static readonly requestParameters = {
        id: 'id',
        pushId: 'pushId',
        deviceType: 'deviceType',
        appFlavor: 'appFlavor',
        appVersion: 'appVersion'
    };

    messaging: firebase.messaging.Messaging;

    public init(): void {
        // We register the service worker here to override to look
        // for the file in the root directory
        if (this.messaging) {
            log.info("Firebase initialized, skip initializing again");
        } else if (this.isBrowserSupportedForPushNotifications()) {
            // ERBT-5270, ERBT-5910: Inititialize Firebase app and Firebase Cloud Messaging 
            // only if the push notifications are supported.
            firebase.initializeApp(environment.firebaseConfig);
            this.messaging = firebase.messaging();
            // We are using app specific service worker JS files to support different configs.
            // Please note that we don't pass data dynamically into the service worker
            // to avoid loosing them once the service worker gets offline and restarted.
            navigator.serviceWorker.register('firebase-messaging-sw-' + environment.appName  + '.js')
            .then((registration) => {
                this.messaging.useServiceWorker(registration);
                this.getPermission();
            });
        } else {
            log.info('Browser doesn\'t support notifications')
        }
    }

    constructor(http: HttpClient, authService: AuthService, private browserService: BrowserService) {
        super(http, authService);
    }

    getPermission() {
        this.messaging.requestPermission()
        .then(() => {
          log.info('Notification permission granted.');
          return this.messaging.getToken();
        })
        .then(token => this.registerUserInPuschel(token) )
        .catch(error => log.info('Unable to get permission to notify.', error) );
    }


    private registerUserInPuschel(token: string): void {

        const hwid = this.generateHwid();
        
        // ERBT-5307: Send data to Puschel only if hwid is generated.
        // The generateHwid() method returns an ID only if the ID can be stored in the local storage in order
        // to avoid creating mutliple entries in Puschel with different IDs. Otherwise skip sending data to Puschel. 
        if (hwid) {
            const deviceType = this.browserService.browserName + ' ' + this.browserService.browserVersion;

            // This field will be only available in production environment
            // (Set in the build.sh)
            const appVersion = environment.sentryRelease ? environment.sentryRelease : 'snapshot';

            const params = this.createURLSearchParams();
            params.set(PushNotificationService.requestParameters.id, hwid);
            params.set(PushNotificationService.requestParameters.pushId, token);
            params.set(PushNotificationService.requestParameters.deviceType, deviceType);
            params.set(PushNotificationService.requestParameters.appFlavor, environment.appName);
            params.set(PushNotificationService.requestParameters.appVersion, appVersion);

            log.info('Sending the push notification information to Puschel: ' + params);
            this.sendRequest(UrlBuilder.getPuschelUnregisteredUserUrl(), params);
        }
    }

    // Updates the MSISDN of the user with the unique 'hwid' provided by PushWoosh
    public updateMsisdnInPuschel() {
  
        if (this.isBrowserSupportedForPushNotifications()) {

            if (!this.messaging) {
                // A guard to secure that at this point the firebase messaging is initialized.
                log.error('Firebase messaging is not initialized, skip updating MSISDN in Puschel');
                return;
            }

            this.messaging.getToken()
            .then(token => {
                if (token) {
                    const hwid = this.generateHwid();
                    // ERBT-5307: Send data to Puschel only if hwid is generated. 
                    // The generateHwid() method returns an ID only if it can be stored in the local storage in order
                    // to avoid creating mutliple entries in Puschel with different IDs. Otherwise skip sending data to Puschel.
                    if (hwid) {
                        const params = this.createURLSearchParams();
                        params.set(PushNotificationService.requestParameters.id, hwid);
                        log.info('Updating MSISDN in Puschel, updating id: ' + hwid);
                        this.sendRequest(UrlBuilder.getPuschelRegisteredUserUrl(), params);
                    }
                } else {
                    log.info('Avoiding to update MSISDN in Puschel because the token is not defined');
                }
            })
            .catch(error => log.error('Unable to update MSISDN in Puschel', error));
        } else {
            log.info('Unable to update MSISDN in Puschel because the browser currently doesn\'t support push notifications');
        }
      
    }

    /**
    * Determines whether the browser can immediately display
    * the notifications permission pop-up on page load once the 
    * notifications permission is requested.
    * Some browsers such as Firefox desktop due to security issues
    * can display the notifications permission pop-up only when the 
    * permission is requested on a click event anywhere on the website.
    * More: https://support.mozilla.org/en-US/kb/push-notifications-firefox
    * 
    * @returns true if the browser can immediately display the notification 
    *          permission pop-up on page load. Otherwise false, if the browser 
    *          requires a click event to display the notifications permission pop-up.
    * @see "ERBT-5256"
    */
    public isBrowserNotificationPermissionPopUpShownOnPageLoad(): boolean {
        return this.browserService.isMobile()
            || this.browserService.browserName !== 'Firefox';
    }

    private isBrowserSupportedForPushNotifications() {
        return navigator.serviceWorker 
            && 'Notification' in window
            // ERBT-5307: Cookies must be enabled to allow us registering 
            // service worker for push notifications.
            && navigator.cookieEnabled 
            && !this.browserService.isIosDevice()
            && !this.UNSUPPORTED_BROWSERS.find(name => name === this.browserService.browserName);
    }

    private sendRequest(url: string, params: CustomHttpParams): void {
        this.sendObservableRequest(url, RequestMethod.Post, params).subscribe();
    }

    extractData(res: HttpResponse<any>) {
        return res.body || {};
    }

    // Implementation taken from the Pushwoosh SDK to generate the HWID as they were doing it
    // Source: https://github.com/Pushwoosh/web-push-notifications/blob/master/src/functions.ts

    private generateHwid() {
        const pushToken = this.getFakePushToken() || this.generateFakePushToken();
        if (pushToken) {
            const applicationCode = environment.pushwooshId;
            return `${applicationCode}_${this.createUUID(pushToken)}`;
        }
    }

    private createUUID(pushToken: string) {
        const hexDigits = '0123456789abcdef';
        let s = '';
        for (let i = 0; i < 32; i++) {
            const l = pushToken.length - i - 1;
            let charCode = 0;
            if (l >= 0) {
            charCode = pushToken.charCodeAt(l);
            }

            s += hexDigits.substr(charCode % hexDigits.length, 1);
        }
        return s;
    }

    private generateToken(len?: number) {
        len = len || 32;
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < len; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    private generateFakePushToken() {
        const token = this.generateToken();
        try {
            localStorage.setItem(PushNotificationService.KEY_FAKE_PUSH_TOKEN, token);
            return token;
        } catch (error) {
            // ERBT-5307: Do not return a fake push token if we cannot access local storage
            // to store it locally. We want to avoid generating multiple random 
            // fake tokens for the user when local storage is not supported.
            // Unless in the future we find a nice way to return a unique ID for the user.
            log.warn('Access to local storage is not allowed', error);
            return undefined;
        }
    }

    private getFakePushToken() {
        try {
            return localStorage.getItem(PushNotificationService.KEY_FAKE_PUSH_TOKEN);
        } catch (error) {
            log.warn('Access to local storage is not allowed', error);
            return undefined;
        }       
    }

    public unregisterServiceWorker() {
        if (this.isBrowserSupportedForPushNotifications()) {
            // Workaround: We delete FCM local database that holds the cached push 
            // token to solve an issue in Firebase SDK that from what it seems it 
            // returns the cached token after unregistering the service worker 
            // for push notifications and reloading the page (next FCM initialization).
            // A new push token will get generated in the next initialization.
            // See: https://github.com/firebase/firebase-js-sdk/issues/2364
            window.indexedDB.deleteDatabase("firebase-messaging-database");

            // Unregister service worker for push notifications.
            navigator.serviceWorker.getRegistration('firebase-messaging-sw-' + environment.appName + '.js')
                .then((registration) => {
                    if (registration) {
                        registration.unregister();
                        log.info("Removed service worker for push notifications");
                    }
                }).catch(error => log.error('Unable to unregister service worker', error));
        }
    }
}
