<mat-list-item class="caller" [ngClass]="isRedesign ? 'redesigned': ''">
  <div mat-line fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
    <div
      [ngClass]="isRedesign ? 'caller-info':''"
      fxFlex="1 1 auto"
      fxLayout="column">
      <span *ngIf="!isRedesign" class="initial" [style.background-color]="initialBgColor">
        {{ initial }}
      </span>

      <div *ngIf="isRedesign" class="initials-container">
        {{ initial }}
      </div>

    </div>
    <div class="middle" fxFlex="1 1 45%" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="3px">
      <p> {{ callerInfo.name }} </p>
      <p *ngIf="!isRedesign" class="phone-number"> {{ callerInfo.msisdn }} </p>
    </div>
    <div class="end" fxFlex="grow" fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="5px">
      <button [attr.data-test-id]="'delete-member-button'" mat-button (click)="delete($event)" [ngClass]="isRedesign ? 'bt-delete' : 'bt-close'"></button>
    </div>
  </div>
</mat-list-item>
