import { ToneList } from './tone-list';
import { OfferedPackage } from './offered-package';

export enum ProfileStatus {
  NEW,
  AWAITING_ACTIVATION,
  ACTIVE,
  AWAITING_DEACTIVATION,
  DEACTIVATED,
  SUSPENDED,
  CONTRACT_SUSPENDED
}

export class Setting {
  igtId: string;
  rbtSetting: ToneSetting;
  id: string;
  type: string;
  alternativeAudioPreferred: boolean;
}

export enum ToneSettingType {
  TONE,
  STANDARD,
  SHUFFLE,
  PLAYLIST
}

export class CallerInfo {
  name: string;
  msisdn: string;
}

export class Caller {
  info: CallerInfo;
  setting: Setting;
}

export class Group {
  name: string;
  member: CallerInfo[];
  setting: Setting;
}

export class ToneSetting {
  type: string;
  id: string[];
}

export class DayOfWeek {
  weekday: string[];
  setting: Setting;
}

export class TimeOfDay {
  startTime: string;
  endTime: string;
  setting: Setting;
}


export class TimeAndDayOfWeek {

  /** For All day setting start and end time will be set  as '0000' */
  public static allDayTimeFormat = '0000';

  startTime: string;
  endTime: string;
  weekday: string[];
  setting: Setting;

}

export class DayOfYear {

  /**
   *  Leap year will be used to include all dates
   * including Feb 29, for most of the date conversions
   */
  public static arbitraryLeapYear = 2020;

  month: number;
  day: number;
  setting: Setting;
}

export class UserPackages {
  subscriptionPackages: Package[];
  contentPackages: Package[];
}

export class Package {
  offeredPackage: OfferedPackage;
  purchaseDate: number;
  expireDate: number;
  remainingCredits: number;
  stopped: boolean;
  trial: boolean;
  cancellable: boolean;
}

export class Profile {
  status: string;
  ownMsisdn: string;
  hibernate: boolean;
  capa: boolean;
  library: ToneList;
  ugc: ToneList;
  default: Setting;
  caller: Caller[];
  group: Group[];
  otherIgt: ToneList;
  remainingCredits: number;
  dayOfWeek: DayOfWeek[];
  timeOfDay: TimeOfDay[];
  timeAndDayOfWeek: TimeAndDayOfWeek[];
  dayOfYear: DayOfYear[];
  corporate: boolean;
  partnerId: string;
}
