import { Directive, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { NguCarousel, NguCarouselConfig } from "@ngu/carousel";

import { BaseViewComponent } from "../base-view.component";
import { ExternalRouting } from "../../core/external.routing";
import { PurchaseSources } from "../../tracking/purchase.sources";

/**
 * Base class for Carousel components
 */
@Directive()
export class BaseCarouselComponent
  extends BaseViewComponent
{
  @Input() title: string;
  @Input() shopId: string;
  @Input() purchaseSource: PurchaseSources = PurchaseSources.Shop;
  
  @ViewChild("Carousel") Carousel: NguCarousel<string>;
  
  maxItems = 16;

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 3, sm: 4, md: 4, lg: 4, all: 0 },
    load: 4,
    loop: false,
    touch: true,
    velocity: 0.3,
    point: {
      visible: true,
      hideOnSingleSlide: false,
    },
  };

  next() {
    this.Carousel.moveTo(this.Carousel.activePoint + 1);
  }

  prev() {
    this.Carousel.moveTo(this.Carousel.activePoint - 1);
  }

  navigateOnClick(
    router: Router,
    commands: any[],
    preservePurchaseSource: boolean,
    source?: string,
    sourceInfo?: string
  ): void {
    ExternalRouting.navigate(
      router,
      commands,
      preservePurchaseSource,
      source,
      sourceInfo
    );
  }
}
