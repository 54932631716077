<span class="dialog">
    <button mat-button *ngIf="showCloseIcon" (click)="cancel()" class="bt-close" ></button>
    <h1 mat-dialog-title>{{ texts.title | trans }}</h1>
    <img *appLazy appResizable class="square" [src]="promoItem.artwork">
    <div mat-dialog-content>
      <p>{{ texts.content | trans }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="accept()" color='primary' class="bt-main">
        {{ texts.confirm | trans }}
      </button>
      <button mat-raised-button *ngIf="!showCloseIcon" 
      class="bt-main bt-secondary cancel"
      (click)="cancel()">
        {{ texts.cancel | trans }}
        </button>
    </div>
</span>    