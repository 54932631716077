/**
 * Returns the initials of a given name.
 * If `isRedesign` is `false`, it returns the first character of the name.
 * If `isRedesign` is `true`, it combines the first characters of up to two words from the name.
 * @param name - The input name.
 * @param isRedesign - Indicates whether the method is used in a redesign context.
 * @returns The initials as a string.
 */
export function getInitials(name: string, isRedesign: boolean): string {
  
    if (!name) return '';
    
    if (!isRedesign) {
        // If not redesign, return the first character of the name
        return name.charAt(0);
    }
    
    const words = name.split(' ').filter(word => word.length > 0);
    if (words.length === 0) return '';
    
    const firstChar = words[0].charAt(0);
    const secondChar = words.length > 1 ? words[1].charAt(0) : '';
    
    return firstChar + secondChar;
}