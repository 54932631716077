import {
  Input,
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { ToneService } from 'app/tone/tone.service';
import { Tone } from 'app/model';
import { BaseComponent } from 'app/base.component';
import { ClientConfig } from 'app/core/config/client-config';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { ExternalRouting } from 'app/core/external.routing';

@Component({
  selector: 'app-tone-scroller',
  templateUrl: './tone-scroller.component.html',
})
export class ToneScrollerComponent extends BaseComponent implements OnInit {

  _shopId: string;

  @Input()
  tones: Tone[];

  @ViewChild('scroller', { static: true }) tabGroup: ElementRef;

  toneList;
  index = 10;
  newLayout;

  constructor(private toneService: ToneService, router: Router) {

    super(router);

    // In case nothing is provided default to show the new songs
    if (!this.tones && !this.shopId) {
      this.shopId = ClientConfig.newSongsShopId.getString();
    }
  }

  ngOnInit() {

    this.newLayout = environment.opcoConfig.newLayout;

    // Observe the right arrow click of the scroller to add more tones to the
    // scroller
      fromEvent(this.tabGroup.nativeElement as any, 'click')
      .pipe(
        debounceTime(100),
        filter(event => {

          // Filter the clicks on the right arrow
          return event['target'].classList.contains('mat-tab-header-pagination-after');

        }))
        .subscribe(event => {

          if (this.toneList && this.toneList.tone.length > this.index) {
            const start = this.index;
            this.index = this.index + 5; // Increment by 5

            // Adds 5 more tones to the view
            this.tones = this.tones.concat(this.toneList.tone.slice(start, this.index));
          }
        }
      );
  }

  @Input()
  set shopId(shopId: string) {
    this._shopId = shopId;

    if (this._shopId) {

      this.toneService.getRbtsForShop(this._shopId)
        .subscribe(toneList => {

          if (toneList.tone.length < this.index) {
            this.tones = toneList.tone;
          } else {
            // Initially add only 10 tones to the slider to improve performance
            // It will load quickly as we have 100 tones in prod
            this.tones = toneList.tone.slice(0, this.index);
          }

          this.toneList = toneList;

        });

    }
  }

  get shopId(): string {
    return this._shopId;
  }

  clicked(tone: Tone) {
    ExternalRouting.navigate(this.router, ['/rbt/id', tone.id],
        true, PurchaseSources.Shop, this._shopId);
  }

}
