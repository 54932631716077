import { NgModule } from '@angular/core';

import { SharedUgcRoutingModule } from './shared-ugc.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { ToneService } from 'app/tone/tone.service';
import { SharedUgcComponent } from './shared-ugc.component';
import { SharedUgcService } from './shared-ugc.service';


@NgModule({
  imports: [SharedUgcRoutingModule, SharedModule],
  declarations: [SharedUgcComponent],
  providers: [ToneService, SharedUgcService]
})
export class SharedUgcModule { }
