import { OnInit, Input, Component } from '@angular/core';
import { Router } from '@angular/router';

import { PromoService } from './promo.service';
import { ImagePipe } from './../pipes/image.pipe';
import { BasePromoComponent } from './base-promo.component';
import { AuthService } from '../../core/auth/auth.service';
import { ProfileService } from '../../core/profile.service';
import { Profile, Tone } from '../../model';
import { ToneService } from '../../tone/tone.service';
import { ProfileHelper } from '../../core/helper/profile-helper';


@Component({
  selector: 'app-banner-item',
  templateUrl: './banner-item.component.html'
})
export class BannerItemComponent extends BasePromoComponent implements OnInit {

  // TODO The height of the banner image is fixed and the  image transcoder
  // does not have an option to crop the image for varying width. So we have to
  // keep fixed height and width for the image now and center crop image with
  // background position property. We need to find out a better solution for this.
  @Input()
  bannerWidth = 2400;
  @Input()
  bannerHeight = 340;

  imageUrl: string;
  isLoggedIn: boolean;
  profile: Profile;
  defaultTone: Partial<Tone>;
  isShuffleActive: boolean;
  isHomePage: boolean;
  /**
   * The source to use for ERBT-3371 purchase tracking
   * In phase 2 we will distinguish the home banner and
   * the shop banner
   */
  @Input() source: string;
  constructor(
    promoService: PromoService,
    router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private toneService: ToneService) {
      super(promoService, router);

      this.isLoggedIn = this.authService.isLoggedIn();
      if(this.isLoggedIn) {
        this.loadDefaultTone();
      }
      this.isHomePage = this.router.url.indexOf('home') >= 0;
  }
 

  private loadDefaultTone() {
    this.profileService.getProfile().subscribe(profile => {
      this.profile = profile;
      // If profile does not have predefined rbt or igt, isShuffleActive and defaultTone will stay with default values.
      if (!profile.default) {
        return;
      }

      if (ProfileHelper.isShuffleActive(profile.default.rbtSetting)) {
        this.isShuffleActive = true;
        return;
      }
      const defaultToneId = profile.default.rbtSetting.id[0];
      this.toneService.getToneById(defaultToneId).subscribe(tone => {
        this.defaultTone = tone;
      });
    });
  }
  
  setImage() {
    const pipe = ImagePipe.instance;
    this.imageUrl = pipe.transform(
      this.promotionItem.artwork,
      this.bannerWidth,
      this.bannerHeight,
      1
    );
  }

  getPhoneNumber() {
    const phoneNumber = this.profileService.getPhoneNumberFromMsisdn(this.profile.ownMsisdn);
    return phoneNumber;
  }

  getIGTbyProfile() {
    let igt =  ProfileHelper.getIgtTone(this.profile, this.profile.default);

    if (igt) {
      return igt.title;
    }
    else {
      return '';
    }
  }
  // Method being used on the banner-item.component.html
  private showToneCard() {
    return this.isLoggedIn && this.isRedesign && this.isHomePage && (this.defaultTone || this.isShuffleActive);
  }

  // Method being used on the banner-item.component.html
  private showTextBanner() {
    return !this.isRedesign || !this.isLoggedIn || (!this.defaultTone && !this.isShuffleActive);
  }
}