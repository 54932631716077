import { NgModule } from '@angular/core';

import { LandingPromoRoutingModule } from './landing-promo.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from 'app/tone/tone.service';
import { LandingPromoComponent } from './landing-promo.component';


@NgModule({
  imports: [LandingPromoRoutingModule, SharedModule],
  declarations: [LandingPromoComponent],
  providers: [PromoService, ToneService]
})
export class LandingPromoModule { }
