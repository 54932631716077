import { NgModule } from '@angular/core';

import { OptinPromoRoutingModule } from './optin-promo.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from 'app/tone/tone.service';
import { OptinPromoComponent } from './optin-promo.component';

@NgModule({
  imports: [OptinPromoRoutingModule, SharedModule],
  declarations: [OptinPromoComponent],
  providers: [PromoService, ToneService]
})

export class OptinPromoModule { }