import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  NgZone,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { ToneService } from "app/tone/tone.service";
import { Tone } from "../../model";
import { ClientConfig } from "../../core/config/client-config";
import { BaseCarouselComponent } from "./base-carousel.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tone-carousel",
  templateUrl: "./tone-carousel.component.html",
})
export class ToneCarouselComponent
  extends BaseCarouselComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public tones: Tone[] = [];
  private toneSubscription: Subscription;

  @ViewChild("scroller", { static: true }) tabGroup: ElementRef;

  constructor(
    private toneService: ToneService,
    router: Router,
    protected zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super(zone, router);
    if (!this.shopId) {
      this.shopId = ClientConfig.newSongsShopId.getString();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.fetchTones();
  }

  fetchTones(): void {
    this.toneSubscription = this.toneService.getRbtsForShop(this.shopId).subscribe((toneList) => {
      if (toneList.tone.length < this.maxItems) {
        this.tones = toneList.tone;
      } else {
        this.tones = toneList.tone.slice(0, this.maxItems);
      }
      // Trigger change detection after data is fetched
      this.zone.run(() => this.cdr.detectChanges());
    });
  }

  clicked(tone: Tone): void {
    this.navigateOnClick(
      this.router,
      [`/rbt/id/${tone.id}`],
      true,
      this.purchaseSource as string,
      this.shopId
    );
  }

  ngOnDestroy() {
    // Clean up the subscription when the component is destroyed
    if (this.toneSubscription) {
      this.toneSubscription.unsubscribe();
    }
  }
}
