
import { Pipe, PipeTransform } from '@angular/core';

import { TextConfigService } from 'app/core/config/text-config.service';
import log from 'app/core/logging/logger.service';
import { environment } from 'environments/environment';
/**
 * Pipe to add the translation in the app. Client fetches all the texts
 * from the server and saves. This pipe gets text corresponding to the text key.
 * <p/>
 * Format in template: {@code {{ 'text.key' | trans }} }
 * Format for plural: {@code {{ 'text.key' | trans: <quantity> }} }
 */
@Pipe({ name: 'trans' })
export class TranslatePipe implements PipeTransform {

  /** Default map for plurals for each language*/
  private pluralDefaultMap = {
    'en': {
      1: 'one'
    },
    'de': {
      1: 'one'
    },
    'cs': {
      1: 'one',
      2: 'few',
      3: 'few',
      4: 'few'
    }
  };

  constructor() { }

  transform(key: string, quantity?: number): string {
    // In case the key was not configured we return an empty string
  if (key === '') {
      return '';
    }

    let value;

    if (quantity === undefined) {
      value = TextConfigService.getValue(key);
    } else {
      key += '.' + this.getPluralKey(quantity);
      value = TextConfigService.getValue(key);
    }

    if (value === undefined) {
      log.error('Text config key is missing for the key: ' + key);
      return key;
    }

    return value;
  }

  /**
   * Finds the plural suffix based on quantity as per the plural rule for each
   * language.
   *
   * @param quantity the quantity value
   */
  getPluralKey(quantity): string {

    const language = environment.locale.substr(0, 2);
    let pluralSuffix =  this.pluralDefaultMap[language][quantity];

    // Checks it falls on the plural
    if (pluralSuffix === undefined) {
      pluralSuffix = 'other';
    }

    return pluralSuffix;
  }
}
