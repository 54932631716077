<div class="search-wrapper">
  <div class="sharedSearch">
  <input #searchField
      data-test-id="search-input"
      autocapitalize="none"
      type="search"
      (keyup.enter)="doSearch(searchField.value)"
      placeholder="{{ 'shared.search.input.placeholder' | trans }}"
      class="searchInput" [formControl]="searchFormControl"
      [matAutocomplete]="auto"
      [ngClass]="{
        'has-val': searchField.value,
        'close-icon-space': isRedesign
      }" />
      <span class="side-search-icon" *ngIf="!isMobile" (click)="doSearch(searchField.value)"></span>
      <span class="side-close-icon" *ngIf="isMobile" (click)="clearSearchField()"></span>
      <button mat-raised-button class="searchButton" (click)="doSearch(searchField.value)">
      </button>
       <!-- ERBT-6840: Used to inject the close button of the search from the parent. -->
      <ng-content></ng-content>
  </div>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      *ngFor="let suggestion of suggestions | async"
      [value]="suggestion.searchTerm"
      (click)="doSearchSuggestion(suggestion)"
      (keyup.enter)="doSearchSuggestion(suggestion)">
      <div [ngClass]="'searchIcon searchIcon-' + suggestion.type.toLowerCase()"></div>
      {{ suggestion.searchTerm }}
    </mat-option>
  </mat-autocomplete>
  <div *ngIf="isRedesign" class="search-overlay"></div>
</div>