<div [ngClass]="isRedesign ? 'banner-redesign':''" class="banner" *ngIf="promotionItem">
  <div class="image-holder"
   [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}">
  </div>
  <div *ngIf="showTextBanner()" class="banner-TextArea">
    <div class="text-holder">
      <h3>{{ promotionItem.title }}</h3>
      <p *ngIf="promotionItem.description && isRedesign" class="description">{{ promotionItem.description }}</p>
      <!--
        Note that we use "preservePurchaseSource=false" here, because this banner is also used on the shop page
        and in this case we must not forward the current (shop related) purchase source.
        Ideally we would make this configurable in the future
        to allow enabling it again for the banner on the home page.
        But for now this is not necessary, because deep links to the home page are badly supported anyway.
      -->
      <button *ngIf="promotionItem.targetUrl" mat-raised-button class="bt-main bt-secondary" 
        (click)="trackAndForward(promotionItem.targetUrl, 'banner.click', promotionItem.title,
            false, source, promotionId)"
      color="primary">
          <span *ngIf="!buttonText">{{"banner.button.text" | trans}}</span>
          <span *ngIf="buttonText">{{ buttonText }}</span>
      </button>
    </div>
  </div>
  <!-- Redesign banner Logged in -->
  <div *ngIf="showToneCard()" class="banner-short-info" >
    <div class="short-info-wrapper">
      <div class="name-phone-wrapper">
        <span class="name">{{"banner.logged.title.text" | trans}}</span>
        <span class="phone">
          <span class="phone-icon"></span>
          <span>{{getPhoneNumber()}}</span>
        </span>
      </div>
      <div class="tone-image-info">
        <div class="tone-image-holder">
          <ng-container *ngIf="isShuffleActive; else coverImage">
            <div class="shuffle-icon"></div>
          </ng-container>
          <ng-template #coverImage>
            <img *appLazy width="120" height="120" [src]="defaultTone.cover | image:113:113" />
          </ng-template>
          <app-play-button *ngIf="!isShuffleActive" [tones]="[defaultTone]"></app-play-button>
        </div>
        <div class="tone-info">
          <ng-container *ngIf="isShuffleActive; else toneInfo">
            <span *ngIf="getIGTbyProfile()" class="tone-title tone-igt"><span class="igt-icon"></span><span class="tone-igt-title">
                {{getIGTbyProfile()}}
              </span></span>
            <span class="tone-artist">{{'banner.logged.shuffle.artist.text' | trans}}</span>
          </ng-container>
          <ng-template #toneInfo>
            <span *ngIf="getIGTbyProfile()" class="tone-title tone-igt"><span class="igt-icon"></span><span class="tone-igt-title">
                {{getIGTbyProfile()}}
              </span></span>
            <span class="tone-title" [title]="defaultTone?.title">{{defaultTone?.title}}</span>
            <span class="tone-artist" [title]="defaultTone?.artist">{{defaultTone?.artist}}</span>
          </ng-template>
          <button data-test-id="change-default-tone-button" routerLink="/setting/default" mat-raised-button class="bt-main bt-secondary switch-tone-button" [ngClass]="isShuffleActive ? 'shuffle-active':''" color="primary">
            <span>{{ 'banner.logged.button.text' | trans }}</span>
        </button>
        </div>
      </div>
    </div>
  </div>
</div>
