/**
 * Component to the shared UGC landing page
 */
import { OnInit, Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { first } from 'rxjs/operators';

import log from 'app/core/logging/logger.service';

import { DomSanitizer } from '@angular/platform-browser';

import { BaseViewComponent } from 'app/shared/base-view.component';
import { Tone } from '../model/tone';
import { SharedUgcService } from './shared-ugc.service';
import { BrowserService } from 'app/core/browser.service';
import { ResultStatus } from 'app/model';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { ClientConfig } from '../core/config/client-config';
import { environment } from 'environments/environment';
import { ToneService } from 'app/tone/tone.service';

@Component({
  templateUrl: './shared-ugc.component.html'
})

export class SharedUgcComponent extends BaseViewComponent implements OnInit {

  public tone: Tone;
  public textWithAppLink;
  public sharedUgcCoverImage;

  private translate = new TranslatePipe();

  constructor(
    private sharedUgcService: SharedUgcService,
    private browserService: BrowserService,
    private toneService: ToneService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    router: Router,
    zone: NgZone) {
    super(zone, router);
    this.sharedUgcCoverImage = environment.opcoConfig.sharedUgcCoverImage;
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParams['token'];

    if (token === undefined) {
      log.info("No token found as a query parameter, get fallback prelisten tone");
      this.getFallbackTonePrelistenTone();
    } else  {
      log.info('Get Shared UGC with token ' + token);
      this.getSharedUgc(token);
    }

    if (this.browserService.isAndroid()) {
      const appLinkText = this.translate.transform('tone.page.info.webPurchase.html');
      this.textWithAppLink = this.domSanitizer.bypassSecurityTrustHtml(appLinkText);
    }
  };

  private getSharedUgc(token: string): void {
    this.showLoading();
    this.sharedUgcService.getSharedUgc(token).subscribe(result => {
      if (result.resultStatus === ResultStatus[ResultStatus.SUCCESS]) {
        this.hideLoading();
        this.tone = result.sharedUgc;
        log.info("Shared UGC succesfully fetched from the server, " + this.tone);
      } else {
        log.info("Failed to resolve shared UGC token. Get fallback prelisten tone");
        this.getFallbackTonePrelistenTone();
      }
    }, error => this.handleError(error));
  }

  private getFallbackTonePrelistenTone() {
    this.showLoading();
    const shopId = ClientConfig.sharedUgcFallbackToneShopId.getString();
    this.toneService.getRbtsForShop(shopId)
      .pipe(first())
      .subscribe(toneList => {
        this.hideLoading();
        if (toneList.totalCount !== 0) {
          this.tone = toneList.tone[0];
        } else {
          this.handleError('Unable to fetch fallback prelisten tone, shop with id ' + shopId + ' is empty');
        }
      }, error => this.handleError(error));
  }
}
