/**
 * ConfigService is an app-wide singleton and only instantiated once.
 * This loads all the config from the server before app loads.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

import UrlBuilder from '../url-builder';
import NetworkUtil from '../network/network-util';
import HttpHeader from '../network/http-header';
import ContentTypes from '../network/content-types';

/**
 * Previously this class was a child of BaseConfigService, a base class
 * that was extending the BaseNetworkService and that was the parent for this
 * class and the TextConfigService.
 *
 * But this inheritance was causing several circular dependencies, that implies
 * that the AOT compilation doesn't work.
 * After a refactoring of the code the ConfigService is able to make the request
 * to fetch the config data from the server.
 * More information: https://jira.real.com/browse/ERBT-4036
 */
@Injectable()
export class ConfigService {

  private static configMap: Map<string, any> = new Map<string, any>();

  /**
   * Return the config value corresponding to the key.
   * If the key does not exist, returns {@code undefined}
   *
   * @param the config key
   * @returns the config value corresponding to the key.
   * Returns {@code undefined} there is no key.
   */
  static getValue(key: string): any {
    return ConfigService.configMap.get(key);
  }

  constructor(private httpClient: HttpClient) {}

  setConfig(configMap: Map<string, any>) {
    ConfigService.configMap = configMap;
  }

  getUrl(): string {
    return UrlBuilder.getClientConfigUrl();
  }

  /**
   * Creates the promise to fetch the config values from the server.
   * This promise will be subscribed by the app initializer and make sure
   * that the request has been completed before the app launch. App initializer
   * configuration is in  {@code CoreModule}
   *
   * @returns promise to fetch the client configs
   */
  loadConfig(): Promise<any> {
    return this.sendConfigRequest(this.getUrl())
      .then(this.setConfig);
  }

  sendConfigRequest(url: string): Promise<any> {

    const headers = new HttpHeaders();
    headers.append(HttpHeader.acceptLanguage, environment.locale);
    headers.append(HttpHeader.clientId, NetworkUtil.clientKey);
    headers.append(HttpHeader.accept, ContentTypes.json);

    // HttpClient.get() by default returns the JSON data contained in the response body.
    return this.httpClient.get(url, { headers, observe : 'response'})
    .toPromise()
    .then(this.extractData);
  }


  extractData(res: HttpResponse<any>) {
    return NetworkUtil.extractConfigMap(res);
  }
}
