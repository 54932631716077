<span class="dialog">
<button mat-button *ngIf="showCloseIcon" (click)="cancel()" class="bt-close" ></button>
<h1 mat-dialog-title>{{ texts.title | trans }}</h1>
<div mat-dialog-content>
  <p>{{ texts.content | trans }}</p>
  <div *ngIf="offeredPackages">
    <mat-radio-group [(ngModel)]="selectedPackage">
      <mat-radio-button *ngFor="let package of offeredPackages" [value]="package">
        {{ getOfferText(package) }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="confirm()" color='primary' class="bt-main">
    {{ texts.confirm | trans }}
  </button>
  <button mat-raised-button *ngIf="!showCloseIcon"
  class="bt-main bt-secondary cancel"
  (click)="cancel()">
    {{ texts.cancel | trans }}
    </button>
</div>
</span>
