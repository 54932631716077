import {Component, OnDestroy, Input} from '@angular/core';

import {SlideComponent} from './slide.component';

export enum Direction {UNKNOWN, NEXT, PREV}

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html'

})
export class CarouselComponent implements OnDestroy {
    @Input() public noWrap: boolean;
    @Input() public noPause: boolean;
    @Input() public noTransition: boolean;

    @Input() public get interval(): number {
        return this.intervalValue;
    }


    public set interval(value: number) {
        this.intervalValue = value;
        this.restartTimer();
    }

    public slides: Array<SlideComponent> = [];
    public currentInterval: any;
    private isPlaying: boolean;
    private destroyed = false;
    private currentSlide: SlideComponent;
    private intervalValue: number;


    public ngOnDestroy() {
        this.destroyed = true;
    }

    public select(nextSlide:  SlideComponent, direction: Direction = Direction.UNKNOWN) {

        const nextIndex = nextSlide.index;
        if (direction === Direction.UNKNOWN) {
            direction = nextIndex > this.getCurrentIndex() ? Direction.NEXT : Direction.PREV;
        }

        // Prevent this user-triggered transition from occurring if there is already one in progress
        if (nextSlide && nextSlide !== this.currentSlide) {

            this.goNext(nextSlide, direction);
        }
    }

    private goNext(slide: SlideComponent, direction: Direction) {
        if (this.destroyed) {
            return;
        }

        slide.direction = direction;
        slide.active = true;

        if (this.currentSlide) {
            this.currentSlide.direction = direction;
            this.currentSlide.active = false;
        }

        this.currentSlide = slide;

        // every time you change slides, reset the timer
        this.restartTimer();
    }

    private getSlideByIndex(index: number) {
        return this.slides[(index)];
    }

    private getCurrentIndex() {
        return !this.currentSlide ? 0 : this.currentSlide.index;
    }

    private next() {
        const newIndex = (this.getCurrentIndex() + 1) % this.slides.length;

        if (newIndex === 0 && this.noWrap) {
            this.pause();
            return;
        }


        return this.select(this.getSlideByIndex(newIndex), Direction.NEXT);
    }

    private prev() {
        const newIndex = this.getCurrentIndex() - 1 < 0 ? this.slides.length - 1 : this.getCurrentIndex() - 1;

        if (this.noWrap && newIndex === this.slides.length - 1) {
            this.pause();
            return;
        }

        return this.select(this.getSlideByIndex(newIndex), Direction.PREV);
    }

    private restartTimer() {
        this.resetTimer();
        const interval = +this.interval;
        if (!isNaN(interval) && interval > 0) {
            this.currentInterval = setInterval(() => {
                const nInterval = +this.interval;
                if (this.isPlaying && !isNaN(this.interval) && nInterval > 0 && this.slides.length) {
                    this.next();
                } else {
                    this.pause();
                }
            }, interval);
        }
    }

    private resetTimer() {
        if (this.currentInterval) {
            clearInterval(this.currentInterval);
            this.currentInterval = null;
        }
    }

    public play() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.restartTimer();
        }
    }

    public pause() {
        if (!this.noPause) {
            this.isPlaying = false;
            this.resetTimer();
        }
    }

    public addSlide(slide: SlideComponent) {
        slide.index = this.slides.length;
        this.slides.push(slide);
        if (this.slides.length === 1 || slide.active) {
            this.select(this.slides[this.slides.length - 1]);
            if (this.slides.length === 1) {
                this.play();
            }
        } else {
            slide.active = false;
        }
    }

}

