import { environment } from '../../../environments/environment';

/**
 * Injectable logger service that logs to console
 * on development builds and does nothing on production builds
 */
export default class Logger {

  private static isProduction = environment.production === true;
  private static noop = () => { };

  static get debug() {
    return !Logger.isProduction ? console.debug.bind(console) : Logger.noop;
  }

  static get error() {
    return !Logger.isProduction ? console.error.bind(console) : Logger.noop;
  }

  static get log() {
    return !Logger.isProduction ? console.log.bind(console) : Logger.noop;
  }

  static get info() {
    return !Logger.isProduction ? console.info.bind(console) : Logger.noop;
  }

  static get warn() {
    return !Logger.isProduction ? console.warn.bind(console) : Logger.noop;
  }

}
