<span class="dialog">
    <h1 mat-dialog-title>{{ texts.title | trans }}</h1>
    <div mat-dialog-content>
      <p>{{ texts.content | trans }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="accept()" color='primary' class="bt-main">
        {{ texts.confirm | trans }}
      </button>
      <button mat-raised-button
      class="bt-main bt-secondary cancel"
      (click)="delete()">
        {{ texts.delete | trans }}
        </button>
       <button mat-raised-button
       class="bt-main bt-secondary cancel"
       (click)="deactivate()">
        {{ texts.deactivate | trans }}
        </button>
    </div>
</span>    