import { Injectable } from '@angular/core';
import { SubscriptionService } from 'app/core/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseTracking } from 'app/tracking/purchase.tracking';
import { AccountService } from 'app/account/account.service';
import { Result, ErrorCode } from 'app/model';
import { Observable } from 'rxjs';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { ClientConfig } from 'app/core/config/client-config';
import { GtagService, GtagEvents } from 'app/core/gtag.service';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { PurchaseSourcesInfo } from 'app/tracking/purchase.sources.info';
import { map } from 'rxjs/operators';
import { LandingPromoType } from '../../../home/promotions/landingPromo/landing-promo.component';

declare var gtag: Function;

@Injectable()
export class RbtActivationService {
  
  private readonly genericErrorText = 'common.error.message';
  
  constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private dialogService: DialogService,
        private subscriptionService: SubscriptionService) {
    }

    canSubscribe(navigateUrl?: string, routerNavigateParameters?: any): Observable<Boolean> {
        return this.subscriptionService.canSubscribe(navigateUrl, routerNavigateParameters);
    }

    activateUser(toneId: string, isPromotion: boolean, isOptIn?: boolean, tariffId?: string, serviceId?: string): Observable<Result> {
        let purchaseSource: string[];
        this.route.queryParams.subscribe(queryParams => {
            purchaseSource = PurchaseTracking.getSourceFromQueryString(queryParams);
        });

        // ERBT-5832: If user is entitled to happy promotion subscription, then attempt to subscribe
        // them with the happy service ID no matter what.
        if (isPromotion && this.subscriptionService.isHappyPromoSubscriptionEntitled()) {
          purchaseSource = [];
          purchaseSource.push(PurchaseSources.Subscription);
          purchaseSource.push(PurchaseSourcesInfo.LandingHappyFlow);
          serviceId = ClientConfig.happySubscriptionServiceId.getString();
        }

        // ERBT-5462: Get the initial purchase sources that is originated from a deep link.
        let deepLinkPurchaseSource = this.subscriptionService.getInitialPurchaseSourcesFromDeepLink();
        if (deepLinkPurchaseSource && deepLinkPurchaseSource.length) {
          purchaseSource = deepLinkPurchaseSource;
        }

        // if we force isPromotion = false (e.g. when the user accepts to subscribe after the popup
        // saying that they are not OMF entitled), we also need to unset isOptIn
        if (isOptIn && !isPromotion) {
          isOptIn = false;
        }
       

        return this.accountService.subscribe(isPromotion, purchaseSource, toneId, serviceId, isOptIn, tariffId)
        .pipe(
          map(result => {
            if (isPromotion && this.subscriptionService.isHappyPromoSubscriptionEntitled()) {
              // ERBT-5832: After a succesfull or a failed (due to a business error) subscription
              // the user is no longer entitled to the happy promo subscription.
              this.subscriptionService.setHappyPromoSubscriptionEntitled(false);
            }
            return result;
          }));
    }

   optIn(): Observable<Result> {
      // TODO any tracking necessary?
      return this.accountService.optin();
    }

    handleHappyActivationError(errorCode: string) {
      let messageError = this.getErrorMessage(errorCode);
      if (messageError === this.genericErrorText) {
        // For happy subscription we display a different generic error message
        // for all the unknown / unmapped IPP errors. 
        messageError = 'register.popup.error.happyPromoNotEligible';
      }
      // Redirect to home
      this.dialogService.errorWithAction(messageError)
      .subscribe(() => this.router.navigate(['/home']));
    }

    // Handles the Not OMF eligible user (ErrorCode.NOT_ELIGIBLE_FOR_PROMOTION)
    displayNotOmfPopup(): Observable<Boolean> {
      return this.dialogService.confirm(
        'register.popup.error.notEntitledFreeTrial.title',
        'register.popup.error.notEntitledFreeTrial.description',
        'register.popup.button.buy',
        'common.button.cancel',
        [ClientConfig.monthlySubscriptionPrice.getNumber()]);
    };

    getErrorMessage(errorCode: string) {
        switch (ErrorCode[errorCode]) {
          case ErrorCode.OUT_OF_CREDIT:
            return 'register.popup.error.outOfCredit';
          case ErrorCode.BARRED:
            return 'register.popup.error.barred';
          case ErrorCode.NOT_ALLOWED_WITH_INITIAL_CREDITS:
            return 'register.popup.error.notAllowedWithInitialCredit';
          case ErrorCode.WRONG_OPERATOR:
            return 'register.popup.error.wrongOperator';
          case ErrorCode.AMOUNT_EXCEEDS_LIMIT:
            return 'register.popup.error.amountExceedsLimit';
          case ErrorCode.BARRED_FOR_MOBILE_PURCHASE:
            return 'register.popup.error.barredForMobilePurchase';
          case ErrorCode.ACCOUNT_SUSPENDED:
            return 'register.popup.error.accountSuspended';
          case ErrorCode.MSISDN_NOT_ACTIVE:
            return 'register.popup.error.msisdnNotActive';
          default:
            // If none of the errors above, take to generic error popup
            return this.genericErrorText;
        }
    }

    trackActivation() {
      GtagService.getInstance().trackEvent(GtagEvents.ACTIVATION);
    }
}
