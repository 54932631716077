import log from '../logging/logger.service';

/**
 * All information about the current tokens.
 */
export class Token {

  /** Current access token */
  private accessToken: string;

  /** Current refresh token */
  private refreshToken: string;

  /** The timestamp when the access token will go away */
  private expiryTime: number;

  /** MSISDN of the user */
  private msisdn: string;

  getAccessToken() {
    if (Date.now() < this.expiryTime) {
      log.info('Access token is not expired');
      return this.accessToken;
    } else {
      log.info('Access token is expired');
      return null;
    }
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  getExpiryTime() {
    return this.expiryTime;
  }

  getMsisdn(): string {
    return this.msisdn;
  }

  setMsisdn(msisdn: string) {
    this.msisdn = msisdn;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  setExpiryTime(expiryTime: number) {
    this.expiryTime = expiryTime;
  }

}
