<!-- The slider is only visible in VFDE (Other Opcos don't have the two version RBT)-->
<div *ngIf="showRbtAlternativePrelisten" class="rbt-version-container">
    <mat-slide-toggle (change)="changeRbtVersion()" [(ngModel)]="toneWithItu" 
        labelPosition="before" color="primary" [disabled]="isToggleDisabled">
        <span>{{ 'setting.switchPlayWithToot' | trans }}</span>
    </mat-slide-toggle>
    <div *ngIf="tones && !allTonesHaveAlternativeVersion">
        <span>{{ switchDisabledDescription | trans }}</span>
    </div>
</div>
<app-play-button class="setting-preview" [tones]="prelisten" [playAlternativeAudio]="!toneWithItu"></app-play-button>
