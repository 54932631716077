
export class ConfigurationTiles {
    configurations: Options[];
    constructor() {
        this.configurations = [];
    }
}

export class Options {
    action: string;
    name: string;
    img: string;
    constructor(name, img, action) {
      this.name = name;
      this.img = img;
      this.action = action;
    }
}
