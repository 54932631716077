import { Params } from '@angular/router';
import CustomHttpParams from 'app/core/network/custom-http-params';

import { PurchaseSources } from 'app/tracking/purchase.sources';

/**
 * Utility class for handling the ERBT-3371 Purchase Source Tracking
 * query parameter that is passed between components
 */
export class PurchaseTracking {

    /**
     * Condense the source and source info into a single obfuscated string
     * that can then be passed as the value of a URL parameter between pages.
     */
    public static encodeSourceTrackingInfo(source: string, sourceInfo?: string): string {
        // prefix the source
        let plain = 's:' + source;

        if (sourceInfo) {
            // Base64 encoding interprets the characters as bytes and chokes if there are chars above U+00FF.
            // The easiest solution is applying URL encoding, which gives an all-ASCII result.
            // It also ensures that there doesn't appear another colon in the result.
            plain = plain + ':' + encodeURIComponent(sourceInfo);
        }
        // We now have something like 's:someSource:someInfo'
        // Obfuscate it a little and base64 encode
        return btoa(PurchaseTracking.obfuscate(plain));
    }

    /**
     * Extract the source and sourceInfo from a condensed string which was previously
     * built with `encodeSourceTrackingInfo`
     *
     * Returns a string array with the source at index 0 and the sourceInfo at index 1 or
     * an empty array if there is no source available
     */
    public static getSourceFromPstParam(paramValue: string): string[] {
        if (!paramValue) {
            return [];
        }
        const partsObfuscated = atob(paramValue);
        const parts = PurchaseTracking.obfuscate(partsObfuscated).split(':');

        if (parts.length === 2 && parts[0] === 's') {
            return [parts[1]];
        }
        if (parts.length === 3 && parts[0] === 's') {
            return [parts[1], decodeURIComponent(parts[2])];
        }
        // something went wrong, maybe the user messed with our URL
        return [];
    }

    /**
     * Extracts the current purchase source from the browser location.
     * Takes `pst` as well as `sourceInfo` parameters into account.
     *
     * @param queryParams query string from browser location
     */
    public static getSourceFromQueryString(queryParams: Params): string[] {
        const pst = queryParams['pst'];
        if (pst) {
            // if purchase source tracking is already there, use it
            return PurchaseTracking.getSourceFromPstParam(pst);
        } else {
            // check for a direct link, indicated by the presence of the
            // sourceInfo query param
            const sourceInfoDeep = queryParams['sourceInfo'];
            if (sourceInfoDeep) {
                return [PurchaseSources.DeepLink, sourceInfoDeep];
            }
        }

        return [];
    }

    /**
     * Obfuscates or deobfuscates a string of bytes.
     * We do this by XORing a sequence of bytes from a LCG (Linear Congruential pseudo-random number Generator)
     * to the given byte string.
     * XOR is self-inverse, so calling the method again removes the obfuscation.
     *
     * See [LCG](https://en.wikipedia.org/wiki/Linear_congruential_generator) at Wikipedia
     */
    private static obfuscate(secret: string): string {
        let str = '';
        let keyStream = 1;
        for (let i = 0; i < secret.length; i++) {
            // Key stream is a simple LCG (the one from Borland C/C++ compiler).
            // This is completely suffitient for our use. We don't need cryptographic security.
            // After all, this is obfuscation, not encryption.
            // eslint-disable-next-line no-bitwise
            keyStream = (22695477 * keyStream + 1) & 0xffffffff;  // mod 2^32

            // XOR in the key stream. Note that we discard the 16 low order bits due to their short period.
            // eslint-disable-next-line no-bitwise
            str += String.fromCharCode((secret.charCodeAt(i) ^ (keyStream >> 16)) & 0xff);
        }
        return str;
    }

    /**
     * Adds purchase source parameters to the POST request for purchase or activation.
     *
     * @param params URL request parameters for purchase or activate call
     * @param purchaseSource purchase source from `getSourceFromQueryString`
     */
    public static addPurchaseSourceToRequest(params: CustomHttpParams, purchaseSource: string[]): void {
        if (purchaseSource && 0 in purchaseSource) {
            params.set('source', purchaseSource[0]);
            if (1 in purchaseSource) {
                params.set('sourceInfo', purchaseSource[1]);
            }
        }
    }
}
