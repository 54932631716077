import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserService } from 'app/core/browser.service';
import { ExternalRouting } from 'app/core/external.routing';
import { SettingService } from 'app/core/setting.service';
import { Tone } from 'app/model/tone';
import { BaseViewComponent } from 'app/shared/base-view.component';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { environment } from 'environments/environment';
import { PlayButtonService } from '../play-button/play-button.service';
import { ProfileService } from '../../core/profile.service';
import { Profile } from '../../model';
import { AuthService } from '../../core/auth/auth.service';


@Component({
  selector: 'app-tone-list-item',
  templateUrl: './tone-list-item.component.html'
})

export class ToneListItemComponent extends BaseViewComponent implements OnInit, AfterViewInit {

  readonly shuffleParam = SettingService.shuffleParam;

  @Input() tone: Tone;

  /** Set to `true` if the tone is in the user's library, i.e., already owned by the user. */
  @Input() library = false;
  @Input() promotion = false;
  @Input() expired = false;

  @Input() selection = false;
  @Input() selected = false;

  @Input() purchaseSource: string;
  @Input() purchaseSourceInfo: string;
  @Input() purchaseSourcePropagateCurrent = true;

  @Input() imageSize = this.isRedesign ? 116 : 60;

  @Input() hidePrice = false;
  @Input() buttonText = 'tone.list.button';

  @Input() showSmsCode: boolean = true;

  @Output() onBuy = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<string>();
  @Output() onPrelistenStarted = new EventEmitter<Tone>();

  smsLinkSeparator: string;
  expiryDate: Date;
  buyText: string;
  profile:Profile;
  isLoggedIn:boolean;

  constructor(
    private browserService: BrowserService,
    private playButtonService: PlayButtonService,
    private profileService: ProfileService,
    private authService: AuthService,
    zone: NgZone,
    router: Router) {
    super(zone, router);
    playButtonService.audio$.subscribe();
    this.smsLinkSeparator = this.browserService.getSmsLinkSeparator();
    this.isLoggedIn = this.authService.isLoggedIn();
    if(this.isLoggedIn) {
      this.profileService.getProfile().subscribe((profile) => {
        this.profile = profile;
      })
    }
  }

  ngOnInit() {
    if (this.library && this.tone && this.tone.expireDate) {
      this.expiryDate = new Date(this.tone.expireDate);
    }
    this.buyText = this.tone?.price === undefined ? 'common.details' : 
    this.tone.price === 0 ? 'common.free' : 
    this.buttonText;

    if (environment.opcoConfig.newLayout) {
      // For VF/DE rebranding the sms code must be visible only for Desktop not for mobile view.
      this.showSmsCode = !this.isMobile && this.showSmsCode;

      // For VF/DE, request the correct image resolution for tone list item.
      this.imageSize = this.isMobile ? 80 : 100;
    }
  }

  delete() {
    this.stopAudio();
    this.onDelete.emit(this.tone.id);
  }

  buy() {
    this.stopAudio();
    if (!this.promotion) {
      ExternalRouting.navigate(this.router, ['/rbt/id', this.tone.id],
        this.purchaseSourcePropagateCurrent,
        this.purchaseSource, this.purchaseSourceInfo);
    }
    this.onBuy.emit(this.tone.id);
  }

  repurchase() {
    this.stopAudio();
    ExternalRouting.navigate(this.router, ['/rbt/id', this.tone.id], true, PurchaseSources.ExpiredContent);
  }

  private stopAudio() {
    this.playButtonService.stopAudio();
  }

   ngAfterViewInit() {
    // Skip the super call to scroll top
  }
}
