import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseNetworkService } from 'app/core/network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import { AuthService } from 'app/core/auth/auth.service';
import UrlBuilder from 'app/core/url-builder';
import { ShopList } from '../model/shop-list';

@Injectable()
export class ShopService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getShops(extraShopIds?: String[]): Observable<ShopList> {
    const url = UrlBuilder.getShopsUrl();
    const params = this.createURLSearchParams();

    if (extraShopIds && extraShopIds.length > 0) {
      params.append('extraShopIds', extraShopIds.join(';'));
    }

    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
