import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { YoungPromoComponent } from './young-promo.component';

@NgModule({
  imports: [RouterModule.forChild(getRoutes())],
  exports: [RouterModule]
})
export class YoungPromoRoutingModule { }

function getRoutes(): Routes {
  // only allow /young for OpCos where it is enabled
  return environment.youngPromoEnabled ? [{ path: 'young', component: YoungPromoComponent }] : [];
}