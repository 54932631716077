
/** A tone (RBT, IGT, UGC).  */
// Documentation taken from protobuf templates
export class Tone {

    /** ID of the tone. */
    id: string;

    /** Artist of the tone. Undefined for UGC. */
    artist: string;

    /** Title of the tone. For UGCs this is the name entered by the user. */
    title: string;


    /**
     * File name of the JPG file containing the cover art for this tone.
     * Must be passed as path argument to the image transcoder.
     */
    cover: string;

    /** Path to the prelisten MP3. */
    prelisten: string;


    /** Genre code of the RBT. Only set for certain calls. */
    genreCode?: string;

    /** Genre name of the RBT. Only set for certain calls. */
    genreName?: string;


    /** Price of the RBT. */
    price?: number;


    /**
     * Some countries have expiration - this is when a rbt in users album needs
     * to be paid again to continue to own it.
     * Date stored as Unix timestamp in milliseconds.
     * @example <caption> To convert to a Date object use the following:</caption>
     * let d: Date = new Date(tone.expireDate);
     */
    expireDate?: number;

    /** Whether the tone is user generated content. */
    isUgc: boolean;

    /** Secure token which uniquely defines a UGC. Only included for certain calls. */
    ugcToken?: String;

    /** URL to the UGC prelisten landing page. Only included for certain calls. */
    landingPage?: string;


    /**
     * The numeric short code of the RBT.
     */
    numericShortCode?: string;

    /**
     * The textual/free-form short code of the RBT.
     */
    textualShortCode?: string;

    /**
     * URL of the alternative (single toot) prelisten MP3.
     * Only set if supported by the platform (currently just VF/DE) as well as by the particular content.
     */
    prelistenAlternativeAudio?: string;
}
