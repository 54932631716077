import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { filter, map } from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  private currentUrl: string = '';
  private regexLib = new RegExp("library(\/[0-2])?");
  private regexAccount = new RegExp("account(\/[0-2])?");

 
  /* The pauseDisplay boolean signals whether or not the application should wait before displaying the breadcrumb.*/
  public pauseDisplay = false;
  public breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    // TODO If enabled for VF/DE register these.
    this.setDefaultBreadcrumb();
    this.listenForRouteChange();
    this.listenForBreadcrumbUpdate();
  }

  private setDefaultBreadcrumb() {
    this.setCurrentUrl();  
    this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute.root);
  }

  private listenForBreadcrumbUpdate() {
    this.breadcrumbService.onUpdate().subscribe((str: string) => {
      this.handleBreadcrumbUpdate(str);
    });
  }

  /**
   * Updates the last breadcrumb with a custom name.
   * Use this method to update the last breadcrumb name asychronously
   * with a dynamic value (e.g. tone or shop name)
   **/
  private handleBreadcrumbUpdate(str: string) {
    // Update last breadcrumb item with the given string (e.g. tone name, shop name).
    if (this.breadcrumbs.length > 0) {
      let lastBreadcrumb: Breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
      lastBreadcrumb.name = str;
      // Show breadcrumb navigation.
      this.pauseDisplay = false;
   } else {
      // No breadcrumb navigation available for this route, skip showing it.
      this.pauseDisplay = true;
   }
  }

  private listenForRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        return route;
      })
    ).subscribe(
      (route: ActivatedRoute) => this.handleCurrentRoute(route)
    );
  }

  private setCurrentUrl() {
    let url: string = this.router.url;

    if (url) {
      //don't need the query parameter list here
      //nor do we need the initial /
      this.currentUrl = this.shortenUrlIfNecessary(url.substring(1));
    }
  }

  private handleCurrentRoute(route: ActivatedRoute) {
    this.setCurrentUrl();
    // Pass the root of the current route to build recursibly the breadcrumbs.
    this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute.root);
  }

  shortenUrlIfNecessary(returnUrl: string): string {
    let questionMark = returnUrl.indexOf("?");

    if (questionMark > -1) {
      returnUrl = returnUrl.substring(0, questionMark);
    }

    return returnUrl;
  }

  getBreadcrumbTextForKey(key) {
    var label = this.breadcrumbService.keyTextMapping.get(key);
    if (!label && this.regexLib.test(this.currentUrl)) {
      label = this.breadcrumbService.keyTextMapping.get( "library_"+key);
    }
    if (!label && this.regexAccount.test(this.currentUrl)) {
     label = this.breadcrumbService.keyTextMapping.get("account_"+key);
    }
    return label;
  }

/**
 * Recursively build breadcrumb according to activated route config.
 * @param route
 * @param url
 * @param breadcrumbs
 */

  buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    //If no routeConfig is avalailable we are on the root path.
    var breadcrumbData = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumbData : null;

    let path = breadcrumbData ? route.routeConfig.path : '';

    let _breadcrumbs = breadcrumbData ? breadcrumbData.breadcrumbs : '';
    let addToPrevious = breadcrumbData ? breadcrumbData.addToPrevious : false;
    let pauseDisplay = breadcrumbData ? breadcrumbData.pauseDisplay : false;

    // Hide breadcrumb navigation until the breadcrumb name is set dynamically by calling handleBreadcrumbUpdate().
    if (pauseDisplay) {
      this.pauseDisplay = true;
    }

    // If the route is dynamic route such as ':id' identify the corresponding breadcrumb name based on the paramName.
    var label = null;
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    const breadcrumb: Breadcrumb = {
      // Add a breadcrumb for the dynamic route such as :id
      name: this.getBreadcrumbTextForKey(label)
    };

    var newBreadcrumbs = [];

    // Add all configured breadcrumb items for this route.
    if (_breadcrumbs) {
      _breadcrumbs.forEach(_label => {
        var breadcrumb : Breadcrumb = {name:  this.getBreadcrumbTextForKey(_label)};
        if (breadcrumb.name) {
           breadcrumbs.push(breadcrumb);
        }
      });
    }

    // Ignore the breadcrumbs from the parent nodes and keep only the last one of the current node.
    if (addToPrevious) {
      breadcrumbs.slice(-1)[0];
    }

    newBreadcrumbs = this.pauseDisplay || breadcrumb.name ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadcrumb(route.firstChild, null, newBreadcrumbs);
    }


    if (addToPrevious) {
      // Add the current breadcrumbs as part of the previous breadcrumb navigation that is stored in memory.
      // That's a workaround in order to include the breadcrumbs of  /library/igt/select and /library/rbt/select 
      // routes as a child of the settings page.
      newBreadcrumbs = [...this.breadcrumbService.currentBreadcrumbs, ...newBreadcrumbs[newBreadcrumbs.length-1]];
    } else {
      // Store temporary the current breadcrumps in memory.
      this.breadcrumbService.currentBreadcrumbs = newBreadcrumbs;
    }
    return newBreadcrumbs;
  }
}