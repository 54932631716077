<!-- We are not using mat-grid-tile because of a bug (or feature) in material 2.
Where it won't calculate the height correctly if the mat-grid-tile is a child of a custom element.
Therefore we have to supply it in the owning component.

See https://github.com/angular/material2/blob/master/src/lib/grid-list/grid-list.ts#L65-->

<div class="tile-box" (click)="toggleFlip()" [@flipState]="flip">
    <div class="tile tile-front">
        <!-- ERBT-4182: Shown only in TMCZ (disable flipping tiles) -->
        <div class="tile-front-overlay">
            <div class="tile-text-holder">
                <p class="tile-title">{{ tone.title }}</p>
                <p class="tile-artist">{{ tone.artist }}</p>
            </div>
            <div class="tile-button">
                <p class="price tile-price">{{'common.priceFormat' | trans | format:tone.price}}</p>
                <a
                    (click)="buy()"
                    mat-raised-button
                    color="primary">
                    {{ 'common.details' | trans }}
                </a>
            </div>
        </div>
        <img appResizable *appLazy class="fill" [src]="tone.cover">
    </div>
    <div class="tile tile-back">
        <div class="tile-back-overlay"></div>
            <img appResizable *appLazy class="fill" [src]="tone.cover">
            <div class="tile-text-holder">
                <p class="tile-title">{{ tone.title }}</p>
                <p class="tile-artist">{{ tone.artist }}</p>
            </div>
            <div class="tile-player-holder">
                <app-play-button class="setting-preview" [tones]="[tone]"></app-play-button>
            </div>
            <div class="tile-button">
                <p class="price tile-price">{{'common.priceFormat' | trans | format:tone.price}}</p>
                <a
                    (click)="buy()"
                    mat-raised-button
                    color="primary">
                    {{ 'common.details' | trans }}
                </a>
            </div>
    </div>
</div>