import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

import { Observable, Subject } from 'rxjs';
import { TranslatePipe } from '../pipes/translate.pipe';
import { Breadcrumb } from './breadcrumb';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

  private subject = new Subject<string>();
  private hideSubject = new Subject<void>();
  private translate: TranslatePipe;

  public currentBreadcrumbs: Breadcrumb[] = [];
  public keyTextMapping = new Map<string, string>();

  constructor() {

    if (!environment.opcoConfig.newLayout) {
      return;
    }
    
    // We make use of the TranslatePipe directly here to avoid using 'trans' pipe in the HTML page
    // in order to also support dynamic texts for the {{ breadcrumb.name }} such as the Shop and Tone name.
    this.translate = new TranslatePipe();
  
    this.keyTextMapping.set('home', this.translate.transform('menu.home'));
    this.keyTextMapping.set('shop', this.translate.transform('menu.shop'));
    this.keyTextMapping.set('rbt', this.translate.transform('menu.shop'));
    this.keyTextMapping.set('faq', this.translate.transform('menu.faq'));

    this.keyTextMapping.set('account', this.translate.transform('menu.myAccount'));
    this.keyTextMapping.set('account_0', this.translate.transform('menu.submenu.account.settings'))
    this.keyTextMapping.set('account_1', this.translate.transform('menu.submenu.account.subscription'));
    this.keyTextMapping.set('account_2', this.translate.transform('menu.submenu.account.contentPackage'));

    this.keyTextMapping.set('library', this.translate.transform('menu.myLibrary'));
    this.keyTextMapping.set('library_0', this.translate.transform('menu.submenu.library.ringBackTones'))
    this.keyTextMapping.set('library_1', this.translate.transform('menu.submenu.library.personalMessages'));
    this.keyTextMapping.set('library_2', this.translate.transform('menu.submenu.library.prerecordedMessages'));

    this.keyTextMapping.set('setting', this.translate.transform('menu.callerExperience'));
    this.keyTextMapping.set('default', this.translate.transform( 'menu.submenu.callerExperience.allCallers'));
    this.keyTextMapping.set('caller', this.translate.transform('menu.submenu.callerExperience.individualCallers'));
    this.keyTextMapping.set('group', this.translate.transform('menu.submenu.callerExperience.groups'));
    this.keyTextMapping.set('dayOfWeek', this.translate.transform('menu.submenu.callerExperience.dayOfWeek'));
    this.keyTextMapping.set('timeOfDay', this.translate.transform('menu.submenu.callerExperience.timeOfDay'));

    this.keyTextMapping.set('caller_details', this.translate.transform('setting.caller.page.addNewCaller'));
    this.keyTextMapping.set('group_details', this.translate.transform('setting.group.page.heading'));
    this.keyTextMapping.set('dayOfWeek_details', this.translate.transform('setting.dayOfWeek.page.heading'));
    this.keyTextMapping.set('timeOfDay_details', this.translate.transform('setting.timeOfDay.page.heading'));

    this.keyTextMapping.set('rbt_select', this.translate.transform('library.select.rbt.page.title'));
    this.keyTextMapping.set('igt_select', this.translate.transform('library.select.igt.page.title'));
    this.keyTextMapping.set('ugc_record', this.translate.transform('ugc.page.title'));
  }

  onUpdate(): Observable<string> {
    return this.subject.asObservable();
  }
  

  updateBreadcrumb(str: string) {
    this.subject.next(str);
  }

  onHideBreadcrumb(): Observable<void> {
    return this.hideSubject.asObservable();
  }

  hideBreadcrumb() {
    this.subject.next(null);
  }
}