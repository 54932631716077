<span class="dialog rbt-select">
  <div class="dialog-header">
    <button mat-button (click)="dialogRef.close(false)" class="bt-close"></button>
    <h1 mat-dialog-title>{{ 'setting.page.rbtSelect.dialog.title' | trans }}</h1>
  </div>
  <div mat-dialog-content>
    <app-spinner [active]="spinnerActive"></app-spinner>
    <ng-container *ngIf="rbtList.totalCount > 0">
      <div class="shuffle-wrapper">
        <div class="shuffle-title-icon">
          <div class="shuffle-icon"></div>
          <div class="shuffle-title">{{ 'setting.page.rbtSelect.dialog.shuffle.text' | trans }}</div>
        </div>
        <div class="select-button">
          <button mat-raised-button color='primary' class="bt-main secondary" (click)="select(SettingService.shuffleParam)" disableRipple="true">
            {{ 'setting.page.igt.rbt.select.button' | trans }}
          </button>
        </div>
      </div>
      <div class="rbt-item-list">
        <div class="rbt-item" *ngFor="let tone of rbtList.tone">
          <div class="play-title">
            <div class="tone-image-holder">
              <img *appLazy  [src]="tone.cover | image:58:58" />
              <app-play-button [tones]="[tone]"  [preLoad]="recording" (onLoad)="updateDuration($event)"></app-play-button>
            </div>
            <div class="rbt-title">
            <span class="tone-title">{{tone.title}}</span>
            <span class="tone-artist">{{tone.artist}}</span>
            </div>
          </div>
          <div class="select-button">
            <button mat-raised-button color='primary' class="bt-main" (click)="select(tone.id)" disableRipple="true">
              {{ 'setting.page.igt.rbt.select.button' | trans }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</span>
