<div class="tone-list-container min-list-height">
  <!-- List view -->
  <mat-list *ngIf="!grid && tracksLoaded" class="tone-list button-less-list">
    <app-tone-list-item 
      [tone]="tone" 
      *ngFor="let tone of cachedTones; trackBy: trackByFn"
      [purchaseSource]="purchaseSource" 
      [purchaseSourceInfo]="purchaseSourceInfo"
      [purchaseSourcePropagateCurrent]="purchaseSourcePropagateCurrent"
      [imageSize]="imageSize">
    </app-tone-list-item>
  </mat-list>

  <!-- Grid view -->
  <mat-grid-list *ngIf="grid && tracksLoaded" cols="12" [gutterSize]="gutterSize">
    <mat-grid-tile 
      *ngFor="let tone of cachedTones; let j = index; trackBy: trackByFn"
      [colspan]="getTileSize(j).width"
      [rowspan]="getTileSize(j).height">
      <app-tone-grid-item mat-ripple 
        [tone]="tone"
        [purchaseSource]="purchaseSource" 
        [purchaseSourceInfo]="purchaseSourceInfo"
        [purchaseSourcePropagateCurrent]="purchaseSourcePropagateCurrent">
      </app-tone-grid-item>
    </mat-grid-tile>
  </mat-grid-list>

  <!-- Spinner while tracks are loading for a new shop -->
  <span class="small-spinner" *ngIf="!tracksLoaded && !initialLoadComplete"></span>

  <!-- Sentinel element for intersection observer -->
  <div class="sentinel" *ngIf="!tracksLoaded"></div>
</div>
