import { Input, OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { PromoService } from './promo.service';
import { BaseComponent } from 'app/base.component';
import { PromoItem } from 'app/model/promotions';
import { AnalyticsService } from 'app/core/analytics.service';
import { ExternalRouting } from 'app/core/external.routing';
import log from 'app/core/logging/logger.service';

@Directive()
export class BasePromoComponent extends BaseComponent implements OnInit {

  private readonly regexPromotion = /PROMOTION\(([^,]+)(?:,\s*button=(.*))?\)/i;

  @Input() promotionConfig: string;
  promotionItem: PromoItem;

  promotionId: string;
  buttonText: string;

  constructor(
    private promoService: PromoService,
    router: Router) {
    super(router);
  }

  ngOnInit() {

    log.info('Rendering promotion with ID: ' + this.promotionConfig);
    this.parsePromotionConfig();


    if (this.promotionId) {
      this.subscriptions.push(
        this.promoService.getPromotionById(this.promotionId)
          .subscribe(promotion => {
            this.promotionItem = this.promoService.getPromotionCompatibleWithDevice(promotion);
            if (this.promotionItem !== null) {
              this.setImage();
            }
          }, error => this.handleError(error))
      );
    }
  }

  /** Override point for sub classes to set the image if necessary */
  setImage() { }

  /**
   * Parses the config with format {@code PROMOTION({promoId}, button={buttonText})}
   */
  protected parsePromotionConfig() {
    if(this.promotionConfig){
      const matches = this.promotionConfig.match(this.regexPromotion);
      if (matches && matches[1]) {
        this.promotionId = matches[1].trim();
        if (matches[2]) {
          this.buttonText = matches[2].trim();
        }
      }
    }
  }

  track(action: string, title?: string) {
    AnalyticsService.getInstance().trackEventAction(action, title);
  }

  /**
   * Track an analytics event and then navigate to the given URL using `ExternalRouting`
   *
   * Supports external links starting with `http://` or `https://`
   * @param url the URL to navigate to, either an internal, relative one or an external, absolute one
   * @param action the analytics action
   * @param title the optional analytics event title
   * @param preservePurchaseSource indicates that the current page's purchase source should be propagated
   * @param source purchase source to be used (if none is available and preserved)
   * @param sourceInfo purchase source info to be used (if none is available and preserved)
   */
  trackAndForward(url: string, action: string, title: string,
        preservePurchaseSource: boolean, source?: string, sourceInfo?: string) {
    this.track(action, title);
    ExternalRouting.navigateExternalUrl(this.router, url,
      preservePurchaseSource, source, sourceInfo);
  }
}
