import { tmoConfig } from './tmo.config';
import { registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/de-AT';
import localeDeAtExtra from '@angular/common/locales/extra/de-AT';

registerLocaleData(localeDeAt, 'de-AT', localeDeAtExtra);

export const environment = {

  production: false,

  baseUrl: '/api-plus-sf',

  // If true takes config from local files
  mock: false,

  locale: 'de-AT',

  sentryUrl: 'https://289cae1595a44f429873633011805d8a@o66140.ingest.sentry.io/5315535',
  sentryRelease: '',

  // Pushwoosh Application Code
  pushwooshId: '2F4C2-BF85E',

  // Google Analytics Id
  googleAnalyticsId: null,
  enableGoogleAnalyticsByDefault: false,

  // Partner id of this application, used to distinguish the client (Not used since Telering shutdown)
  partnerId: '',

  appName: 'tmat',

  /**
   * IE 11 does not support SVG classes so we have to
   * keep the colors in the config
   */
  ugcButtonFillColor: '#ffffff',
  ugcButtonStrokeColor: '#333333',
  ugcButtonActiveColor: '#E20074',
  ugcBoxSize: 225,
  ugcRadius: 100,
  ugcIconWidth: 64,
  ugcIconHeight: 112,
  strokeWidth: 3,


  /**
   * We use the TMDE assets for TMAT, so this will remain as it is
   */
  ugcActiveIcon: 'assets/tmde/img/export/record-act.svg',
  ugcInactiveIcon: 'assets/tmde/img/export/record.svg',

  flippingTilesEnabled: true,

  showContentPackages: false,

  opcoConfig: tmoConfig,

  firebaseConfig: {
    apiKey: 'AIzaSyD08YcZYl6E7VbyPxQ2aeumD78_hq8ANKg',
    projectId: 'html-plus-3c2fe',
    messagingSenderId: '680271891757',
    appId: '1:680271891757:web:c3a41287c4055801fed7a4'
  },

  magentaMomentsEnabled: false,
  youngPromoEnabled:false,
  magentaBlackDaysEnabled:false,

  showAccountBillingDetails: true,

  useMiniTitleLayout: true

};
