import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import HttpHeader from './http-header';
import ContentType from './content-types';
import { AuthError } from '../auth/auth-error';
import { HttpResponseError } from './http-response-error';

/**
 * Contains network related helper methods.
 */
export default class NetworkUtil {

   /** The OAuth2 client id. */
   public static clientKey = 'HtmlPlus';

  /**
   * Extracts the (base) content type (i.e., the mime type without parameters) from the response.
   * and checks it is {@code ContentType.json}
   */
  static isJson(response): boolean {

    let contentType = response.headers.get(HttpHeader.contentType);

    if (contentType) {
      contentType = contentType.split(';')[0].trim();
      return contentType.toLowerCase() === ContentType.json;
    }

    return false;
  }

  /**
   * Converts the json result to key-pair map
   *
   * @param the response from the server
   * @returns the config map
   */
  static extractConfigMap(res: HttpResponse<any>) {

    const outPutMap = new Map<string, any>();
    const body = res.body;

    for (const item of body.config) {
      outPutMap.set(item.key, item.value);
    }

    return outPutMap;
  }

  /**
   * Parses the network related errors
   */
  static parseError(error: HttpErrorResponse | any): Error {


    if (error instanceof AuthError) {
      return error;
    }

    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      if (NetworkUtil.isJson(error)) {

        const err = error.message || JSON.stringify(error.message);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return new HttpResponseError(errMsg);
  }

}
