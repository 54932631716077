import { Component, } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';

import { AnalyticsService } from 'app/core/analytics.service';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { PushNotificationService } from './core/push-notification.service';
import { AuthService } from './core/auth/auth.service';
import { GtagService } from './core/gtag.service';
import { ClientConfig } from './core/config/client-config';
import { BrowserService } from './core/browser.service';
import { ConsentManagementService, ConsentStatusChangeEvent, Vendor } from './core/consent-management.service';

/**
 * The root component of the application. This will loaded and
 * injected to the index.html
 *
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  private _pageClickHandler;

  private readonly errorLazyLoadingFailedId = 2;

  constructor(
    private router: Router,
    private dialogueService: DialogService,
    private pushNotificationService: PushNotificationService,
    private browserService: BrowserService,
    private authService: AuthService) {

    // Initialize analytic service
    AnalyticsService.getInstance(this.authService).init();

    if (ConsentManagementService.isConsentManagementSupported()) {
      // ERBT-5910: Register a privacy consent event handler to initialize
      // push notifications based on the given consent.
      this.registerPrivacyConsentHandler(this.pushNotificationService, this.browserService);
    } else if (this.pushNotificationService
      .isBrowserNotificationPermissionPopUpShownOnPageLoad()) {
      // ERBT-5256: For all browsers, except Firefox Desktop, the notification 
      // pop-up is automatically shown on page load.
      // Initialize push notification service immediately.
      this.pushNotificationService.init();
    }

    const conversionIdAndLabel = ClientConfig.onboardingQuickGtagConversionId.getString();
    if (conversionIdAndLabel !== '') {
      const conversionId = conversionIdAndLabel.split('/')[0];
      GtagService.getInstance().init(conversionId);
    }

    this.router.events.subscribe(event => {
      // Failed to load the lazy loading route
      if (event instanceof NavigationError && event.id === this.errorLazyLoadingFailedId) {
        this.dialogueService.showToast('common.error.networkFailure');
      } else if (event instanceof NavigationEnd) {
        // Track the pageview for Tealium
        AnalyticsService.getInstance().trackPageView(event.url);
      }

    });
  }

  ngAfterViewInit() {
    // ERBT-5256: For Firefox desktop the notification permission pop-up 
    // is only shown when the user triggers a click event anywhere on the website
    // https://support.mozilla.org/en-US/kb/push-notifications-firefox
    if (!this.pushNotificationService
      .isBrowserNotificationPermissionPopUpShownOnPageLoad()
      && !ConsentManagementService.isConsentManagementSupported()) {
      this._pageClickHandler = this.pageClickHandler.bind(this);
      document.body.addEventListener('click', this._pageClickHandler);
    }
  }

  pageClickHandler(event:MouseEvent) {
    // After the first click event anywhere on a page, unbind the click listener
    document.body.removeEventListener('click', this._pageClickHandler);
    // Initialize push notification service
    this.pushNotificationService.init();
  }

  /**
  * This method listens to privacy consent changes in the consent management platform (CMP)
  * and it enables or disables push notifications (FCM) based on the consent
  * given for these vendors.
  * 
  * @param pushNotificationService the push notification service provider
  * @param browserService the browser service provider
  * @see "ERBT-5910"
  */
  registerPrivacyConsentHandler(pushNotificationService: PushNotificationService, browserService: BrowserService) {
    ConsentManagementService.getInstance().statusChange$
      .subscribe((event: ConsentStatusChangeEvent) => {
        if (event.vendor === Vendor.FIREBASE_CLOUD_MESSAGING) {
          if (event.status) {
            // Push notification vendor granted by the user, init push notifications
            pushNotificationService.init();
          } else {
            pushNotificationService.unregisterServiceWorker();
          }
        }
      });
  }
}