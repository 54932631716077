
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';


import { CallPartyType, Profile } from 'app/model';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { FormatPipe } from 'app/shared/pipes/format.pipe';
import { DialogComponent } from './dialog.component';
import { AddCallerComponent } from './add-caller.component';
import { OffersDialogComponent } from './offers-dialog.component';
import { PromoDialogComponent } from './promo-dialog.component';
import { PromoItem } from 'app/model/promotions';

import { OfferedPackage } from 'app/model/index';
import { CapaDialogAction, CapaDialogComponent } from './capa-dialog.component';
import { IGTSelectDialogComponent } from './igt-select-dialog.component';
import { RBTSelectDialogComponent } from './rbt-select-dialog.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

/** Intent class to pass the value of dialog input */
export class DialogIntent {
  constructor(public action: boolean, public inputValue: string) { }
}

/** Intent class to pass the value of dialog input */
export class AddCallerIntent {
  constructor(
    public action: boolean,
    public callerPhoneNumber: string,
    public callerName: string,
  ) { }
}

/**
 * Common service to handle all dialog shown in the app.
 */
@Injectable()
export class DialogService {
  protected static readonly translatePipe = new TranslatePipe();
  protected static readonly formatPipe = new FormatPipe();

  /** Snackbar config */
  private readonly snackBarConfig = new MatSnackBarConfig();
  private readonly duration = 3000;

  /** Holds progress dialog reference */
  private progressDialogRef: MatDialogRef<DialogComponent>;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {
    this.snackBarConfig.duration = this.duration;
  }

  /** Returns dialog component reference */
  private getDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(DialogComponent, options);
  }

  /** Returns offers dialog component reference */
  private getOffersDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(OffersDialogComponent, options);
  }

  private getPromoDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(PromoDialogComponent, options);
  }

  private getCapaDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(CapaDialogComponent, options);
  }

  private getIGTSelectDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(IGTSelectDialogComponent, options);
  }

  private getRBTSelectDialogRef(options?: MatDialogConfig) {
    return this.dialog.open(RBTSelectDialogComponent, options);
  }

  /**
   * Shows confirm dialog and return {@code Observable} for the action.
   *
   * @param title the dialog title  text key
   * @param content the content text key
   * @param confirmButton the confirm button text key
   * @param cancelButton the cancel button text key
   * @param args the objects array to format string if there is any
   * @param showCloseIcon flag that determines whether to show the close icon on the
   *                      top right of the popup instead of showing the cancel button.
   * @returns {@code Observable} for the action
   */
  public confirm(
    title: string,
    content: string,
    confirmButton?: string,
    cancelButton?: string,
    args?: any[],
    showCloseIcon?: boolean
  ): Observable<boolean> {
    const dialogRef = this.getDialogRef();
    dialogRef.componentInstance.setConfirmDialog(
      title,
      content,
      confirmButton,
      cancelButton,
      args,
      showCloseIcon
    );

    return dialogRef.afterClosed();
  }

  /**
   * Shows input dialog and return {@code Observable} for the action.
   *
   * @param title the dialog title  text key
   * @param content the content text key
   * @param confirmButton the confirm button text key
   * @param cancelButton the cancel button text key
   * @param error the error to display if the input is not valid
   * @returns {@code Observable} for the action
   */
  public input(
    title: string,
    content: string,
    confirmButton?: string,
    cancelButton?: string,
    error?: string
  ): Observable<DialogIntent> {
    const dialogRef = this.getDialogRef();
    dialogRef.componentInstance.setInputDialog(
      title,
      content,
      confirmButton,
      cancelButton,
      error
    );

    return dialogRef.afterClosed().pipe(
      mergeMap((action): Observable<DialogIntent> => {
        const inputValue = dialogRef.componentInstance.inputValue;
        return of(new DialogIntent(action, inputValue));
      })
    );
  }

  /**
   * Shows the error pop up.
   *
   * @param content the error dialog content
   * @param args the objects array to format string if there is any
   */
  public error(content: string, args?: any[]) {
    this.getDialogRef().componentInstance.setErrorDialog(content, args);
  }

  /**
   * Shows the error pop up with an action after button click.
   *
   * @param content the error dialog content
   * @param args the objects array to format string if there is any
   */
  public errorWithAction(content: string, args?: any[]) {
    const dialogRef = this.getDialogRef({
      disableClose: true,
    });

    dialogRef.componentInstance.setErrorDialog(content, args);
    return dialogRef.afterClosed();
  }

  /**
   * Shows the info popup
   *
   * @param content the info dialog content
   * @param args the objects array to format string if there is any
   */
  public info(content: string, args?: any[]) {
    this.getDialogRef().componentInstance.setInfoDialog(content, args);
  }

  /**
   * Shows the info popup with custom title
   *
   * @param title the info dialog title
   * @param content the info dialog content
   * @param args the objects array to format string if there is any
   */
  public infoWithTitle(title: string, content: string, args?: any[]) {
    this.getDialogRef().componentInstance.setInfoWithTitleDialog(
      title,
      content,
      args
    );
  }

  /**
   * Shows info dialog and return {@code Observable} for the action.
   *
   * @param content the content text key
   * @param args the objects array to format string if there is any
   * @param confirmButton the confirm button text key
   * @returns {@code Observable} for the action
   */
  public infoWithAction(
    content: string,
    args?: any[],
    confirmButton?: string
  ): Observable<boolean> {
    const dialogRef = this.getDialogRef({
      disableClose: true,
    });

    dialogRef.componentInstance.setInfoWithActionDialog(
      content,
      args,
      confirmButton
    );

    return dialogRef.afterClosed();
  }

  /**
   * Shows the progress dialog
   *
   * @param content the progress dialog content
   */
  public progress(content: string) {
    this.progressDialogRef = this.getDialogRef({
      disableClose: true,
    });
    this.progressDialogRef.componentInstance.setProgressDialog(content);
  }

  /**
   * Closes the progress dialog if active.
   */
  public closeProgress() {
    if (this.progressDialogRef) {
      this.progressDialogRef.close();
    }
  }

  /**
   * Shows add caller dialog and return {@code Observable} for the action.
   *
   * @param callerType the caller type CALLER/GROUP
   * @param error the error to display if the input is not valid
   */
  addCaller(
    callerType: CallPartyType,
    error?: string
  ): Observable<AddCallerIntent> {
    const dialogRef = this.dialog.open(AddCallerComponent);
    dialogRef.componentInstance.setAddCallerDialog(callerType, error);

    return dialogRef.afterClosed().pipe(
      mergeMap((action): Observable<AddCallerIntent> => {
        const callerName = dialogRef.componentInstance.callerName;
        const callerPhoneNumber = dialogRef.componentInstance.callerPhoneNumber;
        return of(new AddCallerIntent(action, callerPhoneNumber, callerName));
      })
    );
  }

  /**
   * Shows a toast message using material snackbar
   *
   * @param textKey the textKey for the message
   * @param args the objects array to format string if there is any
   */
  showToast(textKey: string, args?: any[]) {
    let message = DialogService.translatePipe.transform(textKey);

    if (args) {
      message = DialogService.formatPipe.transform(message, args);
    }

    this.snackBar.open(message, null, this.snackBarConfig);
  }

  public offers(packages: OfferedPackage[]): Observable<OfferedPackage> {
    const dialogRef = this.getOffersDialogRef();
    dialogRef.componentInstance.setOfferedPackages(packages);
    return dialogRef.afterClosed();
  }

  /**
   * Shows a content promotion popup. Returns an `Observable` for the action, which will be true if the user accepted
   * (i.e. clicked the positive button in the popup) the offer or false otherwise
   *
   * @param title the dialog title text key
   * @param content the dialog content text key
   * @param confirmButton the confirm button text key
   * @param cancelButton the cancel button text key
   * @param confirmEventName the name of the analytics event to log when the confirm button is clicked.
   * When omitted, no analytics event is logged.
   * @param cancelEventName the name of the analytics event to log when the cancel button is clicked.
   * When omitted, no analytics event is logged.
   */
  public contentPromo(
    title: string,
    content: string,
    promoItem: PromoItem,
    confirmButton?: string,
    cancelButton?: string,
    confirmEventName?: string,
    cancelEventName?: string
  ): Observable<boolean> {
    const dialogRef = this.getPromoDialogRef({
      disableClose: true,
    });
    dialogRef.componentInstance.setTexts(
      title,
      content,
      confirmButton,
      cancelButton
    );
    dialogRef.componentInstance.setPromoItem(promoItem);
    dialogRef.componentInstance.setAnalyticsEvents(
      confirmEventName,
      cancelEventName
    );

    return dialogRef.afterClosed();
  }

  /**
   * Shows the CAPA warning popup.
   * @see "ERBT-5753"
   */
  public capaWarning(): Observable<CapaDialogAction> {
    const dialogRef = this.getCapaDialogRef({
      disableClose: true,
    });
    return dialogRef.afterClosed();
  }

  /**
   * Shows the IGT Select Dialog.
   * @see "ERBT-7024"
   */
  public igtSelect(profile:Profile): Observable<any> {
    const dialogRef = this.getIGTSelectDialogRef({
      disableClose: false,
      data:profile,
      panelClass: 'tone-selection-dialog',
      autoFocus: false
    });
    return dialogRef.afterClosed().pipe(
      map(action => {
        const toneId = dialogRef.componentInstance.selectedTone;
        return toneId;
      })
    );
  }

  /**
   * Shows the RBT Select Dialog.
   * @see "ERBT-7024"
   */
  public rbtSelect(profile:Profile): Observable<any> {
    const dialogRef = this.getRBTSelectDialogRef({
      disableClose: false,
      data:profile,
      panelClass: 'tone-selection-dialog',
      autoFocus: false
    });
    return dialogRef.afterClosed().pipe(
      map(action => {
        const toneId = dialogRef.componentInstance.selectedTone;
        return toneId;
      })
    );
  }
}
