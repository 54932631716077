<div
  [class.cover-anchor]="cover && !initial"
  [class.anchor]="(cover == null) || initial"
  [class.fallback-img]="cover && !initial"
  [class.stop]="isPlaying"
  [class.loading]="isLoading"
  [class.disabled]="isDisabled"
  [class.redesigned]="isRedesign">
  <span *ngIf="initial"
  [class.initial]="initial"
  [style.background-color]="initialBgColor">{{ initial }}</span>
  <span *ngIf="cover && !initial"><img class="fill" *appLazy [src]="cover" /></span>
  <button [disabled]="isDisabled || isLoading"
    mat-button class="playback-button"
    (click)="togglePlayback($event)"
    [class.stop]="isPlaying"
    [class.loading]="isLoading">
  </button>
</div>
