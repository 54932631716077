<!-- Mobile Menu -->
<mat-toolbar [ngClass]="isRedesign ? 'redesign' : ''" class="primary mobile-menu" *ngIf="isMobile">
  <app-search *ngIf="searchShow && isRedesign" (onSearch)="searchShow = false">
    <!-- Injected into the app-search ng-content -->
    <ng-container *ngIf="isRedesign">
      <div data-test-id="close-search-button-mobile" (click)="searchShow = false" class="close-search-icon"></div>
    </ng-container>
  </app-search>

  <span *ngIf="(isRedesign && !searchShow) || newLayout" class="logo" (click)="handleLogoClick()"></span>
  <span *ngIf="!searchShow && backButton" class="icon back-button-icon" mat-ripple (click) ="goBack()" ></span>
  <span *ngIf="showPageTitle()" class="title">{{ title }}</span>
  <ng-container *ngIf="!simpleHeader">
    <span class="icon search-icon" mat-ripple (click) ="search()" *ngIf="isRedesign || !backButton"></span>
    <span *ngIf="isRedesign && !isLogged"  routerLink='/login'  class="login">
      <div class="login-user-icon"></div>
      <a class="loginText">{{ 'menu.login' | trans }}</a>
    </span>
    <span *ngIf="isRedesign && isLogged"  class="profile">
      <span mat-ripple routerLink='/account'>
        <div class="login-user-icon"></div>
        <a>{{ 'menu.myProfile' | trans }} </a>
      </span>
     <span mat-ripple [routerLink]="['/login/sign-out']">
       <a class="logout" [title]="'menu.logout' | trans">
         <div class="logout-icon"></div>
       </a>
     </span>
    </span>
    <span class="icon"
      [class.list-icon]="isGrid"
      [class.grid-icon]="!isGrid"
      mat-ripple (click) ="toggleToneView()"
      *ngIf="!isRedesign && !backButton && toggleButton">
    </span>
    <span class="icon" (click)="toggleMenu()"  [ngClass]="[menuShow ? 'menu-close-icon' : 'menu-icon']" 
     mat-ripple *ngIf="!backButton || showMenu || isRedesign"></span>
  </ng-container>
  <br/>
</mat-toolbar>

<!-- Desktop Menu TMDE Redesign 2024 -->
<div *ngIf="!newLayout && !isMobile && isRedesign" class="redesign-nav">
  <div class="nav-wrapper">
    <div class="logo-wrapper">
      <div (click)="handleLogoClick()" class="logo"></div>
    </div>
    <div class="menu-search-account">
      <div class="menu">
        <ul>
          <li routerLink='/home' [ngClass]="{'active':pageHome}" >
            <span class="menu-overlay" mat-ripple></span>
            <a>{{ 'menu.home' | trans }}</a>
          </li>
          <li routerLink='/shop' [ngClass]="{'active':pageShop}" >
            <span class="menu-overlay" mat-ripple></span>
            <a>{{ 'menu.shop' | trans }}</a>
          </li>
          <li routerLink='/library' [ngClass]="{'active':pageLibrary, 'select':libraryShow}"
              >
            <span class="menu-overlay" mat-ripple></span>
            <a>{{ 'menu.myLibrary' | trans }}</a>
          </li>
          <li routerLink='/setting' [ngClass]="{'active':pageCaller, 'select':callerExperienceShow }"
              >
            <span class="menu-overlay" mat-ripple></span>
            <a>{{ 'menu.callerExperience' | trans }}</a>
          </li>
        </ul>
      </div>
      <div class="search-account">
        <div class="search">
          <app-search></app-search>
        </div>
        <div class="account">
          <ul>
            <li *ngIf="!isLogged"  routerLink='/login'  class="last login nohover">
              <div class="login-user-icon"></div>
              <a class="loginText">{{ 'menu.login' | trans }}</a>
            </li>
            <li *ngIf="!isLogged"  class="last register nohover">
              <button data-test-id="register-button" mat-raised-button class="bt-main mat-primary" routerLink='/register'>
                {{ 'menu.register' | trans }}
              </button>
            </li>
            <li *ngIf="isLogged" routerLink='/account' mat-ripple class="profile nohover">
              <div class="login-user-icon"></div>
              <a>{{ 'menu.myProfile' | trans }}</a>
            </li>
            <li *ngIf="isLogged" class="nohover last" [title]="'menu.logout' | trans" ><a class="logout last" [routerLink]="['/login/sign-out']">
              <span class="logout-icon"></span>
            </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Desktop Menu Non VFDE -->
<div class="nav-belt" *ngIf="!newLayout && !isMobile && !isRedesign" (mouseleave)="hideSubMenu()" (blur)="hideSubMenu()">
  <span class="logo" (click)="handleLogoClick()"></span>
  <mat-card class="top-menu">
    <mat-card-content >
      <ul>
        <li routerLink='/home' [ngClass]="{'active':pageHome}" (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()">
            <span class="menu-overlay" mat-ripple></span>
            <a>{{ 'menu.home' | trans }}</a>
        </li>
        <li routerLink='/shop' [ngClass]="{'active':pageShop}" (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()">
          <span class="menu-overlay" mat-ripple></span>
          <a>{{ 'menu.shop' | trans }}</a></li>
        <li routerLink='/library' [ngClass]="{'active':pageLibrary, 'select':libraryShow}"
            (mouseenter)="showSubMenu('libraryShow')" (focus)="showSubMenu('libraryShow')">
          <span class="menu-overlay" mat-ripple></span>
          <a>{{ 'menu.myLibrary' | trans }}</a>
        </li>
        <li routerLink='/setting' [ngClass]="{'active':pageCaller, 'select':callerExperienceShow }"
            (mouseenter)="showSubMenu('callerExperienceShow')" (focus)="showSubMenu('callerExperienceShow')">
          <span class="menu-overlay" mat-ripple></span>
          <a>{{ 'menu.callerExperience' | trans }}</a>
        </li>
        <li (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()" class="nohover">
          <app-search></app-search>
        </li>
        <li *ngIf="!isLogged" routerLink='/login' (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()" class="last login">
            <span class="menu-overlay" mat-ripple></span>
          <a class="loginText">{{ 'menu.login' | trans }}</a>
        </li>
        <li *ngIf="!isLogged" (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()" class="last register nohover">
              <button mat-raised-button class="bt-main" routerLink='/register'>
                {{ 'menu.register' | trans }}
              </button>
        </li>
        <li [matMenuTriggerFor]="appMenu" mat-ripple *ngIf="isLogged" (mouseenter)="hideSubMenu()" (focus)="hideSubMenu()" class="last profile nohover">
            <a>{{ 'menu.myProfile' | trans }}</a>
        </li>
        <mat-menu #appMenu="matMenu"  [overlapTrigger]="true" y-position="below" x-position="before">
         <a mat-menu-item routerLink='/account'> {{ 'menu.myAccount' | trans }}</a>
         <a mat-menu-item routerLink='/faq'>{{ 'menu.faq' | trans }} </a>
         <a mat-menu-item [routerLink]="['/login/sign-out']"><span class="logoutButton">
           {{ 'menu.logout' | trans }}</span></a>
        </mat-menu>
      </ul>
    </mat-card-content>
  </mat-card>

<!-- Sub Menu for Library, Settings, Account -->
  <mat-card class="sub-menu" *ngIf="(show || showFixedSubMenu()) && isLogged && !newLayout && !isRedesign" >
      <mat-card-content *ngIf="callerExperienceShow || pageCaller">
      <app-setting-menu></app-setting-menu>
    </mat-card-content>
      <mat-card-content  *ngIf="(libraryShow || pageLibrary) && isLogged">
      <ul class="library">
        <li mat-ripple routerLink="/library/0">{{ 'menu.submenu.library.ringBackTones' | trans }}</li>
        <li mat-ripple routerLink='/library/1'>{{ 'menu.submenu.library.personalMessages' | trans }}</li>
        <li mat-ripple routerLink='/library/2' class="last">{{ 'menu.submenu.library.prerecordedMessages' | trans }}</li>
      </ul>
    </mat-card-content>
    <mat-card-content  *ngIf="pageAccount">
      <ul class="account">
        <li mat-ripple routerLink='/account/0'>{{ 'menu.submenu.account.settings' | trans }}</li>
        <li mat-ripple routerLink='/account/1' class="last">{{ 'menu.submenu.account.subscription' | trans }}</li>
        <li *ngIf="showContentPackages" mat-ripple routerLink='/account/2'>{{ 'menu.submenu.account.contentPackage' | trans }}</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>

<!-- VFDE Menu -->
<div class="nav-belt" *ngIf="newLayout && !isMobile">
  <div class="top-menu">
    <ul>
      <li>
        <span class="logo" (click)="handleLogoClick()"></span>
      </li>
      <li *ngIf="!simpleHeader">
        <app-search></app-search>
      </li>
      <li *ngIf="!simpleHeader && !isLogged" routerLink='/login' class="login">
        <a class="loginText">{{ 'menu.login' | trans }}</a>
      </li>
      <li *ngIf="!simpleHeader && !isLogged" class="register">
            <button mat-raised-button class="bt-main bt-secondary" routerLink='/register'>
              {{ 'menu.register' | trans }}
            </button>
      </li>
      <li class="header-last-button" *ngIf="!simpleHeader && !isLogged">
        <a href="{{ 'menu.opco.link' | trans }}">
        {{ 'menu.opco.link.text' | trans }} <span class="arrow-right"></span></a>
      </li>
      <li class="header-last-button" *ngIf="!simpleHeader && isLogged" [routerLink]="['/login/sign-out']">
          {{ 'menu.logout' | trans }} <span class="logout-icon"></span>
      </li>
    </ul>
  </div>
  <ng-container *ngIf="!simpleHeader">
    <app-dropdown-navbar></app-dropdown-navbar>
    <app-breadcrumb></app-breadcrumb>
  </ng-container>
</div>

<!-- Mobile Menu Drawer -->
<mat-sidenav-container [hasBackdrop]="!newLayout">
  <mat-sidenav #sidenav [position]="menuPosition" [ngClass]="{'mat-sidenav-ios' : isIosDevice, 'redesign' : isRedesign}"
    [autoFocus]="false" [@.disabled]="newLayout">
    <mat-list *ngIf="isMobile">
      <ng-container *ngIf="isRedesign">
        <div *ngIf="!isLogged" class="register">
          <button mat-raised-button class="bt-main bt-secondary mat-primary" routerLink='/register'>
            {{ 'menu.register' | trans }}
          </button>
        </div>
        <mat-list-item mat-ripple routerLink='/home' (click)="sidenav.toggle()">
          <span class="list-item-wrapper">
            <span class="list-item-label">{{ 'menu.home' | trans }}</span>
            <span class="arrow-icon"></span>
          </span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item mat-ripple routerLink='/shop' (click)="sidenav.toggle()">
          <span class="list-item-wrapper">
            <span class="list-item-label"> {{ 'menu.shop' | trans }}</span>
            <span class="arrow-icon"></span>
          </span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item mat-ripple routerLink='/library' (click)="sidenav.toggle()">
          <span class="list-item-wrapper">
            <span class="list-item-label"> {{ 'menu.myLibrary' | trans }}</span>
            <span class="arrow-icon"></span>
          </span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item mat-ripple routerLink='/setting' (click)="sidenav.toggle()">
          <span class="list-item-wrapper">
            <span class="list-item-label"> {{ 'menu.callerExperience' | trans }}</span>
            <span class="arrow-icon"></span>
          </span>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngIf="!isRedesign">
        <mat-list-item mat-ripple routerLink='/home' (click)="sidenav.toggle()">
          <span>{{ 'menu.home' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple routerLink='/shop' (click)="sidenav.toggle()">
          <span>{{ 'menu.shop' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple routerLink='/library' (click)="sidenav.toggle()">
          <span>{{ 'menu.myLibrary' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple routerLink='/setting' (click)="sidenav.toggle()">
          <span>{{ 'menu.callerExperience' | trans }} </span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item mat-ripple routerLink='/account' (click)="sidenav.toggle()">
          <span>{{ 'menu.myAccount' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple routerLink='/faq' (click)="sidenav.toggle()">
          <span>{{ 'menu.faq' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple [routerLink]="['/login']" *ngIf="!isLogged" (click)="sidenav.toggle()">
          <span>{{ 'menu.login' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple [routerLink]="['/login/sign-out']" *ngIf="isLogged" (click)="sidenav.toggle()">
          <span>{{ 'menu.logout' | trans }}</span>
        </mat-list-item>
        <mat-list-item mat-ripple [routerLink]="['/register']" *ngIf="!isLogged" (click)="sidenav.toggle()">
          <span>{{ 'menu.register' | trans }}</span>
        </mat-list-item>
      </ng-container>
    </mat-list>
  </mat-sidenav>
  <app-search (onSearch)="searchShow = false" *ngIf="!isRedesign && isMobile && searchShow"></app-search>
  <div class="wrapper" [ngClass]="isRedesign ? 'redesigned' : ''">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</mat-sidenav-container>

<!-- Footer redesign -->
<footer *ngIf="isRedesign">
  <div *ngIf="showCookieNotificationBar" class="cookieBar">
      <span>{{ 'home.cookie.notification.bar.text' | trans }}</span>
      <span>
        <a href="{{ 'home.cookie.notification.bar.link' | trans }}">
          {{ 'home.cookie.notification.bar.link.text' | trans }}
        </a>
      </span>
      <span>
        <button (click)="acceptCookieNotification()">
          {{ 'common.button.ok' | trans }}
        </button>
      </span>
  </div>
  <div [ngClass]="isRedesign ? 'redesigned':null"  class="footer">
    <div class="footer-wrapper">
      <div class="footer-divider"></div>
      <div class="footer-logo-container">
        <div class="footer-logo">
          <!-- Added dynamically via scss injection as a :before -->
        </div>
        <div class="footer-slogan" [innerHTML]="'footer.slogan.text' | trans"></div>
      </div>
      <div class="footer-bottom-row">
        <div class="copyright"  [innerHTML]="'footer.copyright' | trans"></div>
        <div class="links">
          <span class="footerLink" *ngFor="let footerLink of footerLinks">
            <a [href]="'footer.' + footerLink + '.link' | trans | bypassSecurityForUrl" (click)="openFooterLink($event);">
              {{ 'footer.' + footerLink + '.text' | trans }}
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- Old footer eventually replaced -->
<footer *ngIf="!isRedesign">
  <div *ngIf="showCookieNotificationBar" class="cookieBar">
      <span>{{ 'home.cookie.notification.bar.text' | trans }}</span>
      <span>
        <a href="{{ 'home.cookie.notification.bar.link' | trans }}">
          {{ 'home.cookie.notification.bar.link.text' | trans }}
        </a>
      </span>
      <span>
        <button (click)="acceptCookieNotification()">
          {{ 'common.button.ok' | trans }}
        </button>
      </span>
  </div>
  <div class="footer">
    <div class="footerBar">
      <div class="footerText">
        <div class="footerContent">
          <span class="logo"></span>
          <span class="footerLink" *ngFor="let footerLink of footerLinks">
            <a [href]="'footer.' + footerLink + '.link' | trans | bypassSecurityForUrl" (click)="openFooterLink($event);">
              {{ 'footer.' + footerLink + '.text' | trans }}
            </a>
          </span>
        </div>
        <span class="copyright" [innerHTML]="'footer.copyright' | trans"></span>
      </div>
    </div>
  </div>
</footer>
