<ng-container *ngIf="isLoggedIn; else notLoggedIn">
  <span *ngIf="tone.price == '0.0'">
    {{ 'common.priceFormat' | trans | format: (promotion ? 0 : tone.price) }}
  </span>
  <span *ngIf="profile?.remainingCredits > 0 && tone.price != '0.0'">
    {{ 'account.page.contentPackages.credits.one' | trans | format:1 }}
  </span>
  <span *ngIf="!profile?.remainingCredits && tone.price > 0">
    {{ 'common.priceFormat' | trans | format: (promotion ? 0 : tone.price) }}
  </span>
</ng-container>

<ng-template #notLoggedIn>
  <span *ngIf="tone.price == '0.0'">
    {{ 'common.priceFormat' | trans | format: (promotion ? 0 : tone.price) }}
  </span>
  <span *ngIf="tone.price > 0">
    {{ 'common.priceFormat' | trans | format: (promotion ? 0 : tone.price) }}
  </span>
</ng-template>