import { Input, Component, Output, EventEmitter } from '@angular/core';
import { Tone } from 'app/model/tone';
import log from 'app/core/logging/logger.service';

@Component({
  selector: 'app-igt-list-item',
  templateUrl: './igt-list-item.component.html',
})
export class IgtListItemComponent {

  @Input() tone: Tone;
  @Input() ripple = true;
  @Input() recording = false;
  @Input() select = false;
  @Input() selected = false;
  @Input() redesign = false;

  @Output()
  onDelete = new EventEmitter<string>();

  duration: number;

  constructor() {}

  delete() {
    log.info('Recording ' + this.recording);
    this.onDelete.emit(this.tone.id);
  }

  updateDuration( duration) {
    log.info('Recording duration: ' + duration);
    this.duration = duration;
  }
}
