import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { ClientConfig } from 'app/core/config/client-config';
import UrlBuilder from 'app/core/url-builder';

@Component({
  selector: 'app-legal-text',
  templateUrl: './legal-text.component.html'
})
export class LegalTextComponent {

  legalConfig: string[];
  monthlySubscriptionPrice: number;

  constructor(private router: Router) {
    this.legalConfig = ClientConfig.tonePageLegal.getStringArray();
    this.monthlySubscriptionPrice = ClientConfig.monthlySubscriptionPrice.getNumber();
  }

  /** Listens legal text link clicks */
  listenToLegal(event) {
    UrlBuilder.openExternalOrInternalUrlFromClickEvent(event, this.router);
  }
}
