import {
  Component,
  NgZone,
  ChangeDetectionStrategy
} from '@angular/core';
import { Router } from '@angular/router';

import { BaseViewComponent } from '../base-view.component';

@Component({
  selector: 'app-mini-title-bar',
  templateUrl: './mini-title-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniTitleBarComponent extends BaseViewComponent {
  constructor(zone: NgZone, router: Router) {
    super(zone, router);
  }

}
