<app-tracker></app-tracker>
<app-spinner [active]="spinnerActive">
  <div *ngIf="!isRedesign && !isMobile && bannerPromoConfig !== undefined">
    <div *ngIf="skin" [ngClass]="'banner skin-web-overlay ' + skin"></div>
    <app-banner-item [bannerWidth]="2400" [bannerHeight]="340" [promotionConfig]="bannerPromoConfig"
      source="homeBanner"></app-banner-item>
  </div>
  <app-title-bar title="{{ 'home.page.title' | trans }}">
    <div *ngIf="isRedesign && !isMobile && bannerPromoConfig !== undefined">
      <div *ngIf="skin" [ngClass]="'banner skin-web-overlay ' + skin"></div>
      <app-banner-item [bannerWidth]="2400" [bannerHeight]="283" [promotionConfig]="bannerPromoConfig"
        source="homeBanner"></app-banner-item>
    </div>
    <!-- Banner for mobile view with a separate banner image -
       for now just used on TMDE redesign -->
    <div *ngIf="isRedesign && isMobile && bannerPromoMobileConfig !== undefined">
      <div *ngIf="skin" [ngClass]="'banner skin-web-overlay ' + skin"></div>
      <app-banner-item [bannerWidth]="750" [bannerHeight]="415" [promotionConfig]="bannerPromoMobileConfig"
        source="homeBanner"></app-banner-item>
    </div>
    <div class="home-page" [ngClass]="isRedesign ? 'redesigned' : ''">
    <div *ngIf="isMobile && skin" [ngClass]="'skin-mobile-background ' + skin"></div>
    <div *ngIf="isMobile && skin" [ngClass]="'skin-mobile-overlay ' + skin"></div>
    
    <ng-container *ngIf="showSuspendedSlide">
      <ng-container *ngIf="isRedesign; else noIsRedesign">
        <div class="user-suspended-wrapper">
          <mat-card class="home-card">
            <mat-card-header>
              <span class="warning-icon"></span>
              <h1>{{ 'account.suspended.card.heading' | trans }}</h1>
            </mat-card-header>
            <mat-card-content>
              <p>{{ 'account.suspended.card.body' | trans }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </ng-container>
    
    <ng-template #noIsRedesign>
      <mat-card class="home-card">
        <mat-card-header>
          <h1>{{ 'account.suspended.card.heading' | trans }}</h1>
        </mat-card-header>
        <mat-card-content>
          <p>{{ 'account.suspended.card.body' | trans }}</p>
        </mat-card-content>
      </mat-card>
    </ng-template>
    
    <ng-container *ngIf="!isRedesign">
        <div [ngClass]="('homeTitle ' + skin).trim()">{{ 'home.quickActions' | trans }}</div>
        <div class="homeTiles">
          <app-config-item
            *ngIf="!isMobile"
            [tilesOptions]="configTiles"
            [columns] = "12">
          </app-config-item>
          <app-config-item
            *ngIf="isMobile"
            [tilesOptions]="configTiles"
            [columns] = "6">
          </app-config-item>
        </div>
        <div *ngIf="skin" [ngClass]="'skin-content ' + skin"></div>
        <div *ngIf="!isMobile" class="margin-top-50">
          <div class="col-mat-12">
            <app-carousel [interval]="homeSliderInterval" [noWrap]="false">
              <app-slide *ngFor="let promoConfig  of webPromoConfigs">
                <div class="carousel-box">
                    <app-promo [source]="PurchaseSources.HomeBannerCarousel" [promotionConfig]="promoConfig"></app-promo>
                  </div>
                  <div class="carousel-caption"></div>
              </app-slide>
            </app-carousel>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isRedesign">
        <div class="redesigned-home-wrapper">
         <span *ngIf="browserServ.isAndroid()" class="mobile-section-text-link" [innerHTML]="'app.mobile.section.android.text.html' | trans"></span>
         <span *ngIf="browserServ.isIosDevice()" class="mobile-section-text-link" [innerHTML]="'app.mobile.section.ios.text.html' | trans"></span>
         <app-promo class="top-promo" [source]="PurchaseSources.TopPromoHomePage" [promotionConfig]="topPromo"></app-promo>
         <app-tone-carousel [title]="'newSongs.title' | trans" [purchaseSource]="PurchaseSources.HomeNewTracksCarousel"></app-tone-carousel>
         <app-promotions-carousel [purchaseSource]="PurchaseSources.HomePromotionsCarousel" ></app-promotions-carousel>
         <div class="dynamic-promo">
           <app-promo class="bottom-promo" [source]="PurchaseSources.BottomPromoHomePage" [isFullScreen]="false" [promotionConfig]="bottomPromo"></app-promo>
         </div>
         <div class="faq-section" [ngClass]="isRedesign ? 'redesigned':''">
            <app-faq-list [title]="'faq.page.title'" [isCustomList]="true" [customList]="'home'"></app-faq-list>
         </div>
        </div>
      </ng-container>
      <div *ngIf="!isRedesign && isMobile" class="homeTiles promoTiles">
        <mat-grid-list cols="12" gutterSize="15px">
          <mat-grid-tile
          *ngFor="let promoConfig  of mobilePromoConfigs; let j = index"
          [colspan]="getTileSize(j).width"
          [rowspan]="getTileSize(j).height">
          <app-mobile-promo [promotionConfig]="promoConfig"></app-mobile-promo>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div *ngIf="!isRedesign" class="tone-scroller-holder">
        <app-tone-scroller ></app-tone-scroller>
      </div>
       
    </div>
  </app-title-bar>
</app-spinner>
