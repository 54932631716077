
/** A shop or category. */
// Documentation taken from protobuf.
export class Shop {

    /** ID of the shop. */
    id: string;

    /** Title / name of the shop. */
    title: String;

    /**
     * File name of the JPG file of some cover art representing this category.
     * Must be passed as path argument to the image transcoder.
     */
    image?: String;
}
