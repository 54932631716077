<div class="scroller my-8" #scroller>
  <p class="title">{{ 'newSongs.title' | trans }}</p>
  <mat-tab-group>
    <mat-tab *ngFor="let tone of tones">
      <ng-template mat-tab-label>
        <div (click)="clicked(tone)" class="fallback-img tone-scroll-img">
          <ng-container *ngIf="newLayout">
            <img *appLazy="true" width="187" height="187" [src]="tone.cover | image:187:187" />
          </ng-container>
          <ng-container *ngIf="!newLayout">
            <img *appLazy="true" width="135" height="135" [src]="tone.cover | image:135:135" />
          </ng-container>
          <div class="details" fxLayout="row" fxLayoutAlign="stretch stretch">
            <app-play-button [tones]="[tone]" fxFlex="0 0 20%">
            </app-play-button>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 80%">
                <span class="detailsText">{{ 'common.details' | trans }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
