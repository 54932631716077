import { environment } from 'environments/environment';

import {
  Profile,
  Tone,
  ToneList,
  Setting,
  ToneSetting,
  ToneSettingType,
  Caller,
  CallerInfo,
  Group,
  DayOfWeek,
  TimeOfDay,
  TimeAndDayOfWeek,
  DayOfYear,
  CallParty,
  ProfileStatus
} from 'app/model';

export class ProfileHelper {

  /**
   * Returns one example RBT from a setting in the profile.
   * If the setting uses a single tone, then this tone is returned.
   * If the setting uses a playlist or (TMO) shuffle, then a random tone from the list of candidates is returned.
   * The method may return {@code null} if the setting is invalid, which can occur in certain edge cases:
   * <ul>
   *     <li>TMO style shuffle with a library containing only standard tone</li>
   *     <li>VF/DE if the user does not own the standard tone yet</li>
   *     <li>VF/DE with all tones expired and API Plus version before 1.13, which sends STANDARD without ID</li>
   * </ul>
   *
   * @param profile  user profile
   * @param setting  a play setting (default, single caller, or group setting)
   * @returns a random example RBT from the setting
   */
  static getExampleRbtTone(profile: Profile, setting: Setting): Tone {

    const toneSetting = setting.rbtSetting;
    if (toneSetting) {

      if (toneSetting.type === ToneSettingType[ToneSettingType.SHUFFLE]) {

        // Select a random tone from the user's library,
        // ignoring the standard tone at the end of the list.
        const library = profile.library;

        if (library.totalCount < 2) {
          // If there is no tone other than the standard tone, then we return null.
          return null;
        } else {
          // Ignore the last tone, because it's the standard tone
          const randomIndex = ProfileHelper.getRandom(library.totalCount - 1);
          return library.tone[randomIndex];
        }
      } else if (toneSetting.id && toneSetting.id.length > 0) {
        return ProfileHelper.getRandomToneFromPlayList(profile.library, toneSetting.id);
      }
    }

    // This should really never happen.
    // Unfortunately it does e.g. for
    //  *  VF/DE with all tones expired and API Plus version before 1.13, which sends STANDARD without ID
    //  *  VF/DE for users which do not own the standard tone
    return null;

  }


  private static getRandom(max: number): number {
    return Math.floor(Math.random() * max);
  }

  static getRandomToneFromPlayList(toneList: ToneList, rbtIds: string[]) {
    let randomIndex = 0;
    if (rbtIds.length > 1) {
      randomIndex = ProfileHelper.getRandom(rbtIds.length);
    }

    return ProfileHelper.findTone(toneList.tone, rbtIds[randomIndex]);
  }


  /**
   * Method to find a tone from a ToneList
   *
   * @param toneList the toneList
   * @param toneId the toneId
   * @return {@link Tone} if there is a tone with specified tonId otherwise returns {@code null}
   */
  static findTone(toneList: Tone[], toneId: string): Tone {

    if (!toneList) {
      return null;
    }

    return toneList.find((item: Tone) => {
      return item.id === toneId;
    });
  }


  /**
   * Returns the IGT tone used in the setting.
   * May be a UGC, a predefined IGT, or {@code null} if no intro greeting is set.
   *
   * @param profile  user profile
   * @param setting  a play setting (default, single caller, or group setting)
   * @return IGT tone used in this setting
   */
  static getIgtTone(profile: Profile, setting: Setting) {

    const igtId = setting.igtId;
    let defaultIgt: Tone = null;

    if (igtId !== undefined && igtId !== '') {

      defaultIgt = ProfileHelper.findTone(profile.ugc.tone, igtId);

      // If its not in UGC list, search on IGT list
      if (!defaultIgt) {
        defaultIgt = ProfileHelper.findTone(profile.otherIgt.tone, igtId);
      }

    }

    return defaultIgt;
  }

  static isShuffleActive(toneSetting: ToneSetting): boolean {
    return (toneSetting && (toneSetting.type === ToneSettingType[ToneSettingType.SHUFFLE]
      || toneSetting.type === ToneSettingType[ToneSettingType.PLAYLIST]));
  }

  static isStandardTone(toneSetting: ToneSetting): boolean {
    return toneSetting && toneSetting.type === ToneSettingType[ToneSettingType.STANDARD];
  }

  /**
   * Create call party from profile {@link Caller} object
   * @param profile the profile
   * @param caller the caller
   * @returns {CallParty}
   */
  static createCallPartyFromCaller(profile: Profile, caller: Caller): CallParty {

    const callParty: CallParty = CallParty.createCallerCallParty(
      caller.info.name,
      caller.info.msisdn
    );

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      caller.setting,
      callParty
    );
  }

  /**
   * Create time of day call party from profile {@link TimeOfDay} object
   *
   * @param profile  the profile
   * @param timeOfDay the time of day
   * @returns {CallParty}
   */
  static createCallPartyFromTimeOfDay(profile: Profile, timeOfDay: TimeOfDay): CallParty {

    const callParty: CallParty = CallParty.createTimeOfDayCallParty(
      timeOfDay.startTime,
      timeOfDay.endTime
    );

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      timeOfDay.setting,
      callParty
    );
  }

  /**
   * Create time of day call party from profile {@link TimeAndDayOfWeek} object
   *
   * @param profile  the profile
   * @param timeAndDayOfWeek the time and day of week
   * @returns {CallParty}
   */
  static createCallPartyFromTimeAndDayOfWeek(profile: Profile,
    timeAndDayOfWeek: TimeAndDayOfWeek): CallParty {

    const callParty: CallParty = CallParty.createTimeAndDayOfWeekCallParty(
      timeAndDayOfWeek.startTime,
      timeAndDayOfWeek.endTime,
      timeAndDayOfWeek.weekday
    );

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      timeAndDayOfWeek.setting,
      callParty
    );

  }

  /**
   * Create time of day call party from profile {@link DayOfWeek} object
   *
   * @param profile  the profile
   * @param dayOfWeek the dayOfWeek
   * @returns {CallParty}
   */
  static createCallPartyFromDayOfWeek(profile: Profile, dayOfWeek: DayOfWeek): CallParty {

    const callParty: CallParty = CallParty.createDayOfWeekCallParty(dayOfWeek.weekday);

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      dayOfWeek.setting,
      callParty
    );
  }

  /**
   * Create day of year call party from profile {@link DayOfYear} object
   *
   * @param profile  the profile
   * @param dayOfYear the DayOfYear
   * @returns {CallParty}
   */
  static createCallPartyFromDayOfYear(profile: Profile, dayOfYear: DayOfYear): CallParty {

    const callParty: CallParty = CallParty.createDayOfYearCallParty(
      dayOfYear.day, dayOfYear.month
    );

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      dayOfYear.setting,
      callParty
    );

  }

  /**
   * Create call party from profile {@link DayOfWeek} object
   *
   * @param profile  the profile
   * @param group the group
   * @returns {CallParty}
   */
  static createCallPartyFromGroup(profile: Profile, group: Group): CallParty {

    const noOfMembers = group.member ? group.member.length : 0;
    const callParty: CallParty = CallParty.createGroupCallParty(
      group.name,
      noOfMembers
    );

    return ProfileHelper.applySettingAndReturnCallParty(
      profile,
      group.setting,
      callParty
    );
  }

  /**
   * Sets settings details to the {@code CallParty} object
   *
   * @param profile the profile
   * @param setting  the setting
   * @param callParty the call party
   */
  public static applySettingAndReturnCallParty(
    profile: Profile,
    setting: Setting,
    callParty: CallParty): CallParty {

    if (setting) {
      const rbt = ProfileHelper.getExampleRbtTone(profile, setting);
      callParty.setRbt(rbt);
      callParty.setSetting(setting);
      callParty.setIgt(ProfileHelper.getIgtTone(profile, setting));
    }

    return callParty;
  }

  /**
   * Find caller from profile data with setting id
   *
   * @param profile the profile
   * @param settingId the setting id
   * @returns {Caller} if caller exist else return {@code null}
   */
  static findCallerFromProfileBySettingId(profile: Profile, settingId: string): Caller {
    if (profile.caller) {
      return profile.caller.find((caller: Caller) => caller.setting.id === settingId );
    }

    return null;
  }

  /**
   * Find caller from profile data with caller msisdn
   *
   * @param profile the profile
   * @param callerMsisdn the msisdn
   * @returns {Caller} if caller exist else return {@code null}
   */
  static findCallerFromProfileByMsisdn(profile: Profile, callerMsisdn: string): Caller {
    if (profile.caller) {
      return profile.caller.find((caller: Caller) => caller.info.msisdn === callerMsisdn );
    }

    return null;
  }

  /**
   * Find day of week from profile data with setting id
   *
   * @param profile the profile data
   * @param settingId the setting id
   * @returns {DayOfWeek} if setting exist else return {@code null}
   */
  static findDayOfWeekSettingFromProfile(profile: Profile, settingId: string): DayOfWeek {

    if (profile.dayOfWeek) {
      return profile.dayOfWeek.find((dayOfWeek: DayOfWeek) => {
        return dayOfWeek.setting && dayOfWeek.setting.id === settingId;
      });
    }

    return null;
  }

  /**
   * Find day of week from profile data with setting id
   *
   * @param profile the profile data
   * @param settingId the setting id
   * @returns {DayOfYear} if setting exist else return {@code null}
   */
  static findDayOfYearSettingFromProfile(profile: Profile, settingId: string): DayOfYear {

    if (profile.dayOfYear) {
      return profile.dayOfYear.find((dayOfYear: DayOfYear) => {
        return dayOfYear.setting && dayOfYear.setting.id === settingId;
      });
    }

    return null;
  }


  /**
    * Find time of day setting from profile data with setting id
    *
    * @param profile the profile data
    * @param settingId the setting id
    * @returns {TimeOfDay} if setting exist else return {@code null}
    */
  static findTimeOfDaySettingFromProfile(profile: Profile, settingId: string): TimeOfDay {

    if (profile.timeOfDay) {
      return profile.timeOfDay.find((timeOfDay: TimeOfDay) => {
        return timeOfDay.setting && timeOfDay.setting.id === settingId;
      });
    }

    return null;
  }

  /**
    * Find time and day of week setting from profile data with setting id
    *
    * @param profile the profile data
    * @param settingId the setting id
    * @returns {TimeAndDayOfWeek} if setting exist else return {@code null}
    */
  static findTimeAndDayOfWeekSettingFromProfile(profile: Profile,
    settingId: string): TimeAndDayOfWeek {

    if (profile.timeAndDayOfWeek) {
      return profile.timeAndDayOfWeek.find((timeAndDayOfWeek: TimeAndDayOfWeek) => {
        return timeAndDayOfWeek.setting && timeAndDayOfWeek.setting.id === settingId;
      });
    }

    return null;
  }

  /**
   * Find group from profile data with setting id
   *
   * @param profile the profile data
   * @param settingId the setting id
   * @returns {Group} if group exist else return {@code null}
   */
  static findGroupFromProfile(profile: Profile, settingId: string) {
    if (profile.group) {

      return profile.group.find((group: Group) => {
        return group.setting && group.setting.id === settingId;
      });
    }

    return null;

  }

  /**
   * Finds the days with active setting, it ignores the current setting days.
   * If the {@code settingId} is {@code null}, it returns all active setting days.
   *
   * @param profile the profile
   * @param settingId the optional setting id
   */
  static findOtherActiveDaysOfWeek(profile: Profile, settingId?: string): string[] {

    let daysOfWeek: string[] = [];
    if (profile.dayOfWeek) {

      profile.dayOfWeek.forEach((dayOfWeek: DayOfWeek) => {

        if (dayOfWeek.setting && (!settingId || settingId !== dayOfWeek.setting.id)) {
          daysOfWeek = daysOfWeek.concat(dayOfWeek.weekday);
        }

      });
    }

    return daysOfWeek;
  }

  /**
   * Find a caller from all group members
   *
   * @param profile  the profile
   * @param callerMsisdn  the caller msisdn to find
   */
  static findMemberFromGroup(profile: Profile, callerMsisdn): CallerInfo {
    let groupMember;
    if (profile.group) {

      profile.group.some(group => {

        if (group.member) {
          const caller = group.member.find(member => member.msisdn === callerMsisdn);
          if (caller) {
            groupMember = caller;
            return true;
          }
        }

      });
    }

    return groupMember;
  }

  static checkCallerAlreadyExist(profile: Profile, callerMsisdn): boolean {

    const caller: Caller = ProfileHelper.findCallerFromProfileByMsisdn(profile, callerMsisdn);
    if (caller) {
      return true;
    }

    const member: CallerInfo = ProfileHelper.findMemberFromGroup(profile, callerMsisdn);
    if (member) {
      return true;
    }

    return false;
  }

  static isValidPartner(profile: Profile): boolean {

    // For NEW users we cannot check the partner ID.
    // Return OK for now. Partner ID will be checked during subscription.
    if (ProfileStatus[profile.status] === ProfileStatus.NEW) {
      return true;
    }

    const partnerId = environment.partnerId || '';

    // Checks the partner ID if it exists, empty value for the config will skip the partner ID check.
    return partnerId.trim() === '' || profile.partnerId === partnerId;

  }

}
