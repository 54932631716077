import { OnDestroy, AfterViewInit, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import * as Sentry from "@sentry/browser";

import { AuthError } from 'app/core/auth/auth-error';
import log from 'app/core/logging/logger.service';
import { environment } from '../environments/environment';

@Directive()
export class BaseComponent implements AfterViewInit, OnDestroy {

  public spinnerActive = false;
  protected subscriptions: Subscription[] = new Array();
  // 2024 Redesign
  isRedesign = environment.opcoConfig.isRedesign;
  useMiniTitleLayout = environment?.useMiniTitleLayout;
  constructor(protected router: Router) { }

  showLoading() {
    this.spinnerActive = true;
  }

  hideLoading() {
    this.spinnerActive = false;
  }

  handleError(error: any) {
    if (error instanceof AuthError) {
      if (error.isSevere()) {
        this.captureError(error);
        log.error(error.message);
      }
      this.router.navigate(['/login']);
    } else {
      log.error(error);
      this.captureError(error);
      this.router.navigate(['/error', { code: 500 }]);
    }
  }

  private captureError(error: any) {
    // Check if Sentry SDK is initialized
    if (Sentry.getCurrentHub().getClient() != null) {
      Sentry.captureException(error);
    }
  }

  ngAfterViewInit() {
    // Angular2 does not scroll page to  top after route change,
    // browser persist the previous scroll state. We have to manually
    // do this. It is much smoother as we it happens mostly on mobile
    // devices
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

}
