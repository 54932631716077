<div class="mm-promo-background">
    <app-spinner [active]="spinnerActive">
        <app-title-bar title="{{ 'home.page.title' | trans }}" simpleHeader="true">

            <!-- Container holding the list of tones and the activation checkboxes and button-->
            <ng-container *ngIf="promoItem && !success">
                <div class="mm-landing-promo landing-promo-page">
                    <ng-container>
                        <img class="mm-landing-promo-image landing-promo-image" *appLazy appResizable [src]="promoItem.artwork" />
                    </ng-container>
                    <p class="title mm-title">{{ promoItem.title }}</p>
                    <p class="mm-description description" [innerHtml]="promoItem.description"></p>
                    <p *ngIf="!optin" class="mm-description smaller description" [innerHtml]=" 'magentaMoments.subscribe.description' | trans "></p>
                    <section class="mm-list">
                        <mat-list class="tone-list button-less-list mm-tone-list">
                            <app-tone-list-item [library]="true" [selection]="true" [selected]="isSelected(tone.id)"
                                [tone]="tone" (click)="toneClicked(tone)" *ngFor="let tone of tones">
                            </app-tone-list-item>
                        </mat-list>
                    </section>
                    <p *ngIf="optin" class="mm-description description">{{ "magentaMoments.optin.description" | trans }}</p>
                    <mat-checkbox class="mm-checkbox" [(ngModel)]="termsAccepted">
                        <div (click)="listenToLegal($event)" [innerHtml]="checkboxText() | trans"></div>
                    </mat-checkbox>
                    <section class="text-center">
                        <button [disabled]="!termsAccepted || !selectedToneId"
                            mat-raised-button (click)="redirectToLogin()" class="bt-main" color="primary">
                            {{ buttonText | trans}}
                        </button>
                        <mat-card *ngIf="optin" class="mm-optin-card">
                            <mat-card-content>
                            <div class="mm-optin-card-heading">{{ "magentaMoments.optin.terms.heading" | trans}}</div>
                                <ul class="mm-optin-card-bullet">
                                    <li>{{ "magentaMoments.optin.terms.bullet1" | trans}}</li>
                                    <li>{{ "magentaMoments.optin.terms.bullet2" | trans}}</li>
                                </ul>
                            </mat-card-content>
                        </mat-card>
                    </section>
                </div>
            </ng-container>

            <!-- Container for the success message after subscription or opting in -->
            <ng-container *ngIf="success">
                <div *ngIf="!optin" class="landing-promo-page">
                    <p class="title mm-title">{{ "magentaMoments.success.subscribe.heading1" | trans}}</p>
                    <p class="title mm-title">{{ "magentaMoments.success.subscribe.heading2" | trans}}</p>
                    <p class="mm-description description">{{ "magentaMoments.success.subscribe.text1" | trans}}</p>
                    <p class="mm-description description">{{ "magentaMoments.success.subscribe.text2" | trans}}</p>
                </div>
                <div *ngIf="optin" class="landing-promo-page">
                    <p class="title mm-title">{{ "magentaMoments.success.optin.heading1" | trans}}</p>
                    <p class="title mm-title">{{ "magentaMoments.success.optin.heading2" | trans}}</p>
                    <p class="mm-description description">{{ "magentaMoments.success.optin.text1" | trans}}</p>
                    <p class="mm-description description">{{ "magentaMoments.success.optin.text2" | trans}}</p>
                </div>
                <a
                    href='https://play.google.com/store/apps/details?id=de.tmobile.android.app.rbt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                        alt='Jetzt bei Google Play'
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'
                        style="width: 250px; display: block; margin-left: auto; margin-right: auto;" /></a>
                <a href="https://apps.apple.com/de/app/telekom-freizeichent%C3%B6ne-musik/id1540777905?itsct=apps_box_badge&amp;itscg=30200"
                    style="overflow: hidden; border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"><img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1606953600"
                        alt="Download on the App Store"
                        style="border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"></a>
            </ng-container>

        </app-title-bar>
    </app-spinner>
</div>