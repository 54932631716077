import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { first, catchError, mergeMap, map } from 'rxjs/operators';

import { ClientConfig, DeactivationMode } from 'app/core/config/client-config';
import { Profile, ProfileStatus } from 'app/model/profile';
import { ProfileHelper } from './helper/profile-helper';
import { ProfileService } from './profile.service';
import { AuthService } from './auth/auth.service';
import { AuthError } from 'app/core/auth/auth-error';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { ExternalRouting } from './external.routing';
import * as Sentry from "@sentry/browser";
import log from './logging/logger.service';
import { PurchaseSources } from '../tracking/purchase.sources';
import { PurchaseSourcesInfo } from '../tracking/purchase.sources.info';
import { PurchaseTracking } from 'app/tracking/purchase.tracking';
import { environment } from 'environments/environment';


/**
 * Checks related to subscriber status.
 * Implements redirection and handling logic.
 */
@Injectable()
export class SubscriptionService {
  private deactivationMode;

  /**
   * Flag that will be {@code true} once the activation is successful in
   * order to print the image tracking pixel
   */
  private trackActivation = false;

  /** 
   * The initial purchase sources originated from a deep link that must be 
   * used in subscription and overwrite current purchase sources.
   */
  private deepLinkSources: string[];

  /**
   * Determines whether the user must be subscribed with happy package. This flag is
   * set to true only when the user is launching HTML+ from the happy landing page.
   */
  private happyPromotionEntitled: boolean;

  private readonly happyEntitledSessionKey = "vfhappy";

  constructor(private authService: AuthService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private router: Router) {
    this.deactivationMode = ClientConfig.subscriptionDeactivationMode.getEnum();
  }

  /**
   * Checks if the user can be subscribed.
   * Passes through if the user is new or deactivated.
   * Forwards to the provided url or to home if the user is active or awaiting activation.
   * Blocks users if the user is contract suspended or corporate.
   */
  canSubscribe(navigateUrlForSubscribedUsers?: string, routerNavigateParameters?: any): Observable<boolean> {

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.profileService.getProfile()
      .pipe(first(), mergeMap((profile : Profile) => {

          log.info('Checking if user can be subscribed.');
          const status = ProfileStatus[profile.status];

          if (profile.corporate
            || status === ProfileStatus.CONTRACT_SUSPENDED
            || (status === ProfileStatus.AWAITING_DEACTIVATION
                    && ClientConfig.subscriptionDeactivationMode.getEnum() !== DeactivationMode.END_OF_PERIOD_WORKING)
            || !ProfileHelper.isValidPartner(profile)) {

            return this.isSubscribed();
          } else {
            switch (status) {

              case ProfileStatus.NEW:
              case ProfileStatus.DEACTIVATED:
                return of(true);

              // TODO We need a proper solution here for suspended users
              case ProfileStatus.SUSPENDED:
                if (ClientConfig.subscriptionBlockSuspendedUsers.getBoolean()) {
                  return this.showMessageAndRedirect('register.popup.error.serviceSuspendedBlocked', '/home');
                } else {
                  return this.showMessageAndRedirect('register.popup.error.serviceSuspended', '/home');
                }

              case ProfileStatus.AWAITING_ACTIVATION:
                return this.showMessageAndRedirect(
                  'register.popup.error.awaitingActivation',
                  navigateUrlForSubscribedUsers || '/home', routerNavigateParameters
                );

              case ProfileStatus.ACTIVE:
              // In the special case that we're in DeactivationMode.END_OF_PERIOD_WORKING (VFDE)
              case ProfileStatus.AWAITING_DEACTIVATION:
                return this.showMessageAndRedirect(
                  'register.popup.error.alreadyActive',
                  navigateUrlForSubscribedUsers || '/home', routerNavigateParameters
                );
            }
          }
        }),
        map(canSubscribe => {
          if (!canSubscribe && this.happyPromotionEntitled) {
            // User can't be subscribed.
            // Reset stored value in session storage that determines if the user is eligble for 
            // the happy promotion.
            this.setHappyPromoSubscriptionEntitled(false);
          }
          return canSubscribe;
        }), catchError(error => this.handleError(error)))
  }

  /**
   * Checks if the user is subscribed
   * Forwards to the register if the user is new or deactivated.
   * Passes through if the user is active or awaiting activation.
   * Blocks users and forwards to register if the user is contract suspended or corporate.
   */
  isSubscribed(url?: string, toneId?: string, pst?: string): Observable<boolean> {

    if (!this.authService.isLoggedIn()) {
      log.info('User not authenticated, redirecting to login page');
      this.router.navigate(['/login'], { queryParams: { returnUrl: url, toneId: toneId, pst: pst } });
      return of(false);
    }

    return this.profileService.getProfile()
      .pipe(
        first(), 
        mergeMap((profile) => {
  
          if (!ProfileHelper.isValidPartner(profile)) {
            return this.showMessageAndRedirect('register.popup.error.wrongOperator');
          } else if (profile.corporate) {
            return this.showMessageAndRedirect('register.popup.error.corporate');
          }
          switch (ProfileStatus[profile.status]) {
            case ProfileStatus.NEW:
            case ProfileStatus.DEACTIVATED:
              if (toneId) {
                ExternalRouting.navigate(this.router, ['/register/2/', toneId], true,
                  PurchaseSources.Subscription, PurchaseSourcesInfo.ToneFromAnonymousBrowsing);
              } else {
                ExternalRouting.navigate(this.router, ['/register'], true);
              }
              return of(false);

            case ProfileStatus.ACTIVE:
              // In this case we don't redirect
              // Since we want to let it reach the correct URL
              return of(true);

            case ProfileStatus.AWAITING_ACTIVATION:
              // We don't redirect
              // Since we want to let it reach the correct URL
              return of(true);

            case ProfileStatus.CONTRACT_SUSPENDED:
              // We cannot redirect the user to exit the website, so we go to the home page (ERBT-3922)
              return this.showMessageAndRedirect('register.popup.error.contractSuspended', '/home');

            case ProfileStatus.AWAITING_DEACTIVATION:
              if (this.deactivationMode  === DeactivationMode.END_OF_PERIOD_DEAD) {
                // This for setups like e.g. TMO-DE, which have end-of-month deactivation,
                // but the service is not functional during this time.
                // Thus we don't want to let the user into the app, but propose to reactivate instead.
                this.router.navigate(['account/reactivate/1']);
                return of(false);
              } else {
                // This is for setups like Vodafone,
                // where the service continues to work while waiting for deactivation.

                // For immediate deactivation we also use this branch (for now),
                // because it makes a bit more sense than the other one.
                return of(true);
              }
            case ProfileStatus.SUSPENDED:
              if (ClientConfig.subscriptionBlockSuspendedUsers.getBoolean()) {
                return this.showMessageAndRedirect('register.popup.error.serviceSuspendedBlocked', '/home');
              } else {
                return of(true);
              }
          }
        }), catchError(error => this.handleError(error, url)));

  }

  showMessageAndRedirect(messageId: string, link?: string, routerNavigateParameters?: any): Observable<boolean> {

    link = link || '/register';
    return this.dialogService.infoWithAction(messageId)
      .pipe(mergeMap(() => {
        this.router.navigate([link], routerNavigateParameters);
        return of(false);
      }));
  }

  handleError(error, url?: string): Observable<boolean> {
    if (error instanceof AuthError) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
    } else {
      log.error(error);
      // Check if Sentry SDK is initialized
      if (Sentry.getCurrentHub().getClient() != null) {
        Sentry.captureException(error);
      }
      this.router.navigate(['/error', { code: 500 }]);
    }

    return of(false);
  }

  /** Enables image tracking  */
  enableTracking() {
    this.trackActivation = true;
  }

  /**
   * Checks the image tracking pixel flag is enabled.
   * Reset the flag once it is called to avoid tracking
   * multiple times(refresh or back button press etc)
   */
  isTrackingEnabled() {
    if (this.trackActivation) {
      this.trackActivation = false;
      return true;
    }

    return false;
  }

    /**
     * Use this method to keep the original purchase sources originated 
     * from a deep link and use it upon subscription no matter where the 
     * user was previously navigated.
     * @param queryParams 
     * @see "ERBT-5462"
     */
    setPurchaseSourcesFromDeepLink(queryParams) {
        let sources = PurchaseTracking.getSourceFromQueryString(queryParams);
        if (0 in sources) {
            this.deepLinkSources = sources;
        }
    }

    /**
     * Get the initial purchase sources originated from a deep-link.
     * @see "ERBT-5462"
     */
    getInitialPurchaseSourcesFromDeepLink(): string[] {
        return this.deepLinkSources;
    }

    /**
     * Method for setting a user entitled to the happy promotion subscription for as
     * long as the current tab session is valid. Upon subscription this is set to "false".
     * If the session storage is not supported, as a fallback, the flag that determines
     * if the user is entitled to the happy promo subscription is stored in-memory.
     * @param entitled set to "true" if the user is entitled to the happy promo subscription.
     *                 Otherwise set to "false".
     * @see "ERBT-5832"
     */
    setHappyPromoSubscriptionEntitled(entitled: boolean) {
      try {
        if (!this.isHappyPromotionSupportedForOperator()) {
          return;
        }
        sessionStorage.setItem(this.happyEntitledSessionKey, entitled.toString());
      } catch (error) {
        log.warn('Access to session storage is not allowed', error);
      } finally {
        this.happyPromotionEntitled = entitled;
      }
    }

    /**
     * Determines whether the user is entitled to happy promo subscription.
     * @returns "true" if the user is entitled to happy promo subscription. Otherwise "false".
     * @see "ERBT-5832"
     */
    isHappyPromoSubscriptionEntitled() {
      try {
        if (!this.isHappyPromotionSupportedForOperator()) {
          return false;
        }
        return sessionStorage.getItem(this.happyEntitledSessionKey) === "true";
      } catch (error) {
        log.warn('Access to session storage is not allowed', error);
        return this.happyPromotionEntitled;
      }
  }

  /**
   * Determines whether the happy promotion is supported for this operator.
   * @returns "true" for VF/DE. "false" for TMO.
   */
  private isHappyPromotionSupportedForOperator() {
    return environment.opcoConfig.landingHappyPageUrl;
  }
}
