import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  NgZone,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientConfig } from "../../core/config/client-config";
import { BaseCarouselComponent } from './base-carousel.component';
import { PromoItem } from "../../model/promotions";
import { PromoService } from "../promotion/promo.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-promotions-carousel",
  templateUrl: "./promotions-carousel.component.html",
})
export class PromotionsCarouselComponent
  extends BaseCarouselComponent
  implements OnInit, AfterViewInit, OnDestroy {
  promotionItems: Array<PromoItem> = [];
  ids: Array<string> = [];
  private readonly regexPromotion = /PROMOTION\(([^,]+)(?:,\s*button=(.*))?\)/i;
  private promoSubscriptions: Subscription[] = [];

  @ViewChild("scroller", { static: true }) tabGroup: ElementRef;

  constructor(
    private promoService: PromoService,
    private route: ActivatedRoute,
    router: Router,
    protected zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super(zone, router);
    this.title = ClientConfig.homePromotionsCarouselTitle.getString();
    this.parsePromotionConfig(ClientConfig.homePromotionsCarouselIds.getString());
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.ids.forEach(id => {
      this.getPromoById(id);
    });
  }

  getPromoById(id: string) {
    const subscription = this.promoService.getPromotionById(id).subscribe(promotion => {
      if (promotion) {
        this.promotionItems.push(promotion.item[0]);
      }
    });
    this.promoSubscriptions.push(subscription); // Store the subscription
  }

  clicked(promotion: PromoItem) {
    const match = promotion.targetUrl.match(/id=(\d+)/);
    const promoId: string = match ? match[1] : null;
    this.navigateOnClick(
      this.router,
      [promotion.targetUrl],
      true,
      this.purchaseSource as string,
      promoId
    );
  }

  protected parsePromotionConfig(homePromotionsCarouselIdsConfig: string) {
    if (homePromotionsCarouselIdsConfig) {
      const carouselConfigIds = homePromotionsCarouselIdsConfig.split(';');
      carouselConfigIds.forEach(element => {
        const matches = element.match(this.regexPromotion);
        if (matches && matches[1]) {
          this.ids.push(matches[1].trim());
        }
      });
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all stored subscriptions
    this.promoSubscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
