import { NgZone, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import { BaseComponent } from 'app/base.component';

/**
 * Base class for view components
 */
@Directive()
export class BaseViewComponent extends BaseComponent {

  isMobile = false;
  // The newLayout refers to Vodafone DE rebranding done in ERBT-5644 
  newLayout = false;
  // The redesigned layout refers to the T-Mobile rebranding done in ERBT-6484 
  isRedesign = false;
  private rules = {
    print: 'print',
    screen: 'screen',
    phone: '(max-width: 767.9px)',
    tablet: '(min-width: 768px) and (max-width: 1024px)',
    desktop: '(min-width: 1025px)',
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
  };
  constructor(protected zone: NgZone, router: Router) {
    super(router);

    // Checks whether screen width suits mobile
    this.isMobile = matchMedia(this.rules.phone).matches;

    this.newLayout = environment.opcoConfig.newLayout;
    // 2024 Redesign
    this.isRedesign = environment.opcoConfig.isRedesign;
    // Add listener to the width change
    try {
      matchMedia(this.rules.phone).addEventListener("change", (e) => {
          this.onMediaQueryListEvent(e);
        });
      } catch {
        // IE 11 doesn't support MediaQueryList.onChange event, but it supports
        // addListener() which is deprecated for new browsers.
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/onchange
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
        matchMedia(this.rules.phone).addListener((e) => {
          this.onMediaQueryListEvent(e);
        });
      }
  }

  private onMediaQueryListEvent(e: MediaQueryListEvent) {
    if (e.matches) {
      this.zone.run(() => {
        this.isMobile = true;
      });
    } else {
      this.zone.run(() => {
        this.isMobile = false;
      });
    }
  }

  /**
   * Shuffles the array passed as a parameter
   * @param array
   */
  public shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
