<div class="tone-list-container">
<mat-list *ngIf="!grid" class="tone-list button-less-list">
  <app-tone-list-item [tone]="tone" *ngFor="let tone of cachedTones"
      [purchaseSource]="purchaseSource" [purchaseSourceInfo]="purchaseSourceInfo"
      [purchaseSourcePropagateCurrent]="purchaseSourcePropagateCurrent"
      [imageSize]=imageSize>
  </app-tone-list-item>
</mat-list>

<mat-grid-list *ngIf="grid" cols="12" [gutterSize]="gutterSize">
  <mat-grid-tile *ngFor="let tone of cachedTones; let j = index"
    [colspan]="getTileSize(j).width"
    [rowspan]="getTileSize(j).height">
    <app-tone-grid-item mat-ripple [tone]="tone"
        [purchaseSource]="purchaseSource" [purchaseSourceInfo]="purchaseSourceInfo"
        [purchaseSourcePropagateCurrent]="purchaseSourcePropagateCurrent">
    </app-tone-grid-item>
  </mat-grid-tile>
</mat-grid-list>
<span class="small-spinner" *ngIf="!tracksLoaded"></span>
</div>
