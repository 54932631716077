import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { OptinPromoComponent } from './optin-promo.component';

@NgModule({
  imports: [RouterModule.forChild(getRoutes())],
  exports: [RouterModule]
})
export class OptinPromoRoutingModule { }

function getRoutes(): Routes {
  // only allow /magentaMoments for OpCos where it is enabled
  return environment.magentaMomentsEnabled ? [{ path: 'magentaMoments', component: OptinPromoComponent }] : [];
}