<!-- Define the reusable template for IGT items -->
<ng-template #igtListTemplate let-igtList let-subTitle="subTitle" let-spinnerActive="spinnerActive">
  <app-spinner [active]="spinnerActive"></app-spinner>
  <ng-container *ngIf="igtList.totalCount > 0">
    <p class="ugc-list-title">{{ subTitle | trans }}</p> 
    <mat-list class="igt-tone-list igt-list-option">
      <app-igt-list-item
          *ngFor="let tone of igtList.tone"
          [select]="true"
          [ripple]="true"
          [selected]="selectedTone === tone.id"
          [tone]="tone"
          (click)="select(tone.id)"
      >
      </app-igt-list-item>
    </mat-list>
  </ng-container>
</ng-template>

<span class="dialog igt-select">
  <div class="dialog-header">
    <button mat-button (click)="dialogRef.close(false)" class="bt-close"></button>
    <h1 mat-dialog-title>{{ 'setting.page.igtSelect.dialog.title' | trans }}</h1>
  </div>
  <div mat-dialog-content>
    <p class="ugc-list-title">
      {{ 'setting.NoIgtSelect.title' | trans }}
    </p>
    <mat-list class="igt-tone-list igt-list-option">
      <mat-list-item mat-ripple class="list-item-button" (click)="select(null)">
        <div mat-line>
          <span class="icon igt-disable"></span>
          <p class="title"> {{ 'setting.NoIgtSelect.content' | trans }} </p>
        </div>
      </mat-list-item>
    </mat-list>


    <!-- User profile IGT -->
    <ng-container
        *ngTemplateOutlet="igtListTemplate; context: { $implicit: igtList, subTitle: 'setting.page.igtSelect.dialog.recorded.subTitle', spinnerActive: spinnerActiveIGT }"></ng-container>

    <!-- Predefined IGT from Service -->
    <ng-container
        *ngTemplateOutlet="igtListTemplate; context: { $implicit: igtPredefinedList, subTitle: 'setting.page.igtSelect.dialog.predefined.subTitle', spinnerActive: spinnerActivePredefinedIGT }"></ng-container>
  </div>
</span>
