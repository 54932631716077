<div class="magentaBlackDays-promo-background">
    <app-spinner [active]="spinnerActive">
        <app-title-bar title="{{ 'home.page.title' | trans }}" simpleHeader="true">

            <!-- Container holding the list of tones and the activation checkboxes and button-->
            <ng-container *ngIf="promoItem && success == undefined">
                <div class="magentaBlackDays-landing-promo landing-promo-page">
                    <ng-container>
                        <img class="magentaBlackDays-landing-promo-image landing-promo-image" *appLazy [src]="promoItem.artwork | image:880:1200" />
                    </ng-container>
                    <p class="title mm-title">{{ promoItem.title }}</p>
                    <p class="mm-description description" [innerHtml]="promoItem.description"></p>
                    <p class="mm-description smaller description" [innerHtml]=" 'magentaBlackDays.description' | trans "></p>
                    <section class="magentaBlackDays-list">
                        <mat-list class="tone-list button-less-list mm-tone-list">
                            <app-tone-list-item [library]="true" [selection]="true" [selected]="isSelected(tone.id)"
                                [tone]="tone" (click)="toneClicked(tone)" *ngFor="let tone of tones">
                            </app-tone-list-item>
                        </mat-list>
                    </section>
                    <div class="more-songs-text" [innerHtml]="'magentaBlackDays.moreSongsText.html' | trans"></div>
                    <mat-checkbox class="mm-checkbox" [(ngModel)]="checkbox1">
                        <div (click)="listenToLegal($event)" [innerHtml]="'magentaBlackDays.checkbox1.html' | trans"></div>
                    </mat-checkbox>
                    <mat-checkbox class="mm-checkbox" [(ngModel)]="checkbox2">
                        <div (click)="listenToLegal($event)" [innerHtml]="'magentaBlackDays.checkbox2.html' | trans"></div>
                    </mat-checkbox>
                    <section class="text-center">
                        <button [disabled]="!checkbox1 || !checkbox2 || !selectedToneId"
                            mat-raised-button (click)="redirectToLogin()" class="bt-main" color="primary">
                            {{ 'magentaBlackDays.button.subscribe' | trans}}
                        </button>
                    </section>
                    <div class="legal-info-blackDays">
                        <p [innerHtml]=" 'magentaBlackDays.page.legal.html' | trans "></p>
                    </div>
                </div>
            </ng-container>

            <!-- Container for the success message after subscription -->
            <ng-container *ngIf="success">
                <div class="landing-promo-page">
                    <p class="title mm-title activated">{{ "magentaBlackDays.success.heading1" | trans}}</p>
                    <p class="mm-description description" [innerHTML]="'magentaBlackDays.success.text1' | trans"></p>
                    <p class="mm-description description" [innerHTML]="'magentaBlackDays.success.text2' | trans"></p>
                </div>
                <a
                    href='https://play.google.com/store/apps/details?id=de.tmobile.android.app.rbt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                        alt='Jetzt bei Google Play'
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'
                        style="width: 250px; display: block; margin-left: auto; margin-right: auto;" /></a>
                <a href="https://apps.apple.com/de/app/telekom-freizeichent%C3%B6ne-musik/id1540777905?itsct=apps_box_badge&amp;itscg=30200"
                    style="overflow: hidden; border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"><img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1606953600"
                        alt="Download on the App Store"
                        style="border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"></a>
            </ng-container>

            <ng-container *ngIf="success == false">
                <div class="landing-promo-page notEligible">
                    <p class="title mm-title activated">{{ "magentaBlackDays.notEligible.heading1" | trans}}</p>
                    <p class="mm-description description" [innerHTML]="'magentaBlackDays.notEligible.text1' | trans"></p>
                    <button mat-raised-button (click)="redirectToLandingPromo()" class="bt-main" color="primary" [innerHTML]="'magentaBlackDays.notEligible.buttontext' | trans">
                </button>
                    <p class="mm-description description" [innerHTML]="'magentaBlackDays.notEligible.text2' | trans"></p>
                </div>
                <a
                    href='https://play.google.com/store/apps/details?id=de.tmobile.android.app.rbt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                        alt='Jetzt bei Google Play'
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'
                        style="width: 250px; display: block; margin-left: auto; margin-right: auto;" /></a>
                <a href="https://apps.apple.com/de/app/telekom-freizeichent%C3%B6ne-musik/id1540777905?itsct=apps_box_badge&amp;itscg=30200"
                    style="overflow: hidden; border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"><img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1606953600"
                        alt="Download on the App Store"
                        style="border-radius: 13px; width: 250px; height: 83px; display: block; margin-left: auto; margin-right: auto;"></a>
            </ng-container>

        </app-title-bar>
    </app-spinner>
</div>