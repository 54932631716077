import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {


    static loadScript(scriptUrl: string, scriptId?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            var script = document.createElement('script');
            script.src = scriptUrl;
            if (scriptId) {
                script.id = scriptId;
            }
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        }).catch(function () { 
            // Failed to load script, ignore the error.
        });
    }
}