<div class="lead" *ngFor="let config of legalConfig">

<p
  *ngIf="config === 'TERMSANDCONDITIONS' "
  [innerHTML]=" 'tone.page.legal.terms.html' | trans"
  (click)="listenToLegal($event)">
</p>

<p
  *ngIf="config === 'WITHDRAW' "
  [innerHTML]=" 'tone.page.legal.withdrawal.html' | trans"
  (click)="listenToLegal($event)"></p>

<p *ngIf="config === 'STOP' ">
  {{ 'tone.page.legal.stop' | trans }}
</p>

<p *ngIf="config === 'MONTHLY' ">
  {{ 'tone.page.legal.monthlySubscription' | trans | format:monthlySubscriptionPrice }}
</p>

</div>
