import { Directive, OnChanges, AfterViewChecked, ElementRef, Input } from '@angular/core';

import { ImagePipe } from '../pipes/image.pipe';


/**
 * Directive to be used with images.
 * Queries the image transcoder with the right size depending on the bounds of the element.
 * @example <img appResizable [src]="tone.cover" />
 */
@Directive({
  selector: '[appResizable]'
})
export class ResizableDirective implements AfterViewChecked, OnChanges {

  @Input()
  public src: string;

  private lastWidth?: number;
  private lastHeight?: number;

  private minChange = 100;
  private loaded = false;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges() {
    this.loaded = false;
  }

  ngAfterViewChecked() {
    this.test();
  }

  test() {

    // Angular discourages direct DOM access.
    // But we need the image expected width & height to query the right size.
    // Therefore we have to access the native element.
    // See http://stackoverflow.com/questions/34919738/getting-element-height-angular-2

    const el = this.elementRef.nativeElement;
    const computedStyle = getComputedStyle(el);

    const width = parseInt(el.width, 10);
    let height = parseInt(el.height, 10);

    // Fix for padding trick to get a responsive square image
    // which still queries the correct height
    const paddingBottom = parseInt(computedStyle.paddingBottom, 10);
    if (height === 0) {
      // padding corresponds to height
      height = paddingBottom;
    }

    const widthChanged = (!this.lastWidth && width > 0)
      || Math.abs(width - this.lastWidth) > this.minChange;

    const heightChanged = (!this.lastHeight && height > 0)
      || Math.abs(height - this.lastHeight) > this.minChange;

    // We don't want to fire on every resize.
    // So we check if it changed at least by this.minChange
    if (widthChanged || heightChanged || !this.loaded) {
      const pipe = ImagePipe.instance;
      const imageUrl = pipe.transform(this.src, width, height);

      el.src = imageUrl;
      this.lastWidth = width;
      this.lastHeight = height;
      this.loaded = true;
    }

  }
}
