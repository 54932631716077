import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  EventEmitter,
  NgZone,
  ChangeDetectorRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Tone } from 'app/model/tone';
import { AnalyticsService } from 'app/core/analytics.service';
import { PlayButtonService } from 'app/shared/play-button/play-button.service';
import { RbtVersionService } from '../rbt-version/rbt-version.service';
import log from 'app/core/logging/logger.service';
import { takeUntil } from "rxjs/operators";

enum PlayerAction {
  PAUSED,
  ENDED
}

/**
 * Factory method to create the Audio element
 * @param listener the {@code onended} event listener
 */
export function initAudio(listener: Subject<PlayerAction>): HTMLAudioElement {
  const audio = new Audio();
  audio.onended = () => {
    listener.next(PlayerAction.ENDED);
  };

  return audio;
}

@Component({
  selector: 'app-play-button',
  templateUrl: './play-button.component.html'
})
export class PlayButtonComponent implements OnDestroy, OnInit, OnChanges {

  // Used as a way to communicate between components
  // When any audio is played, an event is triggered to pause all other audio elements.
  // See https://github.com/Reactive-Extensions/RxJS/blob/master/doc/gettingstarted/subjects.md
  private static audioPlaying = new Subject<PlayerAction>();

  // Create a single audio element to play audio
  public static audioElement: HTMLAudioElement = initAudio(PlayButtonComponent.audioPlaying);

  @Input()
  public tones: Tone[];

  @Input()
  public cover: string;

  @Input()
  public preLoad = false;

  @Input()
  public initial: string;

  @Input()
  public initialBgColor;

  @Input()
  public playAlternativeAudio = false;

  @Output()
  onLoad = new EventEmitter<number>();

  @Output()
  onPrelistenStarted = new EventEmitter<Tone>();

  public isPlaying: boolean;

  public isDisabled: boolean;

  public isLoading: boolean;

  private trackCount = 0;

  private subscription: Subscription;

  private tone: Tone;

  isRedesign = environment.opcoConfig.isRedesign;
  destroy$ = new Subject();


  constructor(private zone: NgZone, private playButtonService: PlayButtonService, private rbtVersionService: RbtVersionService,
              private ref: ChangeDetectorRef) {
    this.isPlaying = false;
    this.isLoading = false;
  }

  ngOnChanges() {
    this.isDisabled = !(this.tones && this.tones.length > 0);
  }

  ngOnInit() {

    this.audioPlaying
      .pipe(takeUntil(this.destroy$))
      .subscribe(action => this.handlePlayerAction(action));

    // Used to stop the playback when the grid item is flipped back
    this.playButtonService.audio$
      .pipe(takeUntil(this.destroy$)).subscribe(() => this.stopPlayback());

    if (this.preLoad) {
      this.audio.addEventListener('loadedmetadata', () => {
        this.onLoad.emit(this.audio.duration);
      });

      this.loadSource();
    }

    this.rbtVersionService.rbtVersionChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(alternativeAudioPreferred => {
        this.playAlternativeAudio = alternativeAudioPreferred;
        log.info('Changing prelisten audio to the alternative', this.playAlternativeAudio);
      });

  }

  private handlePlayerAction(action: PlayerAction) {

    if (action === PlayerAction.ENDED && this.isPlaying) {
      this.trackCount++;
      if (this.trackCount < this.tones.length) {
        this.loadSource();
        this.play();
        this.trackPreview();
        return;
      }
    }

    if (this.isPlaying) {
      this.zone.run(() => this.isPlaying = false);
    }

  }

  loadSource() {

    if (this.isDisabled) {
      return;
    }

    if (this.tones.length > this.trackCount) {

      const tone = this.tones[this.trackCount];
      const prelistenAudio = this.playAlternativeAudio && tone.prelistenAlternativeAudio
        ? tone.prelistenAlternativeAudio
        : tone.prelisten;

      if (!this.audio.src || this.audio.src !== prelistenAudio) {
        this.audio.src = prelistenAudio;
        this.tone = this.tones[this.trackCount];
        log.info('Changed audio to prelisten: ', prelistenAudio);
      }
    }

  }

  stopPlayback() {
    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    }
  }

  togglePlayback(event) {

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    } else {
      // We load it here to prevent the browser
      // loading all prelisten files
      this.audioPlaying.next(PlayerAction.PAUSED);
      this.trackCount = 0;
      this.loadSource();
      this.play();
      this.trackPreview();
    }

  }

  private play() {

    // ERBT-5682: Show a loading spinner when the audio is not ready to be played
    // without loading it from the server first.
    if (this.audio.readyState != 4) {
      this.isLoading = true;
      this.ref.detectChanges();
    }

    // audio.play() starts loading audio content asynchronously
    // At the time of writing, HTMLMediaElement.play() returns a promise
    // in Chrome, Edge, Firefox, Opera, and Safari.
    let playPromise = this.audio.play();
    if (playPromise != undefined) {
      playPromise.then(_ => {
        // playback started!
        this.isPlaying = true;
        this.isLoading = false;
        this.ref.detectChanges();
      })
        .catch(error => {
          // playback was prevented
          console.error("Prelisten playback failed with error, " + error);
          this.isPlaying = false;
          this.isLoading = false;
          this.ref.detectChanges();
        });
    } else {
      this.isLoading = false;
      this.isPlaying = true;
      this.ref.detectChanges();
    }
  }

  get audio() {
    return PlayButtonComponent.audioElement;
  }

  get audioPlaying() {
    return PlayButtonComponent.audioPlaying;
  }

  private trackPreview() {
    if (this.tone && this.tone.title) {
      AnalyticsService.getInstance().trackEventAction('play', this.tone.title);
    }
    this.onPrelistenStarted.emit(this.tone);
  }

  ngOnDestroy() {
    this.audio.pause();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
