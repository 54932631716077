/**
 * All possible sources for ERBT-3371 purchase source tracking.
 *
 * Note: Typescript < 2.4 does not support string enums, so this is a regular class instead.
 */
export class PurchaseSources {
    /**
     * Purchase originated from a promotion tile on the home screen
     */
    static PromotionTile = 'promotionTile';

    /**
     * Purchase originated from the home screen promotion popup
     */
    static PromotionPopup = 'promotionPopup';

    /**
     * Purchase originated from the home screen banner
     */
    static HomeBanner = 'homeBanner';
    
    /**
     * Purchase originated from a direct link
     */
    static DeepLink = 'deepLink';

    /**
     * Purchase originated from the shop screen banner
     */
    static ShopBanner = 'shopBanner';

    /**
     * Purchase originated from the banner carousel at home screen
     */
    static HomeBannerCarousel = 'homeBannerCarousel';

    /**
     * Purchase originated from the shop
     */
    static Shop = 'shop';

    /**
     * Purchase originated from the search
     */
    static Search = 'search';

    /**
     * Repurchase of expired/expiring content
     */
    static ExpiredContent = 'expiredContent';

    /**
     * Free tone during subscription
     */
    static Subscription = 'subscription';

    /** 
     * New Tracks Carousel on Home screen
     */
    static HomeNewTracksCarousel = 'HomeNewTracksCarousel';

    /** 
     * Shops Carousel on Home screen
     */
    static HomeShopsCarousel = 'HomeShopsCarousel';

    /** 
     * Top Promo on Home screen
     */
    static TopPromoHomePage = 'TopPromoHomePage';

    /** 
     * Bottom Promo on Home screen
     */
    static BottomPromoHomePage = 'BottomPromoHomePage';

    /** 
     * Song of the week
     */
    static SongOfTheWeek = 'SongOfTheWeek';


}
