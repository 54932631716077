import { Router} from '@angular/router';
import { PurchaseTracking } from '../tracking/purchase.tracking';
import { Params } from '@angular/router';

/**
 * Utility class to facilitate routing to external links
 * https://jira.real.com/browse/ERBT-3640
 * and purchase source tracking (ERBT-3371).
 *
 * At some point we should rename this class to e.g. `RoutingUtils`.
 */
export class ExternalRouting {

    private static readonly extLinks = /^https?:\/\/|^market?:\/\//i;

    /**
     * Navigate to the given URL.
     * If it starts with `http://` or `https://`, it is treated as an external link
     * and opened in the current tab.
     * Otherwise, it is passed on to `navigate`, which takes care of purchase sources.
     *
     * ### Usage
     *
     * ```
     * // Navigate to external page
     * navigateExternalUrl('http://www.external.com/page');
     *
     * // Navigate to internal page
     * navigateExternalUrl('/faq');
     * ```
     * @param router the Router to use for internal links
     * @param url the URL to navigate to, either an internal, relative one or an external, absolute one
     * @param preservePurchaseSource indicates that the current page's purchase source should be propagated
     * @param source (optional) the source for ERBT-3371 purchase source tracking, such as the home screen banner
     * @param sourceInfo (optional) more detailed info on the ```source```
     */
    public static navigateExternalUrl(router: Router, url: string, preservePurchaseSource: boolean,
            source?: string, sourceInfo?: string) {

        if (ExternalRouting.extLinks.test(url)) {
            // treat it as an external link
            window.open(url, '_self');
        } else {
            // treat it as an internal link, add source tracking if present
            ExternalRouting.navigate(router, [url], preservePurchaseSource, source, sourceInfo);
        }
    }

    /**
     * Navigates on `router` according to the given `commands` after injecting current and given purchase sources.
     *
     * Current purchase sources are only applied if `preservePurchaseSource` is `true`.
     * In this case purchase sources given in `source` and `sourceInfo` are ignored.
     *
     * @param router router
     * @param commands commands to execute
     * @param preservePurchaseSource whether the current purchase source should be preserved.
     *          `true` for forward navigation, `false` for global / sidewards navigation.
     * @param source optional purchase source to be propagated
     * @param sourceInfo optional purchase source info to be propagated
     */
    public static navigate(router: Router, commands: any[], preservePurchaseSource: boolean,
                source?: string, sourceInfo?: string) {

        let parameterMap: Params = {};

        if (preservePurchaseSource) {
            const url = router.url;
            const queryStringStart = url.indexOf('?');
           
            if (queryStringStart >= 0) {
                const queryString = url.substring(queryStringStart + 1);
                // Argh!!! Why the hack is there no working Query string parser in JS/ts/Angular!?
                const params = ExternalRouting.parseQueryString(queryString);
                const sources = PurchaseTracking.getSourceFromQueryString(params);

                if (0 in sources) {
                    source = sources[0];
                    sourceInfo = (1 in sources) ? sources[1] : null;
                }
            }
        }

        // We will look if additional parameters are present in the commands
        // e.g.: /shop/id/506?mode=grid
        const route = commands[0];
        const routeParameterStart = route.indexOf('?');
        if (routeParameterStart >= 0) {
            // Keep the original route (/shop/id/506)
            commands = [route.substring(0, routeParameterStart)];
            // Create a parameter map (mode=grid)
            parameterMap = this.parseQueryString(route.substring(routeParameterStart + 1));
        }

        if (source) {
            // PST it's added to the parameter map
            parameterMap['pst'] = PurchaseTracking.encodeSourceTrackingInfo(source, sourceInfo);
        }
       
        router.navigate(commands, {queryParams: parameterMap});
    }

    /**
     * Parases a query string from a URL and puts all (decoded) parameters into map.
     *
     * Duplicate keys are not supported. Only the last value is returned.
     *
     * @param query query string from URL
     */
    private static parseQueryString(query: string): Params {
        const result: Params = {};
        query.split('&').forEach(param => {
            const keyValue = param.split('=');
            const key = ExternalRouting.decodeQueryValue(keyValue[0]);
            const value = keyValue[1] ? ExternalRouting.decodeQueryValue(keyValue.splice(1).join('')) : '';
            result[key] = value;
        });
        return result;
    }

    /**
     * URL-decodes a query string key or value.
     * This is no the same as `decodeURIComponent`,
     * because spaces and plus signs are handled differently in query strings.
     *
     * @param encoded encode query string key or value
     */
    private static decodeQueryValue(encoded: string): string {
        // First get rid of all plus signes (which denote spaces), then decode the rest.
        return decodeURIComponent(encoded.replace('+', ' '));
    }

}
