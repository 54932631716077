import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from 'app/tone/tone.service';
import { YoungPromoComponent } from './young-promo.component';
import { YoungPromoRoutingModule } from './young-promo.routing.module';

@NgModule({
  imports: [YoungPromoRoutingModule, SharedModule],
  declarations: [YoungPromoComponent],
  providers: [PromoService, ToneService]
})

export class YoungPromoModule { }