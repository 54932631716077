import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import log from './logging/logger.service';
import { ScriptService } from './script.service';

// These two objets below are added to window scope when loading the TCF2 CMP script.
declare var __tcfapi: any;
declare var _sp_: any;

/**
 * Interface representing the consent status for a vendor
 */
export interface ConsentStatusChangeEvent {

    /**
     * The vendor
     */
    vendor: Vendor;

    /**
     * The consent status of this vendor
     */
    status: boolean;
}

/** Identifies the different vendors  */
export enum Vendor {
    SENTRY,
    TEALIUM_ADOBE_ANALYTICS,
    GTAG,
    FIREBASE_CLOUD_MESSAGING
}

/** 
 * Service that integrates TCF2 Consent Management Platform (CMP) API for
 * enabling/disabling third-party integrations based on per vendor basis consent.
 * Exposes an observable so that subscribed components can get notified whenever
 * the consent status changes by the user.
 * @see "ERBT-5910"
 */
export class ConsentManagementService {

    private static instance: ConsentManagementService;
    // Observable static change source
    private statusChangeSource: Subject<ConsentStatusChangeEvent>;
    // Total number of previous granted vendors
    private previousGrantedVendors: number;

    /**
    * Observable to subscribe to and get notified when consent status changes for the vendors.
    */
    statusChange$: Observable<ConsentStatusChangeEvent>;

    static getInstance(): ConsentManagementService {
        if (!ConsentManagementService.instance) {
            ConsentManagementService.instance = new ConsentManagementService();
        }
        return ConsentManagementService.instance;
    }

    static isConsentManagementSupported(): boolean {
        if (environment.opcoConfig.cmpScriptUrl) {
            return true;
        }
        return false;
    }

    public static loadScript(): Promise<any> {
        if (ConsentManagementService.isConsentManagementSupported()) {
            return ScriptService.loadScript(environment.opcoConfig.cmpScriptUrl)
        }
        return Promise.resolve();
    }

    public static openConsentManagementDialog() {
        if (_sp_ !== "undefined") {
            _sp_.loadPrivacyManagerModal(environment.production ? '773131' : '770521');
        }
    }

    constructor() {
        this.statusChangeSource = new Subject<ConsentStatusChangeEvent>();
        this.statusChange$ = this.statusChangeSource.asObservable();
        this.previousGrantedVendors = 0;
        this.addConsentStatusChangeListener();
    }

    private addConsentStatusChangeListener() {

        if (__tcfapi === "undefined") {
            // TCF2 CMP API script is not available, do nothing.
            return;
        }

        // Script to check on the consent status changes for all vendors.
        // A consent status change event is triggered:
        //  a) On page load if historic consent record in browser storage exists ('tcloaded' event).
        //  b) When the user interacts with the consent prompt ('useractiocomplete' event).
        __tcfapi('addEventListener', 2, function (tcData, success) {
            if (success && (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete')) {
                __tcfapi('getCustomVendorConsents', 2, function (data, success) {
                    if (success) {

                        log.debug("Consent dialog event status:" + tcData.eventStatus);

                        let sentryVendorId = '61e9716a293cdf349b3a07f8';
                        let tealiumVendorId = '5e716f1d9a0b5040d575080d';
                        let adobeVendorId = '5ed7a9a9e0e22001da9d52ad'
                        let gtagVendorId = '5e952f6107d9d20c88e7c975';
                        let firebaseCloudMessagingVendorId = '5ee9e9b4182da52f42468bb8';

                        let grants = data.grants;
                        var analyticsGranted = 0;
                        var sentryGranted = false;
                        var gtagGranted = false;
                        var firebaseCloudMessagingGranted = false;

                        var currentGrantedVendors: number = 0;

                        for (var id in grants) {
                            if (grants.hasOwnProperty(id) && id === tealiumVendorId && grants[id].vendorGrant) {
                                // Tealium is granted
                                analyticsGranted++;
                                currentGrantedVendors++;
                                log.debug("Tealium Vendor Granted");
                            } else if (grants.hasOwnProperty(id) && id === adobeVendorId && grants[id].vendorGrant) {
                                // Adobe is granted
                                analyticsGranted++;
                                currentGrantedVendors++;
                                log.debug("Adobe Analytics Vendor Granted");
                            } else if (grants.hasOwnProperty(id) && id === sentryVendorId && grants[id].vendorGrant) {
                                // Sentry is granted
                                sentryGranted = true;
                                currentGrantedVendors++;
                                log.debug("Sentry Vendor Granted");
                            } else if (grants.hasOwnProperty(id) && id === gtagVendorId && grants[id].vendorGrant) {
                                // Google Tag Manager vendor is granted.
                                gtagGranted = true;
                                currentGrantedVendors++;
                                log.debug("Google Vendor Granted");
                            } else if (grants.hasOwnProperty(id) && id === firebaseCloudMessagingVendorId && grants[id].vendorGrant) {
                                // Firebase Cloud Messaging is granted
                                firebaseCloudMessagingGranted = true;
                                currentGrantedVendors++;
                                log.debug("Firebase Vendor Granted");
                            }
                        }

                        // It's not possible within this scope to access directly the declared observable statusChangeSource.
                        // Thus we need to get the service instance to reach it. At this point the service is instansiated.
                        let consentManagementService = ConsentManagementService.instance;

                        log.debug("Total number of granted vendors: " + currentGrantedVendors);
                        log.debug("Total number of previously granted vendors: " + consentManagementService.previousGrantedVendors);

                        // Reload page whenever the user has revoked consent to previously granted vendors in order to apply 
                        // the changes by reloading only the consented integrations.
                        if (tcData.eventStatus === 'useractioncomplete'
                            && consentManagementService.previousGrantedVendors > currentGrantedVendors) {
                            window.location.reload();
                        }

                        // Update granted vendors
                        consentManagementService.previousGrantedVendors = currentGrantedVendors;

                        // Notify the components subscribed to statusChange$ observable about the new consent status for each vendor.
                        // Both Tealium and Adobe vendors must be granted for enabling Tealium analytics.
                        consentManagementService.statusChangeSource.next({ vendor: Vendor.TEALIUM_ADOBE_ANALYTICS, status: analyticsGranted === 2 });
                        consentManagementService.statusChangeSource.next({ vendor: Vendor.SENTRY, status: sentryGranted });
                        consentManagementService.statusChangeSource.next({ vendor: Vendor.GTAG, status: gtagGranted });
                        consentManagementService.statusChangeSource.next({ vendor: Vendor.FIREBASE_CLOUD_MESSAGING, status: firebaseCloudMessagingGranted });
                    }
                });
            }
        });
    }
}
