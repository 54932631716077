import { Component, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Tone } from 'app/model/tone';
import { BaseViewComponent } from 'app/shared/base-view.component';
import { Profile } from '../../model';


@Component({
  selector: 'app-price',
  templateUrl: './price.component.html'
})

export class PriceComponent extends BaseViewComponent {

    @Input() profile:Profile;
    @Input() isLoggedIn:boolean;
    @Input() tone:Tone;

    constructor(
        zone: NgZone,
        router: Router) {
        super(zone, router);
      }
 
}
