import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {throwError as observableThrowError,  Observable, of} from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { environment } from 'environments/environment';

import CustomHttpParams from '../network/custom-http-params';
import { Token } from './token';
import { ClientConfig } from '../config/client-config';
import { AuthenticationMethod, RefreshTokenMethod, MobileAuthenticationMethod } from './auth-method';
import { AuthError } from './auth-error';
import HttpHeader from '../network/http-header';
import ContentTypes from '../network/content-types';
import NetworkUtil from '../network/network-util';
import UrlBuilder, { RequestMethod } from '../url-builder';
import { HttpStatus } from '../network/http-status';
import log from '../logging/logger.service';

import * as CryptoJS from 'crypto-js';

/**
 * Singleton class that manages authentication state of the application.
 * <p>
 * Most flows are standard OAuth2 authentication as described in
 * <a href="https://tools.ietf.org/html/rfc6749">RFC 6749</a>.
 * The exception is that the initial token is obtained in various non-standard ways
 * (3G authentication, PIN from SMS, etc).
 * <ul>
 *     <li>Each token request returns an <em>access token</em> and a <em>refresh token</em>.
 *          The access token is used for all following resource requests until it expires.
 *          After that, the refresh token is used for retrieving a new pair of tokens.</li>
 *     <li>The server enforces that each refresh token can be used only once.</li>
 *     <li>The access token is stored in the local storage, so that it is still available when if the user
 *         opens the app in a different tab.</li>
 *     <li>The {@code AuthService} automatically combines multiple parallel requests for tokens.
 *         This means that even if {@link #getAccessToken()} is called for multiple resource requests in parallel,
 *         only a single request is sent to the token endpoint(s) and the observable will be shared.</li>
 *     <li>When requesting a token, the client authenticates itself using Basic authentication.
 *         Note that the security of OAuth2 does <em>not</em> depend on this!
 *         It's just an extra precaution to stop script kiddies to play around with our token endpoints.</li>
 * </ul>
 */
@Injectable()
export class AuthService {

  /** Token type "Bearer", the only token type supported by this implementation. */
  public static tokenTypeBearer = 'bearer';

  /** The obfuscated OAuth2 client id. */
  private static clientSecretObfuscated = 'S0iBToC`WESlW2Oyboe0Pl00`{f3';

  /**  The obfuscated access token encryption key */
  private static passPhraseObfuscated = 'SlmOcmP0[GOMWlq7[VmPev<<';

	/**
	 * Description used in response
	 * to {@link GrantType#password} requests with unknown username / password.
	 * Must match {@code PinCodeAuthenticationProvider.ERROR_DESCRIPTION_INVALID_PIN} on the server side.
	 */
  private readonly tokenValueErrorDescriptionInvalidPin = 'invalid pin';

	/**
	 * Description used in responses
	 * to {@link GrantType#password} requests for locked users,
	 * i.e., after too many failed attempts.
	 * <p>
	 * Must match {@code PinCodeServiceImpl.ERROR_DESCRIPTION_TOO_MANY_FAILED_ATTEMPTS} on the server side.
	 */
  private readonly tokenValueErrorDescriptionTooManyAttempts = 'too many failed attempts';

  /**
   * The preference key where we store our encrypted tokens.
   * The name of the key is intentionally misleading / non-descriptive.
   */
  private readonly keyEncryptedToken = 'fallback';

  /**
	 * The  OAuth client secret.
   */
  private readonly clientPassword = AuthService.getKey(AuthService.clientSecretObfuscated); // 'GXBRpZT4fWsqrwuBmuk86'

  /** Passphrase to encode access token  */
  private readonly passPhrase = AuthService.getKey(AuthService.passPhraseObfuscated); // 'FiMnT5dSKVjzeiPw'

  private readonly requestParamMsisdn = 'msisdn';

  private readonly requestParamToken = 'token';

  private readonly requestParamTokenHint = 'token_type_hint';

  /**
   * Stores the active token
   */
  private token: Token;

  /**
   * Stores the ongoing auth request process
   */
  private authProcess: Observable<any>;

  /** Holds user selection to persist refresh token */
  private persistRefreshToken = false;

  /**
   * Method to get client password from obfuscated secret.
   */
  private static getKey(secret) {
    const charArray = [];
    for (let i = 0; i < secret.length; i++) {
      // eslint-disable-next-line no-bitwise
      charArray.push(secret.charCodeAt(i) ^ 1);
    }
    let str = '';
    charArray.forEach((char) => {
      str += String.fromCharCode(char);
    });

    return atob(str);
  }

  constructor(private http: HttpClient) { }

  /**
  * Sends request to send auth pin code to the user.
  *
  * @param msisdn the msisdn of the user
  */
  sendPin(msisdn: string): Promise<any> {
    log.info('Sending sms to the user');

    const params = new CustomHttpParams();
    params.append(this.requestParamMsisdn, msisdn);

    return this.prepareRequest(UrlBuilder.getPinCodeUrl(), params, RequestMethod.Post, false)
    .pipe(map(res => res || {}))
    .toPromise().catch(
      errors => Promise.reject(this.handleAuthenticationError(errors))
      );
  }

  /**
   * Create observable for the authentication request. This will be shared
   * between different parallel requests. All subscribers of this {@code Observable}
   * will be notified once the request finishes
   *
   * @returns the {@code Observable<any>} for the authentication request.
   */
  authenticate(authMethod: AuthenticationMethod, persistRefreshToken?: boolean): Observable<Token> {

    log.info('Persist login flag: ', persistRefreshToken);
    if (persistRefreshToken) {
      this.persistRefreshToken = true;
    }

    // If there is an active request send that one. Create new only if it does not exist.
    if (this.authProcess === null || this.authProcess === undefined) {
      
      // ERBT-4270: We set the withCredentials flag to TRUE only in case of Mobile Authentication
      const withCredentials = authMethod instanceof MobileAuthenticationMethod
        && ClientConfig.mobileAuthWithCredentials.getBoolean();

      this.authProcess = this.prepareRequest(
        authMethod.getUrl(),
        authMethod.getParams(),
        authMethod.getRequestMethod(), withCredentials)
        .pipe(map((res: HttpResponse<any>) => { this.parseRequest(res, authMethod) }),
        catchError((error) => {
          // ERBT-5366: For security reasons most clients (by default) strip out 
          // Authorization header when following a redirect. The API+
          // for such cases returns 401 Unauthorized to indicate that the 
          // authorization header is missing. Replay again the redirect URL
          // with the Authorization header to API+.
          if (error instanceof HttpErrorResponse && HttpStatus.scUnauthorized == error.status) {
            return this.prepareRequest(
              error.url,
              // Parameters are already included in the redirect URL.
              null,
              authMethod.getRequestMethod(), withCredentials)
              .pipe(map((res: HttpResponse<any>) => {
                if (res.ok) {
                  return this.parseRequest(res, authMethod);
                } else {
                  return observableThrowError(this.handleAuthenticationError(res));
                }
              }), catchError(res => {
                return observableThrowError(this.handleAuthenticationError(res));
              }));
          } else {
            return observableThrowError(this.handleAuthenticationError(error));
          }
        })).pipe(share());
    }

    return this.authProcess;
  }

  /**
   * Returns the access token if exists.
   */
  getAccessToken() {
    return this.getToken().getAccessToken();
  }

  /**
   * Create the {@code Observable} to authenticate via refresh token.
   * It throws error if the refresh token is empty and not stored locally.
   *
   * @return the {@code Observable} to authenticate
   */
  getNewAccessToken(): Observable<any> {
    const refreshToken = this.getToken().getRefreshToken();

    if (refreshToken !== undefined && refreshToken !== null) {
      return this.authenticate(new RefreshTokenMethod(refreshToken));
    }

    throw new AuthError('User is not authenticated. Empty token', AuthError.userNotAuthenticated);
  }

  /**
   * Checks an active access token exists or not.
   *
   * @returns {@code true} if an active access token exists.
   */
  hasAccessToken(): boolean {
    const accessToken = this.getToken().getAccessToken();
    return accessToken !== null && accessToken !== undefined;
  }

  /**
    * Checks whether there is a refresh token
    *
    * @returns {@code true} if there is a refresh token.
    */
  hasRefreshToken(): boolean {
    const refreshToken = this.getToken().getRefreshToken();
    return refreshToken !== null && refreshToken !== undefined;
  }

  /**
   * Force reset access token if token exist
   */
  forceResetAccessToken() {
    if (this.hasAccessToken()) {
      this.token.setAccessToken(null);
      this.encryptAndStoreAccessToken();
    }
  }

  /**
   * Check the user is logged in or at least a refresh token is stored.
   */
  isLoggedIn() {
    return this.hasAccessToken() || this.hasRefreshToken();
  }

  /**
   * Makes a local logout
   */
  logOutLocally() {
    this.token = null;
    // Some users might have disabled access to localStorage (Browser setting)
    try {
      localStorage.removeItem(this.keyEncryptedToken);
    } catch (error) {
      // Ignore error, store the token in memory
      log.warn('Access to local storage is not allowed', error);
    }
  }

  /**
   * Requests the logout to API+, then if the request is successful it
   * does a local logout
   */
  logOut(): Observable<any> {

    let validToken = this.token.getRefreshToken();
    let tokenHint = 'refresh_token';

    // In case that the refresh token is not valid we use the access token
    if (validToken === null) {
      validToken = this.token.getAccessToken();
      tokenHint = 'access_token';
    }

    const params = new CustomHttpParams();
    params.append(this.requestParamTokenHint, tokenHint);
    params.append(this.requestParamToken, validToken);
    return this.prepareRequest(UrlBuilder.getRevokeTokenUrl(), params, RequestMethod.Post, false)
    .pipe(map((res: HttpResponse<any>) => {
        if (res.ok) {
          this.logOutLocally();
        }
      }));
  }

  /**
   * Function to determine if the device is connected via mobile network
   * In case of an exception it means that the mobile network is not avalaible
   *
   * ERBT-3951: https://jira.real.com/browse/ERBT-3951
   */
  isMobileAuthenticationPossible(): Observable<any> {

    const mobileAuthenticationMethod = new MobileAuthenticationMethod();

    const params = new CustomHttpParams();
    params.append(MobileAuthenticationMethod.requestParamDryRun, 'true');

    return this.prepareRequest(
      mobileAuthenticationMethod.getUrl(),
      params,
      mobileAuthenticationMethod.getRequestMethod(), ClientConfig.mobileAuthWithCredentials.getBoolean())
      .pipe(
        map((res: HttpResponse<any>) => {
        if (res.ok) {
          return of(true);
        } else {
          log.debug('Mobile network is not avalaible, the server responded with status: ' + res.status);
          return of(false);
        }
      }), catchError((res: HttpResponse<any>) => {
        // ERBT-5366: For security reasons most clients (by default) strip out 
        // Authorization header when following a redirect. The API+
        // for such cases returns 401 Unauthorized to indicate that the 
        // authorization header is missing. Replay again the redirect URL
        // with the Authorization header to API+.
        if (res.status === HttpStatus.scUnauthorized) {
          log.debug('Redirect failed with 401 Unauthorized, try to replay the request to API+');
          return this.prepareRequest(
            res.url,
            // Parameters are already included in the redirect URL.
            null,
            mobileAuthenticationMethod.getRequestMethod(), ClientConfig.mobileAuthWithCredentials.getBoolean())
            .pipe(map((res: HttpResponse<any>) => {
              if (res.ok) {
                return of(true);
              } else {
                log.debug('Mobile network is not avalaible, the server responded with status: ' + res.status);
                return of(false);
              }
            }), catchError((res: HttpResponse<any>) => {
              log.debug('Failed to replay the redirect request, mobile network is not avalaible.');
              return of(false);
            }));
        } else {
          log.info('Mobile network is not avalaible.');
          return of(false);
        }
      }));
  }

  /**
   * Parses authentication request  response from the sever. This will parse the
   * response and stores access token and the refresh token.
   *
   * @param res the response received from the server
   * @param authMethod the authentication method used for the request
   *
   */
  private parseRequest(res: any, authMethod: AuthenticationMethod): Token {
    this.authProcess = null;

    const json = res.body;
    log.debug('Json from the server: ', json);
    const tokenType = json.token_type;

    if (AuthService.tokenTypeBearer.toLowerCase() !== tokenType.toLowerCase()) {
      throw new AuthError('Unsupported token_type' + tokenType, AuthError.tokenParseError);
    }

    const accessToken = json.access_token;
    const msisdn = json.msisdn;
    const expiryTime = this.calculateExpiryTimeStamp(json.expires_in);

    // Stores the refresh token if allowed, there is no need to store the refresh token
    // for 3G authentication
    const refreshToken =
      this.persistRefreshToken ||
        (ClientConfig.authAlwaysStoreRefreshToken.getBoolean() && authMethod.isRefreshTokenAllowed())
        ? json.refresh_token
        : null;

    if (accessToken === '' && refreshToken === '') {
      throw new AuthError('Token is empty' + tokenType, AuthError.tokenParseError);
    }

    // Set up the token
    this.token = new Token();
    this.token.setAccessToken(accessToken);
    this.token.setRefreshToken(refreshToken);
    this.token.setMsisdn(msisdn);
    this.token.setExpiryTime(expiryTime);
    this.encryptAndStoreAccessToken();

    log.info('Tokens received and stored');
    log.debug(this.token);

    return this.token;
  }

  /**
   * Prepares the request to send for authentication.
   * @param url the request URL
   * @param params the url search params
   *
   * @returns the request observable
   */
  private prepareRequest(url: string, params: CustomHttpParams, requestMethod: RequestMethod,
    withCredentials: boolean): Observable<any> {
    
    // The HTTP headers are immutable, each method returns a new instance
    let headers = new HttpHeaders();
    headers = headers.set(HttpHeader.accept, ContentTypes.json);
    headers = headers.set(HttpHeader.acceptLanguage, environment.locale);

    // Don't add a "Client-Id" header.
    // It's not needed by API Plus, because the client ID is available from the Authorization header anyway.
    // Unfortunately omitting the Client-Id header does not solve the issue with CORS preflight requests during GANG authentication.
    // The browser still does a preflight request due to the Authorization header. :-(

    // HTML Plus always adds the client credentials to the token request.
    // There is no need to try to restrict this to certain schemes or destinations,
    // because the browser sandbox anyway allows us to talk only via https and only to our back end.
    // Thus there is no risk of unintentional disclosure of the credential.
    const authHeader = this.getClientAuthHeader();
    headers = headers.set(HttpHeader.authorization, authHeader);

    const isGet = requestMethod === RequestMethod.Get;
    if (params !== null && !isGet)  {
      headers = headers.set(HttpHeader.contentType, ContentTypes.xWwwFormUrlEncode);
    }

    const httpParams = params ? params.toHttpParams() : null;
    
    return requestMethod === RequestMethod.Get
      ? this.http.get(url, { headers, observe: 'response', params: httpParams || null, withCredentials: withCredentials })
      : this.http.post(url, httpParams, { headers: headers, observe: 'response', withCredentials: withCredentials });
  }

  private getToken() {
    if (!this.token) {
      this.loadEncryptedToken();
    }

    return this.token;
  }

  private calculateExpiryTimeStamp(expiryInSeconds: number): number {
    return Date.now() + expiryInSeconds * 1000;
  }

  /**
   * Encrypts and stores access token
   */
  private encryptAndStoreAccessToken() {

    // Creates a json object with access token and expiry time
    const token = JSON.stringify({
      accessToken: this.token.getAccessToken(),
      refreshToken: this.token.getRefreshToken(),
      msisdn: this.token.getMsisdn(),
      expiryTime: this.token.getExpiryTime()
    });

    const wordArray = CryptoJS.AES.encrypt(token, this.passPhrase);
    const encryptedCipher = wordArray.toString();

    // Some users might have disabled access to localStorage (Browser setting)
    try {
      localStorage.setItem(this.keyEncryptedToken, encryptedCipher);
    } catch (error) {
      // Ignore error, use in memory
      log.warn('Access to local storage is not allowed', error);
    }
  }

  /**
   * Stores the refresh token from the storage
   */
  private loadEncryptedToken() {

    let cypher = null;
    // Some users might have disabled access to localStorage (Browser setting)
    try {
      cypher = localStorage.getItem(this.keyEncryptedToken);
    } catch (error) {
      // Ignore error
      log.warn('Access to local storage is not allowed', error);
    }

    this.token = new Token();
    if (cypher !== null) {
      const wordArray = CryptoJS.enc.Utf8.parse(cypher);

      // Decrypt the token
      const decryptedToken = CryptoJS.AES.decrypt(
        cypher,
        this.passPhrase
      ).toString(CryptoJS.enc.Utf8);

      const jsonObject = JSON.parse(decryptedToken);
      this.token.setAccessToken(jsonObject.accessToken);
      this.token.setMsisdn(jsonObject.msisdn);
      this.token.setRefreshToken(jsonObject.refreshToken);
      this.token.setExpiryTime(jsonObject.expiryTime);
      log.info('Token loaded from local storage');
    }

    log.debug(this.token);
  }

  /**
   * Handles all authentication related errors.
   */
  private handleAuthenticationError(error: HttpErrorResponse | any): AuthError {
    this.authProcess = null;

    // Clear all stored tokens, if there is an error
    this.logOutLocally();

    let errMsg;
    let errorCode;

    if (error instanceof HttpErrorResponse) {

      if (NetworkUtil.isJson(error)) {
        const body = error.error || '';
        const err = body.error || JSON.stringify(body);
        const errorDescription = body.error_description;
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

        // Check for authentication related errors
        if (error.status === HttpStatus.scBadRequest) {

          if (errorDescription === this.tokenValueErrorDescriptionInvalidPin) {
            errorCode = AuthError.invalidPinCode;
          } else if (errorDescription === this.tokenValueErrorDescriptionTooManyAttempts) {
            errorCode = AuthError.tooManyAttempts;
          } else {
            errorCode = AuthError.networkError;
          }
        }
      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
        errorCode = AuthError.networkError;
      }

    } else if (!(error instanceof AuthError)) {
      // For other errors wrap it with Auth error. This will be useful identify
      // auth related errors for components.
      errMsg = error.message ? error.message : error.toString();
      errorCode = AuthError.generalError;
    }

    const authError: AuthError = error instanceof AuthError
      ? error
      : new AuthError(errMsg, errorCode);

    return authError;
  }

  getClientAuthHeader(): string {
    const authHeader = 'Basic ' + btoa(NetworkUtil.clientKey + ':' + this.clientPassword);
    return authHeader;
  }

}
