<span class="dialog" *ngIf="!progressShow">
<button mat-button *ngIf="showCloseIcon && cancelShow"
(click)="dialogRef.close(false)" class="bt-close"></button>
<h1 mat-dialog-title>{{ title | trans }}</h1>
<div mat-dialog-content *ngIf="content">
  <div *ngIf="!inputShow">
    <p *ngIf="!content.endsWith('.html')">
      {{ content | trans | format:contentArgs }}
    </p>
    <p *ngIf="content.endsWith('.html')" [innerHtml]="content | trans | format:contentArgs"></p>
  </div>
  <mat-form-field *ngIf="inputShow" dividerColor="accent" appearance="outline">
    <mat-label>{{content | trans | format:contentArgs}}</mat-label>
    <input matInput [(ngModel)]="inputValue">
    <mat-hint class="error-message" *ngIf="errorMessage">{{ errorMessage | trans }}</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions *ngIf="actionShow">
  <button mat-raised-button (click)="dialogRef.close(true)"
  color='primary'
  class="bt-main"
  *ngIf="confirmShow">
    {{ confirmButton | trans }}
  </button>
  <button mat-raised-button
  class="bt-main bt-secondary cancel"
  (click)="dialogRef.close(false)" *ngIf="cancelShow && !showCloseIcon">
    {{ cancelButton | trans }}
    </button>
</div>
</span>
<span class="progress" *ngIf="progressShow">
 <h1 mat-dialog-title>{{ title | trans }}</h1>
 <div mat-dialog-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
 </div>
</span>
