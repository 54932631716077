import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { SubscriptionService } from '../subscription.service';

/**
 * Checks the URLs requires authentication
 */
@Injectable()
export class AuthGuard  {

  constructor(private subscriptionService: SubscriptionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLogin(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkLogin(`/${route.path}`);
  }


  checkLogin(url: string): Observable<boolean> {
    return this.subscriptionService.isSubscribed(url);
  }
}
