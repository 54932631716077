import CustomHttpParams from '../network/custom-http-params';

import GrantType from './grant-type';
import UrlBuilder, { RequestMethod } from '../url-builder';
import { ClientConfig } from '../config/client-config';
import log from '../logging/logger.service';

/**
 * Abstract base class of all authentication methods.
 */
export abstract class AuthenticationMethod {

  /** HTTP query parameter for the grant type. See {@link GrantType} for possible values. */
  protected readonly requestParamGrantType = 'grant_type';

  /** HTTP query parameter for sending the refresh token in {@link GrantType#refresh_token} requests. */
  protected readonly requestParamRefreshToken = 'refresh_token';

  /** HTTP query parameter for sending the username in {@link GrantType#username} requests. */
  protected readonly requestParamUsername = 'username';

  /** HTTP query parameter for sending the password in {@link GrantType#password} requests. */
  protected readonly requestParamPassword = 'password';

  constructor() { }

  abstract getParams(): CustomHttpParams;
  abstract getUrl(): string;

  isRefreshTokenAllowed(): boolean {
    return true;
  }

  /**
   * Returns the request method to be used for this authentication request.
   * By default this is `Post`.
   */
  getRequestMethod(): RequestMethod {
    return RequestMethod.Post;
  }

}

/**
 * An authentication method based on the refresh token.
 */
export class RefreshTokenMethod extends AuthenticationMethod {

  constructor(private refreshToken: string) {
    super();
  }

  getUrl(): string {
    return UrlBuilder.getOauthTokenRequestUrl();
  }

  getParams(): CustomHttpParams {
    const params = new CustomHttpParams();
    params.append(this.requestParamGrantType, GrantType.refreshToken);
    params.append(this.requestParamRefreshToken, this.refreshToken);

    return params;
  }

}

/**
 * An authentication method based on username and password.
 * <p>
 * Must only be used for pin code login
 */
export class PasswordAuthenticationMethod extends AuthenticationMethod {

  constructor(private username: string, private password: string) {
    super();
  }

  getParams(): CustomHttpParams {
    const params = new CustomHttpParams();
    params.append(this.requestParamGrantType, GrantType.password);
    params.append(this.requestParamUsername, this.username);
    params.append(this.requestParamPassword, this.password);
    return params;
  }

  getUrl(): string {
    return UrlBuilder.getOauthTokenRequestUrl();
  }

}

/**
 * An authentication method using 3G header enrichment.
 */
export class MobileAuthenticationMethod extends AuthenticationMethod {

  /** HTTP query parameter to avoid the generation of a token  */
  static readonly requestParamDryRun = 'dryRun';

  static isMobileLoginAllowed(): boolean {
    const mobileAuthUrl = ClientConfig.mobileAuthUrl.getString() || '';
    return mobileAuthUrl.trim() !== '' && MobileAuthenticationMethod.isDeviceCompatibleWithMobileLogin();
  }

  static isDeviceCompatibleWithMobileLogin(): boolean {
    // Get the user agent.
    // Note that the obtained value reflects the value injected by a modify-header plug-in (at least with Firefox),
    // so it is possible to emulate a mobile device on the desktop.
    const userAgent = navigator.userAgent;
    log.debug('User agent: ' + userAgent);

    if (userAgent.match(/Android/i)
      || userAgent.match(/webOS/i)
      || userAgent.match(/iPhone/i)
      || userAgent.match(/iPad/i)
      || userAgent.match(/iPod/i)
      || userAgent.match(/BlackBerry/i)
      || userAgent.match(/Windows Phone/i)
    ) {
      log.debug('The device is compatible with mobile authentication');
      return true;
    } else {
      log.debug('The device is NOT compatible with mobile authentication');
      return false;
    }
  }

  constructor() {
    super();
  }

  getParams(): CustomHttpParams {
    return null;
  }

  getUrl(): string {
    // Get URL for mobile authentication
    const urlConfig = ClientConfig.mobileAuthUrl.getString();

    // Resolve the URL.
    // Config can be full URL (with scheme), relative to root context (if starting with '/'),
    // or relative to API Plus context.
    // The API Plus base URL can also be relative to the browser window location.
    // Such we might need a 3-stage resolution in order to always obtain an absolute URL:
    // 1) window location, 2) API Plus, 3) mobile authentication
    log.debug('Determining mobile authentication URL...');
    const windowUrl = window.location.toString();
    log.debug('Browser URL: ' + windowUrl);
    const apiPlusUrl = new URL(UrlBuilder.getBaseUrl() + '/', windowUrl).toString();
    log.debug('API Plus URL: ' + apiPlusUrl);
    const resultUrl = new URL(urlConfig, apiPlusUrl).toString();
    log.debug('Mobile authentication URL from config value ' + urlConfig + ' resolved as ' + resultUrl);

    return resultUrl;
  }

  isRefreshTokenAllowed(): boolean {
    return false;
  }

  getRequestMethod(): RequestMethod {
    return ClientConfig.mobileAuthMethod.getValue();
  }

}
