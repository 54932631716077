import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing.module';
import { SharedModule } from '../shared/shared.module';
import { ConfigComponent } from './config/config.component';
import { PromoComponent } from './promotions/promo.component';
import { MobilePromoComponent } from './promotions/mobile-promo.component';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from '../tone/tone.service';

@NgModule({
  imports: [HomeRoutingModule, SharedModule],
  declarations: [
    HomeComponent,
    ConfigComponent,
    PromoComponent,
    MobilePromoComponent
  ],
  providers: [PromoService, ToneService]
})
export class HomeModule { }
