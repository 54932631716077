import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BaseComponent } from '../../base.component';
import { Faq } from '../../faq/faq';
import log from 'app/core/logging/logger.service';
import { TranslatePipe } from '../pipes/translate.pipe';

/**
 * Component to render the FAQ page
 */
@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html'
})
export class FaqListComponent extends BaseComponent implements OnInit {

  @Input() isCustomList:boolean;
  @Input() customList:string;
  @Input() title:string = 'faq.page.title';

  readonly textKeyFaqItems = 'faq.items';
  readonly prefix = 'faq.';
  readonly suffixQuestion = '.title';
  readonly suffixAnswer = '.content.html';

  faqs: Faq[] = [];

  constructor(private location: Location, router: Router) {
    super(router);
  }

  ngOnInit() {
        if(this.isCustomList) {
            this.getFaqs(this.buildListKey());
        } else {
            this.getFaqs(this.textKeyFaqItems);
        }
    }

  buildListKey() {
    return this.textKeyFaqItems + '.' + this.customList; 
  }

  getFaqs(listKeys:string) {
    log.debug('Fetching FAQs from the texts');

    // get the config that holds the order of faq items
    const pipe = new TranslatePipe();
    const items: string[] = pipe.transform(listKeys).split(';');
    items.forEach(element => {
      const title: string = this.prefix + element + this.suffixQuestion;
      const content: string = this.prefix + element + this.suffixAnswer;
      this.faqs.push(new Faq(title, content));
    });
    log.info('Got ' + this.faqs.length + ' questions and answers');
  }

  goBack() {
    this.location.back();
  }

}
