/**
 * Error class to handle all authentication related errors
 */
export class AuthError extends Error {

  public static userNotAuthenticated = 0;
  public static invalidPinCode = 1;
  public static tooManyAttempts = 2;

  /** Error codes with severity high needed to be logged as error. */
  public static tokenParseError = 100;
  public static networkError = 101;
  public static generalError = 102;

  constructor(message: string, private errorCode: number) {
    super(message);
    // We have to do that as there is a bug on typescript compiler,
    // it does not support to extend native class.
    // Check this link https://github.com/Microsoft/TypeScript/issues/7639
    this.message = message; // 'Error' breaks prototype chain here
    this.errorCode = errorCode;
    // ERBT-5605: Set the prototype explicitly, otherwise the returned istance is type of Error.
    // Check this link https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#example
    this.name = 'AuthError';
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }

  isSevere() {
    return  this.errorCode >= 100;
  }

  getErrorCode() {
    return this.errorCode;
  }
}
