import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component to render list item delete button
 */
@Component({
  selector: 'app-list-item-delete-button',
  templateUrl: './delete-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteButtonComponent {
  @Input() deleteTextId: string;
}
