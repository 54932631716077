<div class="carousel-slider-tones">
    <div class="carousel-title">{{ title }}</div>
    <ng-container *ngIf="tones && tones.length > 0">
      <ngu-carousel #Carousel [inputs]="carouselConfig" [dataSource]="tones">
        <ng-template nguCarouselDef let-tone let-i="index">
          <div class="item" [attr.data-test-id]="'carousel-tone-item-' + i">
            <div class="tile tone-image-holder">
              <div (click)="clicked(tone)" class="fallback-img tone-scroll-img">
                <img [src]="tone.cover | image:270:270" />
                <app-play-button [tones]="[tone]" fxFlex="0 0 20%"></app-play-button>
              </div>
            </div>
          </div>
        </ng-template>
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let j of Carousel.pointNumbers; let i = index" [class.active]="i == Carousel.activePoint"
            (click)="Carousel.moveTo(j)"></li>
        </ul>
      </ngu-carousel>
    </ng-container>
    <button *ngIf="Carousel && Carousel.pointNumbers && Carousel.pointNumbers.length > 1" (click)="next()" class="rightRs"
      [ngClass]="{
        'disabled': Carousel.activePoint == (Carousel.pointNumbers.length - 1),
        'hidden': isMobile
      }"></button>
    <button *ngIf="Carousel && Carousel.pointNumbers && Carousel.pointNumbers.length > 1" (click)="prev()" class="leftRs"
      [ngClass]="{
        'disabled': Carousel.activePoint == 0,
        'hidden': isMobile
      }"></button>
  </div>
  