import { Component } from '@angular/core';
import { environment } from 'environments/environment';

import { PromoItem } from 'app/model/promotions';
import { AnalyticsService } from 'app/core/analytics.service';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Component to show a content promotion popup
 * see https://jira.real.com/browse/ERBT-3558
 */
@Component({
  selector: 'app-promo-dialog',
  templateUrl: './promo-dialog.component.html',
})
export class PromoDialogComponent {

  /** Default texts */
  public texts = {
    title: 'home.promo.popup.title',
    content: 'home.promo.popup.content',
    confirm: 'home.promo.popup.accept.button',
    cancel: 'home.promo.popup.cancel.button'
  };

  public promoItem: PromoItem;
  public showCloseIcon :boolean = environment.opcoConfig.newLayout;
  private analyctisEventConfirm: string;
  private analyticsEventCancel: string;

  constructor(public dialogRef: MatDialogRef<PromoDialogComponent>) { }

  setPromoItem(promoItem: PromoItem) {
    this.promoItem = promoItem;
  }

  setAnalyticsEvents(analyctisEventConfirm: string, analyticsEventCancel: string) {
      this.analyctisEventConfirm = analyctisEventConfirm;
      this.analyticsEventCancel = analyticsEventCancel;
  }

  setTexts(title: string, content: string, confirmButton?: string, cancelButton?: string) {
      this.texts.title = title;
      this.texts.content = content;
      if (confirmButton) {
          this.texts.confirm = confirmButton;
      }
      if (cancelButton) {
          this.texts.cancel = cancelButton;
      }
  }

  /**
   * Called from the component when the user clicks the accept button
   * Registers an analytics event (if required) and returns to the observer.
   */
  accept() {
    if (this.analyctisEventConfirm) {
        AnalyticsService.getInstance().trackEventAction(this.analyctisEventConfirm);
    }
    this.dialogRef.close(true);
  }

  /**
   * Called from the component when the user clicks the cancel button.
   * Registers an analytics event (if required) and returns to the observer.
   */
  cancel() {
    if (this.analyticsEventCancel) {
        AnalyticsService.getInstance().trackEventAction(this.analyticsEventCancel);
    }
    this.dialogRef.close(false);
  }
}
