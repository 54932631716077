
import {throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, retryWhen, mergeMap, switchMap, catchError} from 'rxjs/operators';
import { Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

import CustomHttpParams from '../network/custom-http-params';
import { ClientConfig } from '../config/client-config';
import { AuthService } from '../auth/auth.service';
import { AuthError } from '../auth/auth-error';
import { HttpStatus } from '../network/http-status';
import { MobileAuthenticationMethod } from '../auth/auth-method';
import HttpHeader from '../network/http-header';
import NetworkUtil from '../network/network-util';
import ContentTypes from '../network/content-types';
import log from '../logging/logger.service';
import { CustomHttpUrlEncodingCodec } from './encoder.component';
import UrlBuilder, { RequestMethod } from '../url-builder';

export abstract class BaseNetworkService {

  constructor(private httpClient: HttpClient, @Optional() protected authService: AuthService) { }

  /**
   * Creates an instance of CustomHttpParams using the CustomHttpUrlEncodingCodec to
   * encode correctly the keys and values of the parameters
   */
  protected createURLSearchParams(): CustomHttpParams {
   return new CustomHttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  }

  /**
   * Create the {@code Observable} for all requests. Also handles requests that requires authentication.
   * <p/>
   * If there is an active access token, the request will use the existing access token.
   * Otherwise it checks for the refresh  token and tries to login silently.
   * <p/>
   * If there is an authentication failure, it will re try after fetching the activation login.
   * @param url the request URL
   * @param method the http method
   * @param params optional parameters
   * @return an {@code Observable} for the request
   *
   * @returns an {@code Observable} for the request
   */
  private getObservable(url: string, method: RequestMethod, params?: CustomHttpParams, body?: any): Observable<any> {

    const isAuthRequired = this.isUserAuthRequired(url);

    let  headers = new HttpHeaders();
    headers = headers.append(HttpHeader.acceptLanguage, environment.locale);
    headers = headers.append(HttpHeader.accept, ContentTypes.json);

    // For anonymous requests add a Client-Id header.
    // For authenticated requests this is not needed, because the client is implicitly identified by the authentication.
    if (!isAuthRequired) {
      headers = headers.append(HttpHeader.clientId, NetworkUtil.clientKey);
    }

    // Add the form header for all post requests.
    if (method === RequestMethod.Post && body === null) {
      headers = headers.append(HttpHeader.contentType, ContentTypes.xWwwFormUrlEncode);
    }

    const isClientAuthRequired = UrlBuilder.isClientAuthRequired(url);
    if (isClientAuthRequired) {
      headers = headers.append(HttpHeader.authorization, this.authService.getClientAuthHeader());
    }

    // Do normal request if authentication is not required
    if (!isAuthRequired) {
      return this.getRequest(url, headers, method, params);
    }

    if (this.authService === null || this.authService === undefined) {
      observableThrowError(new Error('Invalid dependency injection'));
    }

    // We need a empty observable here for the retry logic. The empty observable
    // resolves on a null object and executes the mergeMap operations by fetching
    // access token. The retry will always starts from the source observable (in our case its an empty Observable),
    // and this will make sure that a token is requested always.
    // Check this URL https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/core/operators/retrywhen.md
    // for rxjs docs
    return of({}).pipe(mergeMap(() => {

      if (this.authService.hasAccessToken()) {

        // This will give the control to the next observer.
        log.info('Silent login is not required access token is already active');
        return of({});

      } else if (this.authService.hasRefreshToken()) {

        log.info('Silent login is required. This time refresh token is there');
        // The next observer will start execution only after fetching the refresh token.
        return this.authService.getNewAccessToken();

      } else if (ClientConfig.mobileAuthAllowSilentLogin.getBoolean() && MobileAuthenticationMethod.isMobileLoginAllowed()) {
        log.info('Mobile login is allowed. Try to login via mobile login method');
        return this.authService.authenticate(new MobileAuthenticationMethod());
      }

      throw new AuthError('User is not authenticated. Empty token', AuthError.userNotAuthenticated);

    }), mergeMap(() => {
      // Tries with access token
      const accessToken = this.authService.getAccessToken();
      log.info('Received access token:  ' + accessToken);

      const authHeader = AuthService.tokenTypeBearer + ' ' + this.authService.getAccessToken();
      headers = headers.append(HttpHeader.authorization, authHeader);

      return this.getRequest(url, headers, method, params, body);

    }),retryWhen(errors => {

      log.info('Request failed. Checking error status');
      return errors.pipe(switchMap((error) => {

        // Retry only if the authentication failed
        if (error instanceof Response && error.status === HttpStatus.scUnauthorized) {

          // Retry only if there is a refresh token
          if (this.authService.hasRefreshToken()) {
            log.info('Authentication failed. Try again with refresh token');

            // Reset existing access token (It might have expired)
            this.authService.forceResetAccessToken();
            return of(error);

          } else {

            // Clear stored tokens. To make sure that we clean up invalid token
            this.authService.logOutLocally();
            const authError = new AuthError(
              'Failed to authenticate user. Invalid client token',
              AuthError.userNotAuthenticated
            );
            return observableThrowError(authError);
          }

        } else {
          return observableThrowError(error);
        }

      }));
    }));
  }

  /**
   * Override point for sub classes to parse the server data.
   *
   * @param res the response
   */
  abstract extractData(res: HttpResponse<any>): any;

  /**
   * Creates observable server requests. Subclasses can call this method
   * if the request need to be observable.
   *
   * @param url the request URL
   * @param method the http m``ethod
   * @param params optional parameters
   * @param body optional body
   * @return an {@code Observable} for the request
   */
  sendObservableRequest(url: string, method: RequestMethod, params?: CustomHttpParams, body?: any): Observable<any> {
    return this.getObservable(url, method, params, body)
    .pipe(map((res: HttpResponse<any>) => this.extractData(res)),
     catchError(error => observableThrowError(NetworkUtil.parseError(error))));
  }

  /**
    * Creates promise server requests. Subclasses can call this method
    * if the request need to be a promise.
    *
    * @param url the request URL
    * @param method the http method
    * @param params optional parameters
    * @return an {@code Promise} for the request
    */
  sendPromiseRequest(url: string, method: RequestMethod, params?: CustomHttpParams, body?: any): Promise<any> {
    return this.getObservable(url, method, params, body)
      .pipe(map((res: HttpResponse<any>) => res))
      .toPromise()
      .then(this.extractData)
      .catch(error => Promise.reject(NetworkUtil.parseError(error)));
  }

  /**
   * Prepares a server request object with the given details.
   * @param url the request URL
   * @param header the http header
   * @param method the http method
   * @param params optional parameters
   * @return an {@code Promise} for the request
   *
   */
  private getRequest(url: string, headers: HttpHeaders,
    method: RequestMethod,
    params?: CustomHttpParams,
    body?: any): Observable<any> {
        
    const httpParams = params ? params.toHttpParams() : null;

    return method === RequestMethod.Get   
      ? this.httpClient.get(url, { headers, params: httpParams || null, observe : 'response' })
      : this.httpClient.post(url, body, { headers, params: httpParams || null, observe : 'response' });
  }

  /** Returns true if the URL passed by parameter should include user authentication in the request  */
  private isUserAuthRequired(url: string): boolean {
    const windowUrl = window.location.toString();
    return new URL(url, windowUrl).pathname.startsWith(ClientConfig.authUrlPrefix.getString());
  }
}
