/**
 * TranslateService is an app-wide singleton and only instantiated once.
 * This loads all the translation from the server before the app loads.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';




import { BaseNetworkService } from '../network/base-network.service';
import UrlBuilder, { RequestMethod } from '../url-builder';
import { environment } from 'environments/environment';
import NetworkUtil from '../network/network-util';

@Injectable()
export class TextConfigService extends BaseNetworkService {

  private static configMap: Map<string, any>;

  /**
   * Return the config value corresponding to the key.
   * If the key does not exist, returns {@code undefined}
   *
   * @param the config key
   * @returns the config value corresponding to the key.
   * Returns {@code undefined} there is no key.
   */
  static getValue(key: string): any {
    return TextConfigService.configMap.get(key);
  }

  constructor(http: HttpClient) {
    super(http, null);
  }

  setConfig(configMap: Map<string, any>) {
    TextConfigService.configMap = configMap;
  }

  getUrl(): string {

    // If mock is set, fetch the local file
    if (environment.mock) {
      return './assets/locale/locale.en.json';
    }

    return UrlBuilder.getTextConfigUrl();
  }

  loadConfig(): Promise<any> {
    return this.sendPromiseRequest(this.getUrl(), RequestMethod.Get)
      .then(this.setConfig);
  }

  extractData(res: HttpResponse<any>): any {
    return NetworkUtil.extractConfigMap(res);
  }

}
