import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import UrlBuilder from 'app/core/url-builder';
import { Profile, ProfileStatus } from '../../model';
import { BaseComponent } from '../../base.component';
import { ProfileService } from '../../core/profile.service';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-legal-checkboxes',
  templateUrl: './legal-checkboxes.component.html'
})
export class LegalCheckboxesComponent extends BaseComponent implements OnInit  {
  profile: Profile;
  @Output() allChecked = new EventEmitter<boolean>();

  terms: boolean = false;       // Tracks the state of the "terms" checkbox
  withdrawal: boolean = false;  // Tracks the state of the "withdrawal" checkbox
  isSubscribed:boolean = false;
  isAuthenticated: boolean;
  constructor(router: Router, private profileService:ProfileService, private authService: AuthService) {
    super(router);
    this.getProfile();
    this.isAuthenticated = this.authService.isLoggedIn();
  }

  ngOnInit() {
    if (this.profile) {
        this.isSubscribed = this.profile.status !== ProfileStatus[ProfileStatus.NEW] && 
                            this.profile.status !== ProfileStatus[ProfileStatus.DEACTIVATED];
        if (!this.isSubscribed) {
            this.terms = true;
            this.withdrawal = true;
            this.onCheckboxChange(); 
        }
    }
}

  onCheckboxChange() {
    // Emit event if both checkboxes are checked
    const areAllChecked = this.terms && this.withdrawal;
    this.allChecked.emit(areAllChecked);
  }

  /** Listens for legal text link clicks */
  listenToLegal(event) {
    UrlBuilder.openExternalOrInternalUrlFromClickEvent(event, this.router);
  }

  getProfile() {
    this.profileService.getProfile().subscribe(profile => {
      this.profile = profile;
    })
  }
}
