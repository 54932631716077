import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule  } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from 'app/core/core.module';
import { HomeModule } from './home/home.module';
import { LandingPromoModule } from './home/promotions/landingPromo/landing-promo.module';
import { OptinPromoModule } from './home/promotions/optinPromo/optin-promo.module';
import { YoungPromoModule } from './home/promotions/youngPromo/young-promo.module';
import { MagentaBlackDaysModule } from './home/promotions/magentaBlackDays/magenta-black-days.module';
import { SharedModule } from 'app/shared/shared.module';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthGuard } from 'app/core/auth/auth-guard.service';
import { SentryErrorHandler } from 'app/core/error-handler';
import { SharedUgcModule } from 'app/sharedUgc/shared-ugc.module';

import { AppComponent } from './app.component';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from 'app/core/network/custom-url-serializer';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule ,
    CoreModule,
    HomeModule,
    LandingPromoModule,
    OptinPromoModule,
    YoungPromoModule,
    MagentaBlackDaysModule,
    SharedModule,
    SharedUgcModule,
    AppRoutingModule
 ],
  providers: [
    { 
      provide: UrlSerializer,
      useClass: CustomUrlSerializer 
    },
    AuthService,
    AuthGuard,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }