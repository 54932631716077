import { Tone } from './tone';
import { Setting, DayOfYear } from './profile';

/**
 * CallParty type
 */
export enum CallPartyType {
  CALLER,
  GROUP,
  DAY_OF_WEEK,
  TIME_OF_DAY,
  TIME_AND_DAY_OF_WEEK,
  DAY_OF_YEAR
}

export class DayOfWeekView {

  /** Week days */
  static days = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
  ];

  /** Mapping for text key */
  static daysViewCollection = {
    MONDAY: 'daysOfWeek.monday',
    TUESDAY: 'daysOfWeek.tuesday',
    WEDNESDAY: 'daysOfWeek.wednesday',
    THURSDAY: 'daysOfWeek.thursday',
    FRIDAY: 'daysOfWeek.friday',
    SATURDAY: 'daysOfWeek.saturday',
    SUNDAY: 'daysOfWeek.sunday'
  };

  constructor(public id: string, public textKey: string) { }
}

/**
 * Model class to hold call party withe current rbt and igt setting
 */
export class CallParty {

  rbt: Tone;
  igt: Tone;
  setting: Setting;
  daysOfWeek: string[];
  startTime: string;
  endTime: string;
  dayOfYear: Date;

  /**
   * Creates call party for Group
   *
   * @param name the group name
   * @param numberOfMembers the number of members in the group
   */
  static createGroupCallParty(name: string, numberOfMembers: number): CallParty {
    return new CallParty(name, CallPartyType.GROUP, null, numberOfMembers);
  }

  /**
   * Creates call party for Caller
   *
   * @param name the name of the caller
   * @param msisdn the MSISDN of the caller
   */
  static createCallerCallParty(name: string, msisdn: string): CallParty {
    return new CallParty(name, CallPartyType.CALLER, msisdn);
  }

  /**
   * Creates days of week call CallParty
   *
   * @param days the days which setting is active
   */
  static createDayOfWeekCallParty(days: string[]): CallParty {
    const callParty = new CallParty(null, CallPartyType.DAY_OF_WEEK, null);
    callParty.setDaysOfWeek(days);
    return callParty;
  }

  /**
   * Creates day of year call party
   *
   * @param day the date when setting is active
   * @param month the month the month when setting is active
   */
  static createDayOfYearCallParty(day: number, month: number): CallParty {

    const date = new Date(DayOfYear.arbitraryLeapYear, month - 1, day);
    const callParty = new CallParty(null, CallPartyType.DAY_OF_YEAR, null);
    callParty.setDayOfYear(date);
    return callParty;

  }

  /**
   * Creates time of day call party
   *
   * @param startTime the start time of the play setting
   * @param endTime  the end time of the play setting
   */
  static createTimeOfDayCallParty(startTime: string, endTime: string): CallParty {

    const callParty = new CallParty(null, CallPartyType.TIME_OF_DAY, null);
    callParty.setStartTime(startTime);
    callParty.setEndTime(endTime);

    return callParty;
  }

  /**
   * Creates time and day of week setting
   *
   * @param startTime the start time of the play setting
   * @param endTime the end time of the play setting
   * @param days the days which setting is active
   */
  static createTimeAndDayOfWeekCallParty(startTime: string, endTime: string,
    days: string[]): CallParty {

    const callParty = new CallParty(null, CallPartyType.TIME_AND_DAY_OF_WEEK, null);
    callParty.setStartTime(startTime);
    callParty.setEndTime(endTime);
    callParty.setDaysOfWeek(days);

    return callParty;
  }

  constructor(
    public name: string,
    public type: CallPartyType,
    public msisdn: string,
    public numberOfMembers?: number) {
  }

  setRbt(rbt: Tone) {
    this.rbt = rbt;
  }

  setSetting(setting: Setting) {
    this.setting = setting;
  }

  setIgt(igt: Tone) {
    this.igt = igt;
  }

  setDaysOfWeek(daysOfWeek: string[]) {
    this.daysOfWeek = daysOfWeek;
  }

  setStartTime(startTime: string) {
    this.startTime = startTime;
  }

  setEndTime(endTime: string) {
    this.endTime = endTime;
  }

  setDayOfYear(dayOfYear: Date) {
    this.dayOfYear = dayOfYear;
  }

}
