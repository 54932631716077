import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone
} from '@angular/core';

import { CallerInfo } from 'app/model';
import { ContactHelper } from 'app/core/helper/contact-helper';
import { Router } from '@angular/router';
import { BaseViewComponent } from '../base-view.component';
import { getInitials } from '../../core/utils';

@Component({
  selector: 'app-caller-info',
  templateUrl: 'caller-info.component.html'
})
export class CallerInfoComponent extends BaseViewComponent implements OnInit {

  @Input() callerInfo: CallerInfo;
  initial: string;
  initialBgColor: string;

  @Output()
  onDelete = new EventEmitter<CallerInfo>();

  constructor(
    zone: NgZone,
    router: Router) {
    super(zone, router);
  }

  ngOnInit() {
    const name = (this.callerInfo.name || this.callerInfo.msisdn ).trim();
    this.initial = getInitials(this.callerInfo.name, this.isRedesign);

    this.initialBgColor = ContactHelper.getTileColor(name);
  }

  delete(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.onDelete.emit(this.callerInfo);
  }
}
