import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';

/**
 * Common component to show all dialog
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent {

  private readonly confirmButtonTextKey = 'common.button.confirm';
  private readonly cancelButtonTextKey = 'common.button.cancel';
  private readonly okButtonTextKey = 'common.button.ok';

  private readonly errorTitleTextKey = 'common.popup.error.title';
  private readonly infoTitleTextKey = 'common.popup.info.title';

  /** Dialog title text */
  public title;

  /** Dialog content text */
  public content;

  /** Use this when you have text keys with args */
  public contentArgs;

  /** Stores input value */
  public inputValue;

  /** Flag to show/hide input section */
  public inputShow = false;

  /** Flag to show/hide confirm button */
  public confirmShow = true;

  /** Flag to show/hide cancel button */
  public cancelShow = true;

  /** Flag to show/hide buttons section */
  public actionShow = true;

  /** Flag to show/hide progress dialog section */
  public progressShow = false;

  /** Dialog confirm button text */
  public confirmButton = this.confirmButtonTextKey;

  /** Dialog cancel button text */
  public cancelButton = this.cancelButtonTextKey;

  /** Message to show as an error */
  public errorMessage;

  /** Flag to show 'X' close icon on the top right of the popup instead of cancel button */
  public showCloseIcon: boolean = environment.opcoConfig.newLayout;

  constructor(public dialogRef: MatDialogRef<DialogComponent>) { }

  /**
   * Set presets for confirm dialog
   *
   * @param title the dialog title  text key
   * @param content the content text key
   * @param confirmButton the confirm button text key
   * @param cancelButton the cancel button text key
   * @param showCloseIcon flag that determines whether to show the 'X' close icon
   *                      instead of cancel button.
   * @param args the objects array to format string if there is any
   */
  setConfirmDialog(title: string, content: string, confirmButton?: string,
  cancelButton?: string, args?: any[], showCloseIcon?: boolean) {
    this.title = title;
    this.content = content;
    this.contentArgs = args || [];
    this.confirmButton = confirmButton || this.confirmButtonTextKey;
    this.cancelButton = cancelButton || this.cancelButtonTextKey;

    // Only for VF/DE rebranding allow showing the 'X' close icon.
    if (showCloseIcon !== undefined && environment.opcoConfig.newLayout) {
      this.showCloseIcon = showCloseIcon;
    }
  }

  /**
   * Set presets for input dialog
   *
   * @param title the dialog title  text key
   * @param content the content text key
   * @param confirmButton the confirm button text key
   * @param cancelButton the cancel button text key
   * @param error the error to display if the input is not valid
   */
  setInputDialog(title: string, content: string, confirmButton?: string, cancelButton?: string, error?: string) {
    this.inputShow = true;

    this.title = title;
    this.content = content;
    this.confirmButton = confirmButton || this.confirmButtonTextKey;
    this.cancelButton = cancelButton || this.cancelButtonTextKey;
    this.errorMessage = error;
  }

  /**
   * Sets presets for  error dialog
   *
   * @param content the error dialog content text key
   * @param args the objects array to format string if there is any
   */
  setErrorDialog(content: string, args?: any[]) {
    this.cancelShow = false;
    this.title = this.errorTitleTextKey;
    this.content = content;
    this.contentArgs = args || [];
    this.confirmButton = this.okButtonTextKey;
  }

  /**
   * Sets presets for  info dialog
   *
   * @param content the info dialog content text key
   * @param args the objects array to format string if there is any
   */
  setInfoDialog(content: string, args?: any[]) {
    this.setInfoWithActionDialog(content);
  }

  /**
   * Sets presets for  info with title dialog
   *
   * @param title the info dialog title text key
   * @param content the info dialog content text key
   * @param args the objects array to format string if there is any
   */
  setInfoWithTitleDialog(title: string, content: string, args?: any[]) {
    this.setInfoWithActionDialog(content);
    this.title = title;
  }

  /**
   * Set presets for confirm dialog
   *
   * @param content the content text key
   * @param args the objects array to format string if there is any
   * @param confirmButton the ok button text key
   */
  setInfoWithActionDialog(content: string,  args?: any[], okButton?: string) {
    this.cancelShow = false;
    this.title = this.infoTitleTextKey;
    this.content = content;
    this.contentArgs = args || [];
    this.confirmButton = okButton || this.okButtonTextKey;
  }

  /**
   * Sets presets for  progress dialog
   * @param title the progress dialog title
   */
  setProgressDialog(title: string) {
    this.progressShow = true;
    this.title = title;
    this.confirmButton = this.okButtonTextKey;
  }

}
