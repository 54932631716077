import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Tone } from 'app/model';
import log from 'app/core/logging/logger.service';
import { RbtVersionService } from './rbt-version.service';
import { BaseComponent } from 'app/base.component';
import { ClientConfig } from 'app/core/config/client-config';

@Component({
    selector: 'app-rbt-version',
    templateUrl: './rbt-version.component.html'
})

export class RbtVersionComponent extends BaseComponent implements OnInit, OnChanges {

    @Input()
    public tones: Tone[];

    /* Holds the IGT and RBT used as prelisten for the current setting */
    @Input()
    public prelisten: Tone[];

    @Input()
    public toneWithItu: boolean;

    public showRbtAlternativePrelisten = false;
    public isToggleDisabled = false;
    public allTonesHaveAlternativeVersion = true;
    public switchDisabledDescription = '';

    constructor(private rbtVersionService: RbtVersionService, router: Router) {
        super (router);
    }

    ngOnInit() {
        this.showRbtAlternativePrelisten = ClientConfig.alternativeAudioEnabled.getBoolean();
    }

    ngOnChanges() {
        if (!this.showRbtAlternativePrelisten) {
            // RBT alternate version feature is disabled (e.g. for TMO), do nothing.
            return;
        }

        this.isToggleDisabled = !(this.tones && this.tones.length > 0);

        if (!this.isToggleDisabled) {
            this.allTonesHaveAlternativeVersion = this.tones.every((tone) => tone.prelistenAlternativeAudio !== undefined);
            if (!this.allTonesHaveAlternativeVersion) {
                this.disableToggle();
            }
        }
    }

    changeRbtVersion(): void {
        log.info('Requesting to change to the RBT alternative version: ', !this.toneWithItu);
        this.rbtVersionService.rbtVersionChanged(!this.toneWithItu);
    }

    private disableToggle() {
        this.isToggleDisabled = true;
        this.toneWithItu = true;
        this.switchDisabledDescription = this.tones && this.tones.length > 1
            ? 'setting.switchDisabledDescriptionPlaylist'
            : 'setting.switchDisabledDescriptionTone';
    }
}
