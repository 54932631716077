import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
    NgZone,
  } from "@angular/core";
  import { ActivatedRoute, Router } from "@angular/router";
  import { map, take } from "rxjs/operators";

  import { Shop } from "../../model";
  import { ShopService } from "../../shop/shop.service";
  import { combineLatest, Observable } from "rxjs";
  import { ShopList } from "../../model/shop-list";
  import { ClientConfig } from "../../core/config/client-config";
  import { BaseCarouselComponent } from './base-carousel.component';

  @Component({
    selector: "app-shop-carousel",
    templateUrl: "./shop-carousel.component.html",
  })
  export class ShopCarouselComponent
    extends BaseCarouselComponent
    implements OnInit, AfterViewInit
  {
    public shops: Shop[];

    @ViewChild("scroller", { static: true }) tabGroup: ElementRef;

    constructor(
      private shopService: ShopService,
      private route: ActivatedRoute,
      router: Router,
      protected zone: NgZone,
      private cdr: ChangeDetectorRef
    ) {
      super(zone, router);
      if (!this.shopId) {
        this.shopId = ClientConfig.newSongsShopId.getString();
      }
    }

    ngAfterViewInit() {
      this.cdr.detectChanges();
    }

    ngOnInit() {
      this.fetchShops();
    }

    fetchShops(): void {
      this.shopService.getShops().pipe(take(1)).subscribe((shopList: ShopList) => {
        if (shopList.shop.length < this.maxItems) {
          this.shops = shopList.shop;
        } else {
          this.shops = shopList.shop.slice(0, this.maxItems);
        }
        this.zone.run(() => this.cdr.detectChanges());
      });
    }

    clicked(shop: Shop) {
      this.navigateOnClick(
        this.router,
        [`/shop?id=${shop.id}`],
        true,
        this.purchaseSource as string,
        this.shopId
      );
    }
  }
