<div class="landing-promo-background">
<app-spinner [active]="spinnerActive">
    <app-title-bar title="{{ 'home.page.title' | trans }}" simpleHeader="true">
        <ng-container *ngIf="promoItem">
            <div class="landing-promo-page">            
                <ng-container *ngIf="isMobile">
                    <img class="landing-promo-image" *appLazy appResizable [src]="promoItem.artwork" />
                </ng-container>
                <p class="title">{{ promoItem.title }}</p>
                <p class="description">{{ promoItem.description }}</p>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="stretch stretch"
                    fxLayoutAlign.gt-sm="start start" fxLayoutGap="25px" class="mb-4">
                    <mat-list class="tone-list button-less-list">
                        <app-tone-list-item 
                            hidePrice="true" buttonText="tone.list.button.activate"
                            [tone]="tone" *ngFor="let tone of tones"
                            [purchaseSourcePropagateCurrent]="true"
                            [purchaseSource]="recommendedPurchaseSource"
                            [purchaseSourceInfo]="recommendedPurchaseSourceInfo"
                            [imageSize]="imageSize"
                            [showSmsCode]="showSmsCode"
                            (onBuy)="redirectToLogin($event)"
                            (onPrelistenStarted)="trackGtagPrelisten($event)">
                        </app-tone-list-item>
                    </mat-list>
                </div>
                <p *ngIf="skipButtonText" class="skip" (click)="handleSkipButton()"> {{ skipButtonText | trans }} </p>
            </div>
        </ng-container>
    </app-title-bar>
</app-spinner>
</div>