import { Component } from '@angular/core';
import { environment } from 'environments/environment';

import { CallPartyType } from 'app/model';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Component to show add caller dialog
 */
@Component({
  templateUrl: './add-caller.component.html',
})
export class AddCallerComponent {

  private readonly addCallerTitle = 'setting.caller.add.popUp.title';
  private readonly addMemberTitle = 'setting.group.addMember.popUp.title';

  title: string;
  callerName: string;
  callerPhoneNumber: string;
  errorMessage: string;
  showCloseIcon: boolean = environment.opcoConfig.newLayout;

  constructor(public dialogRef: MatDialogRef<AddCallerComponent>) { }

  setAddCallerDialog(callPartyType: CallPartyType, errorMessage?: string) {
    this.title = callPartyType === CallPartyType.CALLER
      ? this.addCallerTitle
      : this.addMemberTitle;

      this.errorMessage = errorMessage;
  }
}
