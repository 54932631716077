import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SubscriptionService } from 'app/core/subscription.service';
import { ClientConfig } from 'app/core/config/client-config';
import log from 'app/core/logging/logger.service';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html'
})
export class TrackerComponent {

  public static paramKey = 'activation';
  public static paramValue = 'success';

  track = false;
  urls = [];

  public static getTrackingParams() {
    const queryParams = {};
    queryParams[TrackerComponent.paramKey] = TrackerComponent.paramValue;
    return { queryParams: queryParams };
  }

  constructor(
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute) {

    if (ClientConfig.pixelTrackUrls.getStringArray().length > 0) {

      this.route.queryParams.subscribe(params => {
        // Checks redirected after subscription
        this.track = params[TrackerComponent.paramKey]
          && params[TrackerComponent.paramKey] === TrackerComponent.paramValue
          && this.subscriptionService.isTrackingEnabled();

        if (this.track) {
          this.urls = ClientConfig.pixelTrackUrls.getStringArray();
          log.info('Tracking URLs: ', this.urls);
        }

      });
    }
  }
}
