<a
*ngIf="promotionItem"
 class="promo-image-holder"
 (click)="trackAndForward(promotionItem.targetUrl, 'mobilePromoTile.click', 
    promotionItem.title, true, source, promotionId)">
  <img *appLazy appResizable class="fillImageTiles"  [src]="promotionItem.artwork">
  <div class="tile-text">
    <p> {{ promotionItem.title }} </p>
  </div>
</a>
