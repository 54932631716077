<div class="dropdown-navbar">
    <ul>
      <li routerLink='/home' [ngClass]="{'active':pageHome}">
        {{ 'menu.home' | trans }}
     </li>
  
     <li routerLink='/shop' [ngClass]="{'active':pageShop}">
        {{ 'menu.shop' | trans }}
      </li>
  
      <li class="dropdown-content" (click)="menuItemClicked('/library')" (mouseenter)="showDropdownContent()"
      [ngClass]="{'active':pageLibrary, 'disable-pointer':isLogged }">
        {{ 'menu.myLibrary' | trans }}
        <ul *ngIf="isLogged && dropdownMenu" class="library">
          <li routerLink="/library/0" (click)= "hideDropdownMenu($event)">{{ 'menu.submenu.library.ringBackTones' | trans }}</li>
          <li routerLink='/library/1' (click)= "hideDropdownMenu($event)">{{ 'menu.submenu.library.personalMessages' | trans }}</li>
          <li routerLink='/library/2' (click)= "hideDropdownMenu($event)">{{ 'menu.submenu.library.prerecordedMessages' | trans }}</li>
        </ul>
      </li>
  
      <li class="dropdown-content" (click)="menuItemClicked('/setting')"  (mouseenter)="showDropdownContent()"
      [ngClass]="{'active':pageCaller, 'disable-pointer':isLogged }">
            {{ 'menu.callerExperience' | trans }} 
          <div *ngIf="isLogged && dropdownMenu">
            <app-setting-menu [disableRipple]="true" (onItemClicked)="hideDropdownMenu($event)"></app-setting-menu>
        </div>
      </li>
  
      <li class="dropdown-content" *ngIf="isLogged" (click)="menuItemClicked('/account')" (mouseenter)="showDropdownContent()"
      [ngClass]="{'active':pageAccount, 'disable-pointer':isLogged }">
          {{ 'menu.myAccount' | trans }}
        <ul class="account" *ngIf="isLogged && dropdownMenu">
          <li routerLink='/account/0' (click)="hideDropdownMenu($event)">{{ 'menu.submenu.account.settings' | trans }}</li>
          <li routerLink='/account/1' (click)="hideDropdownMenu($event)">{{ 'menu.submenu.account.subscription' | trans }}</li>
          <li *ngIf="showContentPackages" routerLink='/account/2' (click)= "hideDropdownMenu($event)">{{ 'menu.submenu.account.contentPackage' | trans }}</li>
        </ul>
       </li>
  
      <li routerLink='/faq' [ngClass]="{'active':pageFaq}">  {{ 'menu.faq' | trans }}</li>
    </ul>
  </div>