import { Input, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationTiles } from 'app/model/configuration-tiles';
import { AnalyticsService } from 'app/core/analytics.service';
import { BaseViewComponent } from 'app/shared/base-view.component';

@Component({
  selector: 'app-config-item',
  templateUrl: './config.component.html'
})
export class ConfigComponent extends BaseViewComponent {

  @Input() tilesOptions: ConfigurationTiles;
  @Input() columns: number;

  gutterSize = 15;

  constructor(router: Router, zone: NgZone) {
      super(zone, router);

      if (this.newLayout && !this.isMobile) {
        // For VF/DE desktop view the space between the home tiles is bigger.
        this.gutterSize = 30;
      }
  }

  track(action: string) {
    AnalyticsService.getInstance().trackEventAction(
      'homeTile.click',
      action.split('.').pop());
  }

}
