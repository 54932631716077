import { ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from "@sentry/browser";
import log from 'app/core/logging/logger.service';

/**
 * Error handler for sentry.io - logs all uncaught errors to the console for
 * dev builds, and then reports them to sentry
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  /**
   * Lazy loading is handled by webpack, there is no specific,
   * error code to validate this error and we have to rely on this
   * ugly method of checking the error message.
   */
  private readonly loadingChunkFailedMessage = 'Error: Loading chunk';

  constructor() { }

  handleError(err: any): void {

    log.error('Reporting uncaught error: ', err);

    // Log to the console if applicable, then let Sentry take over
    if (this.shouldCaptureError(err)) {
      log.error('Reporting uncaught error: ', err);
      Sentry.captureException(err);
    }

  }

  private shouldCaptureError(err: any) {
    // Check if Sentry SDK is initialized
    if (Sentry.getCurrentHub().getClient() != null) {

      // This happen due to network latency, we shows a message to the user,
      // it is not required to be notified
      if (err.message && err.message.includes(this.loadingChunkFailedMessage)) {
        return false;
      }

      return true;
    }

    // Return false if its not set up
    return false;
  }

}
