export default class GrantType {
  /**
    * Authenticate via authorization code.
    * Currently not used.
    *
    * @see <a href="https://tools.ietf.org/html/rfc6749#section-4.1">RFC 6749, Section 4.1</a>
    */
    public static authorizationCode = 'authorization_code';

  /**
    * Resource Owner Password Credentials Grant.
    *
    * @see <a href="https://tools.ietf.org/html/rfc6749#section-4.3">RFC 6749, Section 4.3</a>
    */
    public static password = 'password';

  /**
    * Use a refresh token to obtain a new access token.
    *
    * @see <a href="https://tools.ietf.org/html/rfc6749#section-6">RFC 6749, Section 6</a>
    */
    public static refreshToken = 'refresh_token';

}
