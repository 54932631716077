import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  Router,
  NavigationExtras,
} from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import UrlBuilder from 'app/core/url-builder';
import { AuthService } from 'app/core/auth/auth.service';
import { ClientConfig } from 'app/core/config/client-config';
import { BaseNetworkService } from 'app/core/network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import {
  Setting,
  Result,
  SettingType,
  ToneSettingType
} from 'app/model';
import CustomHttpParams from './network/custom-http-params';

export enum SettingAction {
  CREATE_SETTING,
  CREATE_SETTING_AFTER_CAPA_DISABLED,
  CHANGE_SETTING,
  CHANGE_SETTING_AFTER_CAPA_DISABLED,
  RESET_SETTING,
  DELETE_SETTING,
  CAPA_DISCARD_IGT,
  GROUP_CREATED,
  ADD_GROUP_MEMBER,
  DELETE_GROUP_MEMBER,
  NO_ACTION
}

/**
 * Global service to manage all settings. It keeps the state of the setting updates.
 * {@code SettingService } can be used anywhere you require a setting change
 */
@Injectable()
export class SettingService extends BaseNetworkService {

  /** Shuffle parameter name (TMO pass this to enable shuffle mode) */
  public static shuffleParam = 'SHUFFLE';

  /** Request parameter name for RBT setting. */
  private readonly requestParamRbtId = 'rbtId';

  /** Request parameter name for the IGT. */
  private readonly requestParamIgtId = 'igtId';

  /** Request parameter name for the IGT. */
  private readonly requestParamSettingId = 'id';


  /** Request parameter name for group name. */
  public readonly requestParamGroupName = 'groupName';

  /** Request parameter name for caller msisdn. */
  public readonly requestParamCallerMsisdn = 'callerMsisdn';

  /** Request parameter name for caller name. */
  public readonly requestParamCallerName = 'callerName';

  /** Request parameter name for overwrite */
  private readonly requestParamOverwrite = 'overWrite';

  /** Request parameter name for caller name. */
  private readonly requestParamWeekdays = 'weekdays';

  /** Request parameter name for start time. */
  private readonly requestParamStartTime = 'start';

  /** Request parameter name for end time. */
  private readonly requestParamEndTime = 'end';

  /** Request parameter name for day . */
  private readonly requestParamDay = 'day';

  /** Request parameter name for month . */
  private readonly requestParamMonth = 'month';

  /** Request parameter name for alternativeAudioPreferred */
  private readonly requestParamAlternativeAudioPreferred = 'alternativeAudioPreferred';

  /** Holds new selected rbt tone ids for setting separated by ';' */
  private rbtIds: string[] = null;

  /** Holds new selected igt tone id for setting */
  private igtId: string = null;

  /** Holds identifier for the setting */
  private settingId = null;

  /** Holds  the type of setting */
  private settingType = null;

  /** Holds the current setting. This will be null for new setting */
  private setting: Setting = null;

  /**
   * Holds the alternativeAudioPreferred option
   * If false the RBT will be played with ITU
   * If true the RBT will be played without ITU
   * (Only for VFDE) */
  private alternativeAudioPreferred: boolean = null;

  /** Holds the additional parameters for the settings  */
  private params: CustomHttpParams;

  /** Make this flag true to inform the service that the changes are pending */
  private changesPending = false;

  private settingAction = SettingAction.NO_ACTION;

  constructor(
    private router: Router,
    private location: Location,
    http: HttpClient,
    authService: AuthService) {
    super(http, authService);
  }

  /**
   * Creates the observable to update the setting. It reset all settings
   * properties once subscribed.
   *
   * @returns {@code Observable<Result>} for setting change
   */
  updateSetting(): Observable<Result> {
    return of({}).pipe(mergeMap(() => {

      const url = UrlBuilder.getSettingUrl().replace('{type}', this.settingType);
      const postParams = this.params ? this.params : this.createURLSearchParams();

      if (this.settingId) {
        postParams.set(this.requestParamSettingId, this.settingId);
      }

      if (this.rbtIds) {
        postParams.set(this.requestParamRbtId, this.rbtIds.join(';'));
      }

      if (this.igtId) {
        postParams.set(this.requestParamIgtId, this.igtId);
      }

      if (this.alternativeAudioPreferred != null) {
        postParams.set(this.requestParamAlternativeAudioPreferred, this.alternativeAudioPreferred.toString());
      }
      
      // Reset the state once subscribed
      this.resetSettingProperties();

      return this.sendObservableRequest(
        url,
        RequestMethod.Post,
        postParams
      );

    }));

  }

  /**
   * Resets all settings properties.
   */
  private resetSettingProperties() {
    this.changesPending = false;
    this.settingType = null;
    this.settingId = null;
    this.setting = null;
    this.rbtIds = null;
    this.igtId = null;
    this.params = null;
  }

  /**
   * Initializes the setting changes and saves the state
   * @param settingType the setting type
   * @param settingId the unique identifier for the setting
   * @param setting  the current setting
   */
  enableSetting(settingType: string, settingId: string, setting: Setting) {
    this.settingType = settingType;
    this.settingId = settingId;
    this.setting = setting;
  }

  /**
   * Set params for new caller
   * @param callerMsisdn the caller msisdn
   * @param callerName the caller name
   */
  setNewCallerParams(callerMsisdn: string, callerName: string) {
    this.params = this.createURLSearchParams();
    this.params.set(this.requestParamCallerMsisdn, callerMsisdn);
    this.params.set(this.requestParamCallerName, callerName);
  }

  /**
   * Set params for new day of week settings
   * @param days the selected days
   */
  setNewWeekDaysParams(days: string[]) {
    this.params = this.createURLSearchParams();
    this.params.set(this.requestParamWeekdays, days.join(';'));
  }

  /**
   * Set params for day of year settings
   * @param day the date
   * @param month the month
   */
  setNewDayOfYearParams(day: number, month: number) {
    this.params = this.createURLSearchParams();
    this.params.set(this.requestParamDay, day.toString());
    this.params.set(this.requestParamMonth, month.toString());
  }


  /**
   * Sets params for new group
   * @param groupName the group name
   */
  setNewGroupParams(groupName: string) {
    this.params = this.createURLSearchParams();
    this.params.set(this.requestParamGroupName, groupName);
  }

  /**
   * Sets params for new time of day setting
   * @param startTime the start time
   * @param endTime the end time
   */
  setTimeOfDayParams(startTime: string, endTime: string) {
    this.params = this.createURLSearchParams();
    this.params.set(this.requestParamStartTime, startTime);
    this.params.set(this.requestParamEndTime, endTime);
  }


  /**
   * Set params for time and day of week settings
   *
   * @param startTime the start time
   * @param endTime the end time
   * @param days the selected days
   */
  setNewTimeAndDayOfWeekParams(startTime: string, endTime: string, days: string[]) {
    this.setTimeOfDayParams(startTime, endTime);
    this.params.set(this.requestParamWeekdays, days.join(';'));
  }

  /**
   * Saves properties  to change current rbt setting
   *
   * @param rbtIds {sting[]} the selected rbt ids
   */
  changeRbtSetting(rbtIds: string[], skipRedirect:boolean = false) {

    this.settingAction = this.settingId
      ? SettingAction.CHANGE_SETTING
      : SettingAction.CREATE_SETTING;

    this.rbtIds = rbtIds;
    this.igtId = this.getSettingIgt();
    this.changesPending = true;
    if(!skipRedirect) {
      this.redirectToSettingPage();
    }
  }

  /**
   * Saves properties to change current igt Setting
   *
   * @param igtId the selected igt id
   * @param action the setting action (optional)
   */
  changeIgtSetting(igtId: string, action?: SettingAction, skipRedirect:boolean = false) {

    if (action) {
      this.settingAction = action;
    } else {
      this.settingAction = this.settingId
      ? SettingAction.CHANGE_SETTING
      : SettingAction.CREATE_SETTING;
    }

    this.igtId = igtId;
    this.rbtIds = this.getSettingRbts();
    this.changesPending = true;
    if(!skipRedirect) {
      this.redirectToSettingPage();
    }
  }

  /**
   * Saves properties to change the version of RBT to be played (With/Without ITU)
   *
   * @param alternativeAudioPreferred boolean to set the flag alternativeAudioPreferred to true or false
   */
  changeRbtVersion(alternativeAudioPreferred: boolean) {

    this.settingAction = SettingAction.CHANGE_SETTING;

    this.alternativeAudioPreferred = alternativeAudioPreferred;
    this.igtId = this.getSettingIgt();
    this.rbtIds = this.getSettingRbts();
    this.changesPending = true;
  }

  /**
   * Save settings to reset existing setting. Set {@code igtId} as {@code null}
   * and {@code rbtId} as {@link ClientConfig.defaultVcode}
   *
   * @param settingType  the setting type
   * @param settingId the setting id
   */
  resetSetting(settingType: string, settingId: string): Observable<Result> {
    this.settingAction = SettingAction.RESET_SETTING;

    this.settingType = settingType;
    this.settingId = settingId;

    this.igtId = null;
    this.rbtIds = [ClientConfig.defaultVcode.getString()];
    this.changesPending = true;

    return this.updateSetting();
  }

  /**
   * Creates an observable for delete settings.
   * @param settingType the setting type
   * @param settingId the setting id
   *
   * @returns the observable to delete setting
   */
  deleteSetting(settingType: string, settingId: string): Observable<Result> {
    this.settingAction = SettingAction.DELETE_SETTING;

    const url = UrlBuilder.getSettingDeleteUrl().replace('{type}', settingType);
    const params = this.createURLSearchParams();
    params.set(this.requestParamSettingId, settingId);

    return this.sendObservableRequest(
      url,
      RequestMethod.Post,
      params
    );

  }

  /**
   * Creates an observable for add group member.
   *
   * @param the setting id
   * @param groupName the group name
   * @param memberMsisdn the member msisdn
   * @param memberName the member name
   *
   * @returns the observable to add new group member
   */
  addGroupMember(settingId: string, groupName: string,
    memberMsisdn: string, memberName: string): Observable<Result> {

    this.settingAction = settingId ? SettingAction.ADD_GROUP_MEMBER : SettingAction.GROUP_CREATED;
    const url = UrlBuilder.getAddCallerToGroupUrl();

    const params = this.createURLSearchParams();
    params.set(this.requestParamGroupName, groupName);
    params.set(this.requestParamCallerName, memberName);
    params.set(this.requestParamCallerMsisdn, memberMsisdn);
    params.set(this.requestParamOverwrite, settingId ? 'true' : 'false');

    return this.sendObservableRequest(
      url,
      RequestMethod.Post,
      params
    );

  }

  /**
   * Creates an observable for delete group member.
   *
   * @param groupName the group name
   * @param memberMsisdn the member msisdn
   *
   * @returns the observable to delete group member
   */
  deleteGroupMember(groupName: string, memberMsisdn: string): Observable<Result> {
    this.settingAction = SettingAction.DELETE_GROUP_MEMBER;

    const url = UrlBuilder.getDeleteCallerFromGroupUrl();

    const params = this.createURLSearchParams();
    params.set(this.requestParamGroupName, groupName);
    params.set(this.requestParamCallerMsisdn, memberMsisdn);

    return this.sendObservableRequest(
      url,
      RequestMethod.Post,
      params
    );

  }

  getLastAction() {
    return this.settingAction;
  }

  /**
   * Redirects to the corresponding settings page.
   */
  redirectToSettingPage() {
    if (this.settingType === SettingType.defaultSetting) {
      this.location.replaceState('/home');
      this.router.navigate([`/setting/${this.settingType}`]);
      return;
    }

    const queryParams = {};
    if (this.settingId) {
      queryParams['id'] = this.settingId;
    }

    const navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };

    this.location.replaceState(`/setting/${this.settingType}`);
    this.router.navigate([`/setting/${this.settingType}/details`], navigationExtras);
  }

  /**
  * Redirects to the IGT selection page for the given setting
  */
  redirectToIgtSelectionPage() {
    this.router.navigate(['/library/igt/select']);
  }

  /**
   * Checks setting has been initialized
   *
   * @returns {@code true} setting type and identifier already set.
   */
  isSettingEnabled(): boolean {
    return this.settingType !== null && this.settingType !== undefined;
  }

  /**
   * Checks whether there is a setting change pending to subscribe.
   *
   * @param settingType the setting type
   */
  isSettingsPending(settingType: string): boolean {

    return settingType
      && settingType === this.settingType
      && this.changesPending;
  }

  getSetting() {
    return this.setting;
  }

  getParam(key): string {
    return this.params ? this.params.get(key) : null;
  }

  getSettingType() {
    return this.settingType;
  }

  getSettingId() {
    return this.settingId;
  }

  getSettingRbts() {
    if (this.setting) {
      return this.setting.rbtSetting.type === ToneSettingType[ToneSettingType.SHUFFLE]
        ? [SettingService.shuffleParam]
        : this.setting.rbtSetting.id;
    } else {
      return [ClientConfig.defaultVcode.getString()];
    }
  }

  getSettingIgt() {
    return this.setting ? this.setting.igtId : null;
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }

  setLastSettingAction(action: SettingAction) {
    this.settingAction = action;
  }

  setCapaStatus(capa: boolean): Observable<Result> {
    const params = this.createURLSearchParams();
    params.append('enable', capa.toString());

    return this.sendObservableRequest(
      UrlBuilder.getSetCapaUrl(),
      RequestMethod.Post,
      params
    );
  }
}
