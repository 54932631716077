import { Component, Inject, OnInit } from "@angular/core";

import { Profile, ToneList } from "app/model";
import { LibraryService } from "../../library/library.service";
import { SettingService } from "../../core/setting.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


/**
 * Component to select igt
 */
@Component({
  templateUrl: "./igt-select-dialog.component.html",
})
export class IGTSelectDialogComponent implements OnInit {
  spinnerActiveIGT: boolean;
  spinnerActivePredefinedIGT: boolean;

  igtList: ToneList = new ToneList();
  igtPredefinedList: ToneList = new ToneList();

  selectedTone: string;

  constructor(
    public dialogRef: MatDialogRef<IGTSelectDialogComponent>,
    private libraryService: LibraryService,
    @Inject(MAT_DIALOG_DATA) public data: Profile,
    private settingService: SettingService
  ) {
    this.selectedTone = this.settingService.getSettingIgt();
  }

  ngOnInit(): void {
    this.spinnerActiveIGT = true;
    this.spinnerActivePredefinedIGT = true;
    this.getIgtTonesFromService();
    this.getIgtTonesFromProfile();
  }

  getIgtTonesFromService() {
    this.libraryService.getIgtTones().subscribe((toneList: ToneList) => {
      this.igtPredefinedList = toneList;
      this.spinnerActivePredefinedIGT = false;
    });
  }

  getIgtTonesFromProfile() {
    this.igtList = this.data.ugc;
    this.spinnerActiveIGT = false;
  }

  select(toneId: string) {
    this.selectedTone = toneId;
    this.settingService.changeIgtSetting(this.selectedTone, null, true);
    this.dialogRef.close();
  }
}
