export default class HttpHeader {

  public static contentType = 'Content-Type';
  public static authorization = 'Authorization';
  public static acceptLanguage = 'Accept-Language';
  public static accept = 'Accept';

  /** Custom HTTP header to identify the HtmlPlus channel  */
  public static clientId = 'Client-Id';
}
