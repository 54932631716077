import log from 'app/core/logging/logger.service';
import { environment } from 'environments/environment';

import 'autotrack';
import { AuthService } from './auth/auth.service';
import { ClientConfig } from './config/client-config';
import { ScriptService } from './script.service';
import { ConsentManagementService, ConsentStatusChangeEvent, Vendor } from './consent-management.service';

declare var ga: Function;

declare global {
  interface Window {
    _ddq: any;
  }
}

export abstract class AnalyticsService {

  /** Singleton object */
  private static instace: AnalyticsService;

  static getInstance(authService?: AuthService): AnalyticsService {

    if (!AnalyticsService.instace) {
      if (this.isGoogleAnalyticsConfigured()) {
        // If Tealium is not enabled let's check if Google Analytics is configured
        AnalyticsService.instace = new GoogleAnalyticsService();
        AnalyticsService.instace.initializeAnalyticsFromStorage(environment.enableGoogleAnalyticsByDefault);
      } else if (this.isTealiumAnalyticsConfigured()) {
        AnalyticsService.instace = new TealiumAnalyticsService(authService);
        // ERBT-5079, ERBT-5910: For Tealium analytics by default we disable tracking until 
        // the user consents to cookies used for analytics.
        AnalyticsService.instace.updateAnalyticsTracking(false);
      } else {
        AnalyticsService.instace = new DummyAnalyticsService();
      }
    }

    return AnalyticsService.instace;
  }

  static loadTealiumAnalyticsScript() {
    if (this.isTealiumAnalyticsConfigured() 
    && !AnalyticsService.getInstance().isAnalyticsInitialized()) {
      ScriptService.loadScript('https://www.vodafone.de/scripts/s_code_lite.js')
        .then(() => {
          log.info('Tealium Analytics initialized');
          AnalyticsService.getInstance().setAnalyticsInitialized(true);
          // Enable analytics tracking
          AnalyticsService.getInstance().updateAnalyticsTracking(true);
        });
    }
  }

  static isGoogleAnalyticsConfigured(): boolean {
    return environment.googleAnalyticsId !== null;
  }

  static isTealiumAnalyticsConfigured(): boolean {
    return ClientConfig.tealiumEnabled.getBoolean();
  }

  constructor() {}

  abstract init(): void;

  /**
   * Track all events
   *
   * @param category the event category
   * @param action the action name
   * @param label the opronal label name
   */
  abstract trackEvent(category: string, action: string, label?: string): void;

  /**
   * Track the page view
   * For GA this is done automatically but for Tealium we have to do it manually
   * @param page the page to track
   */
  abstract trackPageView(page: string): void;

  /**
   * Enable or disable Google Analytics or Tealium
   *
   * @param trackingOn the tracking value to be set
   */
  abstract updateAnalyticsTracking(trackingOn: boolean): void;

  /**
   * Returns if Google Analytics or Tealium is enabled
   */
  abstract isAnalyticsEnabled(): boolean;

  /**
   * Tries to fetch the value for the analytics tracking from the local storage.
   * If no value is found, then sets the value with the param enableAnalyticsByDefault
   *
   * @param enableAnalyticsByDefault value to set for the tracking in case that there is not value in the local storage
   */
  abstract initializeAnalyticsFromStorage(enableAnalyticsByDefault: boolean): void;
  
  abstract setAnalyticsInitialized(initialized: boolean): void;

  abstract isAnalyticsInitialized(): boolean;

  /**
   * Track all action events
   *
   * @param action the action name
   * @param label the opronal label name
   */
  public trackEventAction(action: string, label?: string): void {
    AnalyticsService.instace.trackEvent('Actions', action, label);
  }

}

export class GoogleAnalyticsService extends AnalyticsService {

  private static readonly storageKeyAnalytics = 'googleAnalyticsTracking';
  private trackingOn;
  private analyticsInitialized: boolean;

  constructor() {
    super();
    log.info('Google Analytics initialized');
  }

  init(): void {
    ga('create', environment.googleAnalyticsId, 'auto');
    ga('require', 'cleanUrlTracker');
    ga('require', 'eventTracker');
    ga('require', 'outboundLinkTracker');
    ga('require', 'urlChangeTracker');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview', {'anonymizeIp': true});

    AnalyticsService.getInstance().setAnalyticsInitialized(true);
  }

  trackEvent(category: string, action: string, label?: string): void {
    if (this.trackingOn) {
      label = label ? label.trim() : '';
      log.info('GoogleAnalyticsEvent: Action: ' + action + ' Label: ' + label);

      ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      });
    }
  }

  trackPageView(page: string): void { }

  updateAnalyticsTracking(trackingOn: boolean): void {
    this.trackingOn = trackingOn;
    try {
      localStorage.setItem(GoogleAnalyticsService.storageKeyAnalytics, this.trackingOn.toString());
    } catch (error) {
      log.warn('Access to local storage is not allowed', error);
    }

    // Opt-out option
    const disableGoogleAnalyticsKey = 'ga-disable-' + environment.googleAnalyticsId;
    window[disableGoogleAnalyticsKey] = !trackingOn;
  }

  isAnalyticsEnabled(): boolean {
    return this.trackingOn;
  }

  initializeAnalyticsFromStorage(enableAnalyticsByDefault: boolean): void {
    let analyticsTrackingLocalStorage: string;
    try {
      analyticsTrackingLocalStorage = localStorage.getItem(GoogleAnalyticsService.storageKeyAnalytics);
    } catch (error) {
      log.warn('Access to local storage is not allowed', error);
    }
    analyticsTrackingLocalStorage
      ? this.updateAnalyticsTracking(analyticsTrackingLocalStorage === 'true')
      : this.updateAnalyticsTracking(enableAnalyticsByDefault);
  }

  setAnalyticsInitialized(initialized: boolean): void {
    this.analyticsInitialized = initialized;
  }

  isAnalyticsInitialized(): boolean {
    return this.analyticsInitialized;
  }
}

export class TealiumAnalyticsService extends AnalyticsService {

  private tealiumScriptLoaded: boolean;

  private trackingOn: boolean;

  // Holds the last unsent page view event that didn't make it to Tealium
  // cause analytics tracking was disabled.
  private pendingPageViewEvent: string;

  constructor(private authService: AuthService) {
    super();
    log.info('Tealium Analytics will get initialized once the user consents to analytics cookies');
  }

  init(): void {
    this.registerPrivacyConsentHandler();
  }

  trackEvent(category: string, page: string, label?: string): void { }

  trackPageView(page: string): void {
    this.trackTealiumEvent(page);
  }

  updateAnalyticsTracking(trackingOn: boolean): void {
    this.trackingOn = trackingOn;

    if (this.trackingOn && this.pendingPageViewEvent) {
      // Once the user gives consent to analytics we want to send the current page view.
      // For Tealium we do the tracking manually by registering a listener that listens
      // to navigation events in app.component.ts. 
      // If the tracking is disabled the current page view event is skipped.
      this.trackPageView(this.pendingPageViewEvent);
    }
  }

  isAnalyticsEnabled(): boolean {
    return this.trackingOn;
  }

  initializeAnalyticsFromStorage(_enableAnalyticsByDefault: boolean): void {}

  private trackTealiumEvent(page: string) {
    if (this.trackingOn) {
      // Current page view event will be sent to Tealium. 
      // Clear pending page view event.
      this.pendingPageViewEvent = undefined;

      const _ddq = window._ddq || (window._ddq = []);

      // The structure of the site is fix in the first and second level
      // The dynamic structure is the third level
      const siteStructure = ['micropages', 'mytone'];
      siteStructure.push(this.formatPageName(page));

      const dl = {
        siteStructure: siteStructure,
        loginStatus: this.authService.isLoggedIn() ? 'logged in' : 'not logged in',
        pageType: 'content',
        siteArea: 'micropages',
        platformType: 'responsive'
      };

      _ddq.push(['pageview', dl]);
      log.info('Sent Tealium tracking event: ', dl);

    } else {
      // Current page view event will not be sent to Tealium (tracking is disabled).
      // Save the curent page view event in order to send it once analytics tracking is enabled.
      // The tracking can only be enabled by the cookie pop-up displayed on the current page view.
      this.pendingPageViewEvent = page;
    }
  }

  // Formats the url from the route event, removes the first slash and all the parameters after the question mark
  private formatPageName(page: string): string {
    // Case when there's no page in the url, so it's the home page
    if (page === '/') {
      return 'home';
    }

    const matches = page.match(/\/([^\/?;]*)/i);
    // As a fallback in case that the regex fails, let's track the original event url
    if (matches && matches.length > 1) {
      return matches[1].startsWith('rbt')
        ? 'song'
        : matches[1];
    } else {
      return page;
    }
  }

  setAnalyticsInitialized(initialized: boolean): void {
    this.tealiumScriptLoaded = initialized;
  }

  isAnalyticsInitialized(): boolean {
    return this.tealiumScriptLoaded;
  }

  /**
  * This method listens to privacy consent changes in the consent management platform (CMP) and
  * it enables or disables analytics tracking based on the given consent status for Tealium and Adobe vendors.
  * 
  * @see "ERBT-5910"
  */
  registerPrivacyConsentHandler() {
    ConsentManagementService.getInstance().statusChange$.subscribe(
      (event: ConsentStatusChangeEvent) => {
        if (event.vendor !== Vendor.TEALIUM_ADOBE_ANALYTICS) {
          return;
        }
        if (event.status) {
          // Check if Tealium analytics is already initialized
          if (AnalyticsService.getInstance().isAnalyticsInitialized()) {
            // Tealium script is already loaded, enable analytics tracking
            AnalyticsService.getInstance().updateAnalyticsTracking(true);
          } else {
            // Initialize tealium analytics
            AnalyticsService.loadTealiumAnalyticsScript();
          }
          log.info('Tealium Analytics enabled');
        } else {
          // Disable analytics tracking
          AnalyticsService.getInstance().updateAnalyticsTracking(false);
          log.info('Tealium Analytics disabled');
        }
      })
  }
}

/**
 * A dummy implementation of the tracker that doesn't do anything.
 */
export class DummyAnalyticsService extends AnalyticsService {

  constructor() {
    super();
  }

  init(): void {
    // Intentionally empty - dummy implementation
  }

  trackEvent(category: string, action: string, label?: string): void {
    // Intentionally empty - dummy implementation
  }

  trackPageView(page: string): void {
    // Intentionally empty - dummy implementation
   }

  updateAnalyticsTracking(trackingOn: boolean): void {
    // Intentionally empty - dummy implementation
  }

  isAnalyticsEnabled(): boolean {
    return false;
  }

  initializeAnalyticsFromStorage(enableAnalyticsByDefault: boolean): void {
    // Intentionally empty - dummy implementation
  }

  setAnalyticsInitialized(initialized: boolean): void {
    // Intentionally empty - dummy implementation
  }

  isAnalyticsInitialized(): boolean {
    return false;
  }
}