import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * Utility class to solve the encoding problems with URLSearchParams
 * https://jira.real.com/browse/ERBT-3920
 *
 * To solve this issue we use a custom query encoder to overwrite the
 * values of the keys and values with broken symbols.
 *
 * In case of the keys, the broken symbols are: '+', '='
 * In case of the values, the broken symbols are:  '+'
 *
 * Please see:
 * https://github.com/angular/angular/issues/11058
 * https://github.com/angular/angular/blob/5.2.10/packages/http/src/url_search_params.ts#L33-L44
 *
 */
export class CustomHttpUrlEncodingCodec  extends HttpUrlEncodingCodec {
 
    /**
     * @param key The key to be encoded
     */
    encodeKey(key: string): string {
        key = super.encodeKey(key);
        key = key.replace(/\+/gi, '%2B');
        key = key.replace(/\=/gi, '%3D');
        return key;
    }

    /**
     * @param value The value to be encoded
     */
    encodeValue(value: string): string {
        value = super.encodeValue(value);
        value = value.replace(/\+/gi, '%2B');
        return value;
    }
}
