import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PlayButtonService {

    private source = new Subject<boolean>();
    audio$ = this.source.asObservable();

    stopAudio() {
        this.source.next(null);
    }
}
