import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PromoService } from 'app/shared/promotion/promo.service';
import { BasePromoComponent } from 'app/shared/promotion/base-promo.component';
import { PurchaseSources } from 'app/tracking/purchase.sources';

@Component({
  selector: 'app-mobile-promo',
  templateUrl: 'mobile-promo.component.html'
})
export class MobilePromoComponent extends BasePromoComponent {

  /**
   * The source ERBT-3371 Purchase Source Tracking
   * for the mobile promotion tile
   */
  readonly source: string = PurchaseSources.PromotionTile;

  constructor(promoService: PromoService, router: Router) {
    super(promoService, router);
  }
}
