<mat-grid-list cols="{{columns}}" [gutterSize]="gutterSize">
  <mat-grid-tile
  [routerLink]="cfg.action"
  (click)="track(cfg.name)"
  *ngFor="let cfg of tilesOptions.configurations; let j = index"
  [colspan]=2
  [rowspan]=2>
    <div class="tile-content">
      <div class="img-holder" [ngClass]="cfg.img">
        <img src="assets/common/home-dummy.svg">
      </div>
      <p class="tilesText"> {{ cfg.name | trans }} </p>
    </div>
  </mat-grid-tile>
</mat-grid-list>
