import { HttpParams } from '@angular/common/http';

/**
 * CustomHttpParams is a convient way that solves the immutability issues of
 * HttpParams and allows us to use the HttpParams methods without worrying 
 * about the new returned instance.
 * 
 * HttpParams is immutable and all it's methods don't modify the existing 
 * instance but instead they return new instance.
 */
export default class CustomHttpParams {

    private httpParams : HttpParams;

    constructor(options?) {
        this.httpParams = new HttpParams(options);
    }

    /**
     * Retrieves the first value for a parameter.
     * @param param The parameter name.
     * @returns The first value of the given parameter,
     * or `null` if the parameter is not present.
     */
    get(param: string): string | null {
        return this.httpParams.get(param);
    }

    /**
     * Retrieves all values for a  parameter.
     * @param param The parameter name.
     * @returns All values in a string array,
     * or `null` if the parameter not present.
     */
    getAll(param: string): string[] | null {
        return this.httpParams.getAll(param);
    }

    /**
     * Retrieves all the parameters for this body.
     * @returns The parameter names in a string array.
     */
    keys(): string[] {
        return this.httpParams.keys();
    }

    /**
     * Appends a new value to existing values for a parameter.
     * @param param The parameter name.
     * @param value The new value to add.
     */
    append(param: string, value: string): void {
        this.httpParams = this.httpParams.append(param, value)
    }

    /**
     * Constructs a new body with appended values for the given parameter name.
     * @param params parameters and values
     */
    appendAll(params: {
        [param: string]: string | string[]; }): void {
        this.httpParams = this.httpParams.appendAll(params);
    }

    /**
     * Replaces the value for a parameter.
     * @param param The parameter name.
     * @param value The new value.
     * @return A new body with the new value.
     */
    set(param: string, value: string): void {
        this.httpParams = this.httpParams.set(param, value);
    }

    /**
     * Removes a given value or all values from a parameter.
     * @param param The parameter name.
     * @param value The value to remove, if provided.
     * @return A new body with the given value removed, or with all values
     * removed if no value is specified.
     */
    delete(param: string, value?: string): void {
        this.httpParams = this.httpParams.delete(param, value);
    }

    /**
     * Serializes the body to an encoded string, where key-value pairs (separated by `=`) are
     * separated by `&`s.
     */
    toString(): string {
        return this.httpParams.toString();
    }

    toHttpParams(): HttpParams {
        return this.httpParams;
    }
}