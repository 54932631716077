<span class="dialog">
<button mat-button *ngIf="showCloseIcon"
(click)="dialogRef.close(false)" class="bt-close"></button>
<h1 mat-dialog-title>{{ title | trans }}</h1>
<div mat-dialog-content>
  <mat-form-field dividerColor="accent" appearance="outline">
    <mat-label>{{ 'setting.caller.add.label.phoneNumber' | trans }}</mat-label>
    <input matInput [(ngModel)]="callerPhoneNumber">
  </mat-form-field>
  <mat-form-field dividerColor="accent" appearance="outline">
    <mat-label>{{ 'setting.caller.add.label.callerName' | trans }}</mat-label>
    <input matInput [(ngModel)]="callerName">
  </mat-form-field>
  <mat-hint class="error-message" *ngIf="errorMessage">{{ errorMessage | trans }}</mat-hint>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="dialogRef.close(true)"
  color='primary'
  class="bt-main">
    {{ 'common.button.add' | trans }}
  </button>
  <button mat-raised-button *ngIf="!showCloseIcon"
  class="bt-main bt-secondary cancel"
  (click)="dialogRef.close(false)">
    {{ 'common.button.cancel' | trans }}
    </button>
</div>
</span>
