

/* Information about a purchase package. */
export class OfferedPackage {

    /* ID of the package. */
    id: string;

	/* User visible name of the package. */
    name: string;

	/* Purchase price of the package. */
    price: number;

	/* Total number of credits included in the package. */
    totalCredits: number;

	/* Whether the package is recurring, i.e., automatically renewing after its expiration date. */
    recurring: boolean;

    /* Unit of the duration of the package. Possible values: UNKNOWN_UNIT, MINUTE, DAY, MONTH, YEAR */
    durationUnit?: string;

    /* Numeric value of the duration of the package. */
    durationValue?: number;
}
