import { Profile } from './profile';

/* Overall status of a Result */
export enum ResultStatus {
  /* The request was successful. */
  SUCCESS,
  /* The request was understood, but failed for some reason. Check the errorCode. */
  FAILED
}

/* Error code for a FAILED result */
export enum ErrorCode {

  /* Catch-all error code returned if no more specific error code is available. */
  DEFAULT,

	/*
	 * Purchase failed because user is out of credit.
	 * Returned for CommerceServiceException.ERROR_CODE_OUT_OF_CREDIT.
	 */
  OUT_OF_CREDIT,

	/*
	 * Purchase failed because user is "barred" from using this service.
	 */
  BARRED,

	/*
	 * UGC upload failed, because the provided UGC name is already in use.
	 */
  GREETING_NAME_EXISTS,

	/*
	 * Returned by the SF if too many single caller settings exist.
	 * Deprecated, because the client should check this before making the call, so the SF can return a generic error.
	 */
  CALLER_TOO_MANY,

  /* Subscription failed, because user is not customer of the correct operator. */
  WRONG_OPERATOR,

  /* Subscription failed, because the user is not eligible for the requested promotion. */
  NOT_ELIGIBLE_FOR_PROMOTION,

  /* PIN code SMS was not sent, because too many SMS have been sent already. */
  PIN_AUTH_TOO_MANY_SMS,

  /* PIN code SMS was not sent, because user made too many PIN code authentication attempts. */
  PIN_AUTH_TOO_MANY_ATTEMPTS,

	/*
	 * PIN code SMS was not sent, because there is too much load on the server right now.
	 * Currently not used by the SF, but the client supports it.
	 */
  PIN_AUTH_TOO_MUCH_LOAD,

	/*
	 * PIN code SMS was not sent, because the MSISDN is invalid.
	 * Currently not used by the SF, but the client supports it.
	 */
  PIN_AUTH_INVALID_MSISDN,

	/*
	 * The purchase is not possible with the (promotional) initial credits on the prepaid card.
	 * The user must recharge the card at least once to allow purchase of this product.
	 */
  NOT_ALLOWED_WITH_INITIAL_CREDITS,

	/*
	 * Requested amount exceeds the set spent limit.
	 * Might be used by VF/DE, but not seen in the wild yet.
	 * <p>
	 * Canonical VF/DE error message:
	 * "Kauf nicht zulässig. Deine Buchung übersteigt das höchst mögliche Limit."
	 */
  AMOUNT_EXCEEDS_LIMIT,
	/**
	 * Account is barred for mobile purchase.
	 * Might be used by VF/DE, but not seen in the wild yet.
	 * <p>
	 * Canonical VF/DE error message:
	 * "Du bist für Mobiles Bezahlen Dienstleistungen gesperrt.
	 * Du kannst Dich Online oder über die Kundenbetreuung freischalten lassen."
	 */
  BARRED_FOR_MOBILE_PURCHASE,

	/**
	 * Account is (temporarily) suspended.
	 * Might be used by VF/DE, but not seen in the wild yet.
	 * <p>
	 * Canonical VF/DE error message:
	 * "Dein Konto ist gesperrt. Bitte wend Dich an die Kundenbetreuung."
	 */
  ACCOUNT_SUSPENDED,

	/**
	 * The MSISDN has not been activated or is not active anymore.
	 * Currently only used by VF/DE.
	 * <p>
	 * Canonical VF/DE error message:
	 * "Deine Rufnummer ist nicht aktiv. Bitte wend Dich an die Kundenbetreuung."
	 */
  MSISDN_NOT_ACTIVE,

  /**
   * UGC Token cannot be resolved.
   * The token might be syntactically invalid and coudln't be decoded.
   */
  SHARED_UGC_TOKEN_INVALID,

   /**
   * UGC Token is expired.
   * The token is syntactically OK, but the UGC does not exist anymore.
   */
  SHARED_UGC_TOKEN_EXPIRED

}


/*
 * Result of some update operation.
 * Either the status is SUCCESS and the error code is empty.
 * Or the status is FAILED and the error code is set.
 */
export class Result {

  /* Status of the result (SUCCESS or FAILED). */
  resultStatus: string;

  /* For FAILED requests this contains the error code. In the SUCCESS case it is not set. */
  errorCode?: string;

  /*
   * ID of the newly created or updated setting.
   * Returned by /authenticated/settings/type/set call.
   */
  newSettingId?: string;

 /*
  * The partial user profile, containing only the part that has just changed.
  */
  partialProfile?: Profile;

  /*
   * The new, complete profile after the setting change or purchase has completed.
   * Only included by certain calls and only when explicitly requesting it using "addNewProfile" parameter.
   */
   newProfile?: Profile;
}
