<mat-list-item class="call-party" [ngClass]="isRedesign ? 'redesigned': ''">
   <div
      *ngIf="!isRedesign && !newLayout"
      mat-line
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px"
      class="call-party-name">
        <span class="name">{{ callPartyName }} </span>
        <span *ngIf="isCaller">({{ callParty.msisdn }})</span>
    </div>
  <div
      mat-line
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="{{ newLayout ? '15px' : '8px' }}">

      <div
        fxFlex="1 1 auto"
        [fxLayout]="isRedesign ? 'row' : 'column'"
        fxLayoutAlign="center center">
        <div *ngIf="isRedesign" class="initials-container">
          {{ initial }}
        </div>
        <div *ngIf="isRedesign" class="caller-name">
          {{callPartyName}}
        </div>
        <app-play-button
          *ngIf="!isRedesign"
          [initial]="initial"
          [initialBgColor]="initialBgColor"
          [tones]="toneList"
          [cover]="cover | image:imageSize:imageSize">
        </app-play-button>
      </div>

    <div *ngIf="!isRedesign" 
        class="middle"
        fxFlex="1 1 45%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="{{ newLayout ? '10px' : '3px' }}">
        <div *ngIf="newLayout" class="call-party-name">
          <span class="name">{{ callPartyName }} </span>
          <span *ngIf="isCaller">({{ callParty.msisdn }})</span>
      </div>

      <p class="igt-title" [class.igt-title-active]="callParty.igt">
        <span *ngIf="callParty.igt">{{ callParty.igt.title }} &nbsp;</span>
      </p>
      <p class="rbt-title" [class.rbt-title-active]="isRbtActive" [class.shuffle-title-active]="isShuffleActive" >
        <span *ngIf="callParty.rbt && !isShuffleActive">{{  callParty.rbt.title }}</span>
        <span *ngIf="isShuffleActive">{{  'callParty.shuffle.active' | trans }}</span>
     </p>
    </div>
    <div
        class="end"
        fxFlex="grow"
        fxLayout="column"
        fxLayoutAlign="{{ newLayout ? 'center end' : 'stretch end' }}"
        fxLayoutGap="5px">
        <button [attr.data-test-id]="'delete-settings-list-button'" mat-button (click)="delete($event)"  class="bt-delete"></button>
    </div>
  </div>
</mat-list-item>
