import { DayOfWeekView } from 'app/model';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { FormatPipe } from 'app/shared/pipes/format.pipe';

export class CallPartyHelper {

  private static timeOfDayTitleTextKey = 'setting.timeOfDay.title';
  private static dayOfYearTitleTextKey = 'setting.dayOfYear.title';

  private static timeAndDayOfWeekAllDayTitleTextKey = 'setting.timeAndDayOfWeek.allDay.title';
  private static timeAndDayOfWeekTimeDurationTitleTextKey = 'setting.timeAndDayOfWeek.timeDuration.title';

  private static readonly translatePipe = new TranslatePipe();
  private static readonly formatPipe = new FormatPipe();

  /**
   * Prepares the call party title for day of week setting, which is is used
   * in the list page.
   *
   * @param daysOfWeek the days of week from setting
   */
  public static prepareDayOfWeekCallPartyName(daysOfWeek: string[]): string {
    return CallPartyHelper.translateDays(daysOfWeek).join(', ');
  }

  /** Translates  weekdays from the provided text keys */
  public static translateDays(daysOfWeek: string[]) {
    const days = [];
    daysOfWeek.forEach(day => {
      days.push(
        CallPartyHelper.translatePipe.transform(
          DayOfWeekView.daysViewCollection[day] // Gets the text key for each day
        )
      );
    });

    return days;
  }

  /**
   * Prepares the call party title for time of day setting, which is is used
   * in the list page.
   *
   * @param startTime the start time
   * @param endTime the end time
   */
  public static prepareTimeOfDayCallPartyName(startTime: string,
    endTime: string): string {
    return CallPartyHelper.applyPlayTimeDetailsToTheTextKey(startTime, endTime,
      CallPartyHelper.timeOfDayTitleTextKey);
  }

  /**
   * Prepares the call party title for day of year setting, which is is used
   * in the list page.
   *
   * @param dayOfYear the day of year
   */
  public static prepareDayOfYearCallPartyName(dayOfYear: Date): string {

    let callPartyName = CallPartyHelper
      .translatePipe
      .transform(CallPartyHelper.dayOfYearTitleTextKey);

    callPartyName = CallPartyHelper.formatPipe.transform(
      callPartyName, dayOfYear);

    return callPartyName;
  }

  /**
   * Prepares the call party title for time and day of week setting, which is is used
   * in the list page.
   *
   * @param startTime the start time
   * @param endTime the end time
   * @param daysOfWeek the days of week
   */
  public static prepareTimeAndDayOfWeekCallPartyName(startTime: string,
    endTime: string, daysOfWeek: string[]): string {

    let callPartyName;
    const isAllDay = startTime === endTime;

    if (isAllDay) {

      // Choose a different text key for all day setting
      callPartyName = CallPartyHelper
        .translatePipe.transform(this.timeAndDayOfWeekAllDayTitleTextKey);

      callPartyName = CallPartyHelper.formatPipe.transform(
        callPartyName,
        CallPartyHelper.prepareDayOfWeekCallPartyName(daysOfWeek)
      );

    } else {

      callPartyName = CallPartyHelper.applyPlayTimeDetailsToTheTextKey(startTime,
        endTime, CallPartyHelper.timeAndDayOfWeekTimeDurationTitleTextKey, daysOfWeek);
    }

    return callPartyName;
  }

  /**
   * Adds time and day details to the text from provided by the text key.
   *
   * @param startTime the start time
   * @param endTime  the end time
   * @param textKey the text key to apply translation
   * @param daysOfWeek the days of week
   */
  public static applyPlayTimeDetailsToTheTextKey(startTime: string,
    endTime: string, textKey: string, daysOfWeek?: string[]) {

    const startTimeHour: string = startTime.substring(0, 2);
    const startTimeMinute: string = startTime.substring(2, 4);

    const endTimeHour: string = endTime.substring(0, 2);
    const endTimeMinute: string = endTime.substring(2, 4);

    let callPartyName = CallPartyHelper.translatePipe.transform(textKey);

    if (daysOfWeek) {
      callPartyName = CallPartyHelper.formatPipe.transform(
        callPartyName, startTimeHour, startTimeMinute, endTimeHour, endTimeMinute, this.prepareDayOfWeekCallPartyName(daysOfWeek));
    } else {
      callPartyName = CallPartyHelper.formatPipe.transform(
        callPartyName, startTimeHour, startTimeMinute, endTimeHour, endTimeMinute);
    }

    return callPartyName;
  }

}
