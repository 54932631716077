import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  Observable,
  Subscription,
  Subject,
  ObjectUnsubscribedError,
  startWith,
  switchMap,
  shareReplay,
  of,
} from 'rxjs';

import UrlBuilder from 'app/core/url-builder';
import { AuthService } from 'app/core/auth/auth.service';
import { BaseNetworkService } from 'app/core/network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import { Profile, ProfileStatus } from 'app/model';
import { ClientConfig, DeactivationMode } from 'app/core/config/client-config';
import log from 'app/core/logging/logger.service';
import { take, tap } from "rxjs/operators";


/**
 * Custom profile subject to handle profile data. The logic is almost
 * similar to {@code ReplaySubject} but we broadcast cached data only if
 * its available. Also used Subject functionality handle multiple subscription.
 * <p>
 * Check RxJs internal implementation code for {@code ReplaySubject}
 * @see <a href="https://github.com/ReactiveX/rxjs/blob/master/src/ReplaySubject.ts">ReplaySubject.ts</a>
 */
class ProfileSubject extends Subject<Profile> {

  /** Holds profile cache */
  private profile: Profile;

  /** Holds profile data expiry time */
  private expiryTime = 0;

  /** Profile service to emit profile data */
  profileService: ProfileService;

  private source$;

  constructor(profileService: ProfileService) {
    super();
    this.profileService = profileService;
  }


  getPublisher() {
    if (!this.source$) {
      this.source$ = of(this.profile).pipe(
        switchMap((profile) => {
          return profile
            ? of(profile)
            : this.profileService.fetchProfile();
        }),
        switchMap(profile => {
          return this.pipe(
            startWith(profile),
            tap(() => {
              if (!this.isProfileValid()) {
                this.profileService.emitProfileData();
              }
            })
          );
        }),
        shareReplay(1)
      );
    }

    return this.source$;
  }


  /** @inheritdoc */
  next(profile: Profile): void {
    this.profile = profile;
    this.setExpiryTime(profile);
    super.next(profile);
  }

  /** @inheritdoc */
  error(err: any): void {
    if (this.closed) {
      throw new ObjectUnsubscribedError();
    }

    // We don't want to store the error for future subscribers
    // Since we want it to recover

    // we do not stop the stream
    // we want to recover from errors

    const { observers } = this;
    const len = observers.length;
    const copy = observers.slice();
    for (let i = 0; i < len; i++) {
      copy[i].error(err);
    }

    // we do not remove the observers
  }

  /** Invalidates profile data */
  invalidate() {
    this.profile = null;
  }

  /** Sets the profile data expiry time */
  private setExpiryTime(profile: Profile) {

    const expireDuration = this.checkProfileIsInTransition(profile)
      ? ClientConfig.profileCacheExpirationTimeTransitional.getDurationInMs()
      : ClientConfig.profileCacheExpirationTime.getDurationInMs();

    log.info('Profile expiry duration: ' + expireDuration);

    this.expiryTime = Date.now() + expireDuration;
    log.info('Profile expiry time: ' + this.expiryTime);

  }

  /**
   * Determines whether the given profile is transitional, i.e.,
   * should be refreshed after a short amount of time
   * because it is likely to be updated asynchronously from outside the client.
   *
   * @param profile  a user profile
   * @return whether it is in a transitional state
   */
  private checkProfileIsInTransition(profile: Profile): boolean {

    // If we are in AWAITING_ACTIVATION, the status may change to ACTIVE (and also NEW) at any time
    return profile.status === ProfileStatus[ProfileStatus.AWAITING_ACTIVATION]
      // If immediate deactivation is used, then AWAITING_DEACTIVATION will only be visible
      // for a very short amount and we should use DEACTIVATED as soon as possible.
      || (profile.status === ProfileStatus[ProfileStatus.AWAITING_DEACTIVATION]
        && ClientConfig.subscriptionDeactivationMode.getEnum() === DeactivationMode.IMMEDIATE);
  }

  /**
   * Checks the cached profile is valid.
   */
  public isProfileValid() {
    // Checks the cached profile exists
    if (this.profile === null || this.profile === undefined) {
      return false;
    }

    if (this.expiryTime > Date.now()) {
      log.info('Profile not expired');
      return true;
    }

    return false;
  }
}


/**
 * Service to fetch the profile data. If there is no updates on user profile,
 * it always returns the observable of cached profile data. Also make sure that only
 * one request is on flight at all time.
 * <p/>
 *
 * @see <a href="https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/subjects/replaysubject.md">ReplaySubject</a>
 */
@Injectable()
export class ProfileService extends BaseNetworkService {

  /** Holds ongoing profile subscription */
  private profileSubscription: Subscription;

  /**
   * Create a reply subject with buffer size {@code 1}. This object
   * broadcasts each emission to all subscribed and future observers.
   */
  private subject: ProfileSubject;

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
    this.subject = new ProfileSubject(this);
  }

  /**
   * Emits the profile when it is ready. The emitted profile is cached by the subject.
   * <p/>
   * If the data is already available. The {@code ProfileSubject} will emit the existing profile data.
   * If a request is already on flight, the data will be emitted once the request is finished.
   */
  public emitProfileData() {

    if (!this.subject.isProfileValid()) {
      // If the request is on flight, all subscribers will
      // get notified when the request finishes
      if (!this.isProfileSubscriptionActive()) {
        log.info('Profile data is invalid sending request to fetch profile');
        this.fetchProfile().subscribe(profile => this.subject.next(profile));
      }
    }

  }

  fetchProfile() {
    return this.sendObservableRequest(
      UrlBuilder.getProfileUrl(),
      RequestMethod.Get
    ).pipe(
      take(1),
    );
  }

  /**
   * Checks an active profile fetch is ongoing.
   */
  private isProfileSubscriptionActive() {
    return this.profileSubscription !== undefined && !this.profileSubscription.closed;
  }

  /**
   * Returns the profile object Observable
   */
  getProfile(): Observable<Profile> {
    return this.subject.getPublisher();
  }

  /**
   * Invalidates the profile data
   */
  invalidateProfile() {
    this.subject.invalidate();

    // UnSubscribe the ongoing profile subscription if it exists.
    if (this.isProfileSubscriptionActive()) {
      log.info('Closing ongoing subscription.');
      this.profileSubscription.unsubscribe();
    }

    // Emit profile data only if a subscription is active
    log.info('Profile Observers count: ' + this.subject.observers.length);
    if (this.subject.observers.length > 0) {
      this.emitProfileData();
    }
  }

  logOut(): Observable<any> {
    log.log('logging out from profile service');
    this.subject.invalidate();
    return this.authService.logOut();
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }

  getPhoneNumberFromMsisdn(msisdn: string): string {
    return '+' + msisdn;
  }
}