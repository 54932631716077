import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseNetworkService } from 'app/core/network/base-network.service';
import { RequestMethod } from '../core/url-builder';
import { AuthService } from 'app/core/auth/auth.service';
import UrlBuilder from 'app/core/url-builder';
import { PurchaseTracking } from '../tracking/purchase.tracking';

import { ToneList, Tone, PurchaseOptions, Result } from 'app/model';

@Injectable()
export class ToneService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getRecommendedRbts(): Observable<ToneList> {
    const url = UrlBuilder.getRecommendedRbtsUrl();
    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  getRbtsForShop(id: string): Observable<ToneList> {
    const url = UrlBuilder.getRbtsForShopUrl(id);

    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  getToneById(id: string): Observable<Tone> {
    const url = UrlBuilder.getGetToneByIdUrl(id);

    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  getPurchaseOptions(id: string): Observable<PurchaseOptions> {
    const url = UrlBuilder.getGetPurchaseOptions(id);

    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  purchase(toneId: string, isCreditPurchase: boolean, setAsDefault: boolean, packageId?: string, purchaseSource?: string[],
    sourceInfo?: string): Observable<Result> {

    const url = UrlBuilder.getPurchaseUrl();
    const params = this.createURLSearchParams();

    params.append('rbtId', toneId);
    params.append('isCreditPurchase', isCreditPurchase.toString());
    params.append('setAsDefault', setAsDefault.toString());

    if (packageId) {
      params.append('packageId', packageId);
    }

    // ERBT-3371: Add purchase source tracking parameters to the backend call
    PurchaseTracking.addPurchaseSourceToRequest(params, purchaseSource);

    return this.sendObservableRequest(url, RequestMethod.Post, params);
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
