import { ProfileHelper } from 'app/core/helper/profile-helper';
import { Profile } from 'app/model';
import { ClientConfig } from 'app/core/config/client-config';
import { environment } from 'environments/environment';
import log from 'app/core/logging/logger.service';

export class ContactHelper {

  /** Default bg color  for contact tiles */
  private static defaultTileBgColor = '#02879E';

  /**
   * If phone number starts with +49172, remove + and start with 49172
   * · If p.n. starts with 0049172, remove 00 (number becomes 49172)
   * · If p.n. starts with 0172, remove 0, add country code (number becomes 49172)
   * · remove all white space and characters automatically ( *,+,- and so on)
   * · any number which does not start with 0 or have 00 or + should show an
   * error message as not being in the valid international format
   *
   * @param phoneNumber unformatted phone number
   * @param doOpcoMsisdnCheck decides whether to do opco format checks
   * @return formatted MSISDN number consisting only of numbers and starting with
   * country code unless original msisdn started with 00 or +
   */
  static msisdnCheckAndFormat(phoneNumber: string, doOpcoMsisdnCheck = false ): string {

    phoneNumber = phoneNumber || '';
    phoneNumber = phoneNumber.trim();
    if (phoneNumber === '') {
      return null;
    }

    let cleanMsisdn: string;
    const countryCode = ClientConfig.countryCode.getString();
    const trunkPrefix = ClientConfig.trunkPrefix.getString();

    const startsWithPlus = phoneNumber.trim().startsWith('+');
    phoneNumber = phoneNumber.replace(/\D/g, '');
    log.debug('Phone number after removing no digits', phoneNumber);

    if (!startsWithPlus) {
      if (phoneNumber.startsWith('00')) {
        // starts with 00 -> valid international number
        cleanMsisdn = phoneNumber.substring(2);
      } else if (trunkPrefix !== '') {
        // starts with trunk prefix -> valid local number
        if (phoneNumber.startsWith(trunkPrefix)) {
          phoneNumber = phoneNumber.substring(trunkPrefix.length);
          cleanMsisdn = countryCode + phoneNumber;
        } else {

          cleanMsisdn = doOpcoMsisdnCheck
            ? ContactHelper.doOpcoCheckAndReturnMsisdn(phoneNumber, countryCode)
            : null; // Not opco msisdn check and  phone number is without prefix
        }

      } else if (!phoneNumber.startsWith('0') && !phoneNumber.startsWith(countryCode)) {
        // no country code prefix -> we'll add it
        cleanMsisdn = countryCode + phoneNumber;
      } else {
        cleanMsisdn = phoneNumber;
      }
    } else {
      // Started with "+". Can be used directly as MSISDN.
      cleanMsisdn = phoneNumber;
    }

    //noinspection PointlessBooleanExpression,ConstantConditions
    log.info('msisdnCheckAndFormat(' + phoneNumber + ') = ' + cleanMsisdn);
    return cleanMsisdn;
  }

  /**
   * Checks phone number matches opco specific msisdn format.
   *
   * @param phoneNumber the phone number
   * @param countryCode the country code
   *
   * @return msisdn if matches the opco format else return {@code null}
   */
  static doOpcoCheckAndReturnMsisdn(phoneNumber: string, countryCode: string): string {

    const regexString = ClientConfig.opcoMsisdnFormat.getString();
    if (regexString === '') {
      return null;
    }

    const msisdnRegExp = new RegExp('^' + regexString + '$');

    if (msisdnRegExp.test(phoneNumber)) {
      return phoneNumber;
    }

    const msisdn = countryCode + phoneNumber;
    return msisdnRegExp.test(msisdn) ? msisdn : null;
  }

  static validateCaller(profile: Profile, callerMsisdn: string, callerName: string): string {

    let errorMessageId = ContactHelper.validCallerName(callerName);
    if (!errorMessageId) {
      if (!callerMsisdn) {
        errorMessageId = 'setting.caller.error.callerInvalid';
      } else if (ProfileHelper.checkCallerAlreadyExist(profile, callerMsisdn)) {
        errorMessageId = 'setting.caller.error.callerAlreadyExist';
      }
    }

    return errorMessageId;
  }

  /**
   * Gets random bg color for initial tiles in the client
   */
  static getTileColor(str: string): string {

    const colorArray = environment.opcoConfig.initialBgColors;
    if (colorArray.length > 0 && str.length > 0) {

      const hash = ContactHelper.getStringHash(str);
      // if hash returns number < 0, but I want an
      // index > 0 (avoid IndexOutOfBoundsException):
      const index = Math.abs(hash) % colorArray.length;
      return colorArray[index];

    }

    return ContactHelper.defaultTileBgColor;
  }

  /**
   * Returns a hash code for this string. The hash code for a
   * {@code string} object is computed as
   * <blockquote><pre>
   * s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
   * </pre></blockquote>
   * using {@code number} arithmetic, where {@code s[i]} is the
   * <i>i</i>th character of the string, {@code n}  is the length of
   * the string, and {@code ^} indicates exponentiation.
   * (The hash value of the empty string is zero.)
   * @param str the string
   *
   * @return  a hash code value for this object.
   */
  static getStringHash(str: string): number {

    let hash = 0;
    if (str.length > 0) {
      for (let i = 0; i < str.length; i++) {
        hash = 31 * hash + str.charCodeAt(i);
      }
    }

    return hash;
  }

  private static validCallerName(callerName: string): string {

    if (!ContactHelper.validateInputString(callerName)) {
      return 'setting.caller.error.callerNameInvalid';
    }

    if (callerName.length > ClientConfig.maxLengthOfCallerName.getNumber()) {
      return 'setting.caller.error.callerNameMaxCharacter';
    }

    return null;
  }

  private static validateInputString(inputString: string) {
    if (inputString === ''
      || inputString.indexOf('<') >= 0
      || inputString.indexOf('>') >= 0) {
      return false;
    }

    return true;
  }

  static validateNewGroupName(profile: Profile, groupName: string) {

    let errorMessageId = this.validateGroupName(groupName);
    if (!errorMessageId && ProfileHelper.findGroupFromProfile(profile, groupName)) {
      errorMessageId = 'setting.group.error.groupAlreadyExist';
    }

    return errorMessageId;
  }

  private static validateGroupName(groupName: string) {

    if (!ContactHelper.validateInputString(groupName)) {
      return 'setting.group.error.groupNameInvalid';
    }

    if (groupName.length > ClientConfig.maxLengthOfGroupName.getNumber()) {
      return 'setting.group.error.groupNameMaxCharacter';
    }

    return null;
  }

  static validateUgcName(ugcName: string) {

    if (!ContactHelper.validateInputString(ugcName)) {
      return 'setting.ugc.error.ugcNameInvalid';
    }  else {
      return null;
    }
  }
}
