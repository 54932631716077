<ul class="callerExperience">
  <ng-template ngFor let-setting [ngForOf]="availableSetting">
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/caller' *ngIf="setting === 'caller' " (click)="onClick($event)">
      {{ 'menu.submenu.callerExperience.individualCallers' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/default' *ngIf="setting === 'default' " (click)="onClick($event)">
      {{ 'menu.submenu.callerExperience.allCallers' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/group' *ngIf="setting === 'group' " (click)="onClick($event)">
      {{ 'menu.submenu.callerExperience.groups' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/dayOfWeek' *ngIf="setting === 'dayOfWeek' " (click)= "onClick($event)">  
      {{ 'menu.submenu.callerExperience.dayOfWeek' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/timeOfDay' *ngIf="setting === 'timeOfDay' " (click)= "onClick($event)">
      {{ 'menu.submenu.callerExperience.timeOfDay' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/timeAndDayOfWeek' *ngIf="setting === 'timeAndDayOfWeek' " (click)="onClick($event)">
      {{ 'menu.submenu.callerExperience.timeAndDayOfWeek' | trans }}
    </li>
    <li mat-ripple [matRippleDisabled]="disableRipple" routerLink='/setting/dayOfYear' *ngIf="setting === 'dayOfYear' " (click)="onClick($event)">
      {{ 'menu.submenu.callerExperience.dayOfYear' | trans }}
    </li>
  </ng-template>
</ul>
