import { Injectable } from '@angular/core';
import { HttpClient,  HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseNetworkService } from 'app/core/network/base-network.service';
import { AuthService } from 'app/core/auth/auth.service';
import UrlBuilder, { RequestMethod } from 'app/core/url-builder';
import { ToneList } from '../model/tone-list';
import { SuggestionList } from '../model/suggestion-list';
import CustomHttpParams from 'app/core/network/custom-http-params';

@Injectable()
export class SearchService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  private setPageParameters(params: CustomHttpParams, pageSize: number, page: number) {
    params.set('pageSize', pageSize.toString());
    params.set('page', page.toString());
  }

  searchForArtists(searchTerm: string, pageSize: number, page: number): Observable<ToneList> {
    const url = UrlBuilder.getSearchForArtistsUrl();
    const params = this.createURLSearchParams();
    params.set('artist', searchTerm);
    this.setPageParameters(params, pageSize, page);
    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  searchForTonesByTitle(searchTerm: string, pageSize: number, page: number): Observable<ToneList> {
    const url = UrlBuilder.getSearchForTonesByTitleUrl();
    const params = this.createURLSearchParams();
    params.set('title', searchTerm);
    this.setPageParameters(params, pageSize, page);
    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  searchForTonesByKeyword(searchTerm: string, pageSize: number, page: number): Observable<ToneList> {
    const url = UrlBuilder.getSearchForTonesByKeywordUrl();
    const params = this.createURLSearchParams();
    params.set('keyword', searchTerm);
    this.setPageParameters(params, pageSize, page);
    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  getTonesForArtist(artistName: string, pageSize: number, page: number): Observable<ToneList> {
    const url = UrlBuilder.getTonesForArtistUrl();
    const params = this.createURLSearchParams();
    params.set('artistName', artistName);
    this.setPageParameters(params, pageSize, page);
    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  getSearchSuggestion(keyword: string): Observable<SuggestionList> {
    const url = UrlBuilder.getSearchSuggestionUrl();
    const params = this.createURLSearchParams();
    params.set('input', keyword);
    params.set('maxSize', '4');
    return this.sendObservableRequest(url, RequestMethod.Get, params);
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
