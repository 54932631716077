/**
 * Contains app related helper methods
 */
import { environment } from 'environments/environment';
import { ConsentManagementService } from './consent-management.service';

export enum RequestMethod {
  Get,
  Post
};

export default class UrlBuilder {

  /**
   * Returns the base URL of API Plus (without trailing slash).
   */
  static getBaseUrl(): string {
    return environment.baseUrl;
  }

  static getTextConfigUrl(): string {
    return environment.baseUrl + '/config/client/text/htmlPlus';
  }

  static getClientConfigUrl(): string {
    return environment.baseUrl + '/config/client/htmlPlus';
  }

  static getPinCodeUrl(): string {
    return environment.baseUrl + '/oauth/sendPin';
  }

  static getOauthTokenRequestUrl(): string {
    return environment.baseUrl + '/oauth/token';
  }

  static getRevokeTokenUrl(): string {
    return environment.baseUrl + '/oauth/revoke';
  }
  static getProfileUrl(): string {
    return environment.baseUrl + '/authenticated/settings/profile';
  }

  static getShopsUrl(): string {
    return environment.baseUrl + '/content/shops/list';
  }

  static getRecommendedRbtsUrl(): string {
    return environment.baseUrl + '/content/rbt/recommended';
  }

  static getRbtsForShopUrl(id: string): string {
    return environment.baseUrl + '/content/rbt/shop/' + id;
  }

  static getUploadUgcUrl(): string {
    return environment.baseUrl + '/authenticated/ugc/upload';
  }

  static getSharedUgcUrl(token: string): string {
    return environment.baseUrl + '/ugc/sharedUgc/' + token;
  }

  static getUgcByNameBlockingUrl(): string {
     return environment.baseUrl + '/authenticated/ugc/getBlocking';
  }

  static getDeleteUgcUrl(): string {
    return environment.baseUrl + '/authenticated/ugc/delete';
  }

  static getDeleteRbtUrl(): string {
    return environment.baseUrl + '/authenticated/settings/rbt/delete';
  }

  static getSetHibernateUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/hibernate';
  }

  static getSetCapaUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/capa';
  }

  static getSubscribeUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/subscribe';
  }

  static getUnsubscribeUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/unsubscribe';
  }

  static getReactivateUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/reactivate';
  }

  static getOptInUrl(): string {
    return environment.baseUrl + '/authenticated/subscription/optin';
  }

  static getGetToneByIdUrl(id: string): string {
    return environment.baseUrl + '/content/rbt/product/' + id;
  }

  static getGetPurchaseOptions(id: string): string {
    return environment.baseUrl + '/authenticated/getPurchaseOptions?rbtId=' + id;
  }

  static getPurchaseUrl(): string {
    return environment.baseUrl + '/authenticated/purchase';
  }


  static getSearchForArtistsUrl(): string {
    return environment.baseUrl + '/content/artist/search';
  }

  static getSearchForTonesByKeywordUrl(): string {
    return environment.baseUrl + '/content/rbt/search/keyword';
  }

  static getSearchForTonesByTitleUrl(): string {
    return environment.baseUrl + '/content/rbt/search/title';
  }

  static getSearchSuggestionUrl(): string {
    return environment.baseUrl + '/content/searchSuggestion';
  }

  static getTonesForArtistUrl(): string {
    return environment.baseUrl + '/content/rbt/artist';
  }

  static getSettingUrl(): string {
    return environment.baseUrl + '/authenticated/settings/{type}/set';
  }

  static getSettingDeleteUrl(): string {
    return environment.baseUrl + '/authenticated/settings/{type}/delete';
  }

  static getDeleteCallerFromGroupUrl() {
    return environment.baseUrl + '/authenticated/settings/group/deleteMember';
  }

  static getAddCallerToGroupUrl() {
    return environment.baseUrl + '/authenticated/settings/group/addMember';
  }

  static getPromotionByIdUrl(id: string): string {
    return environment.baseUrl + '/promotions/promotion/' + id;
  }

  static getPuschelUnregisteredUserUrl(): string {
    return environment.baseUrl + '/oauth/appInstall/logEvent';
  }

  static getPuschelRegisteredUserUrl(): string {
    return environment.baseUrl + '/authenticated/appInstall/logEvent';
  }

  static getSubscriptionAndContentPackagesUrl(): string {
    return environment.baseUrl + '/authenticated/bookedPackages/list?type=all';
  }

  static getContentPackagesUrl(): string {
    return environment.baseUrl + '/authenticated/bookedPackages/list?type=content';
  }

  static getUnsubscribePackageUrl(): string {
    return environment.baseUrl + '/authenticated/bookedPackages/cancel';
  }

  /**
   * This method opens an external or internal URL for a given click event.
   * In some cases we have to pass links from text tool
   * for internal app navigation, if we use direct url pattern
   * it will reload the app and show the splash screen. In order
   * to avoid that we have to catch the click event and redirect them
   * internal, here it disables the click propagation and opens the URL.
   * 
   * @param event the click event of the parent dom
   * @param router the router for redirecting the user to an internal page.
   */
  static openExternalOrInternalUrlFromClickEvent(event, router) {

    event.preventDefault();
    event.stopPropagation();

    let url = event.target.getAttribute('href') || '';
    url = url.trim();

    if (!url) {
      // Empty URL, do nothing
      return;
    }

    const httpRegExp = new RegExp('^https?://');

    if (httpRegExp.test(url)) {
      // ERBT-5782: Open external links in a new tab
      window.open(url, '_blank');
    } else if (url === 'internal:cookie-popup') {
      // ERBT-5910: Open consent management dialog
      ConsentManagementService.openConsentManagementDialog();
    } else if (url.startsWith('/')) {
      // Navigate to internal page
      router.navigate([url]);
    }
  }

  /** Returns true if the URL passed by parameter should include client authentication in the request  */
  static isClientAuthRequired(url: string): boolean {
    return url.startsWith(this.getBaseUrl() + '/oauth/');
  }
}
