<mat-list-item mat-ripple>
  <div
      mat-line
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="8px">

      <div
        fxFlex="1 1 auto"
        fxLayout="column">
        <span class="artist-img"></span>
      </div>

    <div
        class="middle"
        fxFlex="1 1 45%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="3px">
      <p class="artist-img">{{tone.artist}}</p>
    </div>
    <div
        class="end"
        fxFlex="grow"
        fxLayout="column"
        fxLayoutAlign="center end"
        fxLayoutGap="5px">
    </div>
  </div>
</mat-list-item>
