
// The browser platform with a compiler
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
// Required by @ngu/carousel
import 'hammerjs';
import { environment } from './environments/environment';
// The app module
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

// Compile and launch the module
platformBrowserDynamic().bootstrapModule(AppModule);
