import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

/**
 * Directive for accordion
 */
@Directive({
  selector: '[appAccordion]'
})
export class AccordionDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event.target']) onClick(target: HTMLElement) {
    if (target.closest('.faq-title') || target.closest('.question-title') || target.closest('.arrow')) {
      const hostElem = this.el.nativeElement;

      // Get the parent element of the host element
      const parentElem = hostElem.parentElement;

      if (parentElem) {
        // Get all children of the parent element
        const children = Array.from(parentElem.children);

        // Check if the clicked element is already open
        const isOpen = hostElem.classList.contains('open');

        // Toggle the open class for the clicked element
        if (!isOpen) {
          this.renderer.removeClass(hostElem, 'hidden');
          this.renderer.addClass(hostElem, 'open');
        } else {
          this.renderer.removeClass(hostElem, 'open');
          this.renderer.addClass(hostElem, 'hidden');
        }

        // Loop through all children except the clicked element
        children.forEach(child => {
          if (child !== hostElem) {
            // Toggle the hidden class for other elements
            this.renderer.removeClass(child, 'open');
            this.renderer.addClass(child, 'hidden');
          }
        });
      }
    }
  }
}
