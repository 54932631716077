import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { ClientConfig, Tiles } from 'app/core/config/client-config';
import { ExternalRouting } from 'app/core/external.routing';
import log from 'app/core/logging/logger.service';
import { ProfileService } from 'app/core/profile.service';
import { ConfigurationTiles, Options, ProfileStatus } from 'app/model';
import { BaseViewComponent } from 'app/shared/base-view.component';
import { DialogService } from 'app/shared/dialog/dialog.service';
import { PromoService } from 'app/shared/promotion/promo.service';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { BrowserService } from '../core/browser.service';

@Component({
  templateUrl: './home.component.html'
})
export class HomeComponent extends BaseViewComponent implements OnInit {

  private readonly storageKeyLandingPromo = 'landingPromo';

  columns: number;
  public configTiles: ConfigurationTiles;
  public mobilePromoConfigs: string[];
  public webPromoConfigs: string[];
  public bannerPromoConfig: string;
  public bannerPromoMobileConfig: string;
  public topPromo: string;
  public bottomPromo: string;
  public homeSliderInterval: number;
  public skin: string;
  public browserServ: BrowserService;
  showSuspendedSlide = false;
  userAgent: string;
  PurchaseSources = PurchaseSources;
  public popupTexts = { title: '', content: '', accept: '', cancel: '', eventAccept: '', eventCancel: '' };

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private promotionService: PromoService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private browserService: BrowserService,
    zone: NgZone,
    router: Router) {
    super(zone, router);
    this.configTiles = new ConfigurationTiles();
  }

  ngOnInit() {
    this.browserServ = this.browserService;
    const onboardPromoName = ClientConfig.onboardingQuickPromotionName.getString();
    let lastOnboardPromoName;

    try {
      lastOnboardPromoName = localStorage.getItem(this.storageKeyLandingPromo);
      if (!lastOnboardPromoName && onboardPromoName) {
        if (this.authService.isLoggedIn()) {
          this.subscriptions.push(
            this.profileService.getProfile().subscribe(profile => {
              if (profile.status === ProfileStatus[ProfileStatus.NEW]) {
                this.redirectToLandingPromo(onboardPromoName);
              } else {
                this.setHomePage();
              }
            })
          );
        } else {
          this.redirectToLandingPromo(onboardPromoName);
        }
      } else {
        this.setHomePage();
      }
    } catch (error) {
      log.warn('Access to local storage is not allowed', error);
      this.setHomePage();
    }
  }

  private redirectToLandingPromo(onboardPromoName: string) {
    ExternalRouting.navigate(this.router, ['/landingpromo'], true);
  }

  private setHomePage() {

    if (this.authService.isLoggedIn()) {
      this.subscriptions.push(
        this.profileService.getProfile().subscribe(profile => {
          this.showSuspendedSlide = profile.status === ProfileStatus[ProfileStatus.SUSPENDED];
        })
      );
    }

    // Top banner web
    this.bannerPromoConfig = ClientConfig.homeBanner.getString();
      
    // Top banner mobile web
    this.bannerPromoMobileConfig = ClientConfig.homeBannerMobile.getString();
    
    // redesigned Promotion Section
    this.topPromo = ClientConfig.homeTopPromo.getString();

    // Dynamic Promotion Bottom Section  
    this.bottomPromo = ClientConfig.homeBottomPromo.getString();

    // set top app button tiles
    this.configTiles.configurations = this.setConfiguration(this.configTiles.configurations);

    // set upt carousel transition timer
    this.homeSliderInterval = ClientConfig.homeSliderInterval.getDurationInMs();

    // Mobile promo tiles
    this.mobilePromoConfigs = ClientConfig.homeMobilePromo.getStringArray();

    // Web promo slides
    this.webPromoConfigs = ClientConfig.homeWebPromo.getStringArray();

    // Set the skin to show on the home screen
    this.skin = ClientConfig.skin.getString();

    // Promotion popup to show in the home page
    let promotionPopup;

    this.activatedRoute.queryParams.subscribe(params => {
      const activation = params['activation'];
      if (activation === 'success') {
        promotionPopup = ClientConfig.homePromoPopupAfterSubscription.getString();
        this.setPopupTexts(true);
      }

      // In case that there is not promotion after subscription we try to show the regular popup
      if (!promotionPopup) {
        promotionPopup = ClientConfig.homePromoPopup.getString();
        this.setPopupTexts(false);
      }
    });

    if (promotionPopup) {
      this.showContentPromoPopup(promotionPopup);
    }
  }

  private setPopupTexts(isSubscriptionPromotionPopup: boolean): void {
    log.info('Setting popup texts: ' + isSubscriptionPromotionPopup);
    if (isSubscriptionPromotionPopup) {
      this.popupTexts.title = 'home.promo.afterSubscription.title';
      this.popupTexts.content = 'home.promo.afterSubscription.content';
      this.popupTexts.accept = 'home.promo.afterSubscription.accept.button';
      this.popupTexts.cancel = 'home.promo.afterSubscription.cancel.button';
      this.popupTexts.eventAccept = 'home.content.popupAfterSubscription.accept';
      this.popupTexts.eventCancel = 'home.content.popupAfterSubscription.reject';
    } else {
      this.popupTexts.title = 'home.promo.popup.title';
      this.popupTexts.content = 'home.promo.popup.content';
      this.popupTexts.accept = 'home.promo.popup.accept.button';
      this.popupTexts.cancel = 'home.promo.popup.cancel.button';
      this.popupTexts.eventAccept = 'home.content.popup.accept';
      this.popupTexts.eventCancel = 'home.content.popup.reject';
    }
  }


  private setConfiguration(tilesConfiguration): Options[] {
    tilesConfiguration = [];

    const tiles = ClientConfig.homeTiles.getEnumArray();
    const configTiles = this.getConfigTiles();

    for (const tile of tiles) {
      if (configTiles[tile] !== undefined) {
        tilesConfiguration.push(
          new Options(
            configTiles[tile][0],
            configTiles[tile][1],
            configTiles[tile][2])
        );
      }
    }

    return tilesConfiguration;
  }

  getTileSize(index: number): TileSize {
    const initialSizes = [
      { width: 8, height: 8 },
      { width: 4, height: 4 },
      { width: 4, height: 4 },
      { width: 4, height: 4 },
      { width: 4, height: 4 },
      { width: 4, height: 4 }
    ];

    const size = { width: 4, height: 4 };

    if (index < initialSizes.length) {
      return initialSizes[index];
    } else {
      return size;
    }
  }

  getConfigTiles() {
    const option = [];
    option[Tiles.CALLER_LIST] = ['home.configurations.individualCallers', 'caller-list', '/setting/caller'];
    option[Tiles.DEFAULT_SETTING] = ['home.configurations.allCallers', 'all-caller', '/setting/default'];
    option[Tiles.GROUP_LIST] = ['home.configurations.groups', 'group-list', '/setting/group'];
    option[Tiles.RECORD_UGC] = ['home.configurations.recordNewMessage', 'record-ugc', '/library/ugc/record'];
    option[Tiles.RBT_LIBRARY] = ['home.configurations.myRBTs', 'library', '/library/0'];
    option[Tiles.DAY_OF_WEEK_LIST] = ['home.configurations.moreSettings', 'more-setting', '/setting/dayOfWeek'];
    return option;
  }

  /**
   * Creates the content promo popup (https://jira.real.com/browse/ERBT-3558)
   * if it has not been alredy shown previously
   */
  private showContentPromoPopup(promoName: string) {
    const storageKeyPromoPpup = 'lastContentPromoPopupName';

    // check the history, avoid loading the popup if we already showed it
    let lastPromoShown: string = null;
    try {
      lastPromoShown = localStorage.getItem(storageKeyPromoPpup);
      if (lastPromoShown === promoName) {
        log.info('Popup ' + promoName + ' already shown before, skipping it.');
        return;
      }
    } catch (error) {
      // if we can't access the local storage (browser setting), we show the popup
      log.warn('Access to local storage is not allowed', error);
    }

    // use the promo service to load the promo from the server
    log.debug('Loading promotion with name ' + promoName + ' from server');
    this.promotionService.getPromotionById(promoName)
      .subscribe(promotion => {
        // once finished, get the promo compatible and display it in a dialog
        const promoItem = this.promotionService.getPromotionCompatibleWithDevice(promotion);
        if (promoItem !== null) {
          this.dialogService.contentPromo(this.popupTexts.title, this.popupTexts.content,
          promoItem, this.popupTexts.accept, this.popupTexts.cancel,
          this.popupTexts.eventAccept, this.popupTexts.eventCancel)
          .subscribe(action => {
            if (action) {
              // when the user accepts, forward to the link from the promo
              ExternalRouting.navigateExternalUrl(this.router, promoItem.targetUrl,
                  true, PurchaseSources.PromotionPopup, promoName);
            }
          });
          try {
            // save the fact that we showed this popup
            localStorage.setItem(storageKeyPromoPpup, promoName);
          } catch (error) {
            // if we can't access the local storage (browser setting),
            // we'll just have to show the popup again next time
            log.warn('Access to local storage is not allowed', error);
          }
        }
      });
  }

}

class TileSize {
  width: number;
  height: number;
}
