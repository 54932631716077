import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  NgZone,
  OnDestroy,
  Output,
  EventEmitter
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Shop } from "../../model";
import { ShopService } from "../../shop/shop.service";
import { ShopList } from "../../model/shop-list";
import { ClientConfig } from "../../core/config/client-config";
import { BaseCarouselComponent } from './base-carousel.component';
import { Subscription } from "rxjs";

@Component({
  selector: "app-shop-carousel",
  templateUrl: "./shop-carousel.component.html",
})
export class ShopCarouselComponent
  extends BaseCarouselComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public shops: Shop[];
  private shopSubscription: Subscription;
  private isOnShopPage: boolean = false;


  @Output() shopClicked: EventEmitter<Shop> = new EventEmitter<Shop>();

  @ViewChild("scroller", { static: true }) tabGroup: ElementRef;

  constructor(
    private shopService: ShopService,
    private route: ActivatedRoute,
    router: Router,
    protected zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    super(zone, router);
    if (!this.shopId) {
      this.shopId = ClientConfig.newSongsShopId.getString();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    // Check if the current route is '/shop'
    this.isOnShopPage = this.router.url.startsWith('/shop');
    this.fetchShops();
  }

  fetchShops(): void {
    this.shopSubscription = this.shopService.getShops()
      .pipe(take(1)) // Unsubscribes after the first emission
      .subscribe((shopList: ShopList) => {
        if (shopList.shop.length < this.maxItems) {
          this.shops = shopList.shop;
        } else {
          this.shops = shopList.shop.slice(0, this.maxItems);
        }
        this.zone.run(() => this.cdr.detectChanges());
      });
  }

  clicked(shop: Shop): void {
    if (this.isOnShopPage) {
      // Emit the event to the parent component
      this.shopClicked.emit(shop);
    } else {
      // Default behavior: navigate to the shop URL
      this.navigateOnClick(
        this.router,
        [`/shop?id=${shop.id}`],
        true,
        this.purchaseSource as string,
        this.shopId
      );
    }
  }

  ngOnDestroy() {
    // Clean up the subscription when the component is destroyed
    if (this.shopSubscription) {
      this.shopSubscription.unsubscribe();
    }
  }
}
