import { OnInit, Component, NgZone } from '@angular/core';
import { BaseViewComponent } from 'app/shared/base-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientConfig } from 'app/core/config/client-config';
import { ProfileStatus, Tone } from 'app/model';
import { ToneService } from 'app/tone/tone.service';
import { PromoItem, Promotions } from 'app/model/promotions';
import { PromoService } from 'app/shared/promotion/promo.service';
import log from 'app/core/logging/logger.service';
import { PurchaseSources } from 'app/tracking/purchase.sources';
import { PurchaseSourcesInfo } from 'app/tracking/purchase.sources.info';
import { ExternalRouting } from 'app/core/external.routing';
import { GtagService, GtagEvents } from 'app/core/gtag.service';
import { SubscriptionService } from 'app/core/subscription.service';
import { AuthService } from 'app/core/auth/auth.service';
import { ProfileService } from 'app/core/profile.service';

export enum LandingPromoType {
    QUICK = "quick",
    HAPPY = "happy",
    // ERBT-6475: Magenta Moments Landing Promo & Opt-In pages
    MAGENTA_MOMENTS = "magentaMoments",
    MAGENTA_MOMENTS_OPTIN = "magentaMomentsOptIn",
    // ERBT-7247: Young Landing Promo
    YOUNG = "young",
    // ERBT-7290: Black Friday Landing Promo
    MAGENTA_BLACK_DAYS = "magentaBlackDays"
  }

@Component({
    templateUrl: './landing-promo.component.html'
})

export class LandingPromoComponent extends BaseViewComponent implements OnInit {
    private readonly storageKeyLandingPromo = 'landingPromo';
    public promoItem: PromoItem;
    public tones: Tone[];
    public imageSize: number;
    public promoName: string;
    public showSmsCode: boolean;
    public showSkipButton: boolean;
    public skipButtonText: string;
    private promoType: LandingPromoType;

    constructor(zone: NgZone, router: Router, private toneService: ToneService,
         private promotionService: PromoService, private route: ActivatedRoute,
         private subscriptionService: SubscriptionService,
         private profileService: ProfileService,
         private authService: AuthService) {
        super(zone, router);
    }

    ngOnInit() {
        this.determineLandingPromotion();

        if (!this.promoName) {
            this.redirectToHome();
        } else if (this.promoType == LandingPromoType.HAPPY 
                && this.authService.isLoggedIn()) {
                this.showLoading();
                this.subscriptions.push(
                  this.profileService.getProfile().subscribe(profile => {
                    let isNotSubscribed = [ProfileStatus.NEW, ProfileStatus.DEACTIVATED]
                    .some(item => item === ProfileStatus[profile.status]);
                    // Skip happy landing page if the user is already subscribed.
                    if (isNotSubscribed) {
                        this.setLandingPromo(this.promoName, this.promoType);
                    } else {
                        this.redirectToHome();
                    }
                  })
                );
        } else {
            this.setLandingPromo(this.promoName, this.promoType);
        }
    }

    determineLandingPromotion(): void {
        if (this.router.url.indexOf(LandingPromoType.HAPPY) >= 0) {
          this.promoType = LandingPromoType.HAPPY;
          this.promoName = ClientConfig.landingHappyPromotionName.getString();
        } else {
          this.promoType = LandingPromoType.QUICK;
          this.promoName = ClientConfig.onboardingQuickPromotionName.getString();
        }
    }

    private setLandingPromo(promoName: string, promoType: string): void {
        this.showLoading();

        switch(promoType) {
            case LandingPromoType.HAPPY:
                this.subscriptionService.setHappyPromoSubscriptionEntitled(true);
                this.skipButtonText = null;
                this.showSmsCode = false;
                break;
            default:
                this.subscriptionService.setHappyPromoSubscriptionEntitled(false);
                this.skipButtonText = 'landingPromo.page.skip';
                this.showSmsCode = true;
        }

        if (this.newLayout) {
            this.imageSize = this.isMobile ? 80 : 100;
        } else {
            this.imageSize = this.isMobile ? 60 : 90;
        }

        this.promotionService.getPromotionById(promoName)
        .subscribe((promotions: Promotions) => {
            this.promoItem = this.getPromotionWithShopId(promotions);
            if (this.promoItem !== undefined) {
                const promoShopId = this.parsePromoShopId(this.promoItem.targetUrl);
                this.toneService.getRbtsForShop(promoShopId).subscribe(toneList => {
                    this.tones = toneList.tone;
                    this.hideLoading();
                }, error => this.handleError(error));
            } else {
                log.info('The shop ID configured for the promotion: ' + promoName + ' is not valid. Redirecting to home');
                this.redirectToHome();
            }

        }, error => this.handleError(error));
    }

    private getPromotionWithShopId(promotions: Promotions): PromoItem {
        for (let i = 0; i < promotions.item.length; i++) {
            const promoItem = promotions.item[i];
            const promoShopId = this.parsePromoShopId(promoItem.targetUrl);
            if (promoShopId !== null) {
              return promoItem;
            }
        }
    }

    private parsePromoShopId(targetUrl: string): string {
        const shopIdMatcher = /.*\/shop\/id\/(\d+)(\?.*)?/.exec(targetUrl);
        return shopIdMatcher == null
            ? null
            : shopIdMatcher[1];
    }

    redirectToRBT(toneId: string) {
        this.saveOnboardPromoInLocalStorage();
        GtagService.getInstance().trackEvent(GtagEvents.TRACK_SELECTION);
        ExternalRouting.navigate(this.router, ['/rbt/id', toneId], true, this.recommendedPurchaseSource, this.recommendedPurchaseSourceInfo);
    }

    redirectToLogin(toneId: string) {
        this.saveOnboardPromoInLocalStorage();
        GtagService.getInstance().trackEvent(GtagEvents.TRACK_SELECTION);
        ExternalRouting.navigate(this.router, ['/login', { landingPromoToneId: toneId, landingPromoType: this.promoType }],
            true, this.recommendedPurchaseSource, this.recommendedPurchaseSourceInfo);
    }

    redirectToHome() {
        this.router.navigate(['/home']);
    }

    handleSkipButton() {
        this.saveOnboardPromoInLocalStorage();
        GtagService.getInstance().trackEvent(GtagEvents.SKIP_BUTTON);
        const onboardingQuickSkipLink = ClientConfig.onboardingQuickSkipLink.getString();

        // ERBT-5462: After skipping landing page we want to keep the 
        // initial purchase source info that is originated from a direct link 
        // and use it during user subscription that might take place at some later point.
        this.route.queryParams.subscribe(queryParams => {
            this.subscriptionService.setPurchaseSourcesFromDeepLink(queryParams);
        });

        this.router.navigate([onboardingQuickSkipLink]);
    }

    trackGtagPrelisten(tone: Tone) {
        log.info('Prelistening tone:', tone);
        GtagService.getInstance().trackEvent(GtagEvents.PRELISTEN);
    }

    private saveOnboardPromoInLocalStorage() {
        if (LandingPromoType.QUICK === this.promoType) {
            try {
                localStorage.setItem(this.storageKeyLandingPromo, this.promoName);
            } catch (error) {
                log.warn('Access to local storage is not allowed', error);
            }
        }
    }

    get recommendedPurchaseSource(): string {
        return PurchaseSources.Subscription;
    }

    get recommendedPurchaseSourceInfo(): string {
        switch(this.promoType) {
            case LandingPromoType.HAPPY:
                return PurchaseSourcesInfo.LandingHappyFlow;
            default:
                return PurchaseSourcesInfo.OnboardingQuickFlow;
        }
    }
}
