<div class="carousel-slider-tones">
    <div class="carousel-title">{{ title }}</div>
      <!-- Render shop list -->
      <ng-container *ngIf="promotionItems && promotionItems.length > 0">
        <ngu-carousel #Carousel [inputs]="carouselConfig" [dataSource]="promotionItems" >
          <ng-template nguCarouselDef let-promotion let-i="index">
            <div class="item" [attr.data-test-id]="'carousel-shop-item-' + i">
              <div class="tile tone-image-holder">
                <div (click)="clicked(promotion)" class="fallback-img tone-scroll-img">
                  <img [src]="promotion.artwork | image:270:270" />
                </div>
              </div>
              <div class="shop-title">{{ promotion.title }}</div>
            </div>
          </ng-template>
          <ul class="myPoint" NguCarouselPoint>
            <li *ngFor="let j of Carousel.pointNumbers; let i = index" [class.active]="i == Carousel.activePoint"
              (click)="Carousel.moveTo(j)"></li>
          </ul>
        </ngu-carousel>
      </ng-container>
      <button *ngIf="Carousel && Carousel.pointNumbers && Carousel.pointNumbers.length > 1" (click)="next()" class="rightRs"
        [ngClass]="{
          'disabled': Carousel.activePoint == (Carousel.pointNumbers.length - 1),
          'hidden': isMobile
        }"></button>
      <button *ngIf="Carousel && Carousel.pointNumbers && Carousel.pointNumbers.length > 1" (click)="prev()" class="leftRs"
        [ngClass]="{
          'disabled': Carousel.activePoint == 0,
          'hidden': isMobile
        }"></button>
  </div>
  