<h1 class="main-heading faq-heading">{{ title | trans }}</h1>
<ul class="faq">
    <li *ngFor="let question of faqs; let idx = index" appAccordion class="hidden" [attr.data-test-id]="'faq-item-' + idx">
      <h1 mat-line class="faq-title"> 
        <span class="question-title">{{ question.title | trans }}</span>
        <span class="arrow"></span>
      </h1>
      <p mat-line class="faq-content" [innerHTML]="question.content | trans"></p>
    </li>
  </ul>
<div class="faq-link">
    <a *ngIf="isCustomList" routerLink="/faq" data-test-id="help-page-link">{{ 'home.page.faq.link.text' | trans }}</a>
</div>
