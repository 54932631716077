import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { environment } from 'environments/environment';

import log from 'app/core/logging/logger.service';

/**
 * Pipe to format the text with values.
 * <p/>
 * Format in template: {@code {{ 'text.key' | trans | format:['value1', 'value2'] }} }
 * Sample text with placeholder example, {@code 'Text {0}' }, {@code 'Text {0} Date: {1,date,MMddyyyy}'}
 */
@Pipe({ name: 'format' })
export class FormatPipe implements PipeTransform {

  private datePipe: DatePipe;
  private decimalPipe: DecimalPipe;

  constructor() { }

  getDatePipe(): DatePipe {
    if (this.datePipe === undefined) {
      this.datePipe = new DatePipe(environment.locale);
    }

    return this.datePipe;
  }

  getDecimalPipe(): DecimalPipe {
    if (this.decimalPipe === undefined) {
      this.decimalPipe = new DecimalPipe(environment.locale);
    }

    return this.decimalPipe;
  }

  transform(text: string, ...args: any[]) {

    if (!text) {
      return;
    }

    return text.replace(/{(\d+)(?:,(.*),(.*))?}/g,
      (match: string, index: string, option: string, format: string) => {

        // Pick the argument with the given index
        const value = args[Number(index.trim())];

        // If there is no such argument, then there is nothing we can do.
        // Return the whole placeholder instead.
        if (value === undefined) {
          log.error('Argument missing for substitution: ' + match + ' Text: ' + text + ' Args: ' + args);
          return match;
        }

        // Try to format the value
        if (option !== undefined && format !== undefined) {
          try {
            return this.getFormattedValue(option.trim(), format.trim(), value);
          } catch (error) {
            // TODO report this to the server.
            log.error(error);
          }
        }

        // If there is no format or if formatting fails,
        // then return the unformatted value.
        // This also improves the behavior in bug ERBT-3799.
        return value;
      });
  }

 /**
  * Formats the placeholder based options.
  * </p>
  * Currently support date and number format
  * Syntax date:  {0,date,MMddyyyy}
  * Syntax number:  {1,number,3.1-5}
  *
  * @param option the option (date|number)
  * @param format the (date|decimal) format (eg: MMddyyyy)
  * @param value the value to format
  **/
  getFormattedValue(option: string, format: string, value: any) {
    let pipeTransform;
    switch (option) {
      case 'date':
        pipeTransform = this.getDatePipe();
        break;
      case 'number':
        pipeTransform = this.getDecimalPipe();
        break;
      default:
        throw new SyntaxError('Invalid format type ' + option);
    }
    return pipeTransform.transform(value, format);
  }
}
