import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-setting-menu',
  templateUrl: './setting-menu.component.html',
})
export class SettingMenuComponent {

  @Input()
  public disableRipple = false;

  @Output()
  onItemClicked: EventEmitter<string> = new EventEmitter();

  availableSetting: string[];

  constructor() {
    this.availableSetting = environment.opcoConfig.availableSettings;
  }

  onClick(event) {
    // Communicate click event to the parent component
    this.onItemClicked.emit(event);
  }
}
