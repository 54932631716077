import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseNetworkService } from '../core/network/base-network.service';
import { AuthService } from '../core/auth/auth.service';
import { ToneList, Tone, Result } from '../model';
import { ClientConfig } from '../core/config/client-config';
import UrlBuilder, { RequestMethod } from '../core/url-builder';

@Injectable()
export class LibraryService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getIgtTones(): Observable<ToneList> {
    const url = UrlBuilder.getRbtsForShopUrl(ClientConfig.igtShopId.getString());
    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  uploadContent(title: string, body: any): Observable<Result> {
    const params = this.createURLSearchParams();
    params.append('greetingName', title);

    return this.sendObservableRequest(
      UrlBuilder.getUploadUgcUrl(),
      RequestMethod.Post,
      params,
      body
    );
  }

  /**
   * Gets the Observable for the blocking request.
   *
   * @param ugcName {string} the ugc name
   */
  getBlockingRequest(ugcName: string): Observable<Tone> {
    const params = this.createURLSearchParams();
    params.append('greetingName', ugcName);

    return this.sendObservableRequest(
      UrlBuilder.getUgcByNameBlockingUrl(),
      RequestMethod.Get,
      params
    );
  }


  deleteUgc(ugcId: string): Observable<Result> {
    const params = this.createURLSearchParams();
    params.append('igtId', ugcId);

    return this.sendObservableRequest(
      UrlBuilder.getDeleteUgcUrl(),
      RequestMethod.Post,
      params
    );
  }

  deleteRbt(rbtId: string): Observable<Result> {
    const params = this.createURLSearchParams();
    params.append('rbtId', rbtId);

    return this.sendObservableRequest(
      UrlBuilder.getDeleteRbtUrl(),
      RequestMethod.Post,
      params
    );
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
