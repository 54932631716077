import { OfferedPackage } from './offered-package';

export class PurchaseOptions {
    toneId: string;

    price?: number;

    /* If set, the tone can be purchased by consuming one of the available credits. */
    availableCredits?: number;

	/* A list of packages which can be bought. The RBT is automatically bought with the first credit from the package. */
    offeredPackage: OfferedPackage[];
}

