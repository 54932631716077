<mat-list-item [attr.data-test-id]="'igt-list-item'" [attr.mat-ripple]="ripple" [class.recording]="recording"
[class.selected]="selected" [class.tone-selection]="select">
  <div mat-line fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div>
      <app-play-button
        [tones]="[tone]"
        [preLoad]="recording"
        (onLoad)="updateDuration($event)">
      </app-play-button>
    </div>
    <div class="middle igt" fxFlex="grow" fxLayout="column"
     fxLayoutAlign="center stretch" fxLayoutGap="3px">
      <p class="track-title" mat-line>{{ tone.title }}</p>
      <p class="artist" *ngIf="recording && !redesign">{{ duration | number:'1.1-2' }}</p>
    </div>
    <div class="end" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px">
      <button [attr.data-test-id]="'delete-ugc-button'" mat-button (click)="delete()" *ngIf="tone.isUgc && !select" class="bt-close"></button>
    </div>
  </div>
</mat-list-item>
