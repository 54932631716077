import log from 'app/core/logging/logger.service';
import { Injectable } from "@angular/core";

@Injectable()
export class BrowserService {

  device: string;
  osVersion: string;
  deviceType: string;
  userAgent: string;

  browserName: string;
  browserVersion: string;

  constructor() {
    this.setDeviceDetails();
  }

  private setDeviceDetails() {

    this.userAgent = navigator.userAgent;
    const matches = this.userAgent.match(/Android|iPhone|iPad|iPod|webOS|BlackBerry|Windows Phone/i);
    this.device = (matches && matches.length > 0) ? matches[0] : null;

    if (/Android/i.test(this.device)) {
      if (!/mobile/i.test(navigator.userAgent)) {
        this.deviceType = 'tablet';
      } else {
        this.deviceType = 'phone';
      }

      const osVersionMatches = (navigator.userAgent).match(/Android\s+([\d\.]+)/i);
      this.osVersion = osVersionMatches[0].replace('Android ', '');

    } else if (/iPhone/i.test(this.device)) {
      this.deviceType = 'phone';
      const osVersionMatches = (navigator.userAgent).match(/OS\s+([\d\_]+)/i);
      this.osVersion = osVersionMatches[0].replace(/_/g, '.').replace('OS ', '');

    } else if (/iPad/i.test(this.device)) {
      this.deviceType = 'tablet';
      const osVersionMatches = (navigator.userAgent).match(/OS\s+([\d\_]+)/i);
      this.osVersion = osVersionMatches[0].replace(/_/g, '.').replace('OS ', '');
    } else {
      // Desktop device
      this.deviceType = 'desktop';
    }

    const browserDetails = this.getBrowserDetails();
    this.browserName = browserDetails.name;
    this.browserVersion = browserDetails.version;

    log.info(` Device: ${this.device} OS version: ${this.osVersion} Device Type: ${this.deviceType} 
      Browser: ${this.browserName} Browser Version: ${this.browserVersion}`);
  }

  // Solution extracted from: https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
  private getBrowserDetails() {
    const ua = navigator.userAgent;
    let tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }

    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
      }
    }

    M = M[2]
      ? [M[1], M[2]]
      : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0],
      version: M[1]
    };
  }

  isIphone(): boolean {
    return this.device && this.device === 'iPhone';
  }

  isAndroid(): boolean {
    return this.device && this.device === 'Android';
  }

  isIosDevice(): boolean {
    return this.device === 'iPhone'
      || this.device === 'iPad'
      || this.device === 'iPod';
  }

  isMobile(): boolean {
    return this.device && (
      this.isAndroid()
      || this.isIosDevice()
      || this.device === 'webOS'
      || this.device === 'BlackBerry'
      || this.device === 'Windows Phone'
    );
  }

  compareVersion(v1, v2, lexicographical = false, zeroExtend = false) {

    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    const isValidPart = (x) => {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    };


    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) {
        v1parts.push('0');
      }

      while (v2parts.length < v1parts.length) {
        v2parts.push('0');
      }
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return -1;
    }

    return 0;
  }

  matches(v1, v2, lexicographical = false, zeroExtend = false) {
    return this.compareVersion(v1, v2, lexicographical, zeroExtend) === 0;
  }

  gt(v1, v2, lexicographical = false, zeroExtend = false) {
    return this.compareVersion(v1, v2, lexicographical, zeroExtend) > 0;
  }

  gte(v1, v2, lexicographical = false, zeroExtend = false) {
    return this.compareVersion(v1, v2, lexicographical, zeroExtend) >= 0;
  }

  lt(v1, v2, lexicographical = false, zeroExtend = false) {
    return this.compareVersion(v1, v2, lexicographical, zeroExtend) < 0;
  }

  lte(v1, v2, lexicographical = false, zeroExtend = false) {
    return this.compareVersion(v1, v2, lexicographical, zeroExtend) <= 0;
  }

  /** Gets the SMS link based on OS */
  getSmsLinkSeparator() {
    if (this.device) {
      if (this.device === 'iPhone') {
        if (this.lt(this.osVersion, '8')) {
          return ';';
        }
        return '&';
      }
    }
    return '?';
  }

}
