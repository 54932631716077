import { Component, Inject } from "@angular/core";
import { SettingService } from "../../core/setting.service";
import { ToneList, Profile } from "../../model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

/**
 * Component to select rbt
 */
@Component({
  templateUrl: "./rbt-select-dialog.component.html",
})
export class RBTSelectDialogComponent {
  spinnerActive: boolean;

  rbtList: ToneList = new ToneList();

  selectedTone: string;
  SettingService = SettingService;
  constructor(
    public dialogRef: MatDialogRef<RBTSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Profile,
    private settingService: SettingService
  ) {}

  ngOnInit(): void {
    this.spinnerActive = true;
    this.getRbtTonesFromProfile();
  }

  getRbtTonesFromProfile() {
    this.rbtList = this.data.library;
    this.spinnerActive = false;
  }

  select(toneId: string) {
    // adding to the selectedTone to be picked up by the dialogService
    this.selectedTone = toneId;
    if (toneId) {
      this.settingService.changeRbtSetting([this.selectedTone], true);
      this.dialogRef.close();
    }
  }
}
