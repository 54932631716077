/**
 * All possible STATIC sources info for ERBT-3371 purchase source tracking.
 *
 * Note: Typescript < 2.4 does not support string enums, so this is a regular class instead.
 */
export class PurchaseSourcesInfo {
    /**
     * Purchase info added to the Subscription source when a purchase is done via the
     * anonymous browsing flow (ERBT-3887)
     */
    static ToneFromAnonymousBrowsing = 'toneFromAnonymousBrowsing';

    /**
     * Purchase info added to the Subscription source when a purchase is done via the
     * onboarding quick flow (ERBT-4430)
     */
    static OnboardingQuickFlow = 'onboardingQuickFlow';

    /**
     * Purchase info added to the Subscription source when a purchase is done via the
     * landing happy flow (ERBT-5832)
     */
    static LandingHappyFlow = 'landingHappyFlow';

    /**
     * Purchase info added for the Magenta Moments landing page
     *(ERBT-6475)
     */
    static OptInFlow = 'magentaMomentsOptInFlow';

     /**
     * Purchase info added for the Young landing page
     *(ERBT-7247)
     */
     static YoungPromoFlow = 'youngPromoFlow';
         /**
     * Purchase info added for the MagentaBlackDays landing page
     *(ERBT-7290)
     */
     static MagentaBlackDaysPromoFlow = 'MagentaBlackDaysPromoFlow';
}
