import { SettingType } from '../app/model/setting-type';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

/**
 * Common config for all tmo op-codes
 */
export const tmoConfig = {

  menuPosition: 'start',

  /** Colors for contact tile background */
  initialBgColors: [
    '#FFD329',
    '#FF9A1E',
    '#992C99',
    '#1063AD',
    '#53BAF2',
    '#1BADA2',
    '#BFCB44',
    '#E20074',
    '#6C6C6C'
  ],

  /** Settings available for op code */
  availableSettings: [
    SettingType.callerSetting,
    SettingType.groupSetting,
    SettingType.defaultSetting,
    SettingType.timeAndDayOfWeekSetting,
    SettingType.dayOfYearSetting
  ],

  /** ERBT-5078: The image used as a cover image for the UGC tone on the Shared UGC landing page */
  sharedUgcCoverImage: "assets/common/prelisten_banner.jpg",

  /** ERBT-5644: New rebranded layout is not applicable for TMO */
  newLayout: false,

  /** Space between the tiles for the tone grid list */
  toneGridListGutterSize: 0,

  /** ERBT-5832: URL for the happy landing page. The flow is disabled if empty. */
  landingHappyPageUrl: '',

  /** ERBT-5910: URL of the Consent Management Platform (CMP) script (only VF/DE) */
  cmpScriptUrl: '',
 // Used for the 2024 operators redesign
 isRedesign:false,
};
