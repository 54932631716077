import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared/shared.module';
import { PromoService } from 'app/shared/promotion/promo.service';
import { ToneService } from 'app/tone/tone.service';
import { MagentaBlackDaysRoutingModule } from './magenta-black-days.routing.module';
import { MagentaBlackDaysComponent } from './magenta-black-days.component';

@NgModule({
  imports: [MagentaBlackDaysRoutingModule, SharedModule],
  declarations: [MagentaBlackDaysComponent],
  providers: [PromoService, ToneService]
})

export class MagentaBlackDaysModule { }