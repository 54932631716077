import {
  Directive,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  Input
} from '@angular/core';
import { Subscription , NEVER, fromEvent, interval, merge } from 'rxjs';
import { auditTime, debounceTime, first, share } from 'rxjs/operators';


/**
 * Structural directive which inserts the host component if it would be visible.
 * @example <div *appLazy></div>
 */
@Directive({
  selector: '[appLazy]'
})
export class LazyDirective implements AfterViewInit, OnDestroy {

  static scrollListener = fromEvent(window, 'scroll', { passive: true })
    .pipe(auditTime(100), share());

  static resizeListener = fromEvent(window, 'resize', { passive: true })
    .pipe(debounceTime(100), share());

  static clickListener = fromEvent(window, 'click', { passive: true })
    .pipe(debounceTime(100), share());

  private sub: Subscription = null;
  private offset = 100;

  scrollerFlag = false;

  @Input() set appLazy(scrollerFlag: boolean) {
    this.scrollerFlag = scrollerFlag || false;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngAfterViewInit() {
    if (this.viewContainer.length === 0) {
      // Add timeout to finish the life cycle check
      interval(1).pipe(first()).subscribe(
        () => this.test()
      );
    }
  }

  private startListeners() {
    // For scroller we check the click event,
    // since we are not checking visibility in intervals
    // listens left and right click of the arrow button
    const clickListener = this.scrollerFlag
      ? LazyDirective.clickListener
      : NEVER;

    const po = merge(LazyDirective.scrollListener, clickListener);

    // Run it outside angular change detection zone
    this.sub = 
        merge(LazyDirective.resizeListener, LazyDirective.scrollListener, clickListener)
        .subscribe(() => this.test());
  }

  ngOnDestroy() {
    this.closeSubscription();
  }

  private closeSubscription() {
    if (this.sub && !this.sub.closed) {
      this.sub.unsubscribe();
    }
  }

  test() {

    // Make sure that the image is not attached multiple times
    if (this.viewContainer.length !== 0) {
      return;
    }

    // We check the parent node, this might cause problems if the parent bounds
    // And the template bounds are very different.
    const el = this.viewContainer.element.nativeElement.parentNode;

    const size = (el.offsetWidth * el.offsetHeight);
    const rect = el.getBoundingClientRect();

    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    /* We apply an offset to check if element is visible
    We check both the offset bounds and the original ones.
    This way we avoid tunneling when the user scrolls very fast.

    |--------------------------------------|
    |                                      |
    |--------------------------------------|


    |--------------------------------------|
    |                                      |
    |--------------------------------------|
    */

    const offset = this.offset;
    let loadImage = false;

    if (this.scrollerFlag) {
      const leftIn = rect.left > 0 && rect.left < viewport.width
        || rect.left - offset > 0 && rect.left - offset < viewport.width;

      const rightIn = rect.right > 0 && rect.right <= viewport.width
        || rect.right + offset > 0 && rect.right + offset <= viewport.width;

      loadImage = leftIn || rightIn;

    } else {

      const viewportHeight = viewport.height + 600; // Load the next page as well for smooth scrolling
      const topIn = rect.top > 0 && rect.top < viewportHeight
        || rect.top - offset > 0 && rect.top - offset < viewportHeight;

      const bottomIn = rect.bottom > 0 && rect.bottom <= viewportHeight
        || rect.bottom + offset > 0 && rect.bottom + offset <= viewportHeight;

      loadImage = topIn || bottomIn;
    }

    if (loadImage) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.closeSubscription();
    } else if (this.sub === null) {
      this.startListeners();
    }

  }
}
