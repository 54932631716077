import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { map} from 'rxjs/operators';
import { environment } from 'environments/environment';

import { BrowserService } from './browser.service';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './profile.service';
import { SubscriptionService } from './subscription.service';
import { SettingService } from './setting.service';
import { ConfigService } from './config/config.service';
import { TextConfigService } from './config/text-config.service';
import { PushNotificationService } from './push-notification.service';
import log from 'app/core/logging/logger.service';
import { ConsentManagementService, ConsentStatusChangeEvent, Vendor } from './consent-management.service';

import { Integrations } from '@sentry/tracing';
import * as Sentry from "@sentry/browser";
/**
 * Initializes all services those are required before app launch
 * This factory method needs to return a function (that then returns a promise)
 * @param configService the config service provider
 * @param textConfigService the text config service provider
 * @param profileService the profile service provider
 */
export function initApplication(configService: ConfigService,
  textConfigService: TextConfigService,
  profileService: ProfileService) {

  const promise = new Promise((resolve) => {

    const promisesArray = [
      configService.loadConfig(),
      textConfigService.loadConfig(),
      ConsentManagementService.loadScript()
    ];


    Promise.all(promisesArray).then(() => {
      profileService.getProfile()
      .pipe(map((data) => resolve(data))) // Subject is an observable. We can resolve here.
        .toPromise()
        .catch(error => resolve(error)) // Ignore errors.
        .then();

      if (ConsentManagementService.isConsentManagementSupported()) {
        registerPrivacyConsentHandler();
      }

    });
  });

  return () => promise;
}

/**
 * This method listens to privacy consent changes in the consent management platform (CMP)
 * and it enables or disables Sentry integration based on the consent status for Sentry vendor.
 * 
 * @see ERBT-5910
 */
function registerPrivacyConsentHandler() {
  ConsentManagementService.getInstance().statusChange$.subscribe(
    (event: ConsentStatusChangeEvent) => {
      if (event.vendor !== Vendor.SENTRY) {
        return;
      }
      if (event.status) {
        initializeSentry();
      } else {
        disableSentry();
      }
    });
}

function initializeSentry() {
  // ERBT-5452: Initialize Sentry SDK and start sending events to Sentry.
  initSentrySDK(true);
}

function initSentrySDK(sendEvents: boolean) {
  // Send errors only if the sentry URL is configured for this build.
  // ERBT-5274: Support error reporting for preprod projects.
  if (environment.sentryUrl) {
    Sentry.init({
      dsn: environment.sentryUrl,
      // For a dynamic release version
      release: environment.sentryRelease,
      integrations: [new Integrations.BrowserTracing()],
      // Set enabled to true for sending events to Sentry
      enabled: sendEvents
    });
    log.info('Sentry SDK is initialized with error reporting ' + (sendEvents ? "enabled" : "disabled"));
  }
}

function disableSentry() {
  if (environment.sentryUrl) {
    // Disable Sentry SDK by initializing it with empty configuration.
    Sentry.init();
    log.info('Sentry SDK is disabled');
  }
}

if (!ConsentManagementService.isConsentManagementSupported()) {
  // Consent management pop-up is not supported.
  // Initialize Sentry immediately without waiting for user consent.
  initializeSentry();
}

@NgModule({
  'providers': [
    BrowserService,
    AuthService,
    ProfileService,
    SubscriptionService,
    PushNotificationService,
    SettingService,
    ConfigService,
    TextConfigService,
    {
      'provide': APP_INITIALIZER,
      'useFactory': initApplication,
      'deps': [ConfigService, TextConfigService, ProfileService],
      'multi': true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    // Make sure that the core module is only loaded from AppModule
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
