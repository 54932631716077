import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone
} from '@angular/core';

import {
  Tone,
  CallParty,
  CallPartyType,
} from 'app/model';

import { ProfileHelper } from 'app/core/helper/profile-helper';
import { ContactHelper } from 'app/core/helper/contact-helper';
import { CallPartyHelper } from 'app/core/helper/call-party-helper';
import log from 'app/core/logging/logger.service';
import { ProfileService } from 'app/core/profile.service';
import { environment } from 'environments/environment';
import { BaseViewComponent } from '../base-view.component';
import { Router } from '@angular/router';
import { getInitials } from '../../core/utils';

@Component({
  selector: 'app-call-party',
  templateUrl: 'call-party.component.html'
})
export class CallPartyComponent extends BaseViewComponent implements OnInit {

  @Input() callParty: CallParty;

  imageSize = 60;

  initial: string;
  initialBgColor: string;
  callPartyName: string;

  isShuffleActive = false;

  isRbtActive = false;

  isCaller = true;
  toneList: Tone[] = [];

  newLayout: boolean;

  @Output()
  onDelete = new EventEmitter<CallParty>();

  cover: string;

  constructor(
    private profileService: ProfileService,
    zone: NgZone,
    router: Router) {
    super(zone, router);
  }

  ngOnInit() {

    this.setCallPartyName();
    log.info(`Call party name: ${this.callPartyName}`);

    // For time of caller/group use initial in the box
    if (this.callParty.type === CallPartyType.CALLER
      || this.callParty.type === CallPartyType.GROUP) {
      this.initial = getInitials(this.callPartyName, this.isRedesign);
      this.initialBgColor = ContactHelper.getTileColor(this.callPartyName);
    } else {
      this.initial = null;
      this.cover = this.callParty.rbt.cover;
    }

    this.isCaller = this.callParty.type === CallPartyType.CALLER;
    if (this.isCaller) {
      this.callParty.msisdn = this.profileService.getPhoneNumberFromMsisdn(this.callParty.msisdn);
    }

    if (this.callParty.setting) {
      this.isShuffleActive = ProfileHelper.isShuffleActive(this.callParty.setting.rbtSetting);
      // If the Standard-Tone is selected then do not show the RBT icon as active
      this.isRbtActive = !ProfileHelper.isStandardTone(this.callParty.setting.rbtSetting);
    }

    if (this.callParty.igt) {
      this.toneList.push(this.callParty.igt);
    }

    if (this.callParty.rbt) {
      this.toneList.push(this.callParty.rbt);
    }

    this.newLayout = environment.opcoConfig.newLayout;
    
    if (this.newLayout) {
      // Request the correct resolution for the call party cover.
      this.imageSize = this.isMobile ? 100 : 150;
    }
  }

  private setCallPartyName() {

    switch (this.callParty.type) {

      case CallPartyType.DAY_OF_WEEK:
        this.callPartyName = CallPartyHelper.prepareDayOfWeekCallPartyName(
          this.callParty.daysOfWeek
        );
        return;

      case CallPartyType.TIME_OF_DAY:
        this.callPartyName = CallPartyHelper.prepareTimeOfDayCallPartyName(
          this.callParty.startTime,
          this.callParty.endTime
        );
        return;

      case CallPartyType.DAY_OF_YEAR:
        this.callPartyName = CallPartyHelper.prepareDayOfYearCallPartyName(
          this.callParty.dayOfYear
        );
        return;

      case CallPartyType.TIME_AND_DAY_OF_WEEK:
        this.callPartyName = CallPartyHelper.prepareTimeAndDayOfWeekCallPartyName(
          this.callParty.startTime,
          this.callParty.endTime,
          this.callParty.daysOfWeek
        );
        return;

      default:
        this.callPartyName = (this.callParty.name || '').trim();
        if (this.callPartyName === '') {
          this.callPartyName = this.callParty.msisdn;
        }
        return;
    }

  }

  delete(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.onDelete.emit(this.callParty);
  }

}
