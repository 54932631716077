import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseNetworkService } from '../core/network/base-network.service';
import { AuthService } from '../core/auth/auth.service';
import { Result, UserPackages } from '../model';
import { environment } from 'environments/environment';
import { PurchaseTracking } from '../tracking/purchase.tracking';
import UrlBuilder, { RequestMethod } from '../core/url-builder';
import log from 'app/core/logging/logger.service';

@Injectable()
export class AccountService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  setHibernate(hibernate: boolean): Observable<Result> {
    const params = this.createURLSearchParams();
    params.append('enable', hibernate.toString());

    return this.sendObservableRequest(
      UrlBuilder.getSetHibernateUrl(),
      RequestMethod.Post,
      params
    );
  }

  subscribe(isPromotionSubscription: boolean,
    purchaseSource: string[],
    rbtId?: string,
    promoId?: string,
    isOptInSubscription?: boolean,
    tariffId?: string): Observable<Result> {

    log.info(`Starting subscription:
        Promotion subscription: ${isPromotionSubscription}
        Tone ID: ${rbtId}
        Purchase source: ${purchaseSource}
        Opt-In subscription: ${isOptInSubscription}`);

    const params = this.createURLSearchParams();
    params.append('isPromotionSubscription', isPromotionSubscription.toString());
    params.append('isCreditOnlySubscription', (!rbtId).toString());

    if (rbtId) {
      params.append('rbtId', rbtId);

      // ERBT-3835: We will always assign the chosen tone as the default tone
      params.append('setAsDefault', 'true');
    }

    const partnerId = environment.partnerId || '';
    if (partnerId !== '') {
      params.append('partnerId', partnerId);
    }

    if (promoId) {
       // Promo ID (optional) holds the IPP service ID that is used for subscribing the
       // user with the happy package.
       params.append('promoId', promoId);
    }

    if (isOptInSubscription) {
      // ERBT-6502 - mark the subscription as Opt-In if requested
      params.append('isOptInSubscription', 'true' );
    }

    if(tariffId) {
      params.append('tariffId', tariffId);
    }

    // ERBT-3371: Add purchase source tracking parameters to the back end call
    PurchaseTracking.addPurchaseSourceToRequest(params, purchaseSource);

    return this.sendObservableRequest(
      UrlBuilder.getSubscribeUrl(),
      RequestMethod.Post,
      params
    );
  }

  unsubscribe(): Observable<Result> {
    return this.sendObservableRequest(
      UrlBuilder.getUnsubscribeUrl(),
      RequestMethod.Post
    );
  }

  getSubscriptionAndContentPackages(): Observable<UserPackages> {
    log.info('Fetching the subscription and content packages of the user');
    return this.sendObservableRequest(
      UrlBuilder.getSubscriptionAndContentPackagesUrl(),
      RequestMethod.Get
    );
  }

  getContentPackages(): Observable<UserPackages> {
    log.info('Fetching the content packages of the user');
    return this.sendObservableRequest(
      UrlBuilder.getContentPackagesUrl(),
      RequestMethod.Get
    );
  }

  unsubscribePackage(packageId: string): Observable<Result> {
    const params = this.createURLSearchParams();
    params.set('id', packageId);
    return this.sendObservableRequest(UrlBuilder.getUnsubscribePackageUrl(), RequestMethod.Post, params);
  }

  reactivate(): Observable<Result> {
    return this.sendObservableRequest(
      UrlBuilder.getReactivateUrl(),
      RequestMethod.Post
    );
  }

  optin(): Observable<Result> {
    return this.sendObservableRequest(
      UrlBuilder.getOptInUrl(),
      RequestMethod.Post
    );
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }
}
