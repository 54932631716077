import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedUgcComponent } from './shared-ugc.component';

const routes: Routes = [
  { path: 'sharedUgc', component: SharedUgcComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedUgcRoutingModule { }
