import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


export enum CapaDialogAction {
  OK,
  DELETE,
  DEACTIVATE
}

/**
 * Component to show CAPA warning popup
 * see https://rncorp.atlassian.net/browse/ERBT-5753
 */
@Component({
  selector: 'app-capa-dialog',
  templateUrl: './capa-dialog.component.html',
})
export class CapaDialogComponent {

  public texts = {
    title: 'capa.selectIgt.popup.title',
    content: 'capa.selectIgt.popup.content',
    confirm: 'capa.selectIgt.popup.ok.button',
    delete: 'capa.selectIgt.popup.delete.button',
    deactivate: 'capa.selectIgt.popup.deactivate.button'
  };


  constructor(public dialogRef: MatDialogRef<CapaDialogComponent>) { }

  /**
   * Called from the component when the user clicks the accept button
   */
  accept() {
    this.dialogRef.close(CapaDialogAction.OK);
  }

  /**
   * Called from the component when the user clicks the delete button.
   */
  delete() {
    this.dialogRef.close(CapaDialogAction.DELETE);
  }

   /**
   * Called from the component when the user clicks the deactivate button.
   */
  deactivate() {
    this.dialogRef.close(CapaDialogAction.DEACTIVATE);
  }
}
