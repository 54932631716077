import { Input, Component } from '@angular/core';
import { Tone } from 'app/model/tone';

@Component({
  selector: 'app-artist-tone-list-item',
  templateUrl: './artist-list-item.component.html',
})

export class ArtistListItemComponent {
  @Input() tone: Tone;
  constructor() { }
}
