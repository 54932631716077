import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, filter, throttleTime, map } from 'rxjs/operators';

import { Tone } from 'app/model/tone';
import { environment } from 'environments/environment';
import { BaseViewComponent } from '../shared/base-view.component';
import { Router } from '@angular/router';

class TileSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-tone-list',
  templateUrl: './tone-list.component.html',
})
export class ToneListComponent extends BaseViewComponent implements OnChanges, OnDestroy {
  private readonly approximateHeightOfToneItem = 75;

  @Input() tones: Tone[] = [];
  @Input() grid = false;
  @Input() purchaseSource: string;
  @Input() purchaseSourceInfo: string;
  @Input() purchaseSourcePropagateCurrent = true;
  @Input() imageSize = this.isRedesign ? 116 : 60;

  gutterSize = environment.opcoConfig.toneGridListGutterSize;
  tracksLoaded = false;
  isInitialFetching = false; // New flag for initial fetching
  cachedTones: Tone[] = [];
  private viewportHeight = window.innerHeight;
  private numberOfContentsPerPage: number;
  private lastScrollPosition = 0;
  initialLoadComplete = false;

  private scrollSubscription: Subscription | null = null;
  private loadSubscription: Subscription | null = null;
  private pageIndex: number = 0;

  constructor(router: Router, zone: NgZone) {
    super(zone, router);
  }

  ngOnChanges(): void {
    this.resetView();
    if (this.tones && this.tones.length) {
      this.isInitialFetching = true; // Set initial fetching flag
      this.loadTones();
    }
  }

  loadTones() {
    if (this.cachedTones.length >= this.tones.length || this.isLoadingContent()) {
      return;
    }
  
    // Reset tracksLoaded before fetching new tones
    this.tracksLoaded = false; 
  
    if (!this.isInitialFetching) {
      this.tracksLoaded = false; // Show spinner for subsequent loads
    }
  
    const cachedToneLength = this.cachedTones.length;
    const end = cachedToneLength === 0
      ? this.numberOfContentsPerPage * 2 // Initially load two pages
      : cachedToneLength + this.numberOfContentsPerPage;
  
    this.cachedTones = this.tones.slice(0, end);
  
    if (this.cachedTones.length >= this.tones.length) {
      // Content loading finished, unsubscribe scroll listener
      this.closeAllSubscriptions();
    } else {
      this.pageIndex++;
      if (cachedToneLength === 0) {
        this.listenScroll();
      }
    }
  
    // Ensure tracksLoaded is set to true after tones are loaded
    setTimeout(() => {
      this.tracksLoaded = true;
    }, 0);
  
    if (this.isInitialFetching) {
      this.isInitialFetching = false; // Reset the flag after initial load
      return;
    }
  
    // Scroll to the last known position only on the second load
    setTimeout(() => {
      const additionalHeight = (this.cachedTones.length - cachedToneLength) * this.approximateHeightOfToneItem;
      const newScrollPosition = this.lastScrollPosition + additionalHeight;
      window.scrollTo(0, newScrollPosition);
    }, 0);
  }
  

  private listenScroll() {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        throttleTime(100), // Throttle scroll events to avoid too many triggers
        debounceTime(200), // Debounce to allow the user to stop scrolling
        map(() => window.scrollY), // Get the scroll position
        filter(() => !this.isLoadingContent()),
        filter(scrollTop => scrollTop + this.viewportHeight >= document.body.scrollHeight - 500) // Check if near the bottom
      )
      .subscribe(() => {
        this.lastScrollPosition = window.scrollY; // Update last known scroll position
        this.loadTones();
      });
  }

  private resetView() {
    this.numberOfContentsPerPage = Math.ceil(this.viewportHeight / this.approximateHeightOfToneItem);
    this.pageIndex = 0;

    this.cachedTones = [];
    this.closeAllSubscriptions();
    this.tracksLoaded = false;
    this.initialLoadComplete = false; // Reset the initial load flag
  }

  getTileSize(index: number): TileSize {
    const composition = [
      { width: 6, height: 6 },
      { width: 6, height: 6 },
      { width: 8, height: 8 },
      { width: 4, height: 4 },
      { width: 4, height: 4 },
      { width: 6, height: 6 },
      { width: 6, height: 6 },
      { width: 4, height: 4 },
      { width: 8, height: 8 },
      { width: 4, height: 4 }, // Out of place, only works this way. Probably a bug in Material
    ];

    const compositionIndex = index % composition.length;
    return composition[compositionIndex];
  }

  private isLoadingContent() {
    return this.loadSubscription && !this.loadSubscription.closed;
  }

  private closeAllSubscriptions() {
    if (this.scrollSubscription && !this.scrollSubscription.closed) {
      this.scrollSubscription.unsubscribe();
    }

    if (this.loadSubscription && !this.loadSubscription.closed) {
      this.loadSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.closeAllSubscriptions();
  }

  trackByFn(index: number, item: Tone) {
    return item.id; // Use a unique identifier (e.g., tone ID) to track items
  }
}
