import log from 'app/core/logging/logger.service';
import { ScriptService } from './script.service';
import { ConsentManagementService, ConsentStatusChangeEvent, Vendor } from './consent-management.service';

declare var gtag: Function;

export enum GtagEvents {
    ACTIVATION,
    PRELISTEN,
    TRACK_SELECTION,
    SKIP_BUTTON,
    PIN_CODE
}

function initNow(conversionId: string): void {
    ScriptService.loadScript('https://www.googletagmanager.com/gtag/js?id=AW-761151215')
    .then(() => {
        gtag('js', new Date());
        gtag('config', conversionId, { 'anonymize_ip': true });
        log.info('Gtag initialized with ID:', conversionId);
    });
    GtagService.getInstance().setGtagTrackingEnabled(true);
}

export class GtagService {

    private static instance: GtagService;
    private conversionId: string;
    private trackingEnabled: boolean;

    static getInstance(): GtagService {
        if (!GtagService.instance) {
            GtagService.instance = new GtagService();
        }

        return GtagService.instance;
    }

    init(conversionId: string): void {
        this.conversionId = conversionId;
        this.registerPrivacyConsentHandler(conversionId);
    }

    /**
     * This method listens to privacy consent changes in the consent management platform (CMP)
     * and it enables or disables Gtag event tracking based on the consent status for Google Tag vendor.
     *
     * @param conversionId the GTM conversion ID
     * @see "ERBT-5910"
     */
    registerPrivacyConsentHandler(conversionId: string) {
        ConsentManagementService.getInstance().statusChange$.subscribe(
            (event: ConsentStatusChangeEvent) => {
                if (event.vendor !== Vendor.GTAG) {
                    return;
                }

                if (event.status) {
                    // Google Tag Manager is granted.
                    initNow(conversionId);
                } else {
                    // gtag vendor is not granted, disable tracking
                    GtagService.getInstance().setGtagTrackingEnabled(false);
                }
            });
    }

    trackEvent(event: GtagEvents) {
        if (this.conversionId) {
            switch (event) {
                case GtagEvents.ACTIVATION:
                    this.track('rmM0CL3RzZUBEO_9-OoC');
                    break;
                case GtagEvents.PRELISTEN:
                    this.track('WbBKCJyx4JcBEO_9-OoC');
                    break;
                case GtagEvents.TRACK_SELECTION:
                    this.track('XL3WCI7v1JcBEO_9-OoC');
                    break;
                case GtagEvents.SKIP_BUTTON:
                    this.track('KYRlCMPD4JcBEO_9-OoC');
                    break;
                case GtagEvents.PIN_CODE:
                    this.track('WrGpCMLZ4ZYBEO_9-OoC');
                    break;
            }
        }
    }

    private track(label: string) {
        // Send gtag events only if tracking is enabled
        if (this.trackingEnabled) {
            const conversionIdAndLabel = this.conversionId + '/' + label;
            log.info('Tracking Gtag event with conversion ID and label: ' + conversionIdAndLabel);
            gtag('event', 'conversion', {
                'send_to': conversionIdAndLabel
            });
        }
    }

    setGtagTrackingEnabled(enabled: boolean) {
        this.trackingEnabled = enabled;
    }
}
