import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  NgZone,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

import { BaseViewComponent } from '../base-view.component';
import { AuthService } from 'app/core/auth/auth.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { ClientConfig } from 'app/core/config/client-config';
import log from 'app/core/logging/logger.service';
import { AnalyticsService } from 'app/core/analytics.service';
import { MatSidenav } from '@angular/material/sidenav';
import UrlBuilder from 'app/core/url-builder';
import { BrowserService } from 'app/core/browser.service';

let instance: TitleBarComponent;

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
})
export class TitleBarComponent extends BaseViewComponent implements OnInit, AfterViewInit {

  private readonly storageKeyCookieNotificationBar = 'showCookieNotificationBar';
  private readonly footerLinksTextKey = 'footer.links';

  footerLinks = [];

  /** Header title for the mobile view  */
  @Input() title = '';
  /** Shows the back button for the mobile view  */
  @Input() backButton = false;
  /** Shows the button to change between list and grid view (mobile view) */
  @Input() toggleButton = false;
  /** Shows the menu icon for the mobile view */
  @Input() showMenu = false;
  /** Boolean to know the view that is displayed (list or grid view) */
  @Input() isGrid = true;
  /** Boolean to the hide some of the elements in the title bar for the mobile view  */
  @Input() simpleHeader = false;

  @Output() onToggle = new EventEmitter<boolean>();
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  public show: boolean;
  public callerExperienceShow: boolean;
  public libraryShow = false;
  public searchShow = false;
  public menuShow = false;

  public isLogged = false;
  public pageHome = false;
  public pageShop = false;
  public pageCaller = false;
  public pageLibrary = false;
  public pageAccount = false;
  public pageFaq = false;
  public pageLandingPromo = false;
  public pageRegister = false;
  public menuPosition = environment.opcoConfig.menuPosition;
  public isIosDevice = false;

  public showCookieNotificationBar: boolean;
  public showContentPackages: boolean;

  constructor(
    private authService: AuthService,
    private browserService: BrowserService,
    private location: Location,
    zone: NgZone,
    router: Router) {
    super(zone, router);
    this.isLogged = this.authService.isLoggedIn();
    instance = this;
    this.isIosDevice = this.browserService.isIosDevice();
  }

  ngOnInit() {
    const pipe = new TranslatePipe();
    this.footerLinks = pipe.transform(this.footerLinksTextKey).split(';');
    log.info('Footer links', this.footerLinks);
    this.determineLocationPage();

    // Shows the cookie notification bar if the config key is set to TRUE
    // AND the user didn't accept yet the notification
    let storageKeyCookieNotificationBar :string = null;
    try {
      storageKeyCookieNotificationBar = localStorage.getItem(this.storageKeyCookieNotificationBar);
    } catch (error) {
      log.warn('Access to local storage is not allowed', error);
    }
    this.showCookieNotificationBar = ClientConfig.showCookieNotificationBar.getBoolean() &&
    storageKeyCookieNotificationBar === null;

    this.showContentPackages = environment.showContentPackages;

    this.sidenav.closedStart.subscribe(() => {
      this.menuShow = false;
    });
  }

  acceptCookieNotification(): void {
    try {
      localStorage.setItem(this.storageKeyCookieNotificationBar, 'false');
    } catch (error) {
      log.warn('Access to local storage is not allowed', error);
    }
    this.showCookieNotificationBar = false;
  }

  determineLocationPage(): void {
    if (this.router.url.indexOf('library') >= 0) {
      this.pageLibrary = true;
    } else if (this.router.url.indexOf('setting') >= 0) {
      this.pageCaller = true;
    } else if (this.router.url.indexOf('home') >= 0) {
      this.pageHome = true;
    } else if (this.router.url.indexOf('shop') >= 0) {
      this.pageShop = true;
    } else if (this.router.url.indexOf('account') >= 0) {
      this.pageAccount = true;
    } else if (this.router.url.indexOf('faq') >= 0) {
      this.pageFaq = true;
    } else if (this.router.url.indexOf('landingpromo') >= 0) {
      this.pageLandingPromo = true;
    } else if (this.router.url.indexOf('register') >= 0) {
      this.pageRegister = true;
    } else {
      this.pageLibrary = false;
      this.pageCaller = false;
    }
  }

  /*
   * Determines whether the page title should be shown in the toolbar.
   * For VF/DE the page title is not visible for the landing and register page.
   */
  showPageTitle() {
    if ((this.newLayout && (this.pageLandingPromo || this.pageRegister)) || this.isRedesign) {
      return false;
    }
    // For all other cases show page title
    return true;
  }

  goBack() {
    this.location.back();
  }

  toggleToneView() {
    this.onToggle.emit(this.isGrid);
  }

  showSubMenu(action): void {
    if ( !this.pageLibrary || !this.pageCaller) {
      this.hideSubMenu();
      this.show = true;
      this[action] = true;
    }
    if (this.pageLibrary && action === 'callerExperienceShow') {
      this.pageLibrary = false;
    }else if ( this.pageCaller && action === 'libraryShow') {
      this.pageCaller = false;
    }
    this.pageAccount = false;

  }

  hideSubMenu(): void {
    this.show = false;
    this.callerExperienceShow = false;
    this.libraryShow = false;
    this.showFixedSubMenu();
  }

  search() {
    if (this.searchShow) {
      this.searchShow = false;
    } else {
      this.searchShow = true;

      if (this.menuShow) {
        this.sidenav.toggle();
        this.menuShow = false;
      }
    }
  }

  toggleMenu() {
    this.sidenav.toggle();
    this.menuShow = !this.menuShow;
    this.searchShow = false;
  }

  showFixedSubMenu(): boolean {
    this.determineLocationPage();
    if (this.router.url.indexOf('library') >= 0) {
      return true;
    }else if (this.router.url.indexOf('setting') >= 0) {
      return true;
    }else if (this.router.url.indexOf('account') >= 0) {
      return true;
    }else {
      return false;
    }
  }

  ngAfterViewInit() {
    // Skip the super call to scroll top
  }

  /** Handles click on logo */
  handleLogoClick() {
    const logoUrl = ClientConfig.opcoLogoUrl.getString() || '';
    if (logoUrl !== '') {
      AnalyticsService.getInstance().trackEventAction('logoTitleBar.click');
      location.href = logoUrl;
    } else {
      this.router.navigate(['/home']);
    }
  }

  openFooterLink(event) {
    UrlBuilder.openExternalOrInternalUrlFromClickEvent(event, this.router);
  }
}