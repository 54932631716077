import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RbtVersionService {

    private rbtVersionChangeSource = new Subject<boolean>();
    rbtVersionChange$ = this.rbtVersionChangeSource.asObservable();

    constructor() { }

    rbtVersionChanged(alternativeAudioPreferred: boolean) {
        this.rbtVersionChangeSource.next(alternativeAudioPreferred);
    }
}
