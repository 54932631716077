<div>
    <app-spinner [active]="spinnerActive">
        <app-title-bar title="{{ 'ugc.shared.title' | trans }}" [simpleHeader]="isMobile">

            <div fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutAlign="center"
            fxLayoutAlign.gt-sm="start start"
            class="mx-2 my-2">
                <p class="title">{{'ugc.shared.header' | trans }}</p>
            </div>

            <div *ngIf="tone">
                <div fxLayout="column"
                 class="mx-2 my-2">
                    <div class="shared-ugc-image-holder  tone-image-holder">
                        <img class="square" [src]="sharedUgcCoverImage"/>
                        <app-play-button [tones]="[tone]"></app-play-button>
                    </div>
                </div>
                <div fxLayout="column"
                 fxLayout.gt-sm="row"
                 fxLayoutAlign="center"
                 fxLayoutAlign.gt-sm="start start"
                 class="mx-2 mb-1">
                    <p [innerHTML]="'ugc.shared.description.html' | trans "></p>
                </div>
                <div fxLayout="column"
                fxLayout.gt-sm="row"
                fxLayoutAlign="center"
                class="mx-2 my-3">
                    <app-offer-app></app-offer-app>
                </div>
            </div>

            <div fxLayout="column"
            class="mx-2 my-2">
                <div class="shared-ugc middle-list-container">
                    <ul>
                        <li>{{ 'ugc.shared.features.header' | trans }}</li>
                        <li><span class="item-1">{{ 'ugc.shared.features.first' | trans }}</span></li>
                        <li><span class="item-2">{{ 'ugc.shared.features.second' | trans }}</span></li>
                        <li><span class="item-3">{{ 'ugc.shared.features.third' | trans }}</span></li>
                    </ul>
                </div>
            </div>
        </app-title-bar>
    </app-spinner>
</div>