import { Router } from '@angular/router';
import { Input,  Component } from '@angular/core';
import { transition,  animate,  trigger,  state,  style } from '@angular/animations';
import { environment } from 'environments/environment';

import { ExternalRouting } from '../../core/external.routing';
import { Tone } from 'app/model/tone';
import { PlayButtonService } from '../play-button/play-button.service';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-tone-grid-item',
  templateUrl: './tone-grid-item.component.html',
  providers: [PlayButtonService],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(180deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0deg)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-out'))
    ])
  ]})
export class ToneGridItemComponent {

  @Input()
  tone: Tone;

  @Input() purchaseSource: string;
  @Input() purchaseSourceInfo: string;
  @Input() purchaseSourcePropagateCurrent = true;

  flip = 'inactive';

  constructor(private playButtonService: PlayButtonService, private router: Router, private breadcrumbService: BreadcrumbService) {
    playButtonService.audio$.subscribe();
  }

  toggleFlip() {

    if (environment.flippingTilesEnabled) {

      if (this.flip === 'active') {
        // Stop playback
        this.playButtonService.stopAudio();
      }
      this.flip = (this.flip === 'inactive') ? 'active' : 'inactive';
    } else {
      // ERBT-4182: In case that the flipping tiles are not enabled
      // go to the tone detail page
      this.buy();
    }

  }

  buy() {
    // Hide breadcrumb navigation while loading the tone
    this.breadcrumbService.hideBreadcrumb();
    ExternalRouting.navigate(this.router, ['/rbt/id', this.tone.id],
        this.purchaseSourcePropagateCurrent,
        this.purchaseSource, this.purchaseSourceInfo);
  }
}
