<mat-list-item *ngIf="!isRedesign" [class.selected]="selected" [class.tone-selection]="selection">
  <div
      mat-line
      class="tone-item-container"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="{{ newLayout ? '15px': '8px' }}">

      <div
        fxFlex="{{'1 1 ' + imageSize + 'px' }}"
        fxLayout="column">
        <app-play-button
          *ngIf="tone.id !== shuffleParam "
          [tones]="[tone]"
          [cover]="tone.cover | image:imageSize:imageSize"
          (onPrelistenStarted)="onPrelistenStarted.emit($event)">
        </app-play-button>
        <span class="icon-shuffle" *ngIf="tone.id === shuffleParam "></span>
      </div>

    <div *ngIf="tone.id !== shuffleParam "
        class="middle"
        [class.library]="library"
        fxFlex="1 1 100%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="3px">

      <p class="track-title">{{tone.title}}</p>
      <p class="artist">{{tone.artist}}</p>
      <p class="expiry" *ngIf="library && expiryDate">{{ 'library.rbt.expiryDate' | trans | format:expiryDate }}</p>
      <p class="sms" *ngIf="!library && showSmsCode">{{'common.sms' | trans }}
        <span class="sms-code">
          <span *ngIf="!isMobile">{{ tone.numericShortCode }}</span>
          <a *ngIf="isMobile"
            [href]="'rbt.sms.link' | trans | format:smsLinkSeparator:tone.numericShortCode | bypassSecurityForUrl">
            {{ tone.numericShortCode }}
          </a>
        </span>
      </p>
    </div>
    <div *ngIf="tone.id === shuffleParam"
        class="middle"
        [class.library]="library"
        fxFlex="1 1 100%"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        fxLayoutGap="3px">
         <p class="track-title">{{ 'setting.rbt.shuffleButton.label' | trans }}</p>
    </div>
    <div
      class="end"
      fxFlex="grow"
      fxLayout="{{ expired ? 'row': 'column' }}"
      fxLayoutAlign="{{ expired ? 'end center' : (newLayout && tone.price !== undefined && !hidePrice  ? 'end end' : 'center end') }}"
      fxLayoutGap="5px">

      <p class="price" *ngIf="!hidePrice && !library && (tone.price !== undefined)">{{'common.priceFormat' | trans | format:promotion ? 0 : tone.price}}</p>

      <button
        mat-raised-button
        color="primary"
        *ngIf="!library && promotion"
        (click)="buy()">
        {{ buyText | trans}}
      </button>
      <a
        mat-raised-button
        color="primary"
        *ngIf="!library && !promotion"
        (click)="buy()">
        {{ buyText | trans}}
      </a>
      <button mat-button (click)="delete()" *ngIf="library && !selection" class="bt-close" ></button>
      <button mat-button (click)="repurchase()" *ngIf="expired" class="bt-expired" ></button>
    </div>
  </div>
</mat-list-item>


<div *ngIf="isRedesign" class="tone-list-container redesigned" [class.selected]="selected" [class.tone-selection]="selection" [class.library-item]="library">
  <div  class="tone-item-container">
    <div class="tone-info">
      <div class="tone-image-holder">
        <ng-container *ngIf="tone.id === shuffleParam ; else coverImage">
          <div class="shuffle-icon"></div>
        </ng-container>
        <ng-template #coverImage>
          <img *appLazy  [src]="tone.cover | image:imageSize:imageSize" />
        </ng-template>
        <app-play-button [tones]="[tone]"  (onPrelistenStarted)="onPrelistenStarted.emit($event)"></app-play-button>
      </div>
      <div *ngIf="tone.id !== shuffleParam " class="tone-details" [class.library]="library">
        <div>
          <p class="track-title">{{tone.title}}</p>
          <p class="artist">{{tone.artist}}</p>
        </div>
      <div *ngIf="!isMobile">
        <p class="expiry" *ngIf="library && expiryDate">{{ 'library.rbt.expiryDate' | trans | format:expiryDate }}</p>
        <p class="sms" *ngIf="!library && showSmsCode">{{'common.sms' | trans }}
          <span class="sms-code">
            <span *ngIf="!isMobile">{{ tone.numericShortCode }}</span>
            <a *ngIf="isMobile"
              [href]="'rbt.sms.link' | trans | format:smsLinkSeparator:tone.numericShortCode | bypassSecurityForUrl">
              {{ tone.numericShortCode }}
            </a>
          </span>
        </p>
      </div>
      </div>
      <div *ngIf="tone.id === shuffleParam" class="middle" [class.library]="library">
        <p class="track-title">{{ 'setting.rbt.shuffleButton.label' | trans }}</p>
      </div>
    </div>

    <div class="purchase-details">
      <p class="price" *ngIf="!hidePrice && !library && (tone.price !== undefined)">
       <app-price [tone]="tone" [isLoggedIn]="isLoggedIn" [profile]="profile"></app-price>
    </p>

      <button mat-raised-button color="primary" *ngIf="!library && promotion" (click)="buy()">
        {{ buyText | trans}}
      </button>
      <a mat-raised-button color="primary" *ngIf="!library && !promotion" (click)="buy()">
        {{ buyText | trans}}
      </a>
      <button [attr.data-test-id]="'delete-tone-button'" mat-button (click)="delete()" *ngIf="library && !selection" class="bt-close"></button>
      <button mat-button (click)="repurchase()" *ngIf="expired" class="bt-expired"></button>
    </div>
  </div>
</div>
