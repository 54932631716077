import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseNetworkService } from 'app/core/network/base-network.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Promotions, PromoItem } from 'app/model/promotions';
import UrlBuilder, { RequestMethod } from 'app/core/url-builder';
import log from 'app/core/logging/logger.service';
import { ClientConfig } from 'app/core/config/client-config';
import { BrowserService } from 'app/core/browser.service';

@Injectable()
export class PromoService extends BaseNetworkService {

  constructor(http: HttpClient, authService: AuthService, private browserService: BrowserService) {
    super(http, authService);
  }

  getPromotionById(id: string): Observable<Promotions> {
    const url = UrlBuilder.getPromotionByIdUrl(id);
    return this.sendObservableRequest(url, RequestMethod.Get);
  }

  extractData(res: HttpResponse<any>) {
    return res.body || {};
  }

  /**
   * Function to retrieve the first promotion compatible with the device
   * It searches through the subpromotions and returns the first promotion that is compatible with the device
   * If none of the promotions or subpromotions are compatible, it returns NULL
   *
   * @param promotion the promotion
   * @return the first promoItem that is compatible with the device or NULL in case non of the promotions are compatible
   */
  getPromotionCompatibleWithDevice(promotion: Promotions): PromoItem {
    const androidUrl = ClientConfig.homePromoPopupAndroidUrls.getRegExp();
    const iphoneUrl = ClientConfig.homePromoPopupIphoneUrls.getRegExp();
    for (const promoItem of promotion.item) {
      const isAndroidPromotion = androidUrl.test(promoItem.targetUrl);
      const isIphonePromotion = iphoneUrl.test(promoItem.targetUrl);
      if ((isAndroidPromotion && this.browserService.isAndroid())
       || (isIphonePromotion && this.browserService.isIphone())
       || (!isAndroidPromotion && !isIphonePromotion)) {
        return promoItem;
      }
    }

    log.info('Skipping to show the promotion ' + promotion.promotionId + ' because is not compatible with the device');
    return null;
  }
}
