import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  NgZone
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map, debounceTime, filter, mergeMap as mergeMap } from 'rxjs/operators';

import { SearchService } from 'app/search/search.service';
import { Suggestion } from 'app/model';
import log from 'app/core/logging/logger.service';
import { ClientConfig } from 'app/core/config/client-config';

import { PurchaseSources } from 'app/tracking/purchase.sources';
import { ExternalRouting } from 'app/core/external.routing';
import { BaseViewComponent } from '../base-view.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent extends BaseViewComponent implements OnInit {

  @Output() onSearch = new EventEmitter();

  searchFormControl = new FormControl();
  suggestions: Observable<Suggestion[]>;

  constructor(zone: NgZone, router: Router, private searchService: SearchService) {
      super(zone, router);
  }

  ngOnInit() {

    this.listenForSearchSuggestions();
  }


  public doSearch(keyword: String): void {
    log.info('Search keyword ' + keyword);
    this.onSearch.emit();

    keyword = keyword || '';
    keyword = keyword.trim();

    if (keyword !== '' && keyword.length > 0) {
      ExternalRouting.navigate(this.router, ['/search', { keyword: keyword }],
          false, // always ignore current purchase source
          PurchaseSources.Search, keyword.toString());
    }

  }

  public doSearchSuggestion(suggestion: Suggestion): void {
    this.doSearch(suggestion.searchTerm);
  }

  public clearSearchField() {
    this.searchFormControl.reset();
    // Reset existing search suggestions by ommitning an empty observable.
    this.suggestions = of([]);
    // After resetting the obserable set it again so that omits new suggestions.
    this.listenForSearchSuggestions();
  }

  private listenForSearchSuggestions() {
    if (ClientConfig.searchEnableSuggestions.getBoolean()) {

      this.suggestions = this.searchFormControl.valueChanges
      .pipe(
        debounceTime(100),
        filter(input => input.length > 0),
        mergeMap(input =>
            this.searchService
                .getSearchSuggestion(input)
                .pipe(map(suggestions => suggestions.suggestion))
        ));
    }
  }

}
